import styled from "@emotion/styled";
import Select from "@material-ui/core/Select";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const SelectInputWrapper = styled(Select)<StyledPropsWithTheme>`
  border: none;
  height: 38px;
  width: 200px;
  margin-left: 3px;
  & .MuiSelect-root {
    display: flex;
    align-items: center;
  }

  &.MuiInput-underline {
    &::before,
    ::after {
      display: none;
    }
  }
`;

export const Styled = {
  SelectInputWrapper,
};
