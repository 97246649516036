import { Marker } from "@react-google-maps/api";
// eslint-disable-next-line import/no-unresolved
import cluster from "@sdge-assets/cluster.svg";
import React from "react";

const Groups: React.FC<any> = ({ groups }) => {
  return (
    <>
      {groups?.map((item: any) => {
        return (
          <Marker
            position={{ lat: +item.latitude, lng: +item.longitude }}
            key={item.latitude}
            zIndex={20}
            icon={cluster}
            label={`${item.numberOfItems}`}
          ></Marker>
        );
      })}
    </>
  );
};
export default Groups;
