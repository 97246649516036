import React, { FC, useEffect, useState } from "react";
import { TabControlsPropsType, Tab } from "./TabControls.types";
import { Styled } from "./TabControls.styled";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { handleRouterChange } from "../../app/store/drawer/drawer.slice";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";

const TabControls: FC<TabControlsPropsType> = ({ tabs, isDrawer }) => {
  const { push } = useHistory();
  //
  const { pathname } = useLocation();
  const [value, setValue] = useState<number>(0);
  const { router } = useAppSelector(selectRightDrawer);
  const dispatch = useAppDispatch();

  const handleTabChange = (
    event: React.ChangeEvent<{ checked: boolean }>,
    value: number
  ): void => {
    setValue(value);
  };

  const handleTabClick = (tabPath: string) => {
    if (!isDrawer) {
      const parentPath: string = pathname.split("/")[1];
      const path: string = parentPath + tabPath;
      push("/" + path);
    } else {
      dispatch(handleRouterChange({ subPath: tabPath }));
    }
  };

  useEffect(() => {
    if (!isDrawer) {
      const subPath: string = pathname.split("/")[2];
      const selectedTab: Tab | undefined = tabs?.find(
        (tab: Tab) => tab.path === `/${subPath}`
      );
      setValue(selectedTab ? tabs?.indexOf(selectedTab) : 0);
    } else {
      const subPath: string = router.subPath ?? "";
      const selectedTab: Tab | undefined = tabs?.find(
        (tab: Tab) => tab.path === subPath
      );
      setValue(selectedTab ? tabs?.indexOf(selectedTab) : 0);
    }
  }, [pathname, router.subPath]);

  return (
    <Styled.TabControlsWrapper value={value} onChange={handleTabChange}>
      {tabs?.map(({ label, id, path }) => {
        return (
          <Styled.TabWrapper
            label={label}
            key={id}
            onClick={() => handleTabClick(path)}
          />
        );
      })}
    </Styled.TabControlsWrapper>
  );
};

export default TabControls;
