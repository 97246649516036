import { FC } from "react";
import { Styled } from "./Envcard.styled";
import { Box } from "@material-ui/core";

const Envcard: FC<any> = ({ active, cardInfo }) => {
  return (
    <Styled.CardWrapper active={active}>
      <Styled.Row bold>
        <Box component="span">{cardInfo.title}</Box>
      </Styled.Row>
      <Styled.Row>
        <Box component="span">Changed 90% Complete</Box>
        <Box component="span">{cardInfo.changed90complete}</Box>
      </Styled.Row>
      <Styled.Row>
        <Box component="span">Not Issued Passed 30 Days</Box>
        <Box component="span">{cardInfo.notIssuedPassed30Days}</Box>
      </Styled.Row>
      <Styled.Row>
        <Box component="span">Permits At Risk</Box>
        <Box component="span">{cardInfo.permitsAtRisk}</Box>
      </Styled.Row>
    </Styled.CardWrapper>
  );
};

export default Envcard;
