import layersControl from "../../assets/layersControl.svg";
import fullScreenControl from "../../assets/fullScreen.svg";

export const createLayersControl = () => {
  const controlDiv = document.createElement("div");
  const svg = document.createElement("img");
  svg.setAttribute("src", layersControl);
  controlDiv.appendChild(svg);
  controlDiv.style.margin = "6px";
  controlDiv.style.cursor = "pointer";
  return controlDiv;
};

export const createFullScreenControl = () => {
  const fullScreen = document.createElement("div");
  const svg = document.createElement("img");
  svg.setAttribute("src", fullScreenControl);
  fullScreen.appendChild(svg);
  fullScreen.style.margin = "6px";
  fullScreen.style.cursor = "pointer";
  return fullScreen;
};

export const createMapTypeSelect = () => {
  const mapTypeSelect = document.createElement("select");
  const mapTypes = ["hybrid", "roadmap", "satellite", "terrain "];
  mapTypes.forEach((item) => {
    const elem = document.createElement("option");
    elem.style.padding = "25px";
    elem.innerText = item;
    mapTypeSelect.appendChild(elem);
  });
  mapTypeSelect.style.border = "none";
  mapTypeSelect.style.outline = "none";
  mapTypeSelect.style.margin = "10px";
  mapTypeSelect.style.height = "40px";
  mapTypeSelect.style.textAlign = "center";
  mapTypeSelect.style.fontWeight = "500";
  mapTypeSelect.style.borderRadius = "2px";
  return mapTypeSelect;
};
