import { MarkerPropsType, MarkersObjectType, MarkerType } from "../Map.types";
import { InfoWindow, Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import greenActiveSvg from "../../../assets/greenActiveSvg.svg";
import greenMarker from "../../../assets/greenMarker.svg";
import centerActiveMarker from "../../../assets/centerActiveSvg.svg";
import { markersObject } from "../Map.constants";
import {
  handleChangeDrawerOptions,
  handleRouterChange,
} from "../../../app/store/drawer/drawer.slice";
import { useDispatch } from "react-redux";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

const Markers: React.FC<MarkerPropsType> = ({
  markers,
  activeMarker,
  setActiveMarker,
  activeConstructionPolesList,
}) => {
  const [infoBoxOpen, setInfoBoxOpen] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const issueTracker = pathname.split("/").includes("issue_tracker");
  const { filteringRules, activeRowId } = useAppSelector(
    (state) => state.customersSlice
  );

  const handleGetMarkerInfo = (item: MarkerType) => {
    setActiveMarker(item.poleId);
    if (item.overlapping) {
      setInfoBoxOpen(false);
    } else {
      setInfoBoxOpen(true);
    }

    const drawerRouterOptions = {
      ...(issueTracker
        ? { parentPath: RightDrawerParentPaths.CUSTOMER_TABLE }
        : {}),
      backArrow: false,
      prevPath: "/",
      newPath: "overlapping",
      subPath: "info",
    };
    const drawerOptions = {
      isOpen: true,
      issueId: item?.issueId ?? "",
      title: item.poleId,
      cardId: item.poleId,
    };
    dispatch(handleRouterChange(drawerRouterOptions));
    dispatch(handleChangeDrawerOptions(drawerOptions));
  };

  const getMarkerColor = (item: any) => {
    if (activeRowId === item.poleId) {
      return centerActiveMarker;
    }
    if (
      activeConstructionPolesList.length &&
      activeConstructionPolesList.includes(item.poleId)
    ) {
      return greenActiveSvg;
    }
    if (filteringRules.length) {
      return greenActiveSvg;
    }
    let image = activeMarker === item.poleId ? greenActiveSvg : greenMarker;
    markersObject.map((markerItem: MarkersObjectType) => {
      if (item.colorCodingEvent.split(",")[0] === markerItem.variant) {
        image =
          markerItem.markers[
            activeMarker === item.poleId ? "activeMarker" : "marker"
          ];
      }
    });
    return image;
  };

  return (
    <>
      {markers.map((item: MarkerType, index) => {
        return (
          <Marker
            position={{ lat: +item.latitude, lng: +item.longitude }}
            key={item.poleId + index}
            zIndex={20}
            onClick={() => handleGetMarkerInfo(item)}
            icon={getMarkerColor(item)}
          >
            {infoBoxOpen && activeMarker === item.poleId && (
              <InfoWindow
                position={{
                  lat: +item.latitude,
                  lng: +item.longitude + 0.000001,
                }}
              >
                <div style={{ color: "black" }}>
                  No overlapping and flights yet
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </>
  );
};

export default Markers;
