import { ButtonType } from "@sdge-utils/globalTypes";
import React, { FC } from "react";
import { Styled } from "./Button.styled";

const Button: FC<ButtonType> = ({
  title,
  icon: Icon,
  isActive,
  outlined,
  iconRight,
  onClick,
  ...rest
}) => {
  return (
    <Styled.Button
      isActive={isActive}
      iconRight={iconRight}
      onClick={onClick}
      outlined={outlined}
      {...rest}
    >
      {iconRight ? (
        <>
          {title}
          {Icon && <Icon />}
        </>
      ) : (
        <>
          {Icon && <Icon />}
          {title}
        </>
      )}
    </Styled.Button>
  );
};

export default Button;
