import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "../../utils/globalTypes";
import { Toolbar, Box, AppBar, IconButton, Drawer } from "@material-ui/core";
import { drawerWidth } from "./MainLayout.constants";
import { PageType } from "./MainLayout.types";

const MainDrawer = styled(Drawer)(
  {
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      height: "100%",
      justifyContent: "space-between",
    },
    width: drawerWidth,
    transition: "width 0.2s ease 0s",
  },
  ({ open }) => !open && { width: 0 }
);

const MenuButton = styled(IconButton)<StyledPropsWithTheme>`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const AppBarWrapper = styled(AppBar)<StyledPropsWithTheme<PageType>>`
  width: ${({ open }) => (open ? `calc(100% - ${drawerWidth}px)` : "100%")};
  transition: all 0.3s ease 0s;
`;

const LayoutWrapper = styled(Box)<StyledPropsWithTheme>`
  box-sizing: border-box;
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    width: 10px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const LayoutToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LayoutToolbarLeft = styled(Box)`
  display: flex;
  align-items: center;
`;

const MainIcon = styled(Box)`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > img {
    padding: 10px;
    height: 100%;
    object-fit: contain;
  }
`;

const DrawerTop = styled(Box)`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
`;

const Page = styled(Box)<StyledPropsWithTheme<PageType>>`
  width: ${({ open }) => (open ? `calc(100% - ${drawerWidth}px)` : "100%")};
  height: calc(100vh - 10px);
  transition: width 0.3s ease 0s;
`;

const OverLay = styled(Box)`
  margin-top: 50px;
`;

const AppName = styled(Box)<StyledPropsWithTheme>`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const TopBarActionWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const InfoBox = styled.div`
  margin-top: 50px;
  color: white;
  font-size: 40px;
`;

export const Styled = {
  LayoutWrapper,
  LayoutToolbar,
  MainIcon,
  DrawerTop,
  Page,
  OverLay,
  AppBarWrapper,
  MenuButton,
  MainDrawer,
  LayoutToolbarLeft,
  AppName,
  TopBarActionWrapper,
  InfoBox,
};
