import Button from "../Button";
import React, { useRef, FC } from "react";
import { AddIcon, CloseIcon } from "../../icons";

import { Styled } from "./AttachButton.styled";
import { Icon } from "react-feather";
import { AttachButtonPropsType } from "./AttachButton.types";
const AttachButton: FC<AttachButtonPropsType> = ({ onChange, value }) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const handleClick = () => {
    hiddenFileInput.current && hiddenFileInput.current.click();
  };
  const handleDelete = () => {
    onChange(null);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files && event.target.files[0];
    fileUploaded ? onChange(fileUploaded) : onChange(null);
  };
  const handleInput = (e: any) => {
    e.target.value = null;
  };
  return (
    <Styled.AttachButtonWrapper>
      <Button title="Attach file" icon={AddIcon} onClick={handleClick} />
      <Styled.FileItem>
        <Styled.FileName>{value?.name}</Styled.FileName>
        {value?.name && (
          <Button icon={CloseIcon as Icon} onClick={handleDelete} />
        )}
      </Styled.FileItem>
      <Styled.AttachInput
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        onClick={handleInput}
      />
    </Styled.AttachButtonWrapper>
  );
};

export default AttachButton;
