import React, { FC } from "react";
import { Box, FormControl } from "@mui/material";
import { Styled } from "./SelectSortItem.styled";
import { OptionsData, SelectSortItemTypes } from "./SelectSortItem.types";
import { useAppDispatch } from "../../../../app/hooks";
import {
  generateSortItem,
  itemSortRequst,
} from "../../../../utils/helpers/sortHelpers/sortHelpers";

const SelectSortItem: FC<SelectSortItemTypes> = ({
  currentField,
  currentSelect,
  optionsData,
  order,
  sort,
  filteringRules,
  searchInputValue,
  handleSort,
  sortData,
}) => {
  const dispatch = useAppDispatch();

  const changeValue = (value: string) => {
    dispatch(handleSort(generateSortItem(value, order, currentField, sort)));
    dispatch(
      sortData([
        0,
        itemSortRequst(value, order, currentField, sort, searchInputValue),
        filteringRules,
      ])
    );
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl>
        <Styled.SelectInputWrapper
          native
          defaultValue={currentSelect}
          onChange={(e) => changeValue(e.target.value as string)}
        >
          <option value="">{currentSelect}</option>
          {optionsData.map(({ id, value, label }: OptionsData) => (
            <option key={id} value={value}>
              {label}
            </option>
          ))}
        </Styled.SelectInputWrapper>
      </FormControl>
    </Box>
  );
};

export default SelectSortItem;
