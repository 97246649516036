import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MainTable from "./MainTable";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  handleLoadedPagesChange,
  handleSort,
  handleHiddenColumn,
  handlePinnedColumn,
  filterOptions,
  handleSearch,
  fieldsHiddenOptions,
  sortOptions,
  handleFilter,
  quickViewsOptions,
  handleColumnDefs,
  handleColumnOrder,
  handleSelectedRows,
  massUpdateOptions,
  handleChangeRequested,
  setActiveIssueId,
  handleSelectedRowsGroupNames,
  handleInputText,
} from "@sdge-store/construction/construction.slice";
import { DownloadLinks, RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import {
  handleChangeDrawerOptions,
  handleRouterChange,
} from "@sdge-store/drawer/drawer.slice";
import { GridOptions, SelectionChangedEvent } from "ag-grid-community";
import {
  generateColumnDefs,
  generateColumnOrder,
  generateExportBody,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { ConstructionMassUpdateOption } from "@sdge-components";
import ConstructionFilterOptionsMenu from "@sdge-components/Filtering/ConstructionFilterOptionsMenu";
import ConstructionHiddenOptionsMenu from "@sdge-components/HideOptionsMenu/ConstructionHiddenOptionsMenu";
import ConstructionQuickViewOptions from "@sdge-components/QuickViewsOptions/ConstructionQuickViewOptions";
import ConstructionSortOptionsMenu from "@sdge-components/Sorting/ConstructionSortOptionsMenu";
import {
  fetchConstructionData,
  getConstructionCustomersList,
  getConstructionOverlapping,
  getEnvPermitsConstruction,
  getFacilityIdList,
  // getFacilityIdList,
  getHoldStatus,
  getMuniPermitsConstruction,
  getSapTileContentByGroup,
  getStatusValues,
  sortConstructionData,
} from "@sdge-store/construction/construction.async";
import { ExportMenu } from "./components/ExportMenu";
import { Styled } from "./components/ExportMenu/ExportMenu.styled";
import {
  constructionCustomersFields,
  constructionCustomersHeaders,
  constructionEnvPermitsFields,
  constructionEnvPermitsHeaders,
  constructionInteractionsFields,
  constructionInteractionsHeaders,
  constructionMuniPermitsFields,
  constructionMuniPermitsHeaders,
  constructionSapFields,
  constructionSapHeaders,
  constructionStatusFields,
  constructionStatusHeaders,
  parcelFields,
  parcelHeaders,
  sapFieldsForInteractions,
  sapHeadersForInteractions,
} from "./components/ExportMenu/ExportMenu.constants";
import { filterHeaders, filterNames } from "./Table.utils";
import { useExport } from "./useExport";
import { constructionItemsDateConvert } from "@sdge-utils/helpers/gridHelpers/gridHelpers";
import { usePermission } from "@sdge-hooks";
import { getCoordinates } from "@sdge-store/Map/map.async";

const isThirdColumn = (params: any) => {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  const indexOfButton = displayedColumns.findIndex(
    (item: any) => item.maxWidth === 50
  );
  const thisIsFirstColumn = displayedColumns[indexOfButton] == params.column;
  return thisIsFirstColumn;
};
const ConstructionMainTable = () => {
  const exportFilteredFile = useExport("construction");
  const dispatch = useAppDispatch();
  const [_, setGridApi] = useState<any>(null);
  const [columnApi, setColumnApi] = useState<any>(null);
  const { getPermission } = usePermission();
  const hasPermissions = !getPermission("constractionMassUpdate");

  const {
    construction,
    totalElements,
    loading,
    page,
    sortingRules,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    totalPages,
    areQuickVeiwsOptionsOpen,
    selectedRows,
    areMassUpdateOptionsOpen,
    massUpdateItemsRequest,
    inputText,
    searchInputValue,
    filter,
    bmcdTierVisibility,
    selectedRowsGroupNames,
  } = useAppSelector(selectConstruction);

  const getRowData = ({ data }: any) => {
    const drawerRouterOptions = {
      parentPath: RightDrawerParentPaths.CONSTRUCTION_TABLE,
      backArrow: false,
      prevPath: "/",
      newPath: "overlapping",
      subPath: "info",
    };
    const drawerOptions = {
      isOpen: true,
      title: data.groupName || "",
      cardId: data.groupName || data.facilityId || "",
      numberOfPoles: data.numberOfPoles,
      issueId: data.id,
    };
    dispatch(handleRouterChange(drawerRouterOptions));
    dispatch(handleChangeDrawerOptions(drawerOptions));
    dispatch(getConstructionOverlapping(data.groupName));
    dispatch(getSapTileContentByGroup(data.groupName));
    dispatch(getConstructionCustomersList(data.groupName));
    dispatch(getConstructionOverlapping(data.groupName));
    dispatch(getEnvPermitsConstruction(data.groupName));
    dispatch(getMuniPermitsConstruction(data.groupName));
    dispatch(getStatusValues(data.groupName));
    dispatch(getCoordinates(data.groupName));
    // dispatch(getIssueTrackerCustomersList(data.id));
    dispatch(getFacilityIdList(data.groupName));
    // dispatch(getPermits(data.id));
    dispatch(
      setActiveIssueId({
        id: data.id,
        facilityId: data.facilityId,
        groupName: data?.groupName,
      })
    );
    dispatch(handleChangeRequested(true));
  };
  const selectionRef = useRef<any>(null);
  const getSelectedRowData = useCallback((params: SelectionChangedEvent) => {
    clearTimeout(selectionRef.current);
    selectionRef.current = setTimeout(() => {
      const selectedNodes = params.api.getSelectedNodes();
      const selectedRowsIndexes = selectedNodes.map((item) => item.rowIndex);
      const selectedRowsGroupNames = selectedNodes.map(
        (item) => item.data.groupName
      );
      dispatch(handleSelectedRowsGroupNames(selectedRowsGroupNames));
      dispatch(handleSelectedRows(selectedRowsIndexes));
    }, 300);
    return () => {
      clearTimeout(selectionRef.current);
    };
  }, []);

  const gridOptions: GridOptions = useMemo(
    () => ({
      suppressDragLeaveHidesColumns: true,
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
      suppressPropertyNamesCheck: true,
      overlayNoRowsTemplate:
        ' <img src="https://www.ag-grid.com/example-assets/loading.gif" />',
      suppressMenuHide: true,
      rowHeight: 40,
      onGridReady: (params) => {
        if (!loading) {
          params.columnApi.autoSizeAllColumns();
        }
        setGridApi(params.api);
        setColumnApi(params.columnApi);
      },
      onDragStopped: (params) => {
        const allColumns: any = params.columnApi.getAllGridColumns();
        dispatch(handleColumnDefs(generateColumnDefs(allColumns)));
        dispatch(
          handleColumnOrder(generateColumnOrder(allColumns, "construction"))
        );
      },
      rowSelection: "multiple",
      onRowClicked: (rowInfo) => getRowData(rowInfo),
      onSelectionChanged: (params) => getSelectedRowData(params),
      rowMultiSelectWithClick: true,
    }),
    []
  );

  const allFilteringCols = [...columnDefs];
  const buttonRef = useRef<any>(null);
  const [exportType, setExportType] = useState("grid");

  let exportRequest: DownloadLinks;
  let fileName: string;
  let body: any;
  const columnNames = columnDefs
    .filter((item: any) => item.field)
    .map((item: any) => {
      return item.field;
    });

  const headers = columnDefs
    .filter((item: any) => item.headerName)
    .map((item: any) => {
      return item.headerName;
    });

  switch (exportType) {
    case "parcel": {
      exportRequest = DownloadLinks.CONSTRUCTION_PARCEL;
      fileName = "construction_parcel";
      body = {
        filters: filter,
        searchText: searchInputValue.substring(12) || null,
        components: [
          {
            name: "construction",
            columns: filterNames(columnNames).filter(
              (item: string) => item !== "numberOfPolesReplacement"
            ),
            headers: filterHeaders(headers).filter(
              (item: string) => item !== "Number of Pole Replacements"
            ),
          },
          {
            name: "sap",
            columns: sapFieldsForInteractions,
            headers: sapHeadersForInteractions,
          },
          {
            name: "parcel",
            columns: parcelFields,
            headers: parcelHeaders,
          },
        ],
      };
      break;
    }
    case "permit": {
      exportRequest = DownloadLinks.CONSTRUCTION_PERMIT;
      fileName = "construction_permit";
      body = {
        filters: filter,
        searchText: searchInputValue.substring(12) || null,
        components: [
          {
            name: "construction",
            columns: filterNames(columnNames).filter(
              (item: string) => item !== "numberOfPolesReplacement"
            ),
            headers: filterHeaders(headers).filter(
              (item: string) => item !== "Number of Pole Replacements"
            ),
          },
          {
            name: "sap",
            columns: sapFieldsForInteractions,
            headers: sapHeadersForInteractions,
          },
          {
            name: "muni-permit",
            columns: constructionMuniPermitsFields,
            headers: constructionMuniPermitsHeaders,
          },
          {
            name: "env-permit",
            columns: constructionEnvPermitsFields,
            headers: constructionEnvPermitsHeaders,
          },
        ],
      };
      break;
    }
    case "gridAndCustomers": {
      exportRequest = DownloadLinks.CONSTRUCTION_CUSTOMERS;
      fileName = "construction_customers";
      body = {
        filters: filter,
        searchText: searchInputValue.substring(12) || null,
        components: [
          {
            name: "construction",
            columns: filterNames(columnNames),
            headers: filterHeaders(headers),
          },
          {
            name: "sap",
            columns: constructionSapFields,
            headers: constructionSapHeaders,
          },
          {
            name: "customer",
            columns: constructionCustomersFields,
            headers: constructionCustomersHeaders,
          },
        ],
      };
      break;
    }
    case "gridAndCustomersInteractions": {
      exportRequest = DownloadLinks.CONSTRUCTION_CUSTOMERS_INTERACTIONS;
      fileName = "construction_package_pole";

      body = {
        filters: filter,
        searchText: searchInputValue.substring(12) || null,
        components: [
          {
            name: "construction",
            columns: filterNames(columnNames),
            headers: filterHeaders(headers),
          },
          {
            name: "sap",
            columns: sapFieldsForInteractions,
            headers: sapHeadersForInteractions,
          },
          {
            name: "customer",
            columns: constructionCustomersFields,
            headers: constructionCustomersHeaders,
          },
          {
            name: "interaction",
            columns: constructionInteractionsFields,
            headers: constructionInteractionsHeaders,
          },
        ],
      };
      break;
    }
    case "grid":
    default: {
      exportRequest = DownloadLinks.CONSTRUCTION_GRID;
      fileName = "construction";
      body = {
        searchText: searchInputValue.substring(12) || null,
        filters: filter,
        components: [
          {
            name: "construction",
            columns: filterNames(columnNames, "grid"),
            headers: filterHeaders(headers, "grid"),
          },
          {
            name: "sap",
            columns: constructionSapFields,
            headers: constructionSapHeaders,
          },
          {
            name: "status",
            columns: constructionStatusFields,
            headers: constructionStatusHeaders,
          },
        ],
      };
      break;
    }
  }

  const handleExportClick = () => {
    buttonRef?.current?.click();
  };

  const handleExportSubmit = () => {
    dispatch(
      handleChangeSnackbarState({
        isOpen: true,
        alertColor: "success",
        message: "Download has started",
      })
    );
    const generatedExportBody = generateExportBody(
      allFilteringCols,
      filteringRules,
      bmcdTierVisibility
    );

    exportFilteredFile(
      exportRequest,
      {
        ...generatedExportBody,
        ...body,
        filters: bmcdTierVisibility
          ? body.filters
          : [
              ...body.filters,
              {
                fieldName: "bmcd_tier_3",
                negate: true,
                operation: "equals",
                relation: "AND",
                value: "Y",
              },
            ],
        ids: selectedRowsGroupNames,
      },
      fileName
    );
  };
  const rowItems = useMemo(
    () => constructionItemsDateConvert(construction),
    [construction]
  );
  const data = {
    items: rowItems,
    totalElements,
    loading,
    page,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    totalPages,
    sortingRules,
    areQuickVeiwsOptionsOpen,
    selectedRows,
    areMassUpdateOptionsOpen,
    massUpdateItemsRequest,
    inputText,
    MassUpdateOptions: ConstructionMassUpdateOption,
    FilterOptionsMenu: ConstructionFilterOptionsMenu,
    HideOptionsMenu: ConstructionHiddenOptionsMenu,
    QuickViewsOptions: ConstructionQuickViewOptions,
    SortOptionsMenu: ConstructionSortOptionsMenu,
    title: "Construction",
    hasPermissions,
  };
  const storeFunctions = {
    fieldsHiddenOptions,
    sortOptions,
    filterOptions,
    quickViewsOptions,
    handleSearch,
    handleLoadedPagesChange,
    sortData: sortConstructionData,
    handleHiddenColumn,
    handlePinnedColumn,
    handleSort,
    handleFilter,
    fetchData: fetchConstructionData,
    resetSelectedIds: handleSelectedRowsGroupNames,
    handleSelectedRows,
    handleInputText,
  };
  const options = {
    massUpdateOptions,
    gridOptions,
    storeSelector: selectConstruction,
    handleExportClick,
    columnApi,
    isThirdColumn,
  };

  useEffect(() => {
    dispatch(getHoldStatus());
  }, []);

  return (
    <>
      <Styled.ExportTypeContainer>
        <ExportMenu
          buttonRef={buttonRef}
          setExportType={setExportType}
          handleExportSubmit={handleExportSubmit}
        />
      </Styled.ExportTypeContainer>

      <MainTable
        data={data}
        storeFunctions={storeFunctions}
        options={options}
      />
    </>
  );
};

export default ConstructionMainTable;
