import React from "react";

const Flown = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="6"
        width="20"
        height="14"
        rx="2"
        stroke="#4AB670"
        strokeWidth="2"
      />
      <rect
        x="14"
        y="3"
        width="5"
        height="3"
        rx="1"
        stroke="#4AB670"
        strokeWidth="2"
      />
      <circle cx="12" cy="13" r="4" stroke="#4AB670" strokeWidth="2" />
    </svg>
  );
};

export default Flown;
