import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Styled } from "./DrawerUserInfo.styled";
import { DrawerUserInfoType } from "./DrawerUserInfo.types";
import { useHistory } from "react-router-dom";
import { StandardButton } from "../../components/index";
import { useAppDispatch } from "../../app/hooks";
import { logout } from "@sdge-store/user/user.async";

const DrawerUserInfo: FC<DrawerUserInfoType> = ({ fullName, email, src }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleLogOut = () => {
    dispatch(logout(history));
  };

  return (
    <Box>
      <Box>
        <StandardButton title="Logout" onClick={handleLogOut} />
      </Box>
      <Styled.DrawerUserInfoWrapper>
        <Styled.PersonalInfoWrapper>
          <Box>{fullName}</Box>
          <Styled.TextWrapper title={email}>{email}</Styled.TextWrapper>
        </Styled.PersonalInfoWrapper>
        <Styled.UserAvatar src={src} alt={fullName}>
          {fullName[0]}
        </Styled.UserAvatar>
      </Styled.DrawerUserInfoWrapper>
    </Box>
  );
};

export default DrawerUserInfo;
