const permissions: any = {
  circuit: { autoComplete: "circuit_id", access: "circuit" },
  tlId: { autoComplete: "tlid", access: "tlid" },
  mission: { autoComplete: "mission", access: "mission" },
  hftdTier: { autoComplete: "hftd", access: "hftd" },
  descope: { autoComplete: "descope", access: "descope" },
  descopeNotes: {
    autoComplete: "descope_notes",
    access: "descope_notes",
  },
  deprioritizeNotes: {
    autoComplete: "deprioritize_note",
    access: "deprioritize_note",
  },
};

export const Constants = { permissions };
