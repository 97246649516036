import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const Row = styled(Box)`
  padding: 15px;
`;

const NotificationDrawer = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  & .MuiTabs-root {
    margin: 0 20px;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.background.default};
  }
  & .MuiTabs-flexContainer {
    padding: 4px;
  }
  & .Mui-selected {
    background-color: ${({ theme }) =>
      theme.palette.tables?.checkbox?.checkedBackground};
    box-shadow: none;
  }
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 10px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.light} !important;
    opacity: 0.15;
  }
`;
const CellDrawerRouterWrapper = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  & .MuiTabs-root {
    margin: 0 20px;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.background.default};
  }
  & .MuiTabs-flexContainer {
    padding: 4px;
  }
  & .Mui-selected {
    background-color: ${({ theme }) =>
      theme.palette.tables?.checkbox?.checkedBackground};
    box-shadow: none;
  }
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 10px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.light} !important;
    opacity: 0.15;
  }
`;
export const Styled: any = {
  NotificationDrawer,
  CellDrawerRouterWrapper,
  Row,
};
