import { useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import qs from "qs";

export function useQsQueryParams<T extends Record<any, any>>(
  defaultParams: T = {} as T
): {
  params: T;
  setParams: (params: Partial<T>) => void;
  createLink: (params: T, link?: string) => string;
  deleteParams: (names: string[]) => void;
} {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const searchParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as unknown as T;

  const deleteParams = (names: string[]) => {
    const newParams = Object.keys(searchParams).reduce<Record<string, string>>(
      (result, key) => {
        if (names.includes(key)) {
          const { [key]: _, ...rest } = result;

          return rest;
        }

        return { ...result, [key]: searchParams[key] };
      },
      {}
    );

    if (Object.keys(newParams).length === 0) {
      history.replace(pathname);
    } else
      history.replace(
        qs.stringify(newParams, {
          encode: true,
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })
      );
  };

  const setParams = (params: Partial<T>) => {
    const allParams = { ...searchParams, ...params };

    const newParams = Object.entries(allParams).reduce(
      (result, [key, value]) => {
        return {
          ...result,
          ...(value !== "" && value !== null ? { [key]: value } : {}),
        };
      },
      {}
    );

    history.replace(
      qs.stringify(newParams, {
        encode: true,
        addQueryPrefix: true,
        arrayFormat: "repeat",
      })
    );
  };

  const createLink = (params: T, link?: string) => {
    const allParams = { ...searchParams, ...params };

    const newParams = Object.entries(allParams).reduce(
      (result, [key, value]) => {
        return { ...result, ...(value !== "" ? { [key]: value } : {}) };
      },
      {}
    );

    const search = qs.stringify(newParams, {
      encode: true,
      addQueryPrefix: true,
      skipNulls: true,
    });

    return link ? `${link}${search}` : search;
  };

  useEffect(() => {
    const notFoundParam = Object.keys(defaultParams).some(
      (item) => !search.includes(item)
    );

    if (notFoundParam) {
      history.replace(
        qs.stringify(
          { ...defaultParams, ...searchParams },
          { encode: true, addQueryPrefix: true }
        )
      );
    }
  });

  return {
    params: Object.entries(searchParams).reduce(
      (res, [key, value]) => ({
        ...res,
        [key]: Number.isNaN(Number(value)) ? value : Number(value),
      }),
      defaultParams
    ) as T,
    setParams,
    createLink,
    deleteParams,
  };
}
