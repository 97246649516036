import React, { FC } from "react";

const MenuDown: FC<any> = ({ isColor }) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.38411 5.53907C5.18421 5.77895 4.81579 5.77894 4.61589 5.53907L0.683409 0.820091C0.412023 0.494428 0.643601 -3.84617e-07 1.06752 -3.5004e-07L8.93248 2.91478e-07C9.3564 3.26055e-07 9.58798 0.494429 9.31659 0.820093L5.38411 5.53907Z"
        fill={isColor ? "#ffffff" : "#8C8F9B"}
      />
    </svg>
  );
};

export default MenuDown;
