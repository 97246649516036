import { Box, LinearProgress, RadioGroup } from "@material-ui/core";
import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const ModalContainer = styled(Box)<StyledPropsWithTheme>`
  overflow-y: auto;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 8px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.dark} !important;
    opacity: 0.15;
  }
`;

const RadioGroupList = styled(RadioGroup)<StyledPropsWithTheme>`
  overflow-y: auto;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 5px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    opacity: 0.15;
  }
`;

const ProgressWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
`;
const LinearPogressWrapper = styled(LinearProgress)`
  margin-right: 20px;
  margin-left: 20px;
`;
export const Styled = {
  ModalContainer,
  ProgressWrapper,
  LinearPogressWrapper,
  RadioGroupList,
};
