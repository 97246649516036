import { createSlice } from "@reduxjs/toolkit";
import { Constants } from "./overllaps.constants";
import { OverlapsSliceState } from "./overlaps.types";
import {
  addOverlapsQuickView,
  deleteQuickView,
  fetchOverlapsData,
  fetchOverlapsQuickViews,
  filterOverlapsData,
  sortOverlapsData,
  updateOverlapsQuickView,
} from "@sdge-store/overlaps/overlaps.async";
import {
  getHidddenColumns,
  getPinnedColumns,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";

const initialOverlapsState: OverlapsSliceState = {
  overlapping_programs: [],
  columnOrder: Constants.columnsOrder,
  initialColumnDefs: Constants.columnDefs,
  initialColumnsOrder: Constants.columnsOrder,
  columnDefs: Constants.columnDefs,
  hiddenColumns: [],
  frozenColumns: [],
  selectedRows: [],
  quickViews: [],
  selectedQuickVewName: "",
  selectedQuickViewPrivacy: false,
  loading: false,
  page: 0,
  totalElements: 0,
  loadedElements: 0,
  totalPages: 1,
  isLast: false,
  isPaginated: true,
  isLoading: true,
  areHiddenFieldsOpen: false,
  areSortOptionsOpen: false,
  areFilterOptionsOpen: false,
  areQuickVeiwsOptionsOpen: false,
  isQuickVeiwsModalOpen: false,
  sortingRules: "",
  sort: [],
  filteringRules: [],
  filter: [],
  searchInputValue: "",
  selectedQuickViewId: "",
  inputText: "",
};

const overlapsSlice = createSlice({
  name: "overlapping_programs",
  initialState: initialOverlapsState,
  reducers: {
    handleColumnDefs: (state, action) => {
      state.columnDefs = action.payload;
    },
    handleColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    handlePrivacy: (state, action) => {
      state.selectedQuickViewPrivacy = action.payload;
    },
    handleQuickViewId: (state, action) => {
      state.selectedQuickViewId = action.payload;
    },
    handleLoadedPagesChange: (state) => {
      if (state.page < state.totalPages && state.isLoading) {
        state.isPaginated = true;
        state.page = state.page + 1;
      }
    },
    handleCurrentPage: (state, action) => {
      state.page = action.payload;
    },
    handleHiddenColumn: (state, action) => {
      const foundIndex = state.columnDefs.findIndex(
        (item) => item.field === action.payload
      );
      if (state.columnDefs[foundIndex].hide === true) {
        state.columnDefs[foundIndex].hide = false;
      } else {
        state.columnDefs[foundIndex].hide = true;
      }
      state.hiddenColumns = getHidddenColumns(state.columnDefs);
    },
    handlePinnedColumn: (state, action) => {
      const foundIndex = state.columnDefs.findIndex(
        (item) => item.field === action.payload
      );
      if (state.columnDefs[foundIndex].pinned === null) {
        state.columnDefs[foundIndex].pinned = "left";
      } else {
        state.columnDefs[foundIndex].pinned = null;
      }
      state.frozenColumns = getPinnedColumns(state.columnDefs);
    },
    handleSort: (state, action) => {
      state.sort = action.payload;
    },
    handleFilter: (state, action) => {
      state.filter = action.payload;
    },
    handleFilteringRules: (state, action) => {
      state.filteringRules = action.payload;
    },
    fieldsHiddenOptions: (state, action) => {
      state.areHiddenFieldsOpen = action.payload;
    },
    sortOptions: (state, action) => {
      state.areSortOptionsOpen = action.payload;
    },
    filterOptions: (state, action) => {
      state.areFilterOptionsOpen = action.payload;
    },
    quickViewsOptions: (state, action) => {
      state.areQuickVeiwsOptionsOpen = action.payload;
    },
    handleQuickViewsModal: (state, action) => {
      state.isQuickVeiwsModalOpen = action.payload;
    },
    handleSearch: (state, action) => {
      state.searchInputValue = action.payload;
    },
    handleColumnChoosen: (state, action) => {
      state.quickViews = action.payload;
    },
    handleQuickViewsParams: (state, action) => {
      state.columnDefs = action.payload.columnDefs;
      state.hiddenColumns = action.payload.hiddenColumns;
      state.frozenColumns = action.payload.frozenColumns;
      state.columnOrder = action.payload.columnOrder;
      state.sort = action.payload.sort;
      state.filter = action.payload.filter;
      state.selectedQuickVewName = action.payload.selectedName;
      state.filteringRules = action.payload.filteringRules;
    },
    handleSelectedRows: (state, action) => {
      if (action.payload.length < state.selectedRows.length) {
        state.selectedRows = action.payload;
      } else {
        state.selectedRows = [
          ...(new Set([...state.selectedRows, ...action.payload]) as any),
        ];
      }
    },
    handleInputText: (state, action) => {
      state.inputText = action.payload;
    },
    handleClearOverlappingGrid: (state, action) => {
      if (action.payload !== "/overlapping") {
        state.overlapping_programs = [];
      }
    },
  },
  extraReducers: {
    [fetchOverlapsData.fulfilled]: (state: OverlapsSliceState, action: any) => {
      if (action.payload) {
        if (state.page === 0) {
          state.overlapping_programs = action.payload.content;
          state.loadedElements = action.payload.elementsOnPage;
        } else {
          if (action.payload.isQuickViews) {
            state.overlapping_programs = action.payload.content;
            state.loadedElements = action.payload.elementsOnPage;
            state.page = 0;
          } else {
            state.overlapping_programs = [
              ...state.overlapping_programs,
              ...action.payload.content,
            ];
            state.loadedElements =
              state.loadedElements + action.payload.elementsOnPage;
          }
        }
        state.sortingRules = action.payload.sortingRules;
        state.totalElements = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
      }
      state.isLoading = true;
    },
    [fetchOverlapsData.pending]: (state) => {
      state.isLoading = false;
    },
    [sortOverlapsData.fulfilled]: (state: OverlapsSliceState, action: any) => {
      if (action.payload) {
        state.overlapping_programs = action?.payload?.content;
        state.loadedElements = action.payload?.elementsOnPage;
        state.totalElements = action.payload.totalElements;
        state.page = action.payload.pageNumber;
        state.totalPages = action.payload.totalPages;
        state.sortingRules = action.payload?.sortingRules;
      }
      state.loading = false;
      state.isPaginated = false;
    },
    [sortOverlapsData.pending]: (state: OverlapsSliceState) => {
      state.loading = true;
    },
    [sortOverlapsData.rejected]: (state: OverlapsSliceState) => {
      state.loading = false;
    },
    [filterOverlapsData.fulfilled]: (
      state: OverlapsSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.overlapping_programs = action.payload?.content;
        state.loadedElements = action.payload?.elementsOnPage;
        state.sortingRules = action.payload?.sortingRules;
        state.page = action.payload.pageNumber;
        state.totalElements = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
      }
      state.loading = false;
      state.isPaginated = false;
    },
    [filterOverlapsData.pending]: (state: OverlapsSliceState) => {
      state.loading = true;
    },
    [filterOverlapsData.rejected]: (state: OverlapsSliceState) => {
      state.loading = false;
    },
    [fetchOverlapsQuickViews.fulfilled]: (
      state: OverlapsSliceState,
      action: any
    ) => {
      state.quickViews = action.payload.map((item: any) => {
        return { ...item, selected: false };
      });
      state.loading = false;
    },
    [fetchOverlapsQuickViews.pending]: (state: OverlapsSliceState) => {
      state.loading = true;
    },
    [addOverlapsQuickView.fulfilled]: (
      state: OverlapsSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.quickViews = [...state.quickViews, action.payload].sort(
          (a: any, b: any) =>
            a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: "base",
            })
        );
      }
    },
    [updateOverlapsQuickView.fulfilled]: (
      state: OverlapsSliceState,
      action: any
    ) => {
      if (action.payload) {
        const newquickViews = state.quickViews.map((item) => {
          if (item) {
            if (item.id === action.payload.id) {
              return { ...action.payload, selected: true };
            } else {
              return item;
            }
          }
        });
        state.quickViews = newquickViews;
      }
    },
    [deleteQuickView.fulfilled]: (state: OverlapsSliceState, action: any) => {
      state.quickViews = state.quickViews
        .filter((item) => item.id !== action.payload)
        .filter((item) => {
          if (item.name === state.selectedQuickVewName) {
            return { ...item, selected: true };
          } else {
            return { item };
          }
        });
    },
  },
});

export const {
  handleColumnDefs,
  handleLoadedPagesChange,
  handleCurrentPage,
  handleHiddenColumn,
  handlePinnedColumn,
  handleSort,
  handleFilter,
  handleFilteringRules,
  fieldsHiddenOptions,
  filterOptions,
  sortOptions,
  handleSearch,
  quickViewsOptions,
  handleColumnOrder,
  handleQuickViewsModal,
  handleColumnChoosen,
  handleQuickViewsParams,
  handleSelectedRows,
  handlePrivacy,
  handleQuickViewId,
  handleInputText,
  handleClearOverlappingGrid,
} = overlapsSlice.actions;
export default overlapsSlice.reducer;
