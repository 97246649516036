import { usePermission } from "@sdge-hooks";
import { selectAutoComplete } from "@sdge-store/autoComplete/autoComplete.selector";
import { dataTransforming } from "@sdge-utils/dataTransform";
import { generateUniqueValues } from "@sdge-utils/helpers/editHelpers/editHelpers";
import { useMemo } from "react";
import { useAppSelector } from "../../../../../../app/hooks";
import { Constants } from "./Construction.constants";
import { selectUser } from "@sdge-store/user/user.selector";
import { Constants as UserConstants } from "@sdge-store/user/constants/user.constants";

const useConstructionInfoContent = () => {
  const { uniqueValues } = useAppSelector(selectAutoComplete);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  const { roles } = useAppSelector(selectUser);

  const isKpE2ConstRole =
    roles.includes(UserConstants.roleKpE2ConstDev) ||
    roles.includes(UserConstants.roleKpE2ConstProd);

  const transformedUniqueValues: { [key: string]: string } = useMemo(() => {
    return dataTransforming(uniqueValues);
  }, [uniqueValues]);

  const { getPermission } = usePermission();

  const uniqueBallInCourt = generateUniqueValues(
    transformedUniqueValues.ballincourt,
    Constants.ballInCourt
  );

  const uniqueStatus = generateUniqueValues(
    transformedUniqueValues.status,
    Constants.status
  );

  const constructionInfo = {
    groupName: { title: "Group Name" },
    ballInCourt: {
      title: "Ball In Court",
      optionsData: uniqueBallInCourt ?? Constants.ballInCourt,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("ballInCourt");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "ballincourt",
    },
    midpoint: {
      title: "Mid-Point Coordinates",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("midpoint");
        }

        return !getPermission("readonly");
      })(),
    },
    procoreLink: { title: "Procore Link", isLink: true },
    kmzLink: { title: "KMZ Link", isLink: true },
    eng: { title: "Group Classification" },
    priority: { title: "Group Priority" },
    projectNumber: {
      title: "Project Number",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("project_number");
        }

        return !getPermission("readonly");
      })(),
    },
    jobNumber: {
      title: "Job Number",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("job_number");
        }

        return !getPermission("readonly");
      })(),
    },
    overhead: {
      title: "Overhead",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("overhead");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "overhead",
    },
    underground: {
      title: "Underground",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("underground");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "underground",
    },
    numberOfPoles: { title: "Number of Poles" },
    numberOfPolesReplacement: { title: "Number Of Poles Replacements" },
    jucfRequired: {
      title: "JUCF Required",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("jucfRequired");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "jucfrequired",
    },
    projectedIfc: {
      title: "Projected Design Complete",
    },
    forecastedIfc: {
      title: "Closeout Date",
      isEdit: true,
      permission: isKpE2ConstRole ? false : true,
      isDate: true,
    },
    issueStatus: {
      title: "Status",
      optionsData: uniqueStatus,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("issue_status");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "status",
    },
    pidsUpload: {
      title: "PIDS Upload",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("pidsupload");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "pidsupload",
    },
    bmcdTier3: {
      title: "BMCD Tier-3",
      autoComplete: "bmcdTier3",
    },
    actualIfc: {
      title: "Actual IFC",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("actualIfc");
        }

        return !getPermission("readonly");
      })(),
      isDate: true,
    },
    designCompletedAccepted: {
      title: "Design Completed Accepted",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("design_completed_accepted");
        }

        return !getPermission("readonly");
      })(),
    },
    onHoldStatus: {
      title: "On Hold Status",
      optionsData: Constants.onHoldStatus,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("on_hold_status");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "onholdstatus",
    },

    onHoldStatusComment: {
      title: "Comments",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("on_hold_status_comment");
        }

        return !getPermission("readonly");
      })(),
    },
  };

  return {
    constructionContent: constructionInfo as typeof constructionInfo & {
      [key: string]: any;
    },
    itemsOrder: Object.keys(constructionInfo),
  };
};

export default useConstructionInfoContent;
