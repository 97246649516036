import { StyledPropsWithTheme } from "./../../utils/globalTypes";
import { Box } from "@material-ui/core";
import styled from "@emotion/styled";

const Table = styled(Box)<StyledPropsWithTheme>`
  height: 100%;
  width: 100%;
  position: relative;
  .ag-root-wrapper {
    background: ${({ theme }) => theme.palette.primary.main};
    border-top: none;
    border-right: none;
    border-left: none;
  }
  .ag-root ::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }
  .ag-root ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) =>
      theme.palette.tables?.scrollBar?.thumb}30 !important;
    border: 1px solid
      ${({ theme }) => theme.palette.tables?.scrollBar?.thumbBorder}30;
  }
  .ag-header-viewport {
    background: ${({ theme }) => theme.palette.primary.main};
  }
  .ag-pinned-left-header {
    background: ${({ theme }) => theme.palette.primary.main};
  }
  div.ag-row-selected {
    background: ${({ theme }) => theme.palette.tables?.rowSelected?.main};
  }
  div.ag-row-focus {
    background: ${({ theme }) => theme.palette.tables?.rowOnHover?.main};
  }
  .ag-row {
    background: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      background: ${({ theme }) => theme.palette.tables?.rowOnHover?.main};
    }
  }
  .ag-checkbox-input-wrapper {
    background: ${({ theme }) => theme.palette.primary.main};
  }
  .ag-checkbox-input-wrapper.ag-checked::after {
    color: ${({ theme }) => theme.palette.tables?.checkbox?.checkedBackground};
  }
  .ag-checkbox-input-wrapper.ag-checked {
    background-color: ${({ theme }) =>
      theme.palette.tables?.checkbox?.checkedColor};
  }
  .ag-cell-value {
    color: ${({ theme }) => theme.palette.text.disabled};
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  }
  .ag-header-cell-text {
    justify-text: left;
    color: ${({ theme }) => theme.palette.tables?.header?.default};
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  }
  div.ag-selection-checkbox.ag-invisible {
    margin-right: 0;
    width: 0;
  }
  .ag-overlay-wrapper {
    background: ${({ theme }) => theme.palette.primary.main};
  }
  .ag-overlay-loading-center {
    background: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0 1px 20px 1px white;
  }
  .ag-selection-checkbox,
  .ag-header-select-all {
    width: 30px;
  }
  .ag-header-icon {
    svg {
      fill: black;
      color: black;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .ag-menu {
    background: none;
    border: 0px;
  }
  .ag-popup-child:not(.ag-tooltip-custom) {
    box-shadow: none;
  }
`;

export const Styled = { Table };
