import { createSlice } from "@reduxjs/toolkit";
import { ProgramsSliceState } from "@sdge-store/programs/programs.types";
import { fetchProgramsData } from "@sdge-store/programs/programs.async";

const ProgramsInitialState: ProgramsSliceState = {
  programs_summary: [],
};

const programsSlice = createSlice({
  name: "programs_summary",
  initialState: ProgramsInitialState,
  reducers: {},
  extraReducers: {
    [fetchProgramsData.fulfilled]: (state, { payload }) => {
      state.programs_summary = payload;
    },
  },
});

export default programsSlice.reducer;
