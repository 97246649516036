import {
  CityIcon,
  CustomersIcon,
  FlightIcon,
  OverlappingIcon,
} from "../../icons";

export const menuItems = Object.freeze([
  { text: "Overlapping", icon: OverlappingIcon, path: "/overlapping" },
  // {
  //   text: "Flight Operations",
  //   icon: PreflightIcon,
  //   path: "/flight_operations",
  // },
  {
    text: "Issue Tracker",
    icon: FlightIcon,
    path: "/issue_tracker",
  },
  // {
  //   text: "Env & Permitting",
  //   icon: EnvironmentalIcon,
  //   path: "/environmental",
  // },
  { text: "Customer Outreach", icon: CustomersIcon, path: "/customers" },
  {
    text: "Construction",
    icon: CityIcon,
    path: "/construction",
  },
  // { text: "Settings", icon: SettingsIcon, path: "/settings" },
]);

export type ItemTypes = typeof menuItems;
