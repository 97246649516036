import { usePermission } from "@sdge-hooks";
import { selectAutoComplete } from "@sdge-store/autoComplete/autoComplete.selector";
import { dataTransforming } from "@sdge-utils/dataTransform";
import { generateUniqueValues } from "@sdge-utils/helpers/editHelpers/editHelpers";
import { useMemo } from "react";
import { useAppSelector } from "../../../../../../app/hooks";
import { Constants } from "./IssueTrackerInfo.constants";

const useIssueTrackerContent = (isChecked?: boolean) => {
  const { uniqueValues } = useAppSelector(selectAutoComplete);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  const transformedUniqueValues: { [key: string]: string } = useMemo(() => {
    return dataTransforming(uniqueValues);
  }, [uniqueValues]);
  const { ballInCourt, assigned } = programInfo;

  const { getPermission } = usePermission();

  const isVegManagement = ballInCourt === "Veg Management";

  const isCityWorks = ballInCourt === "City Works";

  const isEro =
    ballInCourt === "ERO" ||
    assigned === "ERO" ||
    ballInCourt === "CMP" ||
    assigned === "CMP";

  const uniqueClassification = generateUniqueValues(
    transformedUniqueValues.classification,
    Constants.additionalClassification
  );
  const uniqueBallInCourt = generateUniqueValues(
    transformedUniqueValues.ballincourt,
    Constants.ballInCourt
  );
  const uniqueAssigned = generateUniqueValues(
    transformedUniqueValues.assigned,
    Constants.assigned
  );
  const uniqueStatus = generateUniqueValues(
    transformedUniqueValues.status,
    Constants.status
  );

  const changedUniqueAssigned = uniqueAssigned.map((item: any) => {
    if (item.id === "CS") {
      return Constants.changeAssigned;
    }
    return item;
  });

  const issueTrackerInfo = {
    approvedDate: { title: "Approved Date" },
    ballInCourt: {
      title: "Ball In Court",
      optionsData: uniqueBallInCourt ?? Constants.ballInCourt,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("ballincourt");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "ballincourt",
    },
    poleReplacement: {
      title: "Pole Replacement",
      optionsData:
        transformedUniqueValues.pole_replacement ?? Constants.poleReplacement,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("pole_replacement");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "polereplacement",
    },
    crossarmReplacement: {
      title: "Crossarm Replacement",
      optionsData:
        transformedUniqueValues.crossarm_replacement ??
        Constants.crossarmReplacement,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("crossarm_replacement");
        }

        return !getPermission("readonly");
      })(),
      autoComplete: "crossarmReplacement",
    },
    numberOfPoles: {
      title: "No. of Poles per Group",
    },
    conditionCodeId: { title: "Condition Code Id" },
    assessmentReportURL: {
      title: "Assessment Report URL",
      isEdit: true,
      autoComplete: "automated_assessment_report_location",
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (isChecked) {
          return false;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("automated_assessment_report_location");
      })(),
    },
    description: {
      title: "Description",
    },
    groupName: {
      title: "Group Name",
      isEdit: programInfo.bmcdTier3 !== "Y",
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (isChecked) {
          return false;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("group_name");
      })(),
      autoComplete: "group_name",
    },
    bmcdTier3: {
      title: "BMCD Tier-3",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (isChecked) {
          return false;
        }

        return !getPermission("bmcdTier3");
      })(),
      autoComplete: "bmcdTier3",
    },
    initiationCompleteDate: {
      title: "Initiation Complete Date",
      isDate: true,
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (isChecked) {
          return false;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("e2_initiation_complete_date");
      })(),
    },
    classification: {
      title: "Classification",
      optionsData: uniqueClassification ?? Constants.classification,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (isChecked) {
          return false;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("classification");
      })(),
      autoComplete: "classification",
    },
    kmzURL: {
      title: "KMZ URL",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (isChecked) {
          return false;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("kmz_link");
      })(),
      autoComplete: "kmz_link",
    },
    assigned: {
      title: "Assigned",
      optionsData: changedUniqueAssigned ?? Constants.assigned,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (isChecked) {
          return false;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("assigned");
      })(),
    },
    status: {
      title: "Status",
      optionsData: getPermission("_status")
        ? Constants.cityWorksStatus
        : uniqueStatus,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (getPermission("_status")) {
          return !isCityWorks;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("status");
      })(),
    },
    identifiedDate: {
      title: "Issue Identified Date",
      isDate: true,
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (getPermission("identifiedDate")) {
          return !isEro;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly"); // : getPermission("identifiedDate") && getPermission("issue_E2_End")
        }

        return !getPermission("issue_identified_date ");
      })(),
    },
    resolvedDate: {
      title: "Issue Resolved Date",
      isDate: true,
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 === "Y") {
          return true;
        }

        if (
          getPermission("resolvedDate") &&
          isEro &&
          programInfo.bmcdTier3 !== "Y"
        ) {
          return false;
        }

        if (programInfo.bmcdTier === "Y") {
          return !getPermission("readonly");
        }

        if (getPermission("cityworks_resolved_date")) {
          return !isCityWorks;
        }

        if (getPermission("veg_resolved_date")) {
          return !isVegManagement;
        }

        return !getPermission("issue_resolved_date");
      })(),
    },
    vets: {
      title: "Vets",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (getPermission("vets_#")) {
          return !(getPermission("vets_#") && isVegManagement);
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("vets");
      })(),
    },
    forecastedVegClearDate: {
      title: "Forecast Veg Clear Date",
      isDate: true,
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (getPermission("forecasted_veg_clear_date")) {
          return !(
            getPermission("forecasted_veg_clear_date") && isVegManagement
          );
        }

        if (programInfo.bmcdTier3 === "Y") {
          return !getPermission("readonly");
        }

        return !getPermission("forecasted_clear_date");
      })(),
    },
    type: {
      title: "Type",
      isEdit: true,
      permission: (() => {
        if (getPermission("readonly")) {
          return true;
        }

        if (programInfo.bmcdTier3 !== "Y") {
          return !getPermission("type");
        }

        return !getPermission("readonly");
      })(),
    },
  };

  return {
    issueTrackerContent: issueTrackerInfo as typeof issueTrackerInfo & {
      [key: string]: any;
    },
    itemsOrder: Object.keys(issueTrackerInfo),
  };
};

export default useIssueTrackerContent;
