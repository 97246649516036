import styled from "@emotion/styled";
import { isActiveType, StyledPropsWithTheme } from "../../utils/globalTypes";

const EnvironmentalLogo = styled.svg<StyledPropsWithTheme<isActiveType>>`
  path {
    fill: ${({ theme, isActive }) =>
      isActive ? "#fff" : theme.palette.text.secondary};
  }
`;

export const Styled = { EnvironmentalLogo };
