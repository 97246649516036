import { IconButton } from "@material-ui/core";
import {
  Button,
  TextInputWithController,
  DatePickerInput,
} from "@sdge-components";
import React, { useState, FC } from "react";
import { Icon } from "react-feather";
import { EditIcon, CloseIcon } from "../../../../../../icons";
import { Styled } from "./EditInput.styled";

const EditInput: FC<any> = ({
  defaultValue,
  name,
  control,
  onSubmit,
  permission,
  isDate,
  reset,
  autoComplete,
}) => {
  const [isEditable, setIsEditable] = useState(true);

  const setEditable = () => {
    setIsEditable((prev) => !prev);
    if (isEditable === false && onSubmit) {
      onSubmit();
    }
  };

  const emptyField = () => {
    reset(name);
  };

  if (permission) {
    return (
      <Styled.DefaultValueWrapper>{defaultValue}</Styled.DefaultValueWrapper>
    );
  }
  return (
    <Styled.EditableWrapper>
      {isDate && !isEditable ? (
        <DatePickerInput
          control={control}
          name={name}
          defaultValue={defaultValue}
          readOnly
        />
      ) : (
        <TextInputWithController
          name={name}
          control={control}
          defaultValue={defaultValue}
          isDisabled={isEditable}
          autoComplete={autoComplete}
          isDate={isDate}
        />
      )}
      {isDate && !isEditable && (
        <IconButton onClick={emptyField}>
          <CloseIcon size={10} />
        </IconButton>
      )}
      <Button icon={EditIcon as Icon} onClick={setEditable} />
    </Styled.EditableWrapper>
  );
};

export default EditInput;
