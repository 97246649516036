import React from "react";
import { Switch } from "react-router";
import { BrowserRouter, Route } from "react-router-dom";
import { Page404 } from "../../pages";
import { routes } from "./routes";
import { flatten } from "./routes.utils";

const Router: React.FC = () => {
  const generatedRoutes = flatten(routes);
  return (
    <BrowserRouter>
      <Switch>
        {generatedRoutes.map(
          ({ path, layout: Layout, component: Component }) => (
            <Route
              key={path}
              path={path}
              exact
              strict={false}
              render={(props) => <Layout>{<Component {...props} />}</Layout>}
            />
          )
        )}
        <Route render={() => <Page404 />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
