import { TileSection } from "../TileSection";
import { Box, Grid } from "@mui/material";
import React from "react";
import RowItem from "./RowItem";
import useStatusTileContent from "./useStatusTileContent";
import { Typography } from "@material-ui/core";

const StatusTileContent = () => {
  const contentItems = useStatusTileContent();

  return (
    <>
      {contentItems.length ? (
        <>
          {contentItems?.map((item: any, i: number) => {
            return (
              <Box key={i}>
                <TileSection title="" />
                {item.map(({ id, title, content, name, date }: any) => {
                  return (
                    <RowItem
                      title={title}
                      key={id}
                      content={content}
                      name={name}
                      date={date}
                    />
                  );
                })}
              </Box>
            );
          })}
        </>
      ) : (
        <Grid container justifyContent="center">
          <Box mt="20%" width="70%">
            <Typography align="center">No Data</Typography>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default StatusTileContent;
