import { Dispatch } from "@reduxjs/toolkit";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { SnackbarDataTypes } from "@sdge-store/snackbar/snackbar.types";
import { AxiosError } from "axios";
import { removeAllTokens } from "@sdge-hooks/useAuth/useAuth.utils";
import { paths } from "@sdge-utils/routes";

const errorHandler = (error: AxiosError, dispatch: Dispatch) => {
  if (error.response) {
    switch (error.response.status) {
      case 508: {
        const errorData: SnackbarDataTypes = {
          isOpen: true,
          alertColor: "warning",
          message:
            "Other user or process is using the requested resource. Try again in a couple of minutes.",
        };
        dispatch(handleChangeSnackbarState(errorData));
        break;
      }
      case 403: {
        const errorData: SnackbarDataTypes = {
          isOpen: true,
          alertColor: "error",
          message: "Please try again",
        };
        dispatch(handleChangeSnackbarState(errorData));
        break;
      }
      case 401: {
        const errorData: SnackbarDataTypes = {
          isOpen: true,
          alertColor: "error",
          message: "Your token is expired please login again",
        };
        dispatch(handleChangeSnackbarState(errorData));
        removeAllTokens();
        window.location.replace(`/${paths.login}`);
        break;
      }
      case 400: {
        const errorData: SnackbarDataTypes = {
          isOpen: true,
          alertColor: "error",
          message: "Bad Request",
        };
        dispatch(handleChangeSnackbarState(errorData));
        break;
      }
      default: {
        const errorData: SnackbarDataTypes = {
          isOpen: true,
          alertColor: "error",
          message: error.response.data.message || error.response.data.error,
        };
        dispatch(handleChangeSnackbarState(errorData));
      }
    }
  } else {
    const errorData: SnackbarDataTypes = {
      isOpen: true,
      alertColor: "warning",
      message:
        "Other user or process is using the requested resource. Try again in a couple of minutes.",
    };
    dispatch(handleChangeSnackbarState(errorData));
  }
};

export default errorHandler;
