import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../../../utils/globalTypes";

const FilterOptionsWrapper = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

const PickerWrapper = styled.form`
  width: 170px;
  margin-top: 10px;
`;

export const Styled = { FilterOptionsWrapper, PickerWrapper };
