import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../../../utils/globalTypes";

const Row = styled(Box)<{ gap?: boolean }>`
  display: flex;
  gap: ${({ gap }) => (gap ? "12px" : "0")};
`;
const AddMenuWrapper = styled.form<StyledPropsWithTheme>`
  width: 300px;
  height: 200px;
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #131829;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.8),
    0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
`;
export const Styled = { AddMenuWrapper, Row };
