import styled from "@emotion/styled";
import MenuItem from "@material-ui/core/MenuItem";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { Menu } from "@material-ui/core";
import { Button } from "../../..";

const SelectMenuWrapper = styled(Menu)<StyledPropsWithTheme>`
  & .MuiMenu-paper {
    background: none;
  }
  & .MuiMenu-list {
    background-color: ${({ theme }) =>
      theme.palette.selectInput.background[100]};
    width: 100%;
    padding: 8px 4px;
    height: 200px;
    margin-top: 40px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 5px;
      overflow-y: hidden;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: ${({ theme }) => theme.palette.primary.main} !important;
      opacity: 0.15;
    }
  }
`;

const MenuItemWrapper = styled(MenuItem)<StyledPropsWithTheme>`
  &.MuiMenuItem-root {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    background-color: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    height: 40px;
    width: 100%;
  }

  &.MuiMenuItem-root:hover,
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.selectInput.selected[100]};
  }
`;

const SortingButton = styled(Button)<StyledPropsWithTheme>`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

export const Styled = {
  SelectMenuWrapper,
  MenuItemWrapper,
  SortingButton,
};
