import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { Typography } from "@mui/material";

const Title = styled(Typography)<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.formItems.textColor};
`;

const Content = styled(Typography)<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.formItems.textColor};
  white-space: normal;
  word-break: break-all;
`;

export const Styled = {
  Title,
  Content,
};
