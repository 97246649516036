import { createSlice } from "@reduxjs/toolkit";
import { Constants } from "./customers.constants";
import { CustomersSliceState } from "./customers.types";
import {
  addCustomersQuickView,
  createCustomer,
  deleteQuickView,
  fetchCustomersData,
  fetchCustomersQuickViews,
  filterCustomersData,
  getCustomersList,
  getEnvPermitsAssets,
  getMuniPermitsAssets,
  getPreflightCore,
  getTilesInfo,
  getTilesParcelInfo,
  sortCustomersData,
  updateCustomerQuickView,
  updateEnvPermitsAssets,
  updateFilteredRows,
  updateMuniPermitsAssets,
  updateSelectedRows,
} from "./customers.async";
import {
  getHidddenColumns,
  getPinnedColumns,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";

const initialCustomerState: CustomersSliceState = {
  assets: [],
  columnOrder: Constants.columnsOrder,
  columnDefs: Constants.columnDefs,
  initialColumnDefs: Constants.columnDefs,
  initialColumnsOrder: Constants.columnsOrder,
  selectedRows: [],
  selectedRowsIds: [],
  hiddenColumns: [],
  frozenColumns: [],
  quickViews: [],
  massUpdateLoading: false,
  selectedQuickVewName: "",
  areQuickVeiwsOptionsOpen: false,
  massUpdateItemsRequest: [],
  areMassUpdateOptionsOpen: false,
  loading: false,
  page: 0,
  totalElements: 0,
  loadedElements: 0,
  totalPages: 1,
  isLast: false,
  isPaginated: true,
  isLoading: true,
  areHiddenFieldsOpen: false,
  areSortOptionsOpen: false,
  areFilterOptionsOpen: false,
  isQuickVeiwsModalOpen: false,
  sortingRules: "",
  sort: [],
  filteringRules: [],
  filter: [],
  searchInputValue: "",
  programs: [],
  pole: null,
  permit: null,
  environmental: null,
  parcel: null,
  customersList: [],
  activeRowId: "",
  buttonDisabled: false,
  createCustomerLoading: false,
  selectedQuickViewPrivacy: false,
  selectedQuickViewId: "",
  envPermitsAssets: [],
  muniPermitsAssets: [],
  inputText: "",
  massUpdateItems: [],
  preflightCore: [],
  exportLoading: false,
};

const customersSlice = createSlice({
  name: "assets",
  initialState: initialCustomerState,
  reducers: {
    handleColumnDefs: (state, action) => {
      state.columnDefs = action.payload;
    },
    handleExport: (state, action) => {
      state.exportLoading = action.payload;
    },
    handleColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    quickViewsOptions: (state, action) => {
      state.areQuickVeiwsOptionsOpen = action.payload;
    },
    massUpdateOptions: (state, action) => {
      state.areMassUpdateOptionsOpen = action.payload;
    },
    handlePrivacy: (state, action) => {
      state.selectedQuickViewPrivacy = action.payload;
    },
    handleQuickViewId: (state, action) => {
      state.selectedQuickViewId = action.payload;
    },
    handleLoadedPagesChange: (state, action) => {
      const isDropToFirstPage = action?.payload?.isDropToFirstPage ?? false;

      if (isDropToFirstPage) {
        state.isPaginated = true;
        state.page = 0;
        return;
      }

      if (state.page < state.totalPages && state.isLoading) {
        state.isPaginated = true;
        state.page = state.page + 1;
      }
    },
    handleCurrentPage: (state, action) => {
      state.page = action.payload;
    },
    handleHiddenColumn: (state, action) => {
      const foundIndex = state.columnDefs.findIndex(
        (item) => item.field === action.payload
      );
      if (state.columnDefs[foundIndex].hide === true) {
        state.columnDefs[foundIndex].hide = false;
      } else {
        state.columnDefs[foundIndex].hide = true;
      }
      state.hiddenColumns = getHidddenColumns(state.columnDefs);
    },

    handlePinnedColumn: (state, action) => {
      const foundIndex = state.columnDefs.findIndex(
        (item) => item.field === action.payload
      );
      if (state.columnDefs[foundIndex].pinned === null) {
        state.columnDefs[foundIndex].pinned = "left";
      } else {
        state.columnDefs[foundIndex].pinned = null;
      }
      state.frozenColumns = getPinnedColumns(state.columnDefs);
    },

    handleSort: (state, action) => {
      state.sort = action.payload;
    },

    handleFilter: (state, action) => {
      if (action.payload.length === 1) {
        action.payload[0] = {
          ...action.payload[0],
          relation: "AND",
        };
      } else if (action.payload.length > 1) {
        action.payload[0] = {
          ...action.payload[0],
          relation: action.payload[1].relation,
        };
      }
      state.filter = action.payload;
    },

    handleFilteringRules: (state, action) => {
      state.filteringRules = action.payload;
    },

    fieldsHiddenOptions: (state, action) => {
      state.areHiddenFieldsOpen = action.payload;
    },

    sortOptions: (state, action) => {
      state.areSortOptionsOpen = action.payload;
    },

    filterOptions: (state, action) => {
      state.areFilterOptionsOpen = action.payload;
    },
    handleSearch: (state, action) => {
      state.searchInputValue = action.payload;
    },
    handleColumnChoosen: (state, action) => {
      state.quickViews = action.payload;
    },
    handleQuickViewsParams: (state, action) => {
      state.columnDefs = action.payload.columnDefs;
      state.hiddenColumns = action.payload.hiddenColumns;
      state.frozenColumns = action.payload.frozenColumns;
      state.columnOrder = action.payload.columnOrder;
      state.sort = action.payload.sort;
      state.filter = action.payload.filter;
      state.selectedQuickVewName = action.payload.selectedName;
      state.filteringRules = action.payload.filteringRules;
    },
    handleQuickViewsModal: (state, action) => {
      state.isQuickVeiwsModalOpen = action.payload;
    },
    handleSelectedRows: (state, action) => {
      if (action.payload.length < state.selectedRows.length) {
        state.selectedRows = action.payload;
      } else {
        state.selectedRows = [
          ...(new Set([...state.selectedRows, ...action.payload]) as any),
        ];
      }
    },
    handleSelectedIds: (state, action) => {
      if (action.payload.length < state.selectedRowsIds.length) {
        state.selectedRowsIds = action.payload;
      } else {
        state.selectedRowsIds = [
          ...(new Set([...state.selectedRowsIds, ...action.payload]) as any),
        ];
      }
    },
    handleMassUpdateItems: (state, action) => {
      state.massUpdateItems = action.payload;
    },
    handleSetActiveRow: (state, action) => {
      state.activeRowId = action.payload;
    },
    handleInputText: (state, action) => {
      state.inputText = action.payload;
    },
    handleClearCustomersGrid: (state, action) => {
      if (action.payload !== "/customers") {
        state.assets = [];
      }
    },
  },
  extraReducers: {
    [fetchCustomersData.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      if (action.payload) {
        if (state.page === 0) {
          state.assets = action.payload.content;
          state.loadedElements = action.payload.elementsOnPage;
        } else {
          if (action.payload.isQuickViews) {
            state.assets = action.payload.content;
            state.loadedElements = action.payload.elementsOnPage;
            state.page = 0;
          } else {
            state.assets = [...state.assets, ...action.payload.content];
            state.loadedElements =
              state.loadedElements + action.payload.elementsOnPage;
          }
        }
        state.sortingRules = action.payload.sortingRules;
        state.totalElements = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
      }
      state.isLoading = true;
    },
    [fetchCustomersData.pending]: (state) => {
      state.isLoading = false;
    },
    [sortCustomersData.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.assets = action.payload.content;
        state.loadedElements = action.payload.elementsOnPage;
        state.totalElements = action.payload.totalElements;
        state.page = action.payload.pageNumber;
        state.sortingRules = action.payload.sortingRules;
        state.totalPages = action.payload.totalPages;
        state.customersList = [];
        state.envPermitsAssets = [];
        state.muniPermitsAssets = [];
        state.parcel = [];
        state.pole = [];
      }
      state.loading = false;
      state.isPaginated = false;
    },
    [sortCustomersData.pending]: (state: CustomersSliceState) => {
      state.loading = true;
    },
    [sortCustomersData.rejected]: (state: CustomersSliceState) => {
      state.loading = false;
    },
    [filterCustomersData.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.assets = action.payload.content;
        state.loadedElements = action.payload.elementsOnPage;
        state.sortingRules = action.payload.sortingRules;
        state.page = action.payload.pageNumber;
        state.totalElements = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
        state.customersList = [];
        state.envPermitsAssets = [];
        state.muniPermitsAssets = [];
        state.parcel = [];
        state.pole = [];
      }
      state.loading = false;
      state.isPaginated = false;
    },
    [filterCustomersData.pending]: (state: CustomersSliceState) => {
      state.loading = true;
    },
    [filterCustomersData.rejected]: (state: CustomersSliceState) => {
      state.loading = false;
    },
    [getTilesInfo.fulfilled]: (state: CustomersSliceState, action: any) => {
      if (action.payload) {
        state.environmental = action.payload.environmental;
        state.permit = action.payload.permit;
        state.pole = action.payload.pole;
        state.programs = action.payload.programs;
      }
    },
    [getTilesParcelInfo.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      state.parcel = action.payload;
    },
    [getCustomersList.fulfilled]: (state: CustomersSliceState, action: any) => {
      state.customersList = action.payload;
    },
    [fetchCustomersQuickViews.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      state.quickViews = action.payload.map((item: any) => {
        return { ...item, selected: false };
      });
      state.loading = false;
    },
    [fetchCustomersQuickViews.pending]: (state: CustomersSliceState) => {
      state.loading = true;
    },
    [addCustomersQuickView.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.quickViews = [...state.quickViews, action.payload].sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
      }
    },
    [updateCustomerQuickView.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      if (action.payload) {
        const newquickViews = state.quickViews.map((item) => {
          if (item) {
            if (item.id === action.payload.id) {
              return { ...action.payload, selected: true };
            } else {
              return item;
            }
          }
        });
        state.quickViews = newquickViews;
      }
    },
    [updateFilteredRows.pending]: (state: CustomersSliceState) => {
      // state.loading = true;
      state.massUpdateLoading = true;
      state.createCustomerLoading = true;
    },
    [updateSelectedRows.pending]: (state: CustomersSliceState) => {
      // state.loading = true;
      state.massUpdateLoading = true;
      state.createCustomerLoading = true;
    },
    [updateSelectedRows.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      state.massUpdateLoading = false;
      state.selectedRows = [];
      state.selectedRowsIds = [];
      state.createCustomerLoading = false;
      state.massUpdateItemsRequest = action.payload;
    },
    [updateFilteredRows.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      state.massUpdateLoading = false;
      state.createCustomerLoading = false;
      state.massUpdateItemsRequest = action.payload;
    },
    [createCustomer.fulfilled]: (state: CustomersSliceState) => {
      state.buttonDisabled = false;
      state.createCustomerLoading = false;
    },
    [createCustomer.pending]: (state: CustomersSliceState) => {
      state.buttonDisabled = true;
      state.createCustomerLoading = true;
    },
    [createCustomer.rejected]: (state: CustomersSliceState) => {
      state.buttonDisabled = false;
      state.createCustomerLoading = false;
    },
    [deleteQuickView.fulfilled]: (state: CustomersSliceState, action: any) => {
      state.quickViews = state.quickViews
        .filter((item) => item.id !== action.payload)
        .filter((item) => {
          if (item.name === state.selectedQuickVewName) {
            return { ...item, selected: true };
          } else {
            return { item };
          }
        });
    },
    [getEnvPermitsAssets.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      state.envPermitsAssets = action.payload;
    },
    [getMuniPermitsAssets.fulfilled]: (
      state: CustomersSliceState,
      action: any
    ) => {
      state.muniPermitsAssets = action.payload;
    },
    [getPreflightCore.fulfilled]: (state: CustomersSliceState, action: any) => {
      state.preflightCore = action.payload[0] !== null ? action.payload : [];
    },

    //update Env Permits loading hanlder
    [updateEnvPermitsAssets.pending]: (state: CustomersSliceState) => {
      state.createCustomerLoading = true;
    },
    [updateEnvPermitsAssets.fulfilled]: (state: CustomersSliceState) => {
      state.createCustomerLoading = false;
    },
    [updateEnvPermitsAssets.rejected]: (state: CustomersSliceState) => {
      state.createCustomerLoading = false;
    },

    //update Muni Permits loading hanlder
    [updateMuniPermitsAssets.pending]: (state: CustomersSliceState) => {
      state.createCustomerLoading = true;
    },
    [updateMuniPermitsAssets.fulfilled]: (state: CustomersSliceState) => {
      state.createCustomerLoading = false;
    },
    [updateMuniPermitsAssets.rejected]: (state: CustomersSliceState) => {
      state.createCustomerLoading = false;
    },
  },
});

export const {
  handleLoadedPagesChange,
  handleCurrentPage,
  handleSort,
  handleFilter,
  handleFilteringRules,
  fieldsHiddenOptions,
  sortOptions,
  handleHiddenColumn,
  handlePinnedColumn,
  filterOptions,
  handleSearch,
  quickViewsOptions,
  handleColumnChoosen,
  handleQuickViewsParams,
  handleQuickViewsModal,
  handleSelectedRows,
  handleSetActiveRow,
  handleColumnDefs,
  handleColumnOrder,
  handlePrivacy,
  handleQuickViewId,
  handleInputText,
  handleSelectedIds,
  massUpdateOptions,
  handleMassUpdateItems,
  handleExport,
  handleClearCustomersGrid,
} = customersSlice.actions;
export default customersSlice.reducer;
