import { camelCaseTransform } from "@sdge-utils/dataTransform";
import React, { FC } from "react";
import { Styled } from "./CellHistory.styled";
import { ChangeItemType } from "./CellHistory.types";
import { parsePoleHistoryDate } from "@sdge-utils/dateParser";

const PoleHistoryDescription: FC<{ changeItem: ChangeItemType }> = ({
  changeItem,
}) => {
  const { oldValue, op, value } = changeItem;
  switch (op) {
    case "replace":
      return (
        <Styled.Text>{`${op} → ${
          oldValue?.length > 0 ? oldValue : "BLANK"
        } to ${value?.length > 0 ? value : "BLANK"}`}</Styled.Text>
      );
    case "remove":
      return (
        <Styled.Text>{`${op} → ${parsePoleHistoryDate(oldValue)}`}</Styled.Text>
      );
    case "add":
      return (
        <Styled.AddList>
          {op} ↓
          {Object.keys(value).map((item, index) => (
            <Styled.Text key={index}>
              <b>{camelCaseTransform(item)}</b>:{" "}
              {value[item]?.length > 0
                ? parsePoleHistoryDate(value[item])
                : "BLANK"}
            </Styled.Text>
          ))}
        </Styled.AddList>
      );
    default:
      return (
        <Styled.Text>{`${op} → ${parsePoleHistoryDate(
          oldValue
        )} to ${parsePoleHistoryDate(value)}`}</Styled.Text>
      );
  }
};

export default PoleHistoryDescription;
