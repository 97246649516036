export const ballInCourtConstants = [
  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "SUG",
    value: "SUG",
    label: "SUG",
  },
  {
    id: "Northeast",
    value: "Northeast",
    label: "Northeast",
  },
  {
    id: "Beach Cities",
    value: "Beach Cities",
    label: "Beach Cities",
  },
  {
    id: "Orange County",
    value: "Orange County",
    label: "Orange County",
  },
  {
    id: "Metro",
    value: "Metro",
    label: "Metro",
  },
  {
    id: "Ramona",
    value: "Ramona",
    label: "Ramona",
  },
  {
    id: "Eastern",
    value: "Eastern",
    label: "Eastern",
  },
  {
    id: "Mountain Empire",
    value: "Mountain Empire",
    label: "Mountain Empire",
  },
  {
    id: "North Coast",
    value: "North Coast",
    label: "North Coast",
  },
  {
    id: "CMP",
    value: "CMP",
    label: "CMP",
  },
  {
    id: "Construction Services",
    value: "Construction Services",
    label: "Construction Services",
  },
  {
    id: "Cordoba",
    value: "Cordoba",
    label: "Cordoba",
  },
  {
    id: "E2",
    value: "E2",
    label: "E2",
  },
  {
    id: "ERO",
    value: "ERO",
    label: "ERO",
  },
  {
    id: "FiRM/Prime",
    value: "FiRM/Prime",
    label: "FiRM/Prime",
  },
  {
    id: "KP ENV Customer",
    value: "KP ENV Customer",
    label: "KP ENV Customer",
  },
  {
    id: "KP ENV Environmental Release",
    value: "KP ENV Environmental Release",
    label: "KP ENV Environmental Release",
  },
  {
    id: "KP ENV Permits",
    value: "KP ENV Permits",
    label: "KP ENV Permits",
  },
  {
    id: "Reliability",
    value: "Reliability",
    label: "Reliability",
  },
  {
    id: "TCM",
    value: "TCM",
    label: "TCM",
  },
  {
    id: "TED CMP",
    value: "TED CMP",
    label: "TED CMP",
  },
  {
    id: "Veg Management",
    value: "Veg Management",
    label: "Veg Management",
  },
  {
    id: "WiSE",
    value: "WiSE",
    label: "WiSE",
  },
];

export const assignedConstants = [
  {
    id: "Asset Management",
    value: "Asset Management",
    label: "Asset Management",
  },
  {
    id: "Construction Services",
    value: "Construction Services",
    label: "Construction Services",
  },
  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "SUG",
    value: "SUG",
    label: "SUG",
  },
  {
    id: "Northeast",
    value: "Northeast",
    label: "Northeast",
  },
  {
    id: "Beach Cities",
    value: "Beach Cities",
    label: "Beach Cities",
  },
  {
    id: "Orange County",
    value: "Orange County",
    label: "Orange County",
  },
  {
    id: "Metro",
    value: "Metro",
    label: "Metro",
  },
  {
    id: "Ramona",
    value: "Ramona",
    label: "Ramona",
  },
  {
    id: "Eastern",
    value: "Eastern",
    label: "Eastern",
  },
  {
    id: "Mountain Empire",
    value: "Mountain Empire",
    label: "Mountain Empire",
  },
  {
    id: "North Coast",
    value: "North Coast",
    label: "North Coast",
  },
  {
    id: "CMP",
    value: "CMP",
    label: "CMP",
  },
  {
    id: "Cordoba",
    value: "Cordoba",
    label: "Cordoba",
  },
  {
    id: "ERO",
    value: "ERO",
    label: "ERO",
  },
  {
    id: "FiRM/Prime",
    value: "FiRM/Prime",
    label: "FiRM/Prime",
  },
  {
    id: "KP Environmental",
    value: "KP Environmental",
    label: "KP Environmental",
  },
  {
    id: "TCM",
    value: "TCM",
    label: "TCM",
  },
  {
    id: "TED CMP",
    value: "TED CMP",
    label: "TED CMP",
  },
  {
    id: "Veg Management",
    value: "Veg Management",
    label: "Veg Management",
  },
  {
    id: "WiSE",
    value: "WiSE",
    label: "WiSE",
  },
];

export const statusConstants = [
  {
    id: "As-Built",
    value: "As-Built",
    label: "As-Built",
  },
  {
    id: "Design Complete",
    value: "Design Complete",
    label: "Design Complete",
  },
  {
    id: "Assigned",
    value: "Assigned",
    label: "Assigned",
  },

  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "Cordoba Approved",
    value: "Cordoba Approved",
    label: "Cordoba Approved",
  },
  {
    id: "Customer Info",
    value: "Customer Info",
    label: "Customer Info",
  },
  {
    id: "Descoped",
    value: "Descoped",
    label: "Descoped",
  },
  {
    id: "Design %60 Complete",
    value: "Design %60 Complete",
    label: "Design %60 Complete",
  },
  {
    id: "Design %90 Complete",
    value: "Design %90 Complete",
    label: "Design %90 Complete",
  },
  {
    id: "Environmental",
    value: "Environmental",
    label: "Environmental",
  },
  {
    id: "Grouping Approval",
    value: "Grouping Approval",
    label: "Grouping Approval",
  },
  {
    id: "IFC",
    value: "IFC",
    label: "IFC",
  },
  {
    id: "In-Design",
    value: "In-Design",
    label: "In-Design",
  },
  {
    id: "In Construction",
    value: "In Construction",
    label: "In Construction",
  },
  {
    id: "Permitting",
    value: "Permitting",
    label: "Permitting",
  },

  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
  {
    id: "Resolved",
    value: "Resolved",
    label: "Resolved",
  },

  {
    id: "SAP Initiation",
    value: "SAP Initiation",
    label: "SAP Initiation",
  },
  {
    id: "SAP Complete",
    value: "SAP Complete",
    label: "SAP Complete",
  },
  {
    id: "True-Up",
    value: "True-Up",
    label: "True-Up",
  },
];

export const classificationValues = [
  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "CIP",
    value: "CIP",
    label: "CIP",
  },
  {
    id: "CUSTOMER",
    value: "CUSTOMER",
    label: "CUSTOMER",
  },
  {
    id: "EMERGENCY",
    value: "EMERGENCY",
    label: "EMERGENCY",
  },
  {
    id: "ENG",
    value: "ENG",
    label: "ENG",
  },
  {
    id: "NON-ENG",
    value: "NON-ENG",
    label: "NON-ENG",
  },
  {
    id: "TCM",
    value: "TCM",
    label: "TCM",
  },
  {
    id: "TCM-NI",
    value: "TCM-NI",
    label: "TCM-NI",
  },
  {
    id: "TCM PENDING",
    value: "TCM PENDING",
    label: "TCM PENDING",
  },
  {
    id: "UNSORTED",
    value: "UNSORTED",
    label: "UNSORTED",
  },
  {
    id: "VEG",
    value: "VEG",
    label: "VEG",
  },
  {
    id: "UG",
    value: "UG",
    label: "UG",
  },
  {
    id: "RFS",
    value: "RFS",
    label: "RFS",
  },
];

export const crossCheckValues = [
  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "ESH",
    value: "ESH",
    label: "ESH",
  },
  {
    id: "FiRM ESH",
    value: "FiRM ESH",
    label: "FiRM ESH",
  },
  {
    id: "LiDAR",
    value: "LiDAR",
    label: "LiDAR",
  },
  {
    id: "OHVI",
    value: "OHVI",
    label: "OHVI",
  },
  {
    id: "PMR",
    value: "PMR",
    label: "PMR",
  },
  {
    id: "PRiME ESH",
    value: "PRiME ESH",
    label: "PRiME ESH",
  },
  {
    id: "TCM",
    value: "TCM",
    label: "TCM",
  },
  {
    id: "TED CMP",
    value: "TED CMP",
    label: "TED CMP",
  },
  {
    id: "Reliability",
    value: "Reliability",
    label: "Reliability",
  },
  {
    id: "WiSE",
    value: "WiSE",
    label: "WiSE",
  },
];

export const priority = [
  {
    id: "emergency",
    value: "emergency",
    label: "Emergency",
  },
  {
    id: "priority",
    value: "priority",
    label: "Priority",
  },
  {
    id: "non-critical",
    value: "non-critical",
    label: "Non-critical",
  },
];

export const replacement = [
  {
    id: "Y",
    value: "Y",
    label: "Y",
  },
  {
    id: "N",
    value: "N",
    label: "N",
  },
];
