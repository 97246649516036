const DotIcon = ({ size = 8, ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="4" cy="4" r="4" />
    </svg>
  );
};

export default DotIcon;
