import { InputLabel, Select, MenuItem } from "@mui/material";

import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const Label = styled(InputLabel)<StyledPropsWithTheme>`
  &.MuiInputLabel-root {
    top: -8px;
  }
  &.MuiInputLabel-shrink {
    top: -3px;
    color: ${({ theme }) => theme.palette.formItems.labelColor[100]};
  }
`;

const SelectStyled = styled(Select)<StyledPropsWithTheme>`
  & .MuiSelect-select {
    padding: 8px 14px;
  }

  &.Mui-focused {
    & fieldset {
      border: 1px solid
        ${({ theme }) => theme.palette.formItems.borderColor[100]} !important;
    }
  }

  &.MuiOutlinedInput-root {
    color: ${({ theme }) => theme.palette.formItems.textColor[100]};
    &:hover {
      fieldset {
        border-color: ${({ theme }) =>
          theme.palette.formItems.borderHover[100]};
      }
    }
  }
`;

const Error = styled.p<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.formItems.errorColor[100]};
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 5px 0 -5px;
  padding: 0;
`;

const SelectItem = styled(MenuItem)`
  width: 195.4px;
  white-space: normal;
`;

export const Styled = {
  SelectStyled,
  Label,
  SelectItem,
  Error,
};
