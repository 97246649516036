import React, { FC } from "react";
import { Styled } from "./AutoComplete.styled";
import { AutoCompleteTypes } from "./AutoComplete.types";

const AutoComplete: FC<AutoCompleteTypes> = ({
  handleItemClick,
  items,
  isOverflow,
  position,
  sidebar,
}) => {
  return (
    <Styled.AutoCompleteWrapper
      isOverflow={isOverflow}
      left={position.left}
      top={position.top}
      width={position.width}
      sidebar={sidebar}
    >
      {items.map((item, index) => {
        return (
          <Styled.AutoCompleteItem
            key={index + item}
            onClick={() => handleItemClick(item)}
          >
            {item}
          </Styled.AutoCompleteItem>
        );
      })}
    </Styled.AutoCompleteWrapper>
  );
};

export default AutoComplete;
