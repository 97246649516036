import { DatePickerInput, ModalWindow, SelectInput } from "../../components";
import React from "react";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { Styled } from "./Tile.styled";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../app/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CreateMuniPermitsValidationSchema,
  CreateMuniPermitsValidationSchemaAssets,
} from "./CreateMuniPermitsValidationSchema";
import { updateMuniPermits } from "@sdge-store/issueTracker/issueTracker.async";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { CloseIcon } from "../../icons";
import { muniPermitOptions } from "./Tile.constants";
import { updateMuniPermitsAssets } from "@sdge-store/customers/customers.async";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { updateMuniPermitsConstruction } from "@sdge-store/construction/construction.async";

const AddMuniPpermitModal: React.FC<any> = ({
  open,
  setOpen,
  isIssueTrackerPage,
  isConstructionPage,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isIssueTrackerPage || isConstructionPage
        ? CreateMuniPermitsValidationSchema
        : CreateMuniPermitsValidationSchemaAssets
    ),
    mode: "onChange",
  });
  const { buttonDisabled, activeRowId } = useAppSelector(
    (state) => state.customersSlice
  );
  const dispatch = useDispatch();
  const { activeIssueId, activeIssuefacilityId, activeIssueGroupName } =
    useAppSelector((state) => state.issueTrackerSlice);
  const { activeConstructionGroupName } = useAppSelector(selectConstruction);
  const handleCloseModal = () => {
    setOpen(false);
    reset();
  };

  const formSubmit = (values: any) => {
    const body = {
      ...(isIssueTrackerPage
        ? { groupName: activeIssueGroupName, envReleaseNeeded: "No" }
        : isConstructionPage
        ? { groupName: activeConstructionGroupName }
        : {}),
      permitId: "",
      ...values,
    };
    isIssueTrackerPage
      ? dispatch(
          updateMuniPermits({
            id: activeIssueId,
            body,
            facilityId: activeIssuefacilityId,
          })
        )
      : isConstructionPage
      ? dispatch(
          updateMuniPermitsConstruction({
            groupName: activeConstructionGroupName,
            body,
          })
        )
      : dispatch(
          updateMuniPermitsAssets({
            body,
            facilityId: activeRowId,
          })
        );
    reset();
    setOpen(false);
  };

  const handleEdit = (name: string) => {
    setValue(name as `${string}`, "");
  };

  return (
    <ModalWindow isOpen={open} onClose={handleCloseModal}>
      <Box width="500px" p="20px">
        <Box mb="20px">
          <Typography>New Muni Permits</Typography>
        </Box>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box mb="20px">
            <SelectInput
              optionsData={muniPermitOptions}
              control={control}
              defaultValue={""}
              name={
                isIssueTrackerPage || isConstructionPage
                  ? "permitType"
                  : "muniPermitType"
              }
              label="Muni Permit Type # *"
            />
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name={
                  isIssueTrackerPage || isConstructionPage
                    ? "permitDateSubmitted"
                    : "muniPermitDateSubmitted"
                }
                errorMessage={
                  isIssueTrackerPage || isConstructionPage
                    ? errors.permitDateSubmitted?.message
                    : errors.muniPermitDateSubmitted?.message
                }
                label="Muni Permit Date Submitted #"
                defaultValue=""
                parseFormat="YYYY-MM-DD"
              />
              <IconButton
                onClick={() =>
                  handleEdit(
                    isIssueTrackerPage || isConstructionPage
                      ? "permitDateSubmitted"
                      : "muniPermitDateSubmitted"
                  )
                }
              >
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name={
                  isIssueTrackerPage || isConstructionPage
                    ? "permitIssuedDate"
                    : "muniPermitIssuedDate"
                }
                errorMessage={
                  isIssueTrackerPage || isConstructionPage
                    ? errors.permitIssuedDate?.message
                    : errors.muniPermitIssuedDate?.message
                }
                label="Muni Permit Issued Date #"
                defaultValue=""
                parseFormat="YYYY-MM-DD"
              />
              <IconButton
                onClick={() =>
                  handleEdit(
                    isIssueTrackerPage || isConstructionPage
                      ? "permitIssuedDate"
                      : "muniPermitIssuedDate"
                  )
                }
              >
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Muni Constraints # *"
              control={control}
              name={
                isIssueTrackerPage || isConstructionPage
                  ? "permitTimeFrame"
                  : "miniPermitTimeFrame"
              }
              errorMessage={
                isIssueTrackerPage || isConstructionPage
                  ? errors.permitTimeFrame?.message
                  : errors.miniPermitTimeFrame?.message
              }
            />
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name={
                  isIssueTrackerPage || isConstructionPage
                    ? "permitExpirationDate"
                    : "muniPermitExpirationDate"
                }
                errorMessage={
                  isIssueTrackerPage || isConstructionPage
                    ? errors.permitDateSubmitted?.message
                    : errors.muniPermitDateSubmitted?.message
                }
                label="Muni Expiration Date #"
                defaultValue=""
                parseFormat="YYYY-MM-DD"
              />
              <IconButton
                onClick={() =>
                  handleEdit(
                    isIssueTrackerPage || isConstructionPage
                      ? "permitDateSubmitted"
                      : "muniPermitDateSubmitted"
                  )
                }
              >
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="K2 Reference # *"
              control={control}
              name="k2Reference"
              errorMessage={errors.k2Reference?.message}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item>
              <Styled.AddButton
                variant="contained"
                type="submit"
                disabled={buttonDisabled}
              >
                Add Muni Permit
              </Styled.AddButton>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ModalWindow>
  );
};

export default AddMuniPpermitModal;
