import { TileSection } from "../TileSection";
import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import useMuniPermitContentCreator from "./useMuniPermitContentCreator";
import RowItem from "./RowItem";
import { useDispatch } from "react-redux";
import {
  deleteMuniPermit,
  getMuniPermits,
} from "@sdge-store/issueTracker/issueTracker.async";
import { useAppSelector } from "../../app/hooks";
import { DeleteIcon } from "../../icons";
import { Divider, Typography } from "@material-ui/core";
import {
  deleteMuniPermitAssets,
  getMuniPermitsAssets,
} from "@sdge-store/customers/customers.async";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { deleteMuniPermitConstruction } from "@sdge-store/construction/construction.async";
import { usePermission } from "@sdge-hooks";

const MuniPermitTileContent: React.FC<{
  customerPage?: boolean;
  constructionPage?: boolean;
}> = ({ customerPage, constructionPage }) => {
  const dispatch = useDispatch();
  const { activeIssueId, requested, cardsLoading } = useAppSelector(
    (state) => state.issueTrackerSlice
  );
  const { activeRowId } = useAppSelector((state) => state.customersSlice);
  const { getPermission } = usePermission();
  const { activeConstructionGroupName } = useAppSelector(selectConstruction);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  useEffect(() => {
    customerPage
      ? activeRowId && dispatch(getMuniPermitsAssets(activeRowId))
      : !constructionPage &&
        activeIssueId &&
        dispatch(getMuniPermits(activeIssueId));
  }, [
    activeIssueId,
    activeRowId,
    activeConstructionGroupName,
    constructionPage,
    customerPage,
  ]);

  const contentItems = useMuniPermitContentCreator(
    customerPage,
    constructionPage
  );
  const removeMuniPermit = (payload: any) => {
    customerPage
      ? dispatch(deleteMuniPermitAssets(payload))
      : constructionPage
      ? dispatch(deleteMuniPermitConstruction(payload))
      : dispatch(deleteMuniPermit(payload));
  };
  const hasPermissions =
    getPermission("delMuniBesideConstPage") ||
    getPermission("delMuniPermitTile") ||
    (getPermission("del_tile_const") && constructionPage);

  if (cardsLoading) {
    return <> </>;
  }

  if (cardsLoading) {
    return <> </>;
  }

  return (
    <>
      {(!contentItems?.length || contentItems?.length === 0) && requested && (
        <Grid container justifyContent="center">
          <Box mt="20%" width="70%">
            <Typography align="center">No Muni Permits</Typography>
          </Box>
        </Grid>
      )}
      {contentItems?.map((item: any, i: number) => {
        return (
          <Box key={i}>
            {programInfo.bmcdTier3 !== "Y" && hasPermissions ? (
              <TileSection
                title=""
                Icon={DeleteIcon}
                handleAction={() =>
                  removeMuniPermit({
                    muniPermitsId: item.filter((item: any) => item.permitId)[0]
                      .permitId,
                    activeIssueId: customerPage
                      ? activeRowId
                      : constructionPage
                      ? activeConstructionGroupName
                      : activeIssueId,
                    id:
                      constructionPage &&
                      item.filter((item: any) => item.permitId)[0].permitId,
                  })
                }
              />
            ) : (
              <Divider />
            )}
            {item.map(({ id, title, content, name, date }: any) => {
              let newName;
              if (name === "permitType") {
                newName = "muniPermitType";
              } else if (name === "permitDateSubmitted") {
                newName = "muniPermitDateSubmitted";
              } else if (name === "permitTimeFrame") {
                newName = "miniPermitTimeFrame";
              } else if (name === "permitIssuedDate") {
                newName = "muniPermitIssuedDate";
              } else {
                newName = name;
              }
              return (
                <RowItem
                  title={title}
                  key={id}
                  content={content}
                  name={customerPage ? newName : name}
                  date={date}
                  actualPermitId={item[7]?.permitId || ""}
                  customerPage={customerPage}
                  constructionPage={constructionPage}
                />
              );
            })}
          </Box>
        );
      })}
    </>
  );
};

export default MuniPermitTileContent;
