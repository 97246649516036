export const inputsList = [
  { id: 1, name: "customer", label: "Customer # *" },
  { id: 2, name: "name", label: "Customer Name # *" },
  { id: 3, name: "address", label: "Address # *" },
  { id: 4, name: "number", label: "Phone Numbers # *", type: "number" },
  { id: 5, name: "email", label: "Emails # *" },
];

export const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export const emailRegexp =
  /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;

export const naRegexp = new RegExp("n/a");

export const NARegexp = new RegExp("N/A");
