import { CircularProgress } from "@material-ui/core";

import styled from "@emotion/styled";
import React from "react";

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
`;

/**
 * add global styles for CircularProgress
 * move to components
 */
const Loader: React.FC = () => {
  return (
    <Wrapper>
      <CircularProgress color="inherit" />
    </Wrapper>
  );
};

export default Loader;
