import { queryInstance } from "../api/config";

export async function exportFile(url: string, fileName: string) {
  const data = await queryInstance.get(url, { responseType: "arraybuffer" });
  const file = data.data;

  const localUrl = window.URL.createObjectURL(new Blob([file]));

  const link = document.createElement("a");

  link.href = localUrl;
  link.setAttribute("download", `${fileName}`);

  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
}

export async function exportFilteredFile(
  url: string,
  body: any[],
  fileName: string
) {
  const data = await queryInstance.post(url, body, { timeout: 120000 });
  const file = data.data;
  const fileExtensionArray = data.headers["content-type"].split("/");
  const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];

  const localUrl = window.URL.createObjectURL(new Blob([file]));

  const link = document.createElement("a");

  link.href = localUrl;
  link.setAttribute("download", `${fileName}.${fileExtension}`);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
}
