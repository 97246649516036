import { selectIssueTracker } from "@sdge-store/issueTracker/issueTracker.selector";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import {
  handleMassUpdateItems,
  handleSelectedRows,
  handleSelecedRowsIssueIds,
} from "@sdge-store/issueTracker/issueTracker.slice";
import {
  updateFilteredRows,
  updateSelectedRows,
} from "@sdge-store/issueTracker/issueTracker.async";
import MassUpdateOptions from "./MassUpdateOptions";

const IssueMassUpdateOptions = () => {
  const {
    selectedRows,
    massUpdateItems,
    columnDefs,
    selecedRowsIssueIds,
    filteringRules,
    totalElements,
    massUpdateLoading,
  } = useAppSelector(selectIssueTracker);

  const data = {
    selectedRows,
    massUpdateItems,
    columnDefs,
    selecedRowsIssueIds,
    filteringRules,
    totalElements,
    massUpdateLoading,
  };

  const functions = {
    updateFilteredRows,
    updateSelectedRows,
    handleMassUpdateItems,
    handleSelectedRows,
    handleSelectedIssueIds: handleSelecedRowsIssueIds,
  };

  return (
    <MassUpdateOptions data={data} functions={functions} page="issueTracker" />
  );
};

export default IssueMassUpdateOptions;
