import { Link } from "@sdge-components";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { useAppSelector } from "../../app/hooks";
import { parseRowDateString } from "@sdge-utils/dateParser";

const useEnvPermitContentCreator = (
  customerPage: boolean | undefined,
  constructionPage: boolean | undefined
) => {
  const { envPermits } = useAppSelector((state) => state.issueTrackerSlice);
  const { envPermitsAssets } = useAppSelector((state) => state.customersSlice);
  const { envPermitsConstruction } = useAppSelector(selectConstruction);
  const sortedArr = customerPage
    ? [...envPermitsAssets]
    : constructionPage
    ? [...envPermitsConstruction]
    : [...envPermits];
  const content =
    sortedArr.length >= 2
      ? sortedArr.sort((a, b) =>
          (a.quickRelease || "")
            .toString()
            .localeCompare((b.quickRelease || "").toString())
        )
      : sortedArr;
  const regex = new RegExp(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
  );
  return content.length
    ? content?.map((item: any) => {
        const validateLink = regex.test(item?.sharepointLink);
        return [
          {
            id: 1,
            title: "Quick Release",
            content: item?.quickRelease || "-",
            name: "quickRelease",
          },
          {
            id: 2,
            title: "ETS",
            content: item?.ets || "-",
            name: "ets",
          },
          {
            id: 3,
            title: "Environmental Release Date Submitted",
            content: customerPage
              ? item?.envReleaseDateSubmitted &&
                parseRowDateString(item?.envReleaseDateSubmitted)
              : (item?.envReleaseDateSubmitted &&
                  parseRowDateString(item?.envReleaseDateSubmitted)) ||
                "-",
            name: "envReleaseDateSubmitted",
            date: true,
          },
          {
            id: 7,
            title: "Environmental Release Issued Date",
            content: customerPage
              ? item?.envReleaseIssuedDate &&
                parseRowDateString(item?.envReleaseIssuedDate)
              : (item?.envReleaseIssuedDate &&
                  parseRowDateString(item?.envReleaseIssuedDate)) ||
                "-",
            name: "envReleaseIssuedDate",
            date: true,
          },
          {
            id: 4,
            title: "Environmental Constraints",
            content: item?.specialRequirements || "-",
            name: "specialRequirements",
          },
          {
            id: 5,
            title: "Release Expiration Date",
            content: item?.envReleaseExpirationDate || "-",
            name: "envReleaseExpirationDate",
            date: true,
          },
          {
            id: 6,
            title: "Sharepoint Link",
            content:
              item?.sharepointLink && validateLink ? (
                <Link link={item.sharepointLink} name="Sharepoint Link" />
              ) : (
                "-"
              ),
            name: "sharepointLink",
          },
          {
            id: 16,
            title: "",
            content: "",
            permitId: item?.permitId || "",
            name: "permitId",
          },
        ];
      })
    : [];
};

export default useEnvPermitContentCreator;
