import { SmallTable, OverlapsMainTable } from "../../components";
import React from "react";
import { Styled } from "./PreflightPage.styled";

const PreflightPage: React.FC = () => {
  return (
    <Styled.PreflightPageWrapper>
      <Styled.TableGroup>
        <SmallTable fetchLink="https://jsonplaceholder.typicode.com/comments" />
        <SmallTable fetchLink="https://jsonplaceholder.typicode.com/comments" />
        <SmallTable fetchLink="https://jsonplaceholder.typicode.com/comments" />
        <SmallTable fetchLink="https://jsonplaceholder.typicode.com/comments" />
      </Styled.TableGroup>
      <OverlapsMainTable />
    </Styled.PreflightPageWrapper>
  );
};

export default PreflightPage;
