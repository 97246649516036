import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryInstance } from "@sdge-api/config";
import { errorHandler } from "@sdge-api/utils";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { getIssueTrackerCustomersList } from "@sdge-store/issueTracker/issueTracker.async";
import { parseDateString } from "@sdge-utils/dateParser";
import { getFilteredGis } from "@sdge-store/Map/map.async";

export const fetchCustomersData: any = createAsyncThunk(
  "assets/fetchCustomersData",
  async (sortingRules: any[], { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      // add rules to the sorting rules filter property
      const updatedSortingRules = [
        sortingRules[0], // page
        sortingRules[1], // sort
        [...sortingRules[2], ...rules], // filter
        sortingRules[3], // isQuickViews
      ];

      try {
        let response;
        if (updatedSortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/assets?page=${updatedSortingRules[0]}${updatedSortingRules[1]}`,
            { timeout: 120000 }
          );
        } else {
          response = await queryInstance.post(
            `/assets?page=${updatedSortingRules[0]}${updatedSortingRules[1]}`,
            updatedSortingRules[2]
          );
        }
        if (updatedSortingRules[3] === true) {
          return {
            ...response?.data,
            sortingRules: updatedSortingRules[1],
            isQuickViews: true,
          };
        }
        return {
          ...response?.data,
          sortingRules: updatedSortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/assets?page=${sortingRules[0]}${sortingRules[1]}`,
            {
              timeout: 120000,
            }
          );
        } else {
          response = await queryInstance.post(
            `/assets?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
        }
        if (sortingRules[3] === true) {
          return {
            ...response?.data,
            sortingRules: sortingRules[1],
            isQuickViews: true,
          };
        }
        return {
          ...response?.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const sortCustomersData: any = createAsyncThunk(
  "assets/sorting",
  async (sortingRules: any, { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.post(
            `/assets?page=${sortingRules[0]}${sortingRules[1]}`,
            rules,
            {
              timeout: 120000,
            }
          );
        } else {
          response = await queryInstance.post(
            `/assets?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
          if (response) {
            dispatch(
              getFilteredGis({ body: [...sortingRules[2]], center: true })
            );
          }
        }
        return {
          ...response.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/assets?page=${sortingRules[0]}${sortingRules[1]}`,
            {
              timeout: 120000,
            }
          );
        } else {
          response = await queryInstance.post(
            `/assets?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
          if (response) {
            dispatch(
              getFilteredGis({ body: [...sortingRules[2]], center: true })
            );
          }
        }
        return {
          ...response.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const filterCustomersData: any = createAsyncThunk(
  "assets/filtering",
  async (sortingRules: any, { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      const transformedSortingRules = sortingRules[2].map((item: any) => {
        if (item.fieldName === "vnf_date.keyword") {
          return {
            ...item,
            value: parseDateString(item.value),
          };
        }
        return item;
      });
      // add rules to the sorting rules
      const updatedSortingRules = [
        sortingRules[0], // page
        sortingRules[1], // sort
        [...transformedSortingRules, ...rules], // filter
      ];
      try {
        const { data } = await queryInstance.post(
          `/assets?page=${sortingRules[0]}${sortingRules[1]}`,
          updatedSortingRules[2]
        );
        if (data) {
          dispatch(
            getFilteredGis({ body: [...transformedSortingRules], center: true })
          );
        }

        return {
          ...data,
          sortingRules: updatedSortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      const transformedSortingRules = sortingRules[2].map((item: any) => {
        if (item.fieldName === "vnf_date.keyword") {
          return {
            ...item,
            value: parseDateString(item.value),
          };
        }
        return item;
      });

      try {
        const { data } = await queryInstance.post(
          `/assets?page=${sortingRules[0]}${sortingRules[1]}`,
          transformedSortingRules
        );
        if (data) {
          dispatch(
            getFilteredGis({ body: [...transformedSortingRules], center: true })
          );
        }

        return {
          ...data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const getTilesInfo: any = createAsyncThunk(
  "assets/getTilesInfo",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/assets/${payload}/overlapping`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getTilesParcelInfo: any = createAsyncThunk(
  "assets/getTilesParcelInfo",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/assets/${payload}/parcel`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getCustomersList: any = createAsyncThunk(
  "assets/getCustomersList",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/assets/${payload}/customers`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const fetchCustomersQuickViews: any = createAsyncThunk(
  "quick-views/assets",
  async () => {
    const response = await queryInstance.get("/quick-views/assets", {
      timeout: 120000,
    });
    return response.data.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
  }
);

export const addCustomersQuickView: any = createAsyncThunk(
  "quick-views/assets/post",
  async (body: any, { dispatch }) => {
    try {
      const response = await queryInstance.post("/quick-views/assets", body);
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was created",
        })
      );
      return response.data;
    } catch (error) {
      errorHandler(error, dispatch);
    }
  }
);

export const updateCustomerQuickView: any = createAsyncThunk(
  "quick-views/assets/put",
  async (body: any, { dispatch }) => {
    try {
      const response = await queryInstance.put("/quick-views/assets", body);
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was updated",
        })
      );
      return response.data;
    } catch (error) {
      errorHandler(error, dispatch);
    }
  }
);

export const createCustomer: any = createAsyncThunk(
  "assets/createCustomer",
  async (payload: any, { dispatch, getState }) => {
    const state: any = getState();
    const { activeIssuefacilityId } = state.issueTrackerSlice;
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        `/assets/${payload.id}/customers`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The customer is created",
        })
      );
      payload.userKPENVC
        ? dispatch(getIssueTrackerCustomersList(activeIssuefacilityId))
        : dispatch(getCustomersList(payload.id));

      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteQuickView: any = createAsyncThunk(
  "assets/deleteQuickView",
  async (payload: any, { dispatch }) => {
    try {
      await queryInstance.delete(`/quick-views/assets/${payload}`);
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was deleted",
        })
      );
      return payload;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getEnvPermitsAssets: any = createAsyncThunk(
  "assets/getEnvPermitsAssets",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/assets/${payload}/env-permit`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateEnvPermitsAssets: any = createAsyncThunk(
  "assets/updateEnvPermitsAssets",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/assets/${payload.facilityId}/env-permit`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Env Permit is updated",
        })
      );
      await dispatch(getEnvPermitsAssets(payload.facilityId));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteEnvPermitAssets: any = createAsyncThunk(
  "assets/deleteEnvPermitAssets",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.delete(
        `/assets/${payload.envPermitsId}/env-permit`
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Env Permit is deleted",
        })
      );
      await dispatch(getEnvPermitsAssets(payload.activeIssueId));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getMuniPermitsAssets: any = createAsyncThunk(
  "assets/getMuniPermitsAssets",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/assets/${payload}/muni-permit`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteMuniPermitAssets: any = createAsyncThunk(
  "assets/deleteMuniPermitAssets",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.delete(
        `/assets/${payload.muniPermitsId}/muni-permit`
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Muni Permit is deleted",
        })
      );
      await dispatch(getMuniPermitsAssets(payload.activeIssueId));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateMuniPermitsAssets: any = createAsyncThunk(
  "assets/updateMuniPermitsAssets",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/assets/${payload.facilityId}/muni-permit`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Muni Permit is updated",
        })
      );
      await dispatch(getMuniPermitsAssets(payload.facilityId));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateFilteredRows: any = createAsyncThunk(
  "assets/massUpdate-filtered",
  async (payload: any, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const searchText = state.customersSlice.searchInputValue;
      const searchItems = searchText.substring(1).split("=");
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        `/assets/all-filters`,
        { searchText: searchItems[1], ...payload },
        { headers }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Mass Update was made",
        })
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateSelectedRows: any = createAsyncThunk(
  "assets/selected-massUpdate",
  async (payload: any, { getState, dispatch }) => {
    const newFields = { ...payload.fields };
    if (newFields.tlId) {
      delete newFields.tlId;
      newFields.tlid = payload.fields.tlId;
    }
    const body = { ...payload, fields: newFields };
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post("/assets/all", body, {
        headers,
      });
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Mass Update was made",
        })
      );
      const state: any = getState();
      const { sortingRules, filter } = state.customersSlice;
      dispatch(fetchCustomersData([0, sortingRules, filter, true]));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getPreflightCore: any = createAsyncThunk(
  "assets/getPreflightCore",
  async (payload: string, { dispatch }) => {
    try {
      const { data }: { data: any[] } = await queryInstance.get(
        `assets/${payload}/core`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
