import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, KmlLayer } from "@react-google-maps/api";
import { API_KEY, containerStyle, mapCenter } from "./Map.constants";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getFilteredGis, getMarkers } from "../../app/store/Map/map.async";
import { useAppSelector } from "../../app/hooks";
import Groups from "./components/Groups";
import Markers from "./components/Markers";
import {
  createFullScreenControl,
  createLayersControl,
  createMapTypeSelect,
} from "./Map.utils";
import LayersMenu from "@sdge-components/Map/components/LayersMenu";
import { Styled } from "./components/LayersMenu.styled";

const Map = () => {
  const { loadError } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
  });
  const [kmlUrl, setKmlUrl] = useState("");
  const [activeConstructionPolesList, setActiveConstructionPoleList] = useState<
    string[]
  >([]);
  const [bound, setBound] = useState<any>(null);
  const [center, setCenter] = useState(mapCenter);
  const [zoom, setZoom] = useState(12);
  const [mapType, setMapType] = useState("hybrid");
  const [activeMarker, setActiveMarker] = useState("");
  const dispatch = useDispatch();
  const buttonRef = useRef<any>(null);

  const {
    markers,
    groups,
    layers,
    rowData,
    constructionPinsCenter,
    customersPolesCenter,
  } = useAppSelector((state) => state.maps);
  const { activeConstructionGroupName } = useAppSelector(
    (state) => state.constructionSlice
  );
  const { filteringRules } = useAppSelector((state) => state.customersSlice);

  useEffect(() => {
    if (Object.keys(rowData)?.length) {
      setCenter({ lat: +rowData?.latitude, lng: +rowData?.longitude });
      setActiveMarker(rowData?.poleId || rowData?.facilityId);
      setZoom(22);
    }
  }, [rowData]);

  useEffect(() => {
    if (constructionPinsCenter && Object.keys(constructionPinsCenter)?.length) {
      setCenter({
        lat: constructionPinsCenter?.latitude,
        lng: constructionPinsCenter?.longitude,
      });
      if (markers.length) {
        const activeMarkers = markers.map((item) => item.poleId);
        setActiveConstructionPoleList(activeMarkers);
      }
      setZoom(12);
    }

    if (customersPolesCenter && Object.keys(customersPolesCenter)?.length) {
      setCenter({
        lat: customersPolesCenter?.latitude,
        lng: customersPolesCenter?.longitude,
      });
      setZoom(12);
    }
  }, [constructionPinsCenter, customersPolesCenter]);

  const maps = window?.google?.maps;

  const options = {
    panControl: false,
    zoomControl: true,
    mapTypeControl: false,
    controlDiv: true,
    mapTypeSelect: true,
    streetViewControl: false,
    fullScreenButton: true,
    mapTypeId: mapType,
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    fullscreenControl: false,
  };
  const [fullScreen, setFullScreen] = useState(false);
  const createControls = (map: any) => {
    setCenter(mapCenter);
    setZoom(12);
    const fullScreenButton = createFullScreenControl();
    fullScreenButton.addEventListener("click", () => {
      setFullScreen((prev) => !prev);
    });
    map.controls[google.maps.ControlPosition.RIGHT_TOP].push(fullScreenButton);
    const controlDiv = createLayersControl();
    controlDiv.addEventListener("click", () => {
      buttonRef?.current?.click();
      setKmlUrl("");
    });
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(controlDiv);
    const mapTypeSelect = createMapTypeSelect();
    mapTypeSelect.addEventListener("change", () => {
      setMapType(mapTypeSelect.options[mapTypeSelect.selectedIndex].value);
    });
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapTypeSelect);
    map.addListener("bounds_changed", () => {
      setBound(map.getBounds());
    });
    map.addListener("zoom_changed", () => {
      setZoom(map.getZoom());
    });
  };
  const timeout: { current: NodeJS.Timeout | null } = useRef(null);
  const boundString = JSON.stringify(bound);
  const boundParsed = JSON.parse(boundString);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      if (bound) {
        const payload = {
          minLatitude: boundParsed.south,
          maxLatitude: boundParsed.north,
          minLongitude: boundParsed.west,
          maxLongitude: boundParsed.east,
          currentZoom: zoom,
        };
        if (!activeConstructionGroupName && !filteringRules.length) {
          dispatch(getMarkers(payload));
        }
        if (!activeConstructionGroupName && filteringRules.length) {
          const body = [
            {
              operation: "range",
              negate: false,
              fieldName: "latitude",
              value: `${payload.minLatitude},${payload.maxLatitude}`,
              relation: "AND",
            },

            {
              operation: "range",
              negate: false,
              fieldName: "longitude",
              value: `${payload.minLongitude},${payload.maxLongitude}`,
              relation: "AND",
            },
          ];
          dispatch(
            getFilteredGis({ body: [...filteringRules, ...body], center: null })
          );
        }
      }
    }, 700);
  }, [zoom, boundString, filteringRules.length]);

  return (
    <Box
      pb={fullScreen ? "0" : 3}
      position={fullScreen ? "absolute" : "relative"}
      width={fullScreen ? "100vw" : "100%"}
      height={fullScreen ? "100vh" : "420px"}
      zIndex={fullScreen ? "1300" : ""}
      top={fullScreen ? "0" : ""}
      left={fullScreen ? "0" : ""}
    >
      <Styled.LayersContainer>
        {layers?.length && (
          <LayersMenu buttonRef={buttonRef} setKmlUrl={setKmlUrl} />
        )}
      </Styled.LayersContainer>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={options}
        onLoad={createControls}
        id="googleMap"
      >
        {kmlUrl && (
          <KmlLayer
            //TODO add functionality
            onLoad={(kmlLayer) => {
              // eslint-disable-next-line no-console
              console.log(kmlLayer, "моунт");
              kmlLayer.setUrl(kmlUrl);
            }}
            //TODO add functionality
            onUnmount={(kmlLayer) => {
              // eslint-disable-next-line no-console
              console.log(kmlLayer, "unmount");
            }}
            zIndex={1050}
            options={{ preserveViewport: false }}
          />
        )}
        {markers?.length && (
          <Markers
            markers={markers}
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
            activeConstructionPolesList={activeConstructionPolesList}
          />
        )}
        {groups?.length && <Groups groups={groups} />}
      </GoogleMap>
      {loadError && <div>Something went wrong</div>}
    </Box>
  );
};

export default React.memo(Map);
