import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "../../../../utils/globalTypes";
import { Box } from "@material-ui/core";
import { TextInput } from "@sdge-components";

const MassUpdateInputWrapper = styled(Box)`
  width: 180px;
  height: 32px;
`;
const MassUpdateInput = styled(TextInput)<StyledPropsWithTheme>`
  background: ${({ theme }) => theme.palette.tables?.main?.dark};
  border-radius: 4px;
  height: 36px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  padding: 8px;
`;

export const Styled = { MassUpdateInputWrapper, MassUpdateInput };
