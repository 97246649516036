const ROLE_ADMIN_DEV = "DEV_ROLE_DIAR_ADMIN";
const ROLE_ADMIN_PROD = "ROLE_DIAR_ADMIN";
const ROLE_KP_E2_CONST_DEV = "DEV_ROLE_KP_E2_CONST";
const ROLE_KP_E2_CONST_PROD = "ROLE_KP_E2_CONST";
const ROLE_E2_EnD_DEV = "DEV_ROLE_E2_EnD";
const ROLE_E2_EnD_PROD = "ROLE_E2_EnD";

export const Constants = {
  roleAdminDev: ROLE_ADMIN_DEV,
  roleAdminProd: ROLE_ADMIN_PROD,
  roleKpE2ConstDev: ROLE_KP_E2_CONST_DEV,
  roleKpE2ConstProd: ROLE_KP_E2_CONST_PROD,
  roleE2EndDev: ROLE_E2_EnD_DEV,
  roleE2EndProd: ROLE_E2_EnD_PROD,
};
