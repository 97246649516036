import {
  getLength,
  setHeight,
} from "@sdge-utils/helpers/gridHelpers/gridHelpers";
import React, { useState } from "react";
import { MenuDownIcon, MenuUpIcon } from "../../../../icons";
import ButtonTable from "./ButtonTable";
import { Styled } from "./ExtendingButton.styled";

const ExtendingButton = ({ data, value, gridApi, columnApi }: any) => {
  const [isVisible, setIsVisible] = useState(false);
  if (!value) {
    return null;
  }

  const handleExtensionList = () => {
    setIsVisible((prev) => !prev);
    setHeight(
      getLength(data.packages.length),
      gridApi,
      columnApi,
      value,
      isVisible
    );
  };

  return (
    <Styled.ExtendingButtonWrapper>
      <Styled.ButtonWrapper onClick={() => handleExtensionList()}>
        {value}
        <Styled.IconWrapper>
          {isVisible ? <MenuUpIcon /> : <MenuDownIcon />}
        </Styled.IconWrapper>
      </Styled.ButtonWrapper>
      {isVisible && data.packages && (
        <Styled.ListWrapper>
          <ButtonTable rowData={data.packages} />
        </Styled.ListWrapper>
      )}
    </Styled.ExtendingButtonWrapper>
  );
};

export default ExtendingButton;
