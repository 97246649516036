import * as yup from "yup";

const CreateEnvPermitsValidationSchema = yup.object().shape({
  quickRelease: yup.string().required(),
  ets: yup.string().required(),
  // envReleaseDateSubmitted: yup.string().required(),
  // envReleaseIssuedDate: yup.string().required(),
  specialRequirements: yup.string(),
});

export default CreateEnvPermitsValidationSchema;
