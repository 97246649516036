import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "../../utils/globalTypes";
import { Box, InputBase } from "@material-ui/core";

const SearchInputWrapper = styled(Box)`
  width: 200px;
`;
const SearchInput = styled(InputBase)<StyledPropsWithTheme>`
  background: ${({ theme }) => theme.palette.tables?.main?.dark};
  border-radius: 4px;
  height: 36px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  padding: 8px;
`;

export const Styled = { SearchInputWrapper, SearchInput };
