const CheckMark = ({ size = 16, ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.2759 10.3905L3.60931 7.72378L2.6665 8.66659L6.2759 12.2761L13.6092 4.94282L12.6664 4.00001L6.2759 10.3905Z"
        fill="#8C8F9B"
      />
    </svg>
  );
};

export default CheckMark;
