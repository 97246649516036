import { Styled } from "./ArrowRight.styled";

const ArrowRight = ({ size = 24, pointer = false, ...rest }) => {
  return (
    <Styled.ArrowRight
      pointer={pointer}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="#AFB1BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.ArrowRight>
  );
};

export default ArrowRight;
