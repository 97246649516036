import React, { FC, useRef, useEffect, useState } from "react";
import { Styled } from "./FilterOptionsMenu.styled";
import {
  handleFilter,
  handleFilteringRules,
  handleSelectedRows,
  handleSelecedRowsIssueIds,
} from "@sdge-store/issueTracker/issueTracker.slice";
import {
  filterIssueData,
  sortIssueData,
} from "@sdge-store/issueTracker/issueTracker.async";
import { selectIssueTracker } from "@sdge-store/issueTracker/issueTracker.selector";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { generateFilterArr } from "../../utils/helpers/filterHelpers/filterHelpers";
import { FilterOptionsItem } from "./components";
import { AddIcon } from "../../icons";
import { Constants } from "@sdge-store/issueTracker/constants/issueTracker.constants";

const IssueTrackerFilterOptionsMenu: FC = () => {
  const [error, setError] = useState(false);

  const { columnDefs, currentFilter, sortingRules, filteringRules } =
    useAppSelector(selectIssueTracker);
  useEffect(() => {
    if (
      currentFilter.some(
        (item) => item.errorMessage && item.operation !== "empty"
      )
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [currentFilter]);

  const dispatch = useAppDispatch();
  const listEnd = useRef<any>();

  const filteringColumnDefs = [
    ...columnDefs,
    ...Constants.additionalIssueColumnDefs,
  ];

  const scrollToBottom = () => {
    listEnd.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [currentFilter.length]);

  const validColumns = filteringColumnDefs
    .filter(({ field }) => field)
    .filter(({ filtering }) => filtering);

  const applyFilterHandler = () => {
    if (currentFilter.length > 0) {
      const trimmedCurrentFilter = currentFilter.map((item) => {
        return {
          ...item,
          value: item.operation !== "empty" ? item.value.trim() : "",
        };
      });
      dispatch(filterIssueData([0, sortingRules, trimmedCurrentFilter]));
      dispatch(handleFilteringRules(trimmedCurrentFilter));
      dispatch(handleSelectedRows([]));
      dispatch(handleSelecedRowsIssueIds([]));
    }
  };

  return (
    <Styled.FilterOptionsWrapper
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Styled.FilterOptionsList>
        {currentFilter.map((item, index) => (
          <FilterOptionsItem
            key={index}
            field={item.fieldName}
            index={index}
            relation={item.relation}
            negate={item.negate}
            operation={item.operation}
            columnDefs={Constants.filteringOptions}
            currentFilter={currentFilter}
            filteringRules={filteringRules}
            sortingRules={sortingRules}
            handleFilter={handleFilter}
            filterData={filterIssueData}
            sortData={sortIssueData}
            handleFilteringRules={handleFilteringRules}
            advancedOptions
          />
        ))}
        <Styled.ListBottom ref={listEnd}></Styled.ListBottom>
      </Styled.FilterOptionsList>
      <Styled.AddFilterWrapper>
        <Styled.FilterButton
          icon={AddIcon}
          title="Add filter"
          onClick={() =>
            dispatch(
              handleFilter(
                generateFilterArr(
                  currentFilter,
                  validColumns[0].filterField || validColumns[0].field,
                  currentFilter
                )
              )
            )
          }
        />
        {currentFilter.length > 0 && (
          <>
            <Styled.FilterButton
              title="Apply filter"
              isActive
              onClick={applyFilterHandler}
              disabled={error}
            />
            <Styled.DecorateButton
              onClick={(e) => {
                e.preventDefault();
                applyFilterHandler();
              }}
              type="submit"
            />
          </>
        )}
      </Styled.AddFilterWrapper>
    </Styled.FilterOptionsWrapper>
  );
};

export default IssueTrackerFilterOptionsMenu;
