import { camelCaseTransform } from "@sdge-utils/dataTransform";
import { useAppSelector } from "../../../../app/hooks";

const usePreflightInfoCreator = () => {
  const { preflightCore } = useAppSelector((state) => state.customersSlice);

  const preflightInfo = preflightCore.map((item: any, index: number) => {
    const infoKeys = Object.keys(item);
    const itemArray = infoKeys.map((key, keyIndex) => {
      return {
        title: camelCaseTransform(key),
        value: item[key] || "-",
        ...(key === "assesmentReportLink" ? { link: item[key] || "" } : {}),
        id: index + keyIndex,
      };
    });
    return itemArray;
  });
  return preflightCore.length ? preflightInfo : [];
};

export default usePreflightInfoCreator;
