import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const RowButtonWrapper = styled(Box)<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  color: ${({ theme }) => theme.palette.selectInput.selectIcon};
  letter-spacing: 0.03em;
  font-size: 11px;
  width: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export const Styled = {
  RowButtonWrapper,
};
