import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const AttachButtonWrapper = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  flex-direction: column;
`;

const AttachInput = styled.input`
  display: none;
`;
const FileName = styled(Box)`
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FileItem = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
export const Styled = { AttachButtonWrapper, AttachInput, FileItem, FileName };
