import React, { FC } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { Styled } from "./FilterOptionsItem.styled";
import SelectFilterItem from "../SelectFilterItem";
import { Button, DatePickerInput, DatePickerRange } from "@sdge-components";
import { CloseIcon, DeleteIcon } from "../../../../icons";
import FilterInput from "../FilterInput";
import {
  dateFilters,
  generateCurrentInfo,
  generateFilterDelete,
  generateFilteringCols,
  logicals,
  overlappingConditions,
  setInputFilter,
  issueConditions,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import { FilterOptionsItemType } from "./FilterOptionsItem.types";
import { useForm } from "react-hook-form";
import { parseDateString } from "@sdge-utils/dateParser";
import { yupResolver } from "@hookform/resolvers/yup";

import dateFilterValidationSchema from "./FilterOptionsItem.validation";
import { IconButton } from "@material-ui/core";
import { Icon } from "react-feather";
import { useLocation } from "react-router";
import { Constants } from "./FilterOptionItem.constants";

const FilterOptionsItem: FC<FilterOptionsItemType> = ({
  index,
  field,
  relation,
  negate,
  operation,
  columnDefs,
  currentFilter,
  filteringRules,
  sortingRules,
  handleFilter,
  filterData,
  sortData,
  handleFilteringRules,
  pageName,
  advancedOptions,
}) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(dateFilterValidationSchema),
  });
  const { pathname } = useLocation();

  const colInfo = generateCurrentInfo(advancedOptions, columnDefs, field);

  const unfilteredCols = columnDefs
    .filter(({ headerName }) => headerName)
    .filter(({ filtering }) => filtering);

  const filteringCols = generateFilteringCols(
    advancedOptions,
    columnDefs,
    unfilteredCols
  );

  const currFilterInfo = currentFilter.filter(
    (item, itemIndex) => itemIndex === index
  );

  const currInputValue = currFilterInfo[0].value;
  //current relation info
  const unusedRelation = logicals.filter((item) => item.value !== relation);

  const currentFilterRelation = logicals.filter(
    (item) => item.value === relation
  );
  //current operation info
  const unusedOperations = overlappingConditions.filter(
    (item) =>
      !JSON.stringify(item.value).includes(JSON.stringify([operation, negate]))
  );

  const isDate = dateFilters.some(
    (item) =>
      currFilterInfo[0].fieldName.toLowerCase().includes(item) &&
      currFilterInfo[0].fieldName.toLowerCase() !== "status.days_until_mfb_date"
  );

  const unusedOperationDate = overlappingConditions
    .filter(
      (item) =>
        item.label === "equals" ||
        item.label === "not equals" ||
        item.label === "contains"
    )
    .filter(
      (item) =>
        !JSON.stringify(item.value).includes(
          JSON.stringify([operation, negate])
        )
    );
  const unusedOperationDateValue = issueConditions
    .filter(
      (item) =>
        item.label === "equals" ||
        item.label === "not equals" ||
        item.label === "contains" ||
        item.label === "is empty" ||
        item.label === "not empty" ||
        item.label === "not contains" ||
        (isDate && item.label === "range")
    )
    .filter(
      (item) =>
        !JSON.stringify(item.value).includes(
          JSON.stringify([operation, negate])
        )
    );
  const currentFilterOperation = overlappingConditions.filter((item) =>
    JSON.stringify(item.value).includes(JSON.stringify([operation, negate]))
  );
  const currentIssueFilterOperation = issueConditions.filter((item) =>
    JSON.stringify(item.value).includes(JSON.stringify([operation, negate]))
  );

  const isRange = currentIssueFilterOperation[0].label === "range";

  const onChange = (e: any) => {
    const value = typeof e === "string" ? e : e.target.value;
    dispatch(
      handleFilter(
        setInputFilter(currentFilter, value, "value", currFilterInfo, index)
      )
    );
  };

  const onDateChange = (date: any) => {
    const [startDate, endDate] = isRange ? date.date.split(", ") : ["", ""];

    if (!date.date) {
      return;
    } else if (!isRange) {
      dispatch(
        handleFilter(
          setInputFilter(
            currentFilter,
            parseDateString(date.date),
            "value",
            currFilterInfo,
            index
          )
        )
      );
    } else if (
      startDate &&
      endDate &&
      startDate !== "Invalid Date" &&
      endDate !== "Invalid Date"
    ) {
      dispatch(
        handleFilter(
          setInputFilter(
            currentFilter,
            date.date,
            "value",
            currFilterInfo,
            index
          )
        )
      );
    }
  };
  const deleteFilterItem = (index: number) => {
    const ifFoundInFilters = currentFilter.findIndex(
      (item, itemIndex) => itemIndex === index
    );
    const ifFoundInFilteringRules = filteringRules.findIndex(
      (item, itemIndex) => itemIndex === index
    );
    const fixedFilter = currentFilter.filter(
      (item, itemIndex) => itemIndex !== index
    );
    const fixedFilteringRules = filteringRules.filter(
      (item, itemIndex) => itemIndex !== index
    );
    const newFilter = generateFilterDelete(fixedFilter, pageName);
    const newFilteringRules = generateFilterDelete(
      fixedFilteringRules,
      pageName
    );

    if (ifFoundInFilters !== -1 && ifFoundInFilteringRules === -1) {
      dispatch(handleFilter(newFilter));
    } else if (ifFoundInFilters !== -1 && ifFoundInFilteringRules !== -1) {
      dispatch(handleFilter(newFilter));
      if (newFilteringRules?.length === 0) {
        dispatch(handleFilteringRules(newFilteringRules));
        dispatch(sortData([0, sortingRules, newFilteringRules]));
      } else {
        dispatch(handleFilteringRules(newFilteringRules));
        dispatch(filterData([0, sortingRules, newFilteringRules]));
      }
    }
  };

  const isNumber = (currentInput: any) => {
    if (Date.parse(currentInput)) {
      return currentInput;
    } else {
      return "";
    }
  };
  const isDisabled =
    currentIssueFilterOperation[0].label === "is empty" ||
    currentIssueFilterOperation[0].label === "not empty";

  return (
    <Styled.FilterOptionsWrapper>
      {index === 0 ? (
        "Where"
      ) : (
        <SelectFilterItem
          index={index}
          currentFilter={currentFilter}
          handleFilter={handleFilter}
          optionsData={unusedRelation}
          property={"relation"}
          width={"60px"}
          currFilterInfo={currFilterInfo}
          currentField={
            advancedOptions
              ? colInfo[0][0].filterField || colInfo[0][0].field
              : colInfo[0].filterField || colInfo[0].field
          }
          currentSelect={currentFilterRelation[0].label}
        />
      )}

      <SelectFilterItem
        index={index}
        currentFilter={currentFilter}
        handleFilter={handleFilter}
        optionsData={filteringCols}
        property={"fieldName"}
        currentSelect={
          advancedOptions ? colInfo[0][0].headerName : colInfo[0].headerName
        }
        currentField={
          advancedOptions
            ? colInfo[0][0].filterField || colInfo[0][0].field
            : colInfo[0].filterField || colInfo[0].field
        }
        currFilterInfo={currFilterInfo}
        advancedOptions={advancedOptions}
      />

      <SelectFilterItem
        index={index}
        currentFilter={currentFilter}
        handleFilter={handleFilter}
        optionsData={
          pathname.includes("overlapping")
            ? field.toLowerCase().includes("date") ||
              field.toLowerCase().includes("interaction")
              ? unusedOperationDate
              : unusedOperations
            : unusedOperationDateValue
        }
        property={["operation", "negate"]}
        currentField={
          advancedOptions
            ? colInfo[0][0].filterField || colInfo[0][0].field
            : colInfo[0].filterField || colInfo[0].field
        }
        currFilterInfo={currFilterInfo}
        currentSelect={
          pathname.includes("overlapping")
            ? currentFilterOperation[0].label
            : currentIssueFilterOperation[0].label
        }
      />
      {!isDisabled &&
        (isDate ? (
          <>
            <Styled.PickerWrapper onClick={handleSubmit(onDateChange)}>
              {isRange ? (
                <DatePickerRange
                  control={control}
                  name="date"
                  readOnly
                  defaultValue={currFilterInfo[0].value}
                />
              ) : (
                <DatePickerInput
                  control={control}
                  name="date"
                  readOnly
                  defaultValue={isNumber(currInputValue)}
                />
              )}
            </Styled.PickerWrapper>
            <IconButton
              onClick={() => {
                reset({ date: "" });
                dispatch(
                  handleFilter(
                    setInputFilter(
                      currentFilter,
                      "",
                      "value",
                      currFilterInfo,
                      index
                    )
                  )
                );
              }}
            >
              <CloseIcon size={15} />
            </IconButton>
          </>
        ) : (
          <FilterInput
            currentFilter={currFilterInfo}
            onFilterTextChange={onChange}
            value={currInputValue}
            autoComplete={
              Constants.generateAutoCompleteField(
                currFilterInfo[0].fieldName.toLowerCase()
              ) || currFilterInfo[0].fieldName.toLowerCase()
            }
            errorMessage={currFilterInfo[0].errorMessage || ""}
          />
        ))}
      <Button
        icon={DeleteIcon as Icon}
        onClick={() => {
          deleteFilterItem(index);
        }}
      />
    </Styled.FilterOptionsWrapper>
  );
};

export default FilterOptionsItem;
