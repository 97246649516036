import React, { useRef, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { Button, LoadingWrapper } from "@sdge-components";
import { Styled } from "../../CellInfo.styled";
import { useForm } from "react-hook-form";
import { setProgramInfoConstruction } from "@sdge-store/drawer/drawer.async";
import { CircularProgress } from "@mui/material";
import useConstructionInfoContent from "./useConstructionInfoContent";
import DrawerRowItem from "../DrawerRowItem";
import { parseRowDateString } from "@sdge-utils/dateParser";
import { useLocation } from "react-router-dom";

const ConstructionInfo = () => {
  const dispatch = useAppDispatch();
  const {
    programInfo,
    isLoaded,
    programInfoLoading,
    rightSideBarUpdateLoading,
  } = useAppSelector((state) => state.drawerState.programData);
  const { control, handleSubmit, reset, setValue, getValues } = useForm();
  const dataExistent = !!Object.keys(programInfo).length;
  const { writePermissions } = useAppSelector((state) => state.userSlice.info);
  const formRef = useRef(null);
  const [isChanged, setIsChanged] = useState(false);
  const { pathname } = useLocation();
  const constructionPage = pathname.split("/").includes("construction");
  const handleFormSubmit = (data: any) => {
    const newData = {
      ...data,
      actualIfc: data?.actualIfc ? data.actualIfc : "0000-00-00",
      forecastedIfc: data?.forecastedIfc ? data.forecastedIfc : "0000-00-00",
    };
    dispatch(setProgramInfoConstruction(newData));

    setIsChanged(false);
  };
  const resetDate = (field: string) => {
    reset({ ...getValues(), [field]: "" });
  };
  const rightSideBarpermissions = [
    "ballincourt",
    "midpoint",
    "overhead",
    "underground",
    "jucfRequired",
    "pidsUpload",
    "actualIfc",
    "issueStatus",
    "designCompletedAccepted",
    "onHoldStatus",
    "onHoldStatusComment",
  ];

  const isAnyNotEditable = rightSideBarpermissions.some((item) => {
    if (writePermissions.includes("editEro") && constructionPage) {
      return false;
    }
    return (
      writePermissions.includes(item) ||
      item === "designCompletedAccepted" ||
      item === "onHoldStatus" ||
      item === "onHoldStatusComment"
    );
  });

  useEffect(() => {
    reset({ ...programInfo });
    setIsChanged(false);
    return () => {
      reset();
    };
  }, [programInfo]);

  const { constructionContent, itemsOrder } = useConstructionInfoContent();

  return (
    <LoadingWrapper loading={programInfoLoading}>
      {rightSideBarUpdateLoading && (
        <Styled.LoadingLayer>
          <CircularProgress color="inherit" />
        </Styled.LoadingLayer>
      )}
      {dataExistent && isLoaded ? (
        <Styled.CellInfoForm
          onSubmit={(e) => {
            e.preventDefault();
            setIsChanged(true);
          }}
          ref={formRef}
        >
          {isAnyNotEditable && (
            <Styled.Row>
              <Button
                onClick={handleSubmit(handleFormSubmit)}
                title="Update Group"
                isActive={true}
                disabled={!isChanged}
              />
            </Styled.Row>
          )}
          {Object.keys(programInfo)
            .sort((a, b) => itemsOrder.indexOf(a) - itemsOrder.indexOf(b))
            .map((item, index) => {
              const content =
                constructionContent[item as keyof typeof constructionContent];
              const dateItem = ["projectedIfc", "actualIfc", "forecastedIfc"];
              return (
                <DrawerRowItem
                  key={index}
                  title={content?.title}
                  name={item}
                  content={
                    dateItem.includes(item) &&
                    parseRowDateString(programInfo[item])
                      ? parseRowDateString(programInfo[item])
                      : programInfo[item]
                  }
                  optionsData={content?.optionsData}
                  control={control}
                  formRef={formRef}
                  permission={content?.permission}
                  autoComplete={content?.autoComplete}
                  isEdit={content?.isEdit}
                  isDate={content?.isDate}
                  onSubmit={() => setIsChanged(true)}
                  isLink={content?.isLink}
                  setValue={setValue}
                  reset={resetDate}
                />
              );
            })}
        </Styled.CellInfoForm>
      ) : (
        <Styled.NoInfoWrapper>
          No information for this group
        </Styled.NoInfoWrapper>
      )}
    </LoadingWrapper>
  );
};

export default ConstructionInfo;
