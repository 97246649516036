import * as yup from "yup";

export const interactionValidationSchema = yup.object().shape({
  comment: yup.string().required(),
  date: yup.date().required(),
  emails: yup.mixed().when("interactionType", {
    is: "EMAIL",
    then: yup.array().min(1).of(yup.string().email().required()).required(),
  }),
  interactionType: yup.string().required(),
  file: yup.mixed(),
});

export const interactionByGroupNameValidationSchema = yup.object().shape({
  description: yup.string().required(),
  comment: yup.string().required(),
  date: yup.date().required(),
  emails: yup.mixed().when("interactionType", {
    is: "EMAIL",
    then: yup.array().min(1).of(yup.string().email().required()).required(),
  }),
  interactionType: yup.string().required(),
  file: yup.mixed(),
});
