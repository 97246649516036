import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryInstance } from "../../../api/config";
import { errorHandler } from "../../../api/utils";
import { getMarkerInfoPayloadType, getMarkerPayloadType } from "./map.types";

export const getMarkers: any = createAsyncThunk(
  "maps/getMarkers",
  async (payload: getMarkerPayloadType, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/gis?minLatitude=${payload.minLatitude}&maxLatitude=${payload.maxLatitude}&minLongitude=${payload.minLongitude}&maxLongitude=${payload.maxLongitude}&currentZoom=${payload.currentZoom}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getMarkerInfo: any = createAsyncThunk(
  "maps/getMarkerInfo",
  async (payload: getMarkerInfoPayloadType, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `overlapping_programs/byPole?poleId=${payload}`
      );
      return data[0];
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getLayers: any = createAsyncThunk(
  "maps/getLayers",
  async (_, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/gis/layers`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteLayer: any = createAsyncThunk(
  "maps/deleteLayer",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.delete(`/gis/layers/${payload}`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getCoordinates: any = createAsyncThunk(
  "maps/getCoordinates",
  async (payload: string, { dispatch }) => {
    try {
      const { data }: { data: any[] } = await queryInstance.get(
        `/gis/group?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getFilteredGis: any = createAsyncThunk(
  "maps/getFilteredGis",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.post(`/assets/gis`, payload.body);
      return { data, center: payload?.center };
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
