import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../../../utils/globalTypes";

const MassUpdateOptionsWrapper = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;
const PickerWrapper = styled.form`
  width: 170px;
`;
export const Styled = { MassUpdateOptionsWrapper, PickerWrapper };
