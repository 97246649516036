import { FC } from "react";

const Filter: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8H19"
        stroke="#E3E4E7"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7 13H17"
        stroke="#E3E4E7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M9 17H15" stroke="#E3E4E7" strokeLinecap="round" />
    </svg>
  );
};

export default Filter;
