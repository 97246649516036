import { Link } from "@sdge-components";
import {
  ExtendingButton,
  RowIcon,
} from "@sdge-components/MainTable/components";
import customer from "./customer";
import envPermits from "./envPermits";
import muniPermits from "./muniPermits";
import overlapping from "./overlapping";
import sap from "./sap";
import status from "./status";

export const columnDefs = [
  {
    cellRendererFramework: (params: any) => <RowIcon data={params.data} />,
    suppressMenu: true,
    sorting: false,
    filtering: false,
    suppressMovable: true,
    lockPosition: true,
    colId: "event",
    lockPinned: true,
    minWidth: 155,
    suppressNavigable: true,
    pinned: true,
  },
  {
    maxWidth: 50,
    suppressMenu: true,
    suppressMovable: true,
    lockPosition: true,
    colId: "checkbox",
    lockPinned: true,
    pinned: true,
    resizable: false,
    suppressNavigable: true,
  },
  {
    headerName: "Group Name",
    field: "groupName",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "group_name",
    sortField: "group_name.keyword",
    minHeight: 40,
    minWidth: 310,
    cellRendererFramework: (params: any) => {
      const { data, value, api, columnApi } = params;
      return (
        <ExtendingButton
          data={data}
          value={value}
          gridApi={api}
          columnApi={columnApi}
        />
      );
    },
  },
  {
    headerName: "Ball In Court",
    field: "ballInCourt",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "ballincourt",
    sortField: "ballincourt.keyword",
    docEdit: true,
  },
  {
    headerName: "Actual IFC",
    field: "actualIfc",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "actual_ifc",
    sortField: "actual_ifc.keyword",
    docEdit: true,
  },
  {
    headerName: "Closeout Date",
    field: "forecastedIfc",
    hide: false,
    pinned: null,
    sorting: true,
    edit: true,
    filtering: true,
    filterField: "forecasted_ifc",
    sortField: "forecasted_ifc.keyword",
  },
  {
    headerName: "Design Completed Accepted",
    field: "designCompletedAccepted",
    hide: false,
    pinned: null,
    sorting: true,
    edit: true,
    filtering: true,
    filterField: "design_completed_accepted",
    sortField: "design_completed_accepted.keyword",
    docEdit: true,
  },
  {
    headerName: "Group Classification",
    field: "eng",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "eng",
    sortField: "eng.keyword",
  },
  {
    headerName: "JUCF Required ",
    field: "jucfRequired",
    hide: false,
    pinned: null,
    sorting: true,
    edit: true,
    filtering: true,
    filterField: "jucf_required",
    sortField: "jucf_required.keyword",
    docEdit: true,
  },
  {
    headerName: "BMCD Tier-3",
    field: "bmcdTier3",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "bmcd_tier_3",
    sortField: "bmcd_tier_3.keyword",
  },

  {
    headerName: "KMZ Link",
    field: "kmzLink",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    cellRendererFramework: ({ data }: any) => {
      return (
        <>
          {data.kmzLink === "N/A" ? (
            "N/A"
          ) : !data.kmzLink ? (
            ""
          ) : (
            <Link
              link={data.kmzLink}
              name={data.kmzLink === "N/A" ? "N/A" : "KMZ Link"}
            />
          )}
        </>
      );
    },
    filterField: "kmz_link",
    sortField: "kmz_link.keyword",
  },
  // {
  //   headerName: "List Agg",
  //   field: "listAgg",
  //   sortField: "list_agg",
  //   filterField: "list_agg.keyword",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Mid-Point Coordinates",
    field: "midpoint",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "midpoint",
    sortField: "midpoint.keyword",
  },
  {
    headerName: "Number of Poles",
    field: "numberOfPoles",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "%23_of_poles",
    sortField: "%23_of_poles.keyword",
  },
  {
    headerName: "Number of Pole Replacements",
    field: "numberOfPolesReplacement",
    filterField: "%23_of_poles_replacement",
    sortField: "%23_of_poles_replacement.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "On-Hold Status",
    field: "onHoldStatus",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "on_hold_status",
    sortField: "on_hold_status.keyword",
    docEdit: true,
  },
  {
    headerName: "Comments",
    field: "onHoldStatusComment",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "on_hold_status_comment",
    sortField: "on_hold_status_comment.keyword",
    docEdit: true,
  },
  {
    headerName: "Overhead",
    field: "overhead",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "overhead",
    sortField: "overhead.keyword",
    docEdit: true,
  },
  {
    headerName: "PIDS Upload",
    field: "pidsUpload",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "pids_upload",
    sortField: "pids_upload.keyword",
    docEdit: true,
  },
  {
    headerName: "Group Priority",
    field: "priority",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "priority",
    sortField: "priority.keyword",
  },
  {
    headerName: "Procore Link",
    field: "procoreLink",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    cellRendererFramework: ({ data }: any) => {
      return (
        <>
          {data.procoreLink === "N/A" ? (
            "N/A"
          ) : !data.procoreLink ? (
            ""
          ) : (
            <Link
              link={data.procoreLink}
              name={data.procoreLink === "N/A" ? "N/A" : "Procore Link"}
            />
          )}
        </>
      );
    },
    filterField: "procore_link",
    sortField: "procore_link.keyword",
  },
  {
    headerName: "Projected Design Complete",
    field: "projectedIfc",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "projected_ifc",
    sortField: "projected_ifc.keyword",
  },
  {
    headerName: "Status",
    field: "issueStatus",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "issue_status",
    sortField: "issue_status.keyword",
    docEdit: true,
  },
  {
    headerName: "Underground",
    field: "underground",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    filterField: "underground",
    sortField: "underground.keyword",
    docEdit: true,
  },
];

const additionalConstructionColumnDefs = [
  {
    field: "asbuiltsAccepted",
    filtering: true,
    sortField: "asbuilts_accepted",
    headerName: "As Builts Accepted",
    filterField: "status.asbuilts_accepted",
  },
  {
    field: "asbuiltsSubmitted",
    filtering: true,
    sortField: "asbuilts_submitted",
    headerName: "As Builts Submitted",
    filterField: "status.asbuilts_submitted",
  },
  {
    field: "assignedContractor",
    filtering: true,
    sortField: "assigned_contractor",
    headerName: "Assigned Contractor",
    filterField: "status.assigned_contractor",
  },
  {
    field: "constructionCompleteInPercent",
    filtering: true,
    sortField: "construction_complete_in_percent",
    headerName: "Construction Complete in Percent",
    filterField: "status.construction_complete_in_percent",
  },
  {
    field: "constructionCompletion",
    filtering: true,
    sortField: "construction_completion",
    headerName: "Construction Completion",
    filterField: "status.construction_completion",
  },
  {
    field: "constructionMbfDate",
    filtering: true,
    sortField: "construction_mbf_date",
    headerName: "Construction Mbf Date",
    filterField: "status.construction_mbf_date",
  },
  {
    field: "constructionPostcard",
    filtering: true,
    sortField: "construction_postcard",
    headerName: "Construction Postcard",
    filterField: "status.construction_postcard",
  },
  {
    field: "constructionStart",
    filtering: true,
    sortField: "construction_start",
    headerName: "Construction Start",
    filterField: "status.construction_start",
  },
  {
    field: "daysPastGoal",
    filtering: true,
    sortField: "days_past_goal",
    headerName: "Days Past Goal",
    filterField: "status.days_past_goal",
  },
  {
    field: "daysUntilMfbDate",
    filtering: true,
    sortField: "days_until_mfb_date",
    headerName: "Days Until Mfb Date",
    filterField: "status.days_until_mfb_date",
  },
  // {
  //   field: "deferralGoalDate",
  //   filtering: true,
  //   sortField: "deferral_goal_date",
  //   headerName: "Deferral Goal Date",
  //   filterField: "status.deferral_goal_date",
  // },
  // {
  //   field: "deferralNotificationDate",
  //   filtering: true,
  //   sortField: "deferral_notification_date",
  //   headerName: "Deferral Notification Date",
  //   filterField: "status.deferral_notification_date",
  // },
  // {
  //   field: "deferralReason",
  //   filtering: true,
  //   sortField: "deferral_reason",
  //   headerName: "Deferral Reason",
  //   filterField: "status.deferral_reason",
  // },
  // {
  //   field: "delayDeferredTo",
  //   filtering: true,
  //   sortField: "delay_deferred_to",
  //   headerName: "Delay Deferred To",
  //   filterField: "status.delay_deferred_to",
  // },
  // {
  //   field: "delayNotes",
  //   filtering: true,
  //   sortField: "delay_notes",
  //   headerName: "Delay Notes",
  //   filterField: "status.delay_notes",
  // },
  {
    field: "estimatedCompletionDate",
    filtering: true,
    sortField: "estimated_completion_date",
    headerName: "Estimated Completion Date",
    filterField: "status.estimated_completion_date",
  },
  {
    field: "estimatedStartDate",
    filtering: true,
    sortField: "estimated_start_date",
    headerName: "Estimated Start Date",
    filterField: "status.estimated_start_date",
  },
  {
    field: "jobStatus",
    filtering: true,
    sortField: "job_status",
    headerName: "Job Status",
    filterField: "status.job_status",
  },
  {
    field: "wo",
    // filtering: true,
    sortField: "wo",
    headerName: "Wo",
    filterField: "sap.wo#53xxxx",
  },
  {
    field: "mcuOrder",
    filtering: true,
    sortField: "mcu_order",
    headerName: "Mcu Order",
    filterField: "sap.mcu_order",
  },
  {
    field: "contractorNumber",
    filtering: true,
    sortField: "contractor_number",
    headerName: "Contractor Number",
    filterField: "sap.contractor_number",
  },
  {
    field: "sapJnFormSubmittedDate",
    filtering: true,
    sortField: "sap_jn_form_submitted_date",
    headerName: "Sap Jn FormSubmitted Date",
    filterField: "sap.sap_jn_form_submitted_date",
  },
  // {
  //   field: "projectNumber",
  //   filtering: true,
  //   sortField: "project_number",
  //   headerName: "Project Number",
  //   filterField: "sap.project_number",
  // },
  // {
  //   field: "jobNumber",
  //   filtering: true,
  //   sortField: "job_number",
  //   headerName: "Job Number",
  //   filterField: "sap.job_number",
  // },
  {
    field: "jobNotificationOpenDate",
    filtering: true,
    sortField: "job_notification_open_date",
    headerName: "Job Notification Open Date",
    filterField: "sap.job_notification_open_date",
  },
];
const columnsOrder = columnDefs.slice(1).map((item) => item.field);

const filteringOptions = [
  {
    headerName: "Grid Values",
    filtering: true,
    children: columnDefs.filter((item) => item.headerName),
  },
  { headerName: "Env Permits", filtering: true, children: envPermits },
  { headerName: "Muni Permits", filtering: true, children: muniPermits },
  { headerName: "Customer", filtering: true, children: customer },
  { headerName: "Overlapping", filtering: true, children: overlapping },
  { headerName: "Status", filtering: true, children: status },
  { headerName: "Sap", filtering: true, children: sap },
];

export const Constants = {
  columnDefs,
  columnsOrder,
  additionalConstructionColumnDefs,
  filteringOptions,
};
