import { Styled } from "./CellHistory.styled";
import { useAppSelector } from "../../../../app/hooks";
import { HistoryItem } from "@sdge-assets/OverlappingDrawer/Components/CellHistory/CellHistory.types";
import { parseDateString } from "@sdge-utils/dateParser";

const CellHistory = () => {
  const { changes, displayName, owner, changeTime } = useAppSelector(
    (state) => state.drawerState.selectedNotification
  );
  if (!changes?.length) {
    return <Styled.NoHistory>No History</Styled.NoHistory>;
  }
  return (
    <Styled.HistoryWrapper>
      {owner && (
        <Styled.Header>{`${owner} at ${
          changeTime
            ? parseDateString(changeTime, "MM/DD/YYYY HH:mm:ss")
            : "N/A"
        }`}</Styled.Header>
      )}
      {changes.map((item: HistoryItem, index: number) => {
        return (
          <Styled.HistoryItem key={index}>
            <Styled.Body>
              <Styled.Text>{displayName || item.affectedField}:</Styled.Text>
              <Styled.Text>{`${item.previousValue} → ${item.currentValue}`}</Styled.Text>
            </Styled.Body>
          </Styled.HistoryItem>
        );
      })}
    </Styled.HistoryWrapper>
  );
};

export default CellHistory;
