import { Styled } from "./QuickActionDrawer.styled";
import { CircularProgress } from "@material-ui/core";
import * as React from "react";
import { StandardButton } from "@sdge-components";
import { useDispatch, useSelector } from "react-redux";
import { startAggregation, startGating } from "@sdge-store/drawer/drawer.async";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import { normalizedSeconds } from "@sdge-utils/dateParser";

const QuickActionDrawer = () => {
  const dispatch = useDispatch();

  const onStartAggregation = () => {
    dispatch(startAggregation());
  };

  const onStartGating = () => {
    dispatch(startGating());
  };

  const {
    quickAction: { aggregation, aggregationConstructionTracker },
  } = useSelector(selectRightDrawer);

  const aggregationButtonTitle = aggregation.loading ? (
    <CircularProgress color="inherit" />
  ) : aggregation.shouldWait ? (
    <Styled.TimerWrapper>
      <p>Start Aggregation</p>
      <p>{normalizedSeconds(aggregation.interval)}</p>
    </Styled.TimerWrapper>
  ) : (
    <>Start Aggregation</>
  );

  const gatingButtonTitle = aggregationConstructionTracker.loading ? (
    <CircularProgress color="inherit" />
  ) : aggregationConstructionTracker.shouldWait ? (
    <Styled.TimerWrapper>
      <p>Start Aggregation Construction Tracker</p>
      <p>{normalizedSeconds(aggregationConstructionTracker.interval)}</p>
    </Styled.TimerWrapper>
  ) : (
    <>Start Aggregation Construction Tracker</>
  );

  return (
    <Styled.QuickActionWrapper>
      <StandardButton
        onClick={onStartAggregation}
        title={aggregationButtonTitle}
        disabled={aggregation.loading || aggregation.shouldWait}
      />
      <StandardButton
        onClick={onStartGating}
        title={gatingButtonTitle}
        disabled={
          aggregationConstructionTracker.loading ||
          aggregationConstructionTracker.shouldWait
        }
      />
    </Styled.QuickActionWrapper>
  );
};

export default QuickActionDrawer;
