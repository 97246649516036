import React from "react";

const Update = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2903_14149)">
        <path
          d="M5.83333 19.1667L2.5 15.8333L5.83333 12.5"
          stroke="#899196"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 10.8334V12.5C17.5 13.3841 17.1488 14.2319 16.5237 14.8571C15.8986 15.4822 15.0507 15.8334 14.1667 15.8334H2.5"
          stroke="#899196"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1667 0.833374L17.5001 4.16671L14.1667 7.50004"
          stroke="#899196"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 9.16663V7.49996C2.5 6.6159 2.85119 5.76806 3.47631 5.14294C4.10143 4.51782 4.94928 4.16663 5.83333 4.16663H17.5"
          stroke="#899196"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2903_14149">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Update;
