import { Calendar, XCircle } from "react-feather";
import {
  Replacement,
  Critical,
  Complex,
  Descoped,
  Flown,
  CompletedByDiar,
  CompletedByOther,
  Overlapping,
  NewEvent,
  Emergency,
  SensitiveCustomer,
} from "../../../../icons";

export const rowIcons = {
  replacement: { icon: Replacement, description: "Replacement" },
  replaced: { icon: Replacement, description: "Replacement" },
  critical: { icon: Critical, description: "Critical" },
  complex: { icon: Complex, description: "Complex" },
  descoped: { icon: Descoped, description: "Descoped" },
  flown: { icon: Flown, description: "Flown" },
  sensitive_customer: {
    icon: () => SensitiveCustomer({ size: 20 }),
    description: "Sensitive",
  },
  completed: { icon: CompletedByDiar, description: "Completed" },
  completed_by_diar: {
    icon: CompletedByDiar,
    description: "Completed By Diar",
  },
  completed_by_other_programs: {
    icon: CompletedByOther,
    description: "Completed By Other",
  },
  overlapping_programs: { icon: Overlapping, description: "Overlapping" },
  new_event: { icon: NewEvent, description: "New Event" },
  closeout_date_is_blank: {
    icon: XCircle,
    description: "Closeout Date is Blank",
  },
  closeout_date: { icon: Calendar, description: "Closeout Date" },
  emergency: { icon: Emergency, description: "Emergency" },
};
