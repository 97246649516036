import React from "react";
import { Styled } from "./RowIcon.styled";
import { rowIcons } from "./RowIcon.constants";
import { Tooltip } from "@material-ui/core";

const RowIcon: React.FC<any> = ({ data }) => {
  if (typeof data.event !== "string" || data.event.length === 0) {
    return null;
  }

  const events = data.event.split(",");

  if (events.length === 0) {
    return null;
  }

  const icons = events?.map((item: any) => {
    const Icon = rowIcons[item.trim() as keyof typeof rowIcons];
    if (Icon) {
      return { Icon: Icon.icon, title: Icon.description };
    } else {
      return item;
    }
  });

  return (
    <Styled.RowIconWrapper>
      {icons.length > 0
        ? icons.map(({ Icon, title }: any, index: any) => {
            return (
              <Tooltip title={title} key={index}>
                <span>
                  <Icon />
                </span>
              </Tooltip>
            );
          })
        : null}
    </Styled.RowIconWrapper>
  );
};

export default RowIcon;
