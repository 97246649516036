import styled from "@emotion/styled";
import { Button } from "@sdge-components";
import { StyledPropsWithTheme } from "../../../../utils/globalTypes";

const AddItemButton = styled(Button)<StyledPropsWithTheme>`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

export const Styled = { AddItemButton };
