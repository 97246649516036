import { TileSection } from "../TileSection";
import { Box } from "@mui/material";
import React from "react";
import useSapTileContentCreator from "./useSapTileContentCreator";
import RowItem from "./RowItem";
import { useAppSelector } from "../../app/hooks";

const SapTileContent: React.FC<{ constructionPage?: boolean }> = ({
  constructionPage,
}) => {
  const contentItems = useSapTileContentCreator(constructionPage);
  const { cardsLoading } = useAppSelector((state) => state.issueTrackerSlice);
  if (cardsLoading) {
    return <> </>;
  }

  return (
    <>
      {contentItems?.map((item: any, i: number) => {
        return (
          <Box key={i}>
            <TileSection title="" />
            {item.map(({ id, title, content, name, date }: any) => {
              return (
                <RowItem
                  title={title}
                  key={id}
                  content={content}
                  name={name}
                  date={date}
                  constructionPage={constructionPage}
                  itemId={item[7].itemId || ""}
                />
              );
            })}
          </Box>
        );
      })}
    </>
  );
};

export default SapTileContent;
