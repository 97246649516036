import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../utils/globalTypes";

const SortOptionsWrapper = styled(Box)<StyledPropsWithTheme>`
  width: 550px;
  max-height: 200px;
  background: ${({ theme }) => theme.palette.tables?.main?.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px 10px 0px 10px;
  justify-content: space-between;
`;

const SortOptionsList = styled(Box)<StyledPropsWithTheme>`
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 5px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    opacity: 0.15;
  }
`;

const SelectSortWrapper = styled(Box)`
  width: 200px;
`;
const ListBottom = styled(Box)<{ ref: any }>``;

export const Styled = {
  SortOptionsWrapper,
  SortOptionsList,
  SelectSortWrapper,
  ListBottom,
};
