import { Box, Grid } from "@mui/material";
import { Styled } from "./TileSection.styled";
import React from "react";
import { IconButton } from "@material-ui/core";

const TileSection: React.FC<any> = ({ title, Icon, handleAction }) => {
  return (
    <Box pb="10px" pt="20px">
      <Grid container alignItems="center">
        <Grid item>
          <Styled.SubTitle>{title}</Styled.SubTitle>
        </Grid>
        <Grid item flexGrow={1}>
          <Styled.Divider ml="10px" />
        </Grid>
        {Icon && (
          <Grid item>
            <IconButton onClick={handleAction}>{<Icon />}</IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default TileSection;
