const ballInCourt = [
  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "SUG",
    value: "SUG",
    label: "SUG",
  },
  {
    id: "Northeast",
    value: "Northeast",
    label: "Northeast",
  },
  {
    id: "Beach Cities",
    value: "Beach Cities",
    label: "Beach Cities",
  },
  {
    id: "Orange County",
    value: "Orange County",
    label: "Orange County",
  },
  {
    id: "Metro",
    value: "Metro",
    label: "Metro",
  },
  {
    id: "Ramona",
    value: "Ramona",
    label: "Ramona",
  },
  {
    id: "Eastern",
    value: "Eastern",
    label: "Eastern",
  },
  {
    id: "Mountain Empire",
    value: "Mountain Empire",
    label: "Mountain Empire",
  },
  {
    id: "North Coast",
    value: "North Coast",
    label: "North Coast",
  },
  {
    id: "Asset Management",
    value: "Asset Management",
    label: "Asset Management",
  },
  {
    id: "Construction Services",
    value: "Construction Services",
    label: "Construction Services",
  },
  {
    id: "Cordoba",
    value: "Cordoba",
    label: "Cordoba",
  },
  {
    id: "E2",
    value: "E2",
    label: "E2",
  },
  {
    id: "ERO",
    value: "ERO",
    label: "ERO",
  },
  {
    id: "KP ENV Customer",
    value: "KP ENV Customer",
    label: "KP ENV Customer",
  },
  {
    id: "Reliability",
    value: "Reliability",
    label: "Reliability",
  },
  {
    id: "Veg Management",
    value: "Veg Management",
    label: "Veg Management",
  },
  {
    id: "CMP",
    value: "CMP",
    label: "CMP",
  },
  {
    id: "TED CMP",
    value: "TED CMP",
    label: "TED CMP",
  },
  {
    id: "FiRM/Prime",
    value: "FiRM/Prime",
    label: "FiRM/Prime",
  },
  {
    id: "KP ENV Permits",
    value: "KP ENV Permits",
    label: "KP ENV Permits",
  },
  {
    id: "KP ENV Environmental Release",
    value: "KP ENV Environmental Release",
    label: "KP ENV Environmental Release",
  },
  {
    id: "TCM",
    value: "TCM",
    label: "TCM",
  },
  {
    id: "WiSE",
    value: "WiSE",
    label: "WiSE",
  },
  {
    id: "SPRP",
    value: "SPRP",
    label: "SPRP",
  },
];
const onHoldStatus = [
  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "Material",
    value: "Material",
    label: "Material",
  },
  {
    id: "RFI",
    value: "RFI",
    label: "RFI",
  },
  {
    id: "FCO",
    value: "FCO",
    label: "FCO",
  },
  {
    id: "Permit",
    value: "Permit",
    label: "Permit",
  },
  {
    id: "Environmental",
    value: "Environmental",
    label: "Environmental",
  },
  {
    id: "Customer Contact",
    value: "Customer Contact",
    label: "Customer Contact",
  },
  {
    id: "Other",
    value: "Other",
    label: "Other",
  },
];

const status = [
  {
    id: "As-Built",
    value: "As-Built",
    label: "As-Built",
  },
  {
    id: "Assigned",
    value: "Assigned",
    label: "Assigned",
  },

  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "LiDAR Review Initiated",
    value: "LiDAR Review Initiated",
    label: "LiDAR Review Initiated",
  },
  {
    id: "LiDAR Batch Submitted",
    value: "LiDAR Batch Submitted",
    label: "LiDAR Batch Submitted",
  },
  {
    id: "LiDAR Data Received",
    value: "LiDAR Data Received",
    label: "LiDAR Data Received",
  },
  {
    id: "LiDAR Under Review",
    value: "LiDAR Under Review",
    label: "LiDAR Under Review",
  },
  {
    id: "TU Remediation Not Required",
    value: "TU Remediation Not Required",
    label: "TU Remediation Not Required",
  },
  {
    id: "TU Remediation Initiated",
    value: "TU Remediation Initiated",
    label: "TU Remediation Initiated",
  },
  {
    id: "TU Construction Complete",
    value: "TU Construction Complete",
    label: "TU Construction Complete",
  },
  {
    id: "TU Reflight Initiated",
    value: "TU Reflight Initiated",
    label: "TU Reflight Initiated",
  },
  {
    id: "TU Reflight Data Received",
    value: "TU Reflight Data Received",
    label: "TU Reflight Data Received",
  },
  {
    id: "TU Reflight Data Under Review",
    value: "TU Reflight Data Under Review",
    label: "TU Reflight Data Under Review",
  },
  {
    id: "TU Remediation Complete",
    value: "TU Remediation Complete",
    label: "TU Remediation Complete",
  },
  {
    id: "PIDS Upload",
    value: "PIDS Upload",
    label: "PIDS Upload",
  },
  {
    id: "Closed Out",
    value: "Closed Out",
    label: "Closed Out",
  },
  {
    id: "Cordoba Approved",
    value: "Cordoba Approved",
    label: "Cordoba Approved",
  },
  {
    id: "Customer Info",
    value: "Customer Info",
    label: "Customer Info",
  },
  {
    id: "Descoped",
    value: "Descoped",
    label: "Descoped",
  },
  {
    id: "Design %60 Complete",
    value: "Design %60 Complete",
    label: "Design %60 Complete",
  },
  {
    id: "Design %90 Complete",
    value: "Design %90 Complete",
    label: "Design %90 Complete",
  },
  {
    id: "Environmental",
    value: "Environmental",
    label: "Environmental",
  },
  {
    id: "Grouping Approval",
    value: "Grouping Approval",
    label: "Grouping Approval",
  },
  {
    id: "IFC",
    value: "IFC",
    label: "IFC",
  },
  {
    id: "In-Design",
    value: "In-Design",
    label: "In-Design",
  },
  {
    id: "In Construction",
    value: "In Construction",
    label: "In Construction",
  },
  {
    id: "Permitting",
    value: "Permitting",
    label: "Permitting",
  },

  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
  {
    id: "Resolved",
    value: "Resolved",
    label: "Resolved",
  },

  {
    id: "SAP Initiation",
    value: "SAP Initiation",
    label: "SAP Initiation",
  },
  {
    id: "SAP Complete",
    value: "SAP Complete",
    label: "SAP Complete",
  },
  {
    id: "True-Up",
    value: "True-Up",
    label: "True-Up",
  },
  { id: "Design Complete", value: "Design Complete", label: "Design Complete" },
];

export const assigned = [
  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "SUG",
    value: "SUG",
    label: "SUG",
  },
  {
    id: "Northeast",
    value: "Northeast",
    label: "Northeast",
  },
  {
    id: "Beach Cities",
    value: "Beach Cities",
    label: "Beach Cities",
  },
  {
    id: "Orange County",
    value: "Orange County",
    label: "Orange County",
  },
  {
    id: "Metro",
    value: "Metro",
    label: "Metro",
  },
  {
    id: "Ramona",
    value: "Ramona",
    label: "Ramona",
  },
  {
    id: "Eastern",
    value: "Eastern",
    label: "Eastern",
  },
  {
    id: "Mountain Empire",
    value: "Mountain Empire",
    label: "Mountain Empire",
  },
  {
    id: "North Coast",
    value: "North Coast",
    label: "North Coast",
  },
  {
    id: "Asset Management",
    value: "Asset Management",
    label: "Asset Management",
  },
  {
    id: "Cordoba",
    value: "Cordoba",
    label: "Cordoba",
  },
  {
    id: "ERO",
    value: "ERO",
    label: "ERO",
  },
  {
    id: "Veg Management",
    value: "Veg Management",
    label: "Veg Management",
  },
  {
    id: "KP Environmental",
    value: "KP Environmental",
    label: "KP Environmental",
  },
  {
    id: "TCM",
    value: "TCM",
    label: "TCM",
  },
  {
    id: "CMP",
    value: "CMP",
    label: "CMP",
  },
  {
    id: "FiRM/Prime",
    value: "FiRM/Prime",
    label: "FiRM/Prime",
  },
  {
    id: "WiSE",
    value: "WiSE",
    label: "WiSE",
  },
  {
    id: "TED CMP",
    value: "TED CMP",
    label: "TED CMP",
  },
  {
    id: "Construction Services",
    value: "Construction Services",
    label: "Construction Services",
  },
  {
    id: "Reliability",
    value: "Reliability",
    label: "Reliability",
  },
];

export const classification = [
  {
    id: "BLANK",
    value: "",
    label: "BLANK",
  },
  { id: "EMERGENCY", value: "EMERGENCY", label: "EMERGENCY" },
  { id: "ENG", value: "ENG", label: "ENG" },
  { id: "NON-ENG", value: "NON-ENG", label: "NON-ENG" },
  { id: "VEG", value: "VEG", label: "VEG" },
  { id: "CIP", value: "CIP", label: "CIP" },
  { id: "CUSTOMER", value: "CUSTOMER", label: "CUSTOMER" },
  { id: "TCM PENDING", value: "TCM PENDING", label: "TCM PENDING" },
  { id: "TCM", value: "TCM", label: "TCM" },
  { id: "TCM-NI", value: "TCM-NI", label: "TCM-NI" },
  { id: "UNSORTED", value: "UNSORTED", label: "UNSORTED" },
];

export const crossarmReplacement = [
  {
    id: "BLANK",
    value: "",
    label: "BLANK",
  },
  {
    id: "Y",
    value: "Y",
    label: "Y",
  },
  {
    id: "N",
    value: "N",
    label: "N",
  },
];

export const poleReplacement = [
  {
    id: "BLANK",
    value: "",
    label: "BLANK",
  },
  {
    id: "Y",
    value: "Y",
    label: "Y",
  },
  {
    id: "N",
    value: "N",
    label: "N",
  },
];

const additionalAssigned = [
  {
    id: "Construction Services",
    value: "Construction Services",
    label: "Construction Services",
  },
];

export const issueStatus = [
  {
    id: "BLANK",
    value: "",
    label: "BLANK",
  },
  { id: "Design Complete", value: "Design Complete", label: "Design Complete" },
  { id: "Construction", value: "Construction", label: "Construction" },
];

const massUpdateSelect = (param: string): any => {
  switch (param) {
    case "ballInCourt":
      return ballInCourt;
    case "assigned":
      return assigned;
    case "status":
      return status;
    case "issueStatus":
      return [...status, ...issueStatus];
    case "classification":
      return classification;
    case "crossarmReplacement":
      return crossarmReplacement;
    case "poleReplacement":
      return poleReplacement;
    case "onHoldStatus":
      return onHoldStatus;
  }
};

const massUpdateAdditionalSelect = (param: string): any => {
  switch (param) {
    case "assigned":
      return [...assigned, ...additionalAssigned];
    case "status":
      return status;
    case "issueStatus":
      return status;
  }
};

export { massUpdateSelect, massUpdateAdditionalSelect };
