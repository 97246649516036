import { Styled } from "./InteractionItem.styled";
import CheckMark from "../../../../icons/CheckMark";
import React, { FC } from "react";
import DropDownMenu from "./DropDownMenu";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { deleteInteraction } from "@sdge-store/drawer/drawer.async";
import { setEditableInteraction } from "@sdge-store/drawer/drawer.slice";
import { usePermission } from "@sdge-hooks";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import { deleteInteractionByGroup } from "@sdge-store/construction/construction.async";
import { parseDateString } from "@sdge-utils/dateParser";
import { exportFile } from "@sdge-utils/exportFile";

const InteractionItem: FC<any> = ({ data, ind }) => {
  const {
    id,
    comment = "",
    created,
    date,
    emails,
    eventOwner,
    interactionType,
    user,
    attachment,
    description,
    poleId,
    groupName,
  } = data;

  const dispatch = useAppDispatch();
  const { getPermission } = usePermission();

  const handleEdit = () => {
    dispatch(setEditableInteraction(data));
  };
  const { router } = useAppSelector(selectRightDrawer);
  const constractionPage = router.parentPath === "construction_table";
  const issueTrackerPage = router.parentPath === "issue_tracker_table";
  const customerPage = router.parentPath === "customer_table";
  const canModify =
    (constractionPage && groupName) ||
    (issueTrackerPage && poleId) ||
    (customerPage && poleId);

  const handleDelete = () => {
    constractionPage
      ? dispatch(deleteInteractionByGroup({ index: ind, id }))
      : dispatch(deleteInteraction({ index: ind, id }));
    dispatch(setEditableInteraction({}));
  };
  const { email } = useAppSelector((state) => state.userSlice.info);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );

  const { ballInCourt } = programInfo;
  const isInteractionOwner =
    email ===
    (eventOwner.includes("<")
      ? eventOwner.slice(eventOwner.indexOf("<") + 1, eventOwner.indexOf(">"))
      : eventOwner);

  const handleExportInteraction = (attachmentName: string) => {
    exportFile(`/interactions/${id}/attachment`, attachmentName);
  };

  const hasPermissions =
    isInteractionOwner &&
    (getPermission("interaction") ||
      (issueTrackerPage
        ? getPermission("firmInteraction") && ballInCourt === "FiRM/Prime"
        : getPermission("firmInteraction")) ||
      (getPermission("cityWorkInteraction") && ballInCourt === "City Works") ||
      getPermission("eroInteraction"));

  return (
    <Styled.InteractionItemWrapper>
      <Styled.CheckMark>
        <CheckMark />
      </Styled.CheckMark>
      <Styled.Body>
        <Styled.Row>
          <Styled.Text weight={700}>
            {interactionType} on {parseDateString(date, "MM/DD/YYYY")}
          </Styled.Text>
          {hasPermissions && canModify && (
            <DropDownMenu handleEdit={handleEdit} handleDelete={handleDelete} />
          )}
        </Styled.Row>
        {interactionType === "EMAIL" && !!emails?.length && (
          <Styled.Text weight={400}>to {emails.join(" ")}</Styled.Text>
        )}
        <Styled.Text weight={400}>{comment}</Styled.Text>
        {attachment && (
          <Styled.File onClick={() => handleExportInteraction(attachment.name)}>
            {attachment.name}
          </Styled.File>
        )}
        <Styled.Text weight={400} dark={"true"}>
          Event Owner: {eventOwner}
        </Styled.Text>
        {constractionPage && description && (
          <Styled.Text weight={400} dark={"true"}>
            Description: {description}
          </Styled.Text>
        )}
        <Styled.Text weight={400} dark={"true"}>
          {user} at {parseDateString(created, "MM/DD/YYYY")}
        </Styled.Text>
      </Styled.Body>
    </Styled.InteractionItemWrapper>
  );
};

export default InteractionItem;
