import { ModalWindow } from "../../../../components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import React, { ChangeEvent, useState, useRef } from "react";
import { Styled } from "./UploadFolderModal.styled";
import { UploadFolderPropsType } from "./UploadFolder.types";
import { queryInstance } from "../../../../api/config";
import { useDispatch } from "react-redux";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { getLayers } from "@sdge-store/Map/map.async";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { useAppSelector } from "../../../../app/hooks";
import * as axios from "axios";

const UploadFolderModal: React.FC<UploadFolderPropsType> = ({
  chosenFolder,
  isOpen,
  setIsOpen,
}) => {
  const [main, setMain] = useState("");
  const [master, setMaster] = useState<File | null | undefined>(null);
  const [progress, setProgress] = useState(0);
  const cancelFileUpload = useRef<any>(null);
  const [warningMessage, setWarningMessage] = useState("");
  const dispatch = useDispatch();

  const { layers } = useAppSelector((state) => state.maps);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWarningMessage("");
    layers.length &&
      layers.forEach((item) => {
        if (item.name === event.target.value) {
          setWarningMessage(
            "File with this name is already exist, please rename it."
          );
        } else {
          setMain(event.target.value);
          setMaster(
            chosenFolder.find((item: File) => item.name === event.target.value)
          );
        }
      });
    setMain(event.target.value);
    setMaster(
      chosenFolder.find((item: File) => item.name === event.target.value)
    );
  };

  const handleClose = () => {
    setMain("");
    setWarningMessage("");
    setIsOpen(false);
  };

  const handleUpload = async () => {
    const payload = {
      master,
      files: chosenFolder.filter((item: File) => item !== master),
    };
    const formdata = new FormData();
    formdata.append("master", payload.master as File, payload.master?.name);
    payload.files.forEach((file) => formdata.append("files", file, file?.name));
    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          dispatch(
            handleChangeSnackbarState({
              isOpen: true,
              message: "File is uploading",
              alertColor: "info",
            })
          );
        }
        setProgress(percent);
      },
      cancelToken: new axios.default.CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };
    if (main && !warningMessage) {
      try {
        dispatch(
          handleChangeSnackbarState({
            isOpen: true,
            alertColor: "info",
            message: "Uploading is started",
          })
        );
        await queryInstance.post(`/gis/layers/${main}/kml`, formdata, options);
        dispatch(
          handleChangeSnackbarState({
            isOpen: true,
            alertColor: "success",
            message: "Your document is uploaded",
          })
        );
        setProgress(0);
        dispatch(getLayers());
      } catch (e: any) {
        dispatch(
          handleChangeSnackbarState({
            isOpen: true,
            alertColor: "error",
            message: axios.default.isCancel(e)
              ? "Upload was canceled"
              : "Something went wrong. Try again",
          })
        );
      }
    }
  };
  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("User has canceled file upload");
      setProgress(0);
    }
  };
  return (
    <ModalWindow
      isOpen={!!chosenFolder.length && isOpen}
      fullWidth
      title="Please choose the main file"
      onClose={handleClose}
      modalName="upload"
    >
      <Styled.ProgressWrapper>
        <Typography align="center" color="textSecondary">
          {progress > 0 && (
            <>
              <Styled.LinearPogressWrapper
                value={progress}
                variant="determinate"
                color="secondary"
              />
              {progress}%
            </>
          )}
          {warningMessage}
        </Typography>
        <Box mr="20px">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              {progress > 0 && progress < 100 && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => cancelUpload()}
                  disabled={false}
                >
                  Cancel Upload
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleUpload}
                disabled={!!warningMessage || progress > 0}
              >
                APPLY
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={!!warningMessage || progress > 0}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Styled.ProgressWrapper>
      <Styled.ModalContainer padding={4}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={main}
            onChange={handleChange}
          >
            {chosenFolder.map(({ name }: File) => {
              return (
                <FormControlLabel
                  value={name}
                  control={<Radio />}
                  label={name}
                  key={name}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Styled.ModalContainer>
    </ModalWindow>
  );
};

export default UploadFolderModal;
