import React from "react";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { useAppSelector } from "../../app/hooks";
import {
  handleMassUpdateItems,
  handleSelectedRows,
  handleSelectedRowsGroupNames,
} from "@sdge-store/construction/construction.slice";
import {
  updateFilteredRows,
  updateSelectedRows,
} from "@sdge-store/construction/construction.async";
import MassUpdateOptions from "./MassUpdateOptions";

const ConstructionMassUpdateOption = () => {
  const {
    selectedRows,
    massUpdateItems,
    columnDefs,
    selectedRowsGroupNames,
    filteringRules,
    totalElements,
    massUpdateLoading,
  } = useAppSelector(selectConstruction);

  const data = {
    selectedRows,
    massUpdateItems,
    columnDefs: [
      ...columnDefs,
      {
        headerName: " Issue Resolved Date",
        field: "issueResolvedDate",
        edit: true,
      },
    ],
    selecedRowsIssueIds: selectedRowsGroupNames,
    filteringRules,
    totalElements,
    massUpdateLoading,
  };

  const functions = {
    updateFilteredRows,
    updateSelectedRows,
    handleMassUpdateItems,
    handleSelectedRows,
    handleSelectedIssueIds: handleSelectedRowsGroupNames,
  };

  return (
    <MassUpdateOptions data={data} functions={functions} page="construction" />
  );
};

export default ConstructionMassUpdateOption;
