import styled from "@emotion/styled/";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { TextInputWithController } from "@sdge-components";

const CustomersInput = styled(TextInputWithController)`
  margin: 20px 0;
`;

const ModalContainer = styled(Box)<StyledPropsWithTheme>`
  overflow-y: auto;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 5px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    opacity: 0.15;
  }
`;

export const Styled = {
  CustomersInput,
  ModalContainer,
};
