import { FC } from "react";

const MenuUp: FC = () => {
  return (
    <svg
      width="10"
      height="5"
      viewBox="0 0 10 5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 0L10 5H0L5 0Z" fill="#ffffff" />
    </svg>
  );
};

export default MenuUp;
