import { FC } from "react";
import { Styled } from "./Flight.styled";
import { useHistory } from "react-router";
import { IconPathType } from "@sdge-utils/globalTypes";

const Flight: FC<IconPathType> = ({ path }) => {
  const history = useHistory();
  return (
    <Styled.FlightLogo
      isActive={path ? history.location.pathname.includes(path) : false}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.15 1.86001C18.7679 1.47057 18.2835 1.19723 17.7526 1.07158C17.2217 0.945941 16.6661 0.973131 16.15 1.15001L1.99999 5.88001C1.4293 6.06681 0.930677 6.42616 0.572941 6.90845C0.215206 7.39073 0.0160042 7.97218 0.00283413 8.57251C-0.0103359 9.17285 0.163175 9.76247 0.499416 10.26C0.835656 10.7575 1.31804 11.1384 1.87999 11.35L7.11999 13.35C7.23931 13.3956 7.34795 13.4654 7.43913 13.5548C7.53032 13.6443 7.6021 13.7516 7.64999 13.87L9.64999 19.12C9.85357 19.674 10.223 20.1518 10.708 20.4882C11.1929 20.8246 11.7698 21.0033 12.36 21H12.43C13.031 20.9891 13.6135 20.7903 14.0959 20.4317C14.5783 20.073 14.9364 19.5724 15.12 19L19.85 4.83001C20.022 4.31895 20.0476 3.76995 19.9239 3.2451C19.8002 2.72024 19.5321 2.24046 19.15 1.86001ZM18 4.20001L13.22 18.38C13.1645 18.5595 13.053 18.7165 12.9019 18.828C12.7507 18.9396 12.5678 18.9999 12.38 19C12.1932 19.0031 12.01 18.9492 11.8546 18.8456C11.6992 18.742 11.579 18.5936 11.51 18.42L9.50999 13.17C9.36499 12.7885 9.14138 12.4418 8.85367 12.1524C8.56596 11.863 8.22059 11.6373 7.83999 11.49L2.58999 9.49001C2.41288 9.42511 2.26064 9.30621 2.15477 9.1501C2.0489 8.99399 1.99476 8.80856 1.99999 8.62001C2.00014 8.43215 2.0604 8.24928 2.17196 8.09813C2.28352 7.94699 2.44052 7.83551 2.61999 7.78001L16.8 3.05001C16.9628 2.98366 17.1413 2.96588 17.314 2.99883C17.4867 3.03179 17.6462 3.11406 17.7731 3.2357C17.9 3.35734 17.989 3.51314 18.0293 3.68427C18.0695 3.8554 18.0594 4.03453 18 4.20001Z" />
    </Styled.FlightLogo>
  );
};

export default Flight;
