import { SearchIcon } from "../../icons";
import { InputAdornment } from "@material-ui/core";
import React, { FC } from "react";
import { Styled } from "./SearchInput.styled";
import { SearchInputType } from "./SearchInput.types";

const SearchInput: FC<SearchInputType> = ({ onFilterTextChange, value }) => {
  return (
    <Styled.SearchInputWrapper>
      <Styled.SearchInput
        fullWidth
        onChange={onFilterTextChange}
        value={value}
        placeholder="Search"
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Styled.SearchInputWrapper>
  );
};

export default SearchInput;
