import React, {
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
  Profiler,
} from "react";
import {
  UploadFolder,
  UploadFolderModal,
} from "@sdge-components/MainTable/components";
import { Styled } from "./MainTable.styled";
import {
  Button,
  Options,
  OverlapsColumnFilter,
  SearchInput,
  Table,
  Toggle,
} from "@sdge-components";
import {
  AddIcon,
  DownloadIcon,
  FilterIcon,
  HideIcon,
  MassEditIcon,
  MenuDownIcon,
  MenuUpIcon,
  SortedInfoIcon,
} from "../../icons";

import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { queryInstance } from "@sdge-api/config";
import { useHistory, useLocation } from "react-router";
import { generateSearchString } from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import { useDebounce } from "@sdge-hooks";
import { renderToString } from "react-dom/server";
import dayjs from "dayjs";
import {
  handleCellEdit,
  handleToggleBMCD,
} from "@sdge-store/issueTracker/issueTracker.slice";
import { getLocation } from "@sdge-store/user/user.slice";
import { selectUser } from "@sdge-store/user/user.selector";
import { selectIssueTracker } from "@sdge-store/issueTracker/issueTracker.selector";

const MainTable: React.FC<any> = ({ data, storeFunctions, options }) => {
  const {
    items,
    title,
    totalElements,
    loading,
    page,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    totalPages,
    sortingRules,
    areQuickVeiwsOptionsOpen,
    selectedRows,
    selecedRowsIssueIds,
    areMassUpdateOptionsOpen,
    massUpdateItemsRequest,
    MassUpdateOptions,
    FilterOptionsMenu,
    HideOptionsMenu,
    QuickViewsOptions,
    SortOptionsMenu,
    inputText,
    hasPermissions,
  } = data;

  const {
    fieldsHiddenOptions,
    sortOptions,
    filterOptions,
    quickViewsOptions,
    handleSearch,
    handleLoadedPagesChange,
    sortData,
    handleHiddenColumn,
    handlePinnedColumn,
    handleSort,
    handleFilter,
    fetchData,
    handleSelectedRows,
    resetSelectedIds,
    handleInputText,
  } = storeFunctions;

  const {
    massUpdateOptions,
    gridOptions,
    isValueSetter,
    storeSelector,
    handleExportClick,
    columnApi,
    isThirdColumn,
  } = options;
  const uploadFolder = useRef<HTMLInputElement | null>(null);
  const [chosenFolder, setChosenFolder] = useState<File[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { writePermissions } = useAppSelector(selectUser);
  const { pathname } = useLocation();
  const isMap = pathname.split("/").includes("map");
  const isPackSummary = pathname.split("/").includes("summary_package");
  const isPoleSummary = pathname.split("/").includes("summary_pole");
  const isTab = isMap || isPackSummary || isPoleSummary;

  const { bmcdTierVisibility } = useAppSelector(selectIssueTracker);

  const { debounce } = useDebounce(1000);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const toggleFilter = (checked: boolean): void => {
    dispatch(handleLoadedPagesChange({ isDropToFirstPage: true }));
    dispatch(handleToggleBMCD(checked));
    dispatch(fetchData([page, sortingRules, filteringRules]));
  };

  useEffect(() => {
    dispatch(getLocation(pathname));
  }, [pathname]);

  useEffect(() => {
    const rules = [
      {
        fieldName: "bmcd_tier_3",
        negate: true,
        operation: "equals",
        relation: "AND",
        value: "Y",
      },
    ];

    if (isPaginated && !location.pathname.includes("overlapping")) {
      if (filteringRules.length > 0 || bmcdTierVisibility) {
        dispatch(fetchData([page, sortingRules, filteringRules]));
      } else {
        dispatch(fetchData([page, sortingRules, rules]));
      }
    }
    return history.listen((location) => {
      if (
        !location.pathname.includes("issue_tracker") ||
        location.pathname.includes("construction")
      ) {
        if (resetSelectedIds && handleSelectedRows) {
          dispatch(resetSelectedIds([]));
          dispatch(handleSelectedRows([]));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isPaginated, massUpdateItemsRequest, history, pathname]);

  useEffect(() => {
    toggleFilter(false);
  }, [pathname]);

  useEffect(() => {
    debounce(() => {
      dispatch(
        sortData([0, generateSearchString(inputText, sort), filteringRules])
      );
    });
  }, [inputText, massUpdateItemsRequest]);

  const defaultColDef = {
    filter: true,
    resizable: true,
    headerCheckboxSelection: isThirdColumn,
    checkboxSelection: isThirdColumn,
    filterFramework: (params: any) => {
      return (
        <OverlapsColumnFilter
          params={params}
          sortData={sortData}
          hideColumn={handleHiddenColumn}
          pinColumn={handlePinnedColumn}
          sortAction={handleSort}
          filterOptions={filterOptions}
          store={storeSelector}
          handleFilter={handleFilter}
        />
      );
    },
    icons: {
      sortAscending: renderToString(<MenuUpIcon />),
      sortDescending: renderToString(<MenuDownIcon isColor />),
      menu: renderToString(<MenuDownIcon />),
    },
  };

  const valueSetter = (params: any) => {
    let values;
    values = {
      data: params.data,
      oldValue: params.oldValue,
      newValue: params.newValue,
      colDef: params.colDef,
      rowIndex: params.node.rowIndex,
    };
    if (params.column.colId.includes("Date")) {
      const isDate = dayjs(params.newValue, "YYYY-MM-DD", "es", true).isValid();
      if (!isDate && params.newValue.length > 0) {
        alert('please use the following format: "YYYY-MM-DD"');
        values = {
          data: params.data,
          oldValue: params.oldValue,
          newValue: params.oldValue,
          colDef: params.colDef,
          rowIndex: params.node.rowIndex,
        };
      }
    }
    dispatch(handleCellEdit(values));
  };

  const onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      dispatch(handleSearch(`&searchText=${e.target.value}`));
    } else {
      dispatch(handleSearch(""));
    }
    if (handleSelectedRows && resetSelectedIds) {
      dispatch(handleSelectedRows([]));
      dispatch(resetSelectedIds([]));
    }
    dispatch(handleInputText(e.target.value));
  };

  const handleExportGis = async () => {
    dispatch(
      handleChangeSnackbarState({
        isOpen: true,
        alertColor: "info",
        message: "Downloading is started",
      })
    );
    try {
      const data = await queryInstance.post("/gis/kml/export", {});
      const file = data.data;
      const fileExtensionArray = data.headers["content-type"].split("/");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];

      const localUrl = window.URL.createObjectURL(new Blob([file]));

      const link = document.createElement("a");

      link.href = localUrl;
      link.setAttribute("download", `gis.${fileExtension}`);

      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    } catch (e) {
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "error",
          message: "Something went wrong",
        })
      );
    }
  };

  const handleOnUploadFolder = () => {
    uploadFolder.current?.click();
  };

  const handleClick = (e: any) => {
    e.target.value = null;
  };

  const handleOnChange = async (event: SyntheticEvent) => {
    const { files } = event.target as HTMLInputElement;
    if (!files) {
      return;
    }
    if (Object.keys(files).length) {
      setChosenFolder(Object.values(files));
      setIsOpen(true);
    }
  };

  const getHiddenColumns = (columns: any[]) => {
    return columns.filter((item) => item.hide === true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const profiler = (...rest: any) => {
    // eslint-disable-next-line no-console
    // console.log("mainTable", { ...rest });
  };

  return (
    <Profiler id="mainTable" onRender={profiler}>
      <UploadFolderModal
        chosenFolder={chosenFolder}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <Styled.MainTableWrapper>
        <Styled.MainTableHeader>
          <Styled.MainTableTitle>
            <Styled.TitleLeftSection>
              <Styled.TableName>{title}</Styled.TableName>
              <Options
                field="Quick Views"
                component={QuickViewsOptions}
                icon={MenuDownIcon}
                open={areQuickVeiwsOptionsOpen}
                setOpen={quickViewsOptions}
                iconRight={true}
                columnApi={columnApi}
              />
            </Styled.TitleLeftSection>
            <Styled.TitleOptions>
              {!writePermissions?.includes("readonly") && (
                <Button
                  icon={AddIcon}
                  title="Import"
                  onClick={handleOnUploadFolder}
                />
              )}
              <UploadFolder
                handleOnChange={handleOnChange}
                uploadFolder={uploadFolder}
                handleClick={handleClick}
              />
              <Button
                icon={AddIcon}
                title="Export gis"
                onClick={handleExportGis}
              />
              <Button
                icon={DownloadIcon}
                title="Export"
                onClick={handleExportClick}
              />
              {/* <Button icon={MaximizeIcon} /> */}
            </Styled.TitleOptions>
          </Styled.MainTableTitle>
          <Styled.MainTableOptions>
            <Styled.OptionsLeft>
              <SearchInput
                value={inputText}
                onFilterTextChange={onFilterTextChange}
              />
              {!location.pathname.includes("overlapping") && (
                <Toggle checked={false} onChange={toggleFilter} />
              )}
              <Options
                isActive
                field={`${getHiddenColumns(columnDefs).length} fields hidden`}
                component={HideOptionsMenu}
                icon={HideIcon}
                open={areHiddenFieldsOpen}
                setOpen={fieldsHiddenOptions}
              />
              <Options
                isActive
                field={`Sorted by ${sort.length} fields`}
                component={SortOptionsMenu}
                icon={SortedInfoIcon}
                open={areSortOptionsOpen}
                setOpen={sortOptions}
              />
              <Options
                isActive
                field={`${filteringRules.length} filters`}
                component={FilterOptionsMenu}
                icon={FilterIcon}
                open={areFilterOptionsOpen}
                setOpen={filterOptions}
              />
            </Styled.OptionsLeft>
            {!!massUpdateOptions && (
              <Styled.OptionsRight>
                {(selectedRows.length > 0 || filteringRules.length > 0) &&
                  !hasPermissions &&
                  items.length > 0 && (
                    <Options
                      isActive
                      field={"Mass Edit"}
                      component={MassUpdateOptions}
                      icon={MassEditIcon}
                      open={areMassUpdateOptionsOpen}
                      setOpen={massUpdateOptions}
                      placement="left-end"
                    />
                  )}
              </Styled.OptionsRight>
            )}
          </Styled.MainTableOptions>
        </Styled.MainTableHeader>
        <Styled.IssueMainTable isTab={isTab}>
          {items.length > 0 ? (
            <Table
              gridOptions={gridOptions}
              rowData={!loading && items}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              handleLoadedPagesChange={handleLoadedPagesChange}
              page={page}
              totalPages={totalPages}
              isPaginated={isPaginated}
              valueSetter={isValueSetter && valueSetter}
              selectedRows={selectedRows}
              selecedRowsIssueIds={selecedRowsIssueIds}
            />
          ) : (
            "No rows to display"
          )}
        </Styled.IssueMainTable>
        <Styled.MainTableBottom>
          <Box>{selectedRows.length} rows selected</Box>
          <Box>
            {items.length} of {totalElements} records
          </Box>
        </Styled.MainTableBottom>
      </Styled.MainTableWrapper>
    </Profiler>
  );
};

export default React.memo(MainTable);
