import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "../../../../utils/globalTypes";
import { Box } from "@material-ui/core";
import { TextInput } from "@sdge-components/TextInput";

const FilterInputWrapper = styled(Box)`
  width: 190px;
`;
const Filternput = styled(TextInput)<StyledPropsWithTheme>`
  background: ${({ theme }) => theme.palette.tables?.main?.dark};
  border-radius: 4px;
  height: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

export const Styled = { FilterInputWrapper, Filternput };
