import { createSlice } from "@reduxjs/toolkit";
import { AutoCompleteTypes } from "./autoComplete.types";
import {
  getAutocompleteValuesByFilter,
  getUniqueValues,
} from "@sdge-store/autoComplete/autoComplete.async";
import { getUniqueValuesSet } from "@sdge-utils/theme/getUniqueValues";
import { classificationDefaultValues } from "@sdge-store/autoComplete/autoComplete.constants";

const initialAutoCompleteState: AutoCompleteTypes = {
  uniqueValues: {},
  filteredValues: {},
};

export const autoCompleteSlice = createSlice({
  name: "autoComplete",
  initialState: initialAutoCompleteState,
  reducers: {
    clearAutoCompleteFilter: (state, action) => {
      state.filteredValues[action.payload] = [];
    },
  },
  extraReducers: {
    [getUniqueValues.fulfilled]: (state, action) => {
      if (action.payload) {
        state.uniqueValues = {
          ...action.payload,
          classification: getUniqueValuesSet([
            ...action.payload?.classification,
            ...classificationDefaultValues,
          ]),
        };
      }
    },
    [getAutocompleteValuesByFilter.fulfilled]: (state, action) => {
      if (action.payload) {
        state.filteredValues = { ...state.filteredValues, ...action.payload };
      }
    },
  },
});

export const { clearAutoCompleteFilter } = autoCompleteSlice.actions;
export default autoCompleteSlice.reducer;
