import { timeOutTypes, tokenTypes } from "./useAuth.types";

export const setLocalSessionExpiryTime = () => {
  const sessionExpiryTime = String(Date.now() + 60 * 60000);
  localStorage.setItem("session_expiry_time", sessionExpiryTime);
};

export const setTimeOuts = ({ expiry_time }: timeOutTypes) => {
  localStorage.setItem("expiry_time", expiry_time);
  setLocalSessionExpiryTime();
};

export const setToken = ({
  access_token,
  id_token,
  refresh_token,
}: tokenTypes): void => {
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("id_token", id_token);
  localStorage.setItem("refresh_token", refresh_token);
};

export const getAccessToken = (): string | null =>
  localStorage.getItem("access_token");

export const getSessionExpiryTime = (): string | null =>
  localStorage.getItem("session_expiry_time");

export const removeAllTokens = (): void => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expiry_time");
  localStorage.removeItem("session_expiry_time");
};
