import { SortType } from "./sortHelpers.types";

const generateSortingString = (arr: SortType[]): string => {
  const result = arr.map(
    ({ property, direction }) => `&sort=${property},${direction}`
  );
  return result.join("");
};
const sortRequest = (
  field: string,
  sortingOrder: string,
  sort: SortType[],
  searchInput: string
): string => {
  const currentFilter = { property: field, direction: sortingOrder };
  const sortedInfo = [...sort];
  const foundIndex = sort.findIndex(
    (item) => item.property === currentFilter.property
  );
  if (foundIndex === -1) {
    const sortingArray = [...sort, currentFilter];
    return `${searchInput}${generateSortingString(sortingArray)}`;
  } else {
    sortedInfo[foundIndex] = currentFilter;
    return `${searchInput}${generateSortingString(sortedInfo)}`;
  }
};

const generateSortArr = (
  field: string,
  sortingOrder: string,
  sort: SortType[]
): SortType[] => {
  const currentFilter = { property: field, direction: sortingOrder };
  const sortedInfo = [...sort];
  const foundIndex = sort.findIndex(
    (item) => item.property === currentFilter.property
  );
  if (foundIndex === -1) {
    const sortingArray = [...sort, currentFilter];
    return sortingArray;
  } else {
    sortedInfo[foundIndex] = currentFilter;
    return sortedInfo;
  }
};

const generateSortItem = (
  field: string,
  sortingOrder: string,
  currentField: string,
  sort: SortType[]
): SortType[] => {
  const currentFilter = { property: field, direction: sortingOrder };
  const sortedInfo = [...sort];
  const foundIndex = sort.findIndex((item) => item.property === currentField);
  sortedInfo[foundIndex] = currentFilter;
  return sortedInfo;
};

const itemSortRequst = (
  field: string,
  sortingOrder: string,
  currentField: string,
  sort: SortType[],
  searchInput: string
): string => {
  const currentFilter = { property: field, direction: sortingOrder };
  const sortedInfo = [...sort];
  const foundIndex = sort.findIndex((item) => item.property === currentField);
  sortedInfo[foundIndex] = currentFilter;
  return `${searchInput}${generateSortingString(sortedInfo)}`;
};

const isLongitude = (field: string, currentDirection: string): string => {
  if (field === "longitude") {
    if (currentDirection === "ASC") {
      return "DESC";
    } else {
      return "ASC";
    }
  } else {
    return currentDirection;
  }
};

export {
  generateSortingString,
  sortRequest,
  generateSortArr,
  generateSortItem,
  itemSortRequst,
  isLongitude,
};
