const InteractionType = [
  {
    id: 1,
    value: "EMAIL",
    label: "Email",
  },
  {
    id: 2,
    value: "PHONE",
    label: "Phone",
  },
  {
    id: 3,
    value: "TEAMS_CHAT",
    label: "Teams Chat",
  },
  {
    id: 4,
    value: "IN_PERSON",
    label: "In person",
  },
];
export const addInteractionType = [
  {
    id: 5,
    value: "ASSOC_DOCS",
    label: "Assoc Docs",
  },
  {
    id: 6,
    value: "POSTCARD",
    label: "Postcard",
  },
];

export const Constants = {
  InteractionType,
  addInteractionType,
};
