import { FC, useEffect, useRef, useState } from "react";
import { Styled } from "./CellAcknowledgements.styled";
import AcknowledgeForm from "../AcknowledgeForm";
import InteractionForm from "../InteractionForm";
import InteractionItem from "../InteractionItem";
import { StandardButton } from "../../../../components";
import { AddIcon } from "../../../../icons";
import { useAppSelector } from "../../../../app/hooks";
import WithLoading from "@sdge-components/WithLoading/LoadingWrapper";
import { usePermission } from "@sdge-hooks";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import InteractionFilter from "../InteractionFilter";

const CellAcknowledgements: FC = () => {
  const [interactionOpenedLocal, setInteractionOpenedLocal] =
    useState<boolean>(false);
  const {
    interactionsData: { interactions, interactionsLoading },
    selectedInteraction,
    interactionFormKey,
    selectedNotification,
  } = useAppSelector(selectRightDrawer);

  const location = useAppSelector((state) => state.userSlice.location);
  const { constructionInteractions } = useAppSelector(
    (state) => state.constructionSlice
  );
  const isNotification = !!Object.keys(selectedNotification).length;
  const { getPermission } = usePermission();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [acknowledgmentsOpen, setAcknowledgmentsOpen] =
    useState<boolean>(isNotification);
  const interactionOpened =
    interactionOpenedLocal || !!Object.keys(selectedInteraction).length;

  const constractionPage = location.split("/").includes("construction");
  const customerPage = location.split("/").includes("customers");
  const issuePage = location.split("/").indexOf("issue_tracker");
  const interactionsList = constractionPage
    ? constructionInteractions
    : interactions;
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );

  const { ballInCourt, assigned } = programInfo;
  useEffect(() => {
    wrapperRef.current &&
      wrapperRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }, [selectedInteraction]);

  const ButtonText = (
    <>
      <AddIcon /> Add Interaction
    </>
  );

  return (
    <WithLoading loading={interactionsLoading}>
      <Styled.CellAcknowledgementsWrapper ref={wrapperRef}>
        {interactionOpened && (
          <InteractionForm
            key={interactionFormKey}
            setInteractionOpened={setInteractionOpenedLocal}
          />
        )}
        {acknowledgmentsOpen &&
          !interactionOpened &&
          getPermission("acknowledgement") && (
            <AcknowledgeForm setAcknowledgmentsOpen={setAcknowledgmentsOpen} />
          )}
        <Styled.InteractionItemsWrapper>
          {!interactionOpened &&
            !acknowledgmentsOpen &&
            (getPermission("interaction") ||
              (constractionPage || customerPage
                ? getPermission("firmInteraction")
                : getPermission("firmInteraction") &&
                  ballInCourt?.includes("FiRM/Prime")) ||
              (getPermission("cityWorkInteraction") &&
                ballInCourt?.includes("City Works")) ||
              (getPermission("eroInteraction") &&
                (ballInCourt?.includes("ERO") || assigned?.includes("ERO")) &&
                issuePage)) && (
              <Styled.ButtonWrapper>
                <StandardButton
                  title={ButtonText}
                  onClick={() => setInteractionOpenedLocal(true)}
                />
              </Styled.ButtonWrapper>
            )}
          {getPermission("interactionFilter") &&
          interactionsList.length &&
          constractionPage ? (
            <InteractionFilter />
          ) : (
            <div></div>
          )}
          {interactionsList?.map((item: any, index: number) => {
            return <InteractionItem key={item.id} data={item} ind={index} />;
          })}
        </Styled.InteractionItemsWrapper>
      </Styled.CellAcknowledgementsWrapper>
    </WithLoading>
  );
};

export default CellAcknowledgements;
