import styled from "@emotion/styled";

const CloseIcon = styled.svg`
  cursor: pointer;
  user-select: none;
`;

export const Styled = {
  CloseIcon,
};
