import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const StatusWrapper = styled(Box)<StyledPropsWithTheme<{ type: string }>>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  & > span {
    font-family: IBM Plex Sans;
    font-size: 14px;
    color: ${(props) => {
      switch (props.type) {
        case "passed":
          return props.theme.palette.statusComponent.passed[100];
        case "warning":
          return props.theme.palette.statusComponent.warning[100];
        default:
          return props.theme.palette.statusComponent.failure[100];
      }
    }};
  }
`;

export const Styled = {
  StatusWrapper,
};
