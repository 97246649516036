export const filterNames = (arr: any, component = "") => {
  return arr.filter((item: any) => {
    if (component === "grid") {
      return item !== "kmzLink" && item !== "issueStatus";
    } else {
      return (
        item !== "actualIfc" &&
        item !== "forecastedIfc" &&
        item !== "jucfRequired" &&
        item !== "issueStatus" &&
        item !== "kmzLink" &&
        item !== "midpoint" &&
        item !== "overhead" &&
        item !== "pidsUpload" &&
        item !== "procoreLink" &&
        item !== "projectedIfc" &&
        item !== "trueupLidar" &&
        item !== "underground"
      );
    }
  });
};

export const filterHeaders = (arr: any, component = "") => {
  return arr.filter((item: any) => {
    if (component === "grid") {
      return item !== "KMZ Link" && item !== "Status";
    } else {
      return (
        item !== "Actual IFC" &&
        item !== "Closeout Date" &&
        item !== "Overhead" &&
        item !== "Status" &&
        item !== "JUCF Required " &&
        item !== "KMZ Link" &&
        item !== "Mid-Point Coordinates" &&
        item !== "Overhead" &&
        item !== "PIDS Upload" &&
        item !== "Procore Link" &&
        item !== "Projected Design Complete" &&
        item !== "BMCD Tier-3" &&
        item !== "Underground"
      );
    }
  });
};
