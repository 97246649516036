import React, { FC } from "react";

const SortedInfo: FC = () => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.29 12.2899L7 16.5899L2.71 12.2899C2.5217 12.1016 2.2663 11.9958 2 11.9958C1.7337 11.9958 1.47831 12.1016 1.29 12.2899C1.1017 12.4782 0.995911 12.7336 0.995911 12.9999C0.995911 13.2662 1.1017 13.5216 1.29 13.7099L6.29 18.7099C6.38297 18.8037 6.49357 18.8781 6.61543 18.9288C6.73728 18.9796 6.86799 19.0057 7 19.0057C7.13201 19.0057 7.26272 18.9796 7.38458 18.9288C7.50644 18.8781 7.61704 18.8037 7.71 18.7099L12.71 13.7099C12.8032 13.6167 12.8772 13.506 12.9277 13.3842C12.9781 13.2624 13.0041 13.1318 13.0041 12.9999C13.0041 12.8681 12.9781 12.7375 12.9277 12.6157C12.8772 12.4939 12.8032 12.3832 12.71 12.2899C12.6168 12.1967 12.5061 12.1227 12.3843 12.0723C12.2624 12.0218 12.1319 11.9958 12 11.9958C11.8681 11.9958 11.7376 12.0218 11.6158 12.0723C11.4939 12.1227 11.3832 12.1967 11.29 12.2899ZM2.71 7.70994L7 3.40994L11.29 7.70994C11.383 7.80367 11.4936 7.87806 11.6154 7.92883C11.7373 7.9796 11.868 8.00574 12 8.00574C12.132 8.00574 12.2627 7.9796 12.3846 7.92883C12.5064 7.87806 12.617 7.80367 12.71 7.70994C12.8037 7.61698 12.8781 7.50637 12.9289 7.38452C12.9797 7.26266 13.0058 7.13195 13.0058 6.99994C13.0058 6.86793 12.9797 6.73722 12.9289 6.61536C12.8781 6.4935 12.8037 6.3829 12.71 6.28994L7.71 1.28994C7.61704 1.19621 7.50644 1.12182 7.38458 1.07105C7.26272 1.02028 7.13201 0.994141 7 0.994141C6.86799 0.994141 6.73728 1.02028 6.61543 1.07105C6.49357 1.12182 6.38297 1.19621 6.29 1.28994L1.29 6.28994C1.1017 6.47824 0.995911 6.73364 0.995911 6.99994C0.995911 7.26624 1.1017 7.52164 1.29 7.70994C1.47831 7.89824 1.7337 8.00403 2 8.00403C2.2663 8.00403 2.5217 7.89824 2.71 7.70994Z"
        fill="#E3E4E7"
      />
    </svg>
  );
};

export default SortedInfo;
