import { useAppSelector } from "../../app/hooks";
import sensitiveIcon from "../../assets/sensitiveCustomerIcon.svg";

const useParcelTileContentCreator = () => {
  const { parcel } = useAppSelector((state) => state.customersSlice) || [];

  return parcel?.map((item: any) => {
    return [
      {
        id: 4,
        title: "Owner Name",
        content: item?.ownerName || "-",
      },
      {
        id: 6,
        title: "Phone number",
        content: item?.phoneNumber || "-",
      },
      { id: 1, title: "APN #", content: item?.apn || "-" },
      { id: 5, title: "Apn Address 1", content: item?.apnAddress1 || "-" },
      { id: 2, title: "Apn Address 2", content: item?.apnAddress2 || "-" },
      { id: 7, title: "Email", content: item?.email || "-" },
      { id: 8, title: "FacilityId", content: item?.facilityId || "-" },
      {
        id: 3,
        title: "Sensitive customer",
        content: (item?.isSensitiveCustomer && sensitiveIcon) || "-",
        icon: item?.isSensitiveCustomer,
      },
    ];
  });
};

export default useParcelTileContentCreator;
