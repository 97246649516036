import { Styled } from "./ArrowLeft.styled";

const ArrowLeft = ({ size = 24, pointer = false, ...rest }) => {
  return (
    <Styled.ArrowLeft
      pointer={pointer}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g opacity="0.6">
        <path
          d="M15 18L9 12L15 6"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Styled.ArrowLeft>
  );
};

export default ArrowLeft;
