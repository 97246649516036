import styled from "@emotion/styled";
import { Box, ListItem } from "@material-ui/core";
import { StyledPropsWithTheme, isActiveType } from "@sdge-utils/globalTypes";

const ListItemWrapper = styled(ListItem)<StyledPropsWithTheme<isActiveType>>`
  color:${({ theme }) => `${theme.palette.text.secondary};`}
  transition: all 0.3s ease 0s;
  ${({ isActive, theme }) =>
    isActive &&
    `color:#fff;
    background:${theme.palette.primary.light};
     width:263px; 
     border-radius: 0px 30px 30px 0px;
     &:hover {
      background:#1F41CC;
    }`}
  &:hover {
    width: 263px;
    border-radius: 0px 30px 30px 0px;
  }
`;
const ListItemText = styled(Box)<StyledPropsWithTheme>`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: 16px;
`;
export const Styled = { ListItemWrapper, ListItemText };
