import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import * as MUI from "@mui/lab";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const DatePickerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LocalizationProvider = styled(MUI.LocalizationProvider)`
  flex-direction: column;
`;

const DatePicker = styled(MUI.DatePicker)``;

const Input = styled(TextField)<StyledPropsWithTheme>`
  & .MuiInputLabel-root {
    top: -9px;
    max-width: calc(100% - 48px);
    color: ${({ theme }) => theme.palette.formItems.labelColor[100]} !important;
  }
  & .MuiInputLabel-shrink {
    top: -3px;
  }

  &.MuiFormControl-root {
    width: 100%;
  }
  & .MuiOutlinedInput-input {
    padding: 8px 2px 8px 8px;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.formItems.textColor[100]};
  }

  & .MuiOutlinedInput-root {
    &:hover {
      fieldset {
        border-color: ${({ theme }) =>
          theme.palette.formItems.borderHover[100]};
      }
    }
  }

  & .Mui-focused {
    & fieldset {
      border: 1px solid
        ${({ theme }) => theme.palette.formItems.borderColor[100]} !important;
    }
  }
  & svg {
    color: ${({ theme }) => theme.palette.selectInput.selectIcon[100]};
  }
`;

const Error = styled.p<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.formItems.errorColor[100]};
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 5px 0 -5px;
  padding: 0;
`;

export const Styled = {
  DatePickerWrapper,
  LocalizationProvider,
  DatePicker,
  Input,
  Error,
};
