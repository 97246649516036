import React, { FC } from "react";
import { Styled } from "./HiddenOptionContainer.styled";
import { Switch } from "@material-ui/core";
import { HiddenOptionContainerType } from "./HiddenOptionContainer.types";

const HiddenOptionContainer: FC<HiddenOptionContainerType> = ({
  columnDefs,
  switchHidden,
}) => {
  return (
    <Styled.HideOptionsWrapper>
      {columnDefs
        .filter(({ headerName }) => headerName)
        .sort((a, b) => a.headerName.localeCompare(b.headerName))
        .map(({ headerName, hide, field }, index) => (
          <Styled.HideOptionsItem key={index}>
            {headerName}
            <Switch
              checked={hide}
              onChange={() => {
                switchHidden(field);
              }}
            />
          </Styled.HideOptionsItem>
        ))}
    </Styled.HideOptionsWrapper>
  );
};

export default HiddenOptionContainer;
