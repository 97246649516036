import styled from "@emotion/styled";
import { Box } from "@material-ui/core";

const OverlappingPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: all 0.3s ease 0s;
`;

const TableGroup = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
  > * {
    flex: 0.5 1 223px;
  }
`;

export const Styled = { OverlappingPageWrapper, TableGroup };
