export const priority = [
  {
    id: "emergency",
    value: "emergency",
    label: "Emergency",
  },
  {
    id: "priority",
    value: "priority",
    label: "Priority",
  },
  {
    id: "non-critical",
    value: "non-critical",
    label: "Non-critical",
  },
];
