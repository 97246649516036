import styled from "@emotion/styled";
import Select from "@material-ui/core/Select";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const SelectFilterWrapper = styled(Select)<
  StyledPropsWithTheme<{ width?: string }>
>`
  border: none;
  height: 38px;
  width:${({ width }) => width || " 150px"};
  margin-left:3px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  background-color: transparent;
  & .MuiSelect-root {
    display: flex;
    align-items: center;
    }
  }
  &.MuiInput-underline {
    &::before,
    ::after {
      display: none;
    }
  }
`;
export const Styled = {
  SelectFilterWrapper,
};
