import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Styled } from "./TextInput.styled";
import { AutoCompleteItemHandler, TextInputTypes } from "./TextInput.types";
import { AutoComplete } from "./components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAutoComplete } from "@sdge-store/autoComplete/autoComplete.selector";
import { useDebounce } from "@sdge-hooks";
import { getAutocompleteValuesByFilter } from "@sdge-store/autoComplete/autoComplete.async";
import { GetAutocompleteValuesByFilter } from "@sdge-store/autoComplete/autoComplete.types";
import { clearAutoCompleteFilter } from "@sdge-store/autoComplete/autoComplete.slice";
import { onHoldStatusValues } from "./TextInput.constants";
import { muniPermitOptions } from "../MuniPermitTileContent/MuniPermitTile.constants";

const TextInput: FC<TextInputTypes> = ({
  defaultValue,
  label,
  errorMessage,
  type,
  autoComplete,
  isDisabled,
  onChange,
  value,
  multiline,
  onSubmit,
  setValue,
  sidebar,
}) => {
  const [inputValue, setInputValue] = useState<string | number>("");
  const [autoCompleteIsOpen, setAutoCompleteIsOpen] = useState<boolean>(false);
  const [wrapperInfo, setWrapperInfo] = useState({
    left: 0,
    top: 0,
    width: 0,
  });
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  const inputRef = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { debounce } = useDebounce(300);
  const dispatch = useAppDispatch();
  const { uniqueValues, filteredValues } = useAppSelector(selectAutoComplete);
  const { onHoldStatusList } = useAppSelector(
    (state) => state.constructionSlice
  );

  const uniqueGroupName = uniqueValues.group_name;
  const onHoldStatusValuesList =
    onHoldStatusList.length && onHoldStatusList[0]
      ? onHoldStatusList.map((item: string) => {
          if (!onHoldStatusValues.includes(item)) {
            return item;
          }
        })
      : onHoldStatusValues;
  const items =
    (filteredValues[autoComplete ?? ""] &&
    filteredValues[autoComplete ?? ""].length &&
    inputValue
      ? filteredValues[autoComplete ?? ""]
      : uniqueValues[autoComplete ?? ""]) ?? [];

  const statusValues = [...uniqueValues.status];

  const filteredStatuses = !inputValue
    ? statusValues
    : statusValues.filter((item) =>
        item.toLowerCase().includes((inputValue as string).toLowerCase())
      );
  const actualStatuses = filteredStatuses.length
    ? filteredStatuses
    : statusValues;

  const filteredMuniPermits = !inputValue
    ? muniPermitOptions.map((item) => item.value)
    : muniPermitOptions
        .map((item) => item.value)
        .filter((item) =>
          item.toLowerCase().includes((inputValue as string).toLowerCase())
        );
  const actualMuniPermits = filteredMuniPermits.length
    ? filteredMuniPermits
    : muniPermitOptions.map((item) => item.value);

  const itemsList =
    autoComplete === "issue_status"
      ? actualStatuses
      : autoComplete === "munipermit.permit_type"
      ? actualMuniPermits
      : items;

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    setInputValue(e.target.value);
    setValue && setValue(e.target.value);
    onSubmit && onSubmit();
  };

  const handleItemClick: AutoCompleteItemHandler = (value, onChange) => {
    onChange(value);
    setAutoCompleteIsOpen(false);
    onSubmit && onSubmit();
    setValue && setValue(value);
  };

  const clickEvent = (e: MouseEvent) => {
    if (wrapperRef.current) {
      setAutoCompleteIsOpen(wrapperRef.current.contains(e.target as Node));
    }
  };

  const debounceCallBack = () => {
    if (autoComplete) {
      if (value) {
        const filterData: GetAutocompleteValuesByFilter = {
          group: autoComplete,
          filter: value.toString(),
        };
        dispatch(getAutocompleteValuesByFilter(filterData));
      } else {
        dispatch(clearAutoCompleteFilter(autoComplete));
      }
    }
  };

  const scrollEvent = (event: any) => {
    if (
      event.target.contains(wrapperRef.current) &&
      event.target !== document
    ) {
      const wrapperPos: DOMRect | undefined =
        wrapperRef.current?.getBoundingClientRect();
      const targetPos = event.target.getBoundingClientRect();
      if (
        (wrapperPos?.top as number) >= targetPos?.top &&
        targetPos.top + targetPos.height >= wrapperPos!.top + wrapperPos!.height
      ) {
        setIsOverflow(false);
      } else {
        setIsOverflow(true);
      }
      const topPosition =
        (wrapperRef.current?.offsetTop as number) - event.target.scrollTop;
      if (topPosition) {
        if (
          targetPos.top + targetPos.height >=
          wrapperPos!.top + wrapperPos!.height
        ) {
          setWrapperInfo({
            top: topPosition - 190 ?? 0,
            left: wrapperRef.current?.offsetLeft ?? 0,
            width: wrapperRef.current?.offsetWidth ?? 0,
          });
        } else {
          setWrapperInfo({
            top: topPosition,
            left: wrapperRef.current?.offsetLeft ?? 0,
            width: wrapperRef.current?.offsetWidth ?? 0,
          });
        }
      }
    }
  };

  const sideBareItemsList =
    autoComplete === "groupName" ? uniqueGroupName : onHoldStatusValuesList;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (autoComplete) {
      debounce(debounceCallBack);
    }
  }, [value]);
  useEffect(() => {
    document.addEventListener("click", clickEvent);
    document.addEventListener("scroll", scrollEvent, true);
    setWrapperInfo({
      top: wrapperRef.current?.offsetTop ?? 0,
      left: wrapperRef.current?.offsetLeft ?? 0,
      width: wrapperRef.current?.offsetWidth ?? 0,
    });
    return () => {
      document.removeEventListener("click", clickEvent);
      document.removeEventListener("scroll", scrollEvent, true);
    };
  }, [inputValue]);

  return (
    <Styled.TextWrapper ref={wrapperRef} isOverflow={isOverflow}>
      <Styled.TextInput
        variant="outlined"
        multiline={multiline}
        disabled={isDisabled ? isDisabled : false}
        defaultValue={defaultValue}
        label={label}
        size="small"
        onFocus={() => {
          setAutoCompleteIsOpen(true);
        }}
        onChange={inputOnChange}
        value={inputValue}
        error={!!errorMessage}
        autoComplete={autoComplete ? undefined : "off"}
        type={type}
        ref={inputRef}
      />
      {autoCompleteIsOpen && autoComplete && (
        <AutoComplete
          isOverflow={isOverflow}
          position={{
            left: wrapperInfo.left,
            top: wrapperInfo.top,
            width: wrapperInfo.width,
          }}
          items={sidebar ? sideBareItemsList : itemsList}
          handleItemClick={(value) => handleItemClick(value, onChange)}
          sidebar={sidebar}
        />
      )}

      {!!errorMessage && !autoCompleteIsOpen && (
        <Styled.Error>{errorMessage}</Styled.Error>
      )}
    </Styled.TextWrapper>
  );
};

export default TextInput;
