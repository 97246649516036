import styled from "@emotion/styled";
import { Box } from "@mui/material";

const RowIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  span {
    display: flex;
  }
`;

export const Styled = { RowIconWrapper };
