import { MassUpdateData, MassUpdateItem } from "./editHelpers.types";

const generateMassUpdate = (
  massUpdateItems: MassUpdateItem[],
  selectedIssueIds: string[],
  pageName: "construction" | "issueTracker" | "assets"
): MassUpdateData => {
  let massUpdateFields;
  let massUpdateInfo;
  switch (pageName) {
    case "issueTracker":
      const generatedChange: MassUpdateItem = {};
      massUpdateItems.map((item) => {
        const field = item.field;
        const value = item.value;
        if (
          (field === "initiationCompleteDate" && value === "") ||
          (field === "forecastedVegClearDate" && value === "") ||
          (field === "resolvedDate" && value === "")
        ) {
          generatedChange[field] = "0000-00-00";
        } else {
          generatedChange[field] = value;
        }
      });
      const massUpdateData: MassUpdateData = {};
      selectedIssueIds.map((id) => {
        massUpdateData[id] = generatedChange;
      });
      return massUpdateData;
    case "construction":
      massUpdateFields = massUpdateItems.reduce(
        (obj, item) =>
          Object.assign(
            obj,
            (item.field === "actualIfc" && item.value === "") ||
              (item.field === "issueResolvedDate" && item.value === "")
              ? { [item.field]: "0000-00-00" }
              : { [item.field]: item.value }
          ),
        {}
      );
      massUpdateInfo = {
        groupNames: selectedIssueIds,
        fields: massUpdateFields,
      };
      return massUpdateInfo;
    case "assets":
      massUpdateFields = massUpdateItems.reduce(
        (obj, item) => Object.assign(obj, { [item.field]: item.value }),
        {}
      );
      massUpdateInfo = {
        ids: selectedIssueIds,
        fields: massUpdateFields,
      };
      return massUpdateInfo;
  }
};

const genereateFilteredMassUpdate = (
  massUpdateItems: MassUpdateItem[],
  pageName: "construction" | "issueTracker" | "assets",
  filteringRules?: any[]
): any => {
  let massUpdateFields;
  let massUpdateInfo;
  switch (pageName) {
    case "issueTracker":
      const generatedChange: MassUpdateItem = {};
      massUpdateItems.map((item) => {
        const field = item.field;
        const value = item.value;
        generatedChange[field] = value;
      });

      const filteredMassUpdateBody = {
        updateIssueDto: generatedChange,
        filterDtos: filteringRules,
      };

      return filteredMassUpdateBody;

    case "construction":
      massUpdateFields = massUpdateItems.reduce(
        (obj, item) => Object.assign(obj, { [item.field]: item.value }),
        {}
      );

      massUpdateInfo = {
        filters: filteringRules,
        fields: massUpdateFields,
      };

      return massUpdateInfo;

    case "assets":
      massUpdateFields = massUpdateItems.reduce(
        (obj, item) => Object.assign(obj, { [item.field]: item.value }),
        {}
      );

      massUpdateInfo = {
        filters: filteringRules,
        fields: massUpdateFields,
      };

      return massUpdateInfo;
  }
};

const generateUniqueValues = (
  transformedUniqueValues: any,
  hardcodedValues: any[]
): any[] => {
  let allClassifications: any;
  if (hardcodedValues) {
    allClassifications = [...transformedUniqueValues, ...hardcodedValues];
  } else {
    allClassifications = transformedUniqueValues;
    return allClassifications;
  }

  const uniqueValues = Array.from(
    new Set(allClassifications.map((a: any) => a.value))
  ).map((value) => {
    return allClassifications.find((a: any) => a.value === value);
  });

  return uniqueValues;
};

const selectItems = [
  "onHoldStatus",
  "ballInCourt",
  "assigned",
  "issueStatus",
  "classification",
  "crossarmReplacement",
  "poleReplacement",
  "status",
];

const checkSapTilePermissions = (
  ballInCourt: string,
  name: string,
  getPermission: (item: string) => boolean,
  page?: boolean
) => {
  if (
    (getPermission("editVegManagement") &&
      ballInCourt?.includes("Veg Management")) ||
    getPermission("editSapTile")
  ) {
    return true;
  }

  if (page) {
    if (getPermission("edit_tile_const")) {
      return true;
    }
  } else {
    if (
      name === "wo" &&
      ((getPermission("editFirmPrime") &&
        ballInCourt?.includes("FiRM/Prime")) ||
        (getPermission("editEro") &&
          (ballInCourt?.includes("ERO") || ballInCourt?.includes("CMP"))))
    ) {
      return true;
    }
    return false;
  }
};

export {
  generateMassUpdate,
  selectItems,
  genereateFilteredMassUpdate,
  generateUniqueValues,
  checkSapTilePermissions,
};
