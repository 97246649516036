import React, { useRef, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { Button, LoadingWrapper } from "@sdge-components";
import { Styled } from "../../CellInfo.styled";
import { useForm } from "react-hook-form";
import { setProgramInfoIssueTracker } from "@sdge-store/drawer/drawer.async";
import { CircularProgress } from "@mui/material";
import useIssueTrackerContent from "./useIssueTrackerContent";
import DrawerRowItem from "../DrawerRowItem";
import { deleteIssue } from "@sdge-store/issueTracker/issueTracker.async";
import { parseRowDateString } from "@sdge-utils/dateParser";
import { SetManual } from "@sdge-store/drawer/drawer.slice";
import { usePermission } from "@sdge-hooks";

const IssueTrackerInfo = () => {
  const dispatch = useAppDispatch();
  const {
    programInfo,
    isLoaded,
    programInfoLoading,
    rightSideBarUpdateLoading,
  } = useAppSelector((state) => state.drawerState.programData);
  const { manual } = useAppSelector((state) => state.drawerState.drawerOptions);
  const { control, handleSubmit, reset, getValues, setValue } = useForm();
  const { writePermissions } = useAppSelector((state) => state.userSlice.info);
  const { getPermission } = usePermission();
  const dataExistent = !!Object.keys(programInfo).length;

  const formRef = useRef(null);
  const [isChanged, setIsChanged] = useState(false);

  const handleFormSubmit = (data: any) => {
    const tempData = JSON.parse(JSON.stringify(data));

    if (!tempData?.initiationCompleteDate) {
      tempData.initiationCompleteDate = "0000-00-00";
    }
    if (!tempData?.resolvedDate) {
      tempData.resolvedDate = "0000-00-00";
    }
    if (!tempData?.forecastedVegClearDate) {
      tempData.forecastedVegClearDate = "0000-00-00";
    }
    dispatch(setProgramInfoIssueTracker(tempData));
    setIsChanged(false);
  };

  const handleDelete = () => {
    dispatch(deleteIssue(programInfo.id));
  };

  const resetDate = (field: string) => {
    reset({ ...getValues(), [field]: "" });
  };
  const rightSideBarpermissions = [
    "propagateFacilityChanges",
    "ballInCourt",
    "identifiedDate",
    "pole_replacement",
    "kmz_link",
    "group_name",
    "e2_initiation_complete_date",
    "classification",
    "assigned",
    "status",
    "issue_identified_date",
    "issue_resolved_date",
    "vets_#",
    "forecasted_veg_clear_date",
    "resolvedDate",
  ];
  const isAnyNotEditable = rightSideBarpermissions.some((item) => {
    if (
      writePermissions?.includes("cityWorks_resolved_date") &&
      programInfo["ballInCourt"]?.includes("City Works")
    ) {
      return true;
    }
    return writePermissions?.includes(item);
  });

  useEffect(() => {
    reset({ ...programInfo });
    setIsChanged(false);
    return () => {
      reset();
    };
  }, [programInfo]);

  const { issueTrackerContent, itemsOrder } = useIssueTrackerContent();

  const regex = new RegExp(
    "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"
  );

  return (
    <LoadingWrapper loading={programInfoLoading}>
      {rightSideBarUpdateLoading && (
        <Styled.LoadingLayer>
          <CircularProgress color="inherit" />
        </Styled.LoadingLayer>
      )}
      {dataExistent && isLoaded ? (
        <Styled.CellInfoForm
          onSubmit={(e) => {
            e.preventDefault();
            setIsChanged(true);
          }}
          ref={formRef}
        >
          {isAnyNotEditable && (
            <Styled.Row>
              <Button
                onClick={handleSubmit(handleFormSubmit)}
                title="Update Issue"
                isActive={true}
                disabled={!isChanged}
              />
              {manual && getPermission("issueDelete") && (
                <Button
                  onClick={handleDelete}
                  title="Delete Issue"
                  isActive={true}
                  disabled={programInfo.bmcdTier3 === "Y"}
                />
              )}
            </Styled.Row>
          )}
          {Object.keys(programInfo)
            .sort((a, b) => itemsOrder.indexOf(a) - itemsOrder.indexOf(b))
            .map((item) => {
              const manual = regex.test(programInfo.id);
              dispatch(SetManual(manual));

              const content =
                issueTrackerContent[item as keyof typeof issueTrackerContent];
              const dateItem = [
                "approvedDate",
                "initiationCompleteDate",
                "identifiedDate",
                "resolvedDate",
                "forecastVegClearDate",
              ];
              return (
                <DrawerRowItem
                  key={item}
                  title={content?.title}
                  name={item}
                  content={
                    dateItem.includes(item) &&
                    parseRowDateString(programInfo[item])
                      ? parseRowDateString(programInfo[item])
                      : programInfo[item]
                  }
                  optionsData={content?.optionsData}
                  control={control}
                  formRef={formRef}
                  permission={content?.permission}
                  setValue={setValue}
                  autoComplete={content?.autoComplete}
                  reset={resetDate}
                  isEdit={content?.isEdit}
                  isDate={content?.isDate}
                  onSubmit={() => setIsChanged(true)}
                  manual={regex.test(programInfo.id)}
                />
              );
            })}
        </Styled.CellInfoForm>
      ) : (
        <Styled.NoInfoWrapper>No information for pole</Styled.NoInfoWrapper>
      )}
    </LoadingWrapper>
  );
};

export default IssueTrackerInfo;
