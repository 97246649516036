import { Profiler, useCallback, useMemo, useRef, useState } from "react";

import MainTable from "./MainTable";
import { selectIssueTracker } from "@sdge-store/issueTracker/issueTracker.selector";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  handleLoadedPagesChange,
  handleSort,
  handleHiddenColumn,
  handlePinnedColumn,
  filterOptions,
  handleSearch,
  fieldsHiddenOptions,
  sortOptions,
  handleFilter,
  quickViewsOptions,
  handleColumnDefs,
  handleColumnOrder,
  handleSelectedRows,
  massUpdateOptions,
  handleChangeRequested,
  setActiveIssueId,
  handleSelecedRowsIssueIds,
  handleInputText,
} from "@sdge-store/issueTracker/issueTracker.slice";
import {
  IssueMassUpdateOptions,
  IssueTrackerFilterOptionsMenu,
  IssueTrackerHideOptionsMenu,
  IssueTrackerQuickViewsOptions,
  IssueTrackerSortOptionsMenu,
} from "@sdge-components";
import {
  fetchIssueData,
  getEnvPermits,
  getIssueTrackerCustomersList,
  getIssueTrackerOverlapping,
  getMuniPermits,
  // getPermits,
  getSapTileContent,
  sortIssueData,
} from "@sdge-store/issueTracker/issueTracker.async";
import { GridOptions, SelectionChangedEvent } from "ag-grid-community";

import {
  generateColumnDefs,
  generateColumnOrder,
  generateExportBody,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import {
  handleChangeDrawerOptions,
  handleRouterChange,
} from "@sdge-store/drawer/drawer.slice";
import { handleSetRowData } from "@sdge-store/Map/map.slice";
import { DownloadLinks, RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { exportFilteredFile } from "@sdge-utils/exportFile";
import { issueItemsDateConvert } from "@sdge-utils/helpers/gridHelpers/gridHelpers";
import { usePermission } from "@sdge-hooks";

const isThirdColumn = (params: any) => {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  const indexOfButton = displayedColumns.findIndex(
    (item: any) => item.maxWidth === 60
  );
  const thisIsFirstColumn = displayedColumns[indexOfButton] == params.column;
  return thisIsFirstColumn;
};

const IssueMainTable = () => {
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const [columnApi, setColumnApi] = useState<any>(null);
  const { getPermission } = usePermission();

  const {
    issues,
    totalElements,
    loading,
    page,
    sortingRules,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    totalPages,
    areQuickVeiwsOptionsOpen,
    selectedRows,
    selecedRowsIssueIds,
    areMassUpdateOptionsOpen,
    massUpdateItemsRequest,
    inputText,
    bmcdTierVisibility,
  } = useAppSelector(selectIssueTracker);

  const selectedIssues: any = [];

  selecedRowsIssueIds?.forEach((elem) => {
    issues.forEach((item) => {
      if (item.id === elem) {
        selectedIssues.push(item);
      }
    });
  });

  const hasPermissions = getPermission("readonly");
  const hasPermissionsToMassUpdate =
    getPermission("issueMassUpdate") ||
    getPermission("issueVegMassUpdate") ||
    getPermission("firmPrimeMassUpdate") ||
    getPermission("cityWorksMassUpdate");

  const getRowData = ({ data }: any) => {
    const drawerRouterOptions = {
      parentPath: RightDrawerParentPaths.ISSUE_TRACKER_TABLE,
      backArrow: false,
      prevPath: "/",
      newPath: "overlapping",
      subPath: "info",
    };
    const drawerOptions = {
      isOpen: true,
      title: data.facilityId,
      cardId: data.facilityId,
      numberOfPoles: data.numberOfPoles,
      issueId: data.id,
    };
    dispatch(handleRouterChange(drawerRouterOptions));
    dispatch(handleChangeDrawerOptions(drawerOptions));

    dispatch(handleSetRowData(data));
    dispatch(getIssueTrackerCustomersList(data.facilityId));
    dispatch(getIssueTrackerOverlapping(data.id));
    dispatch(getSapTileContent(data.id));
    dispatch(getEnvPermits(data.id));
    dispatch(getMuniPermits(data.id));

    dispatch(
      setActiveIssueId({
        id: data.id,
        facilityId: data.facilityId,
        groupName: data.groupName,
      })
    );
    dispatch(handleChangeRequested(true));
  };
  const selectionRef = useRef<any>(null);
  const getSelectedRowData = useCallback((params: SelectionChangedEvent) => {
    clearTimeout(selectionRef.current);
    selectionRef.current = setTimeout(() => {
      const selectedNodes = params.api.getSelectedNodes();
      const selectedRowsIndexes = selectedNodes.map((item) => item.rowIndex);
      const selectedRowsIssueIds = selectedNodes.map((item) => item.data.id);
      dispatch(handleSelecedRowsIssueIds(selectedRowsIssueIds));
      dispatch(handleSelectedRows(selectedRowsIndexes));
    }, 300);
    return () => {
      clearTimeout(selectionRef.current);
    };
  }, []);

  const gridOptions: GridOptions = useMemo(
    () => ({
      suppressDragLeaveHidesColumns: true,
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
      suppressPropertyNamesCheck: true,
      rowBuffer: 1,
      suppressMaintainUnsortedOrder: true,
      overlayNoRowsTemplate:
        ' <img src="https://www.ag-grid.com/example-assets/loading.gif" />',
      suppressMenuHide: true,
      rowHeight: 40,
      onGridReady: (params) => {
        if (!loading) {
          params.columnApi.autoSizeAllColumns();
        }
        setGridApi(params.api);
        setColumnApi(params.columnApi);
      },
      onDragStopped: (params) => {
        const allColumns: any = params.columnApi.getAllGridColumns();
        dispatch(handleColumnDefs(generateColumnDefs(allColumns)));
        dispatch(
          handleColumnOrder(generateColumnOrder(allColumns, "issueTracker"))
        );
      },
      rowSelection: "multiple",
      onRowClicked: (rowInfo) => getRowData(rowInfo),
      onSelectionChanged: (params) => {
        return getSelectedRowData(params);
      },
      rowMultiSelectWithClick: true,
    }),
    []
  );

  const allFilteringCols = [...columnDefs];

  const handleExportClick = () => {
    dispatch(
      handleChangeSnackbarState({
        isOpen: true,
        alertColor: "success",
        message: "Download has started",
      })
    );
    if (selectedRows.length > 0) {
      gridApi.exportDataAsCsv({
        allColumns: true,
        onlySelected: true,
        fileName: "issues",
      });
    } else {
      const generatedExportBody = generateExportBody(
        allFilteringCols,
        filteringRules,
        bmcdTierVisibility
      );
      exportFilteredFile(
        inputText.length > 0
          ? `${DownloadLinks.ISSUE_TRACKER}?searchText=${inputText}`
          : DownloadLinks.ISSUE_TRACKER,
        generatedExportBody,
        "issues"
      ).catch((e) => {
        dispatch(
          handleChangeSnackbarState({
            isOpen: true,
            alertColor: "error",
            message: e.response.data.error,
          })
        );
      });
    }
  };

  const rowItems = useMemo(() => issueItemsDateConvert(issues), [issues]);

  const data = {
    items: rowItems,
    totalElements,
    loading,
    page,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    totalPages,
    sortingRules,
    areQuickVeiwsOptionsOpen,
    selectedRows,
    selecedRowsIssueIds,
    areMassUpdateOptionsOpen,
    massUpdateItemsRequest,
    inputText,
    MassUpdateOptions: IssueMassUpdateOptions,
    FilterOptionsMenu: IssueTrackerFilterOptionsMenu,
    HideOptionsMenu: IssueTrackerHideOptionsMenu,
    QuickViewsOptions: IssueTrackerQuickViewsOptions,
    SortOptionsMenu: IssueTrackerSortOptionsMenu,
    title: "All Issues",
    hasPermissions,
  };

  const storeFunctions = useMemo(
    () => ({
      fieldsHiddenOptions,
      sortOptions,
      filterOptions,
      quickViewsOptions,
      handleSearch,
      handleLoadedPagesChange,
      sortData: sortIssueData,
      handleHiddenColumn,
      handlePinnedColumn,
      handleSort,
      handleFilter,
      fetchData: fetchIssueData,
      handleSelectedRows,
      resetSelectedIds: handleSelecedRowsIssueIds,
      handleInputText,
    }),
    []
  );

  const options = {
    massUpdateOptions: hasPermissionsToMassUpdate ? massUpdateOptions : null,
    // hasPermissionsToMassUpdate || isVegManagement ? massUpdateOptions : null,
    gridOptions,
    animateRows: false,
    storeSelector: selectIssueTracker,
    handleExportClick,
    columnApi,
    isThirdColumn,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const profiler = (...rest: any[]) => {
    // eslint-disable-next-line no-console
    // console.log("issueTable", { ...rest });
  };

  return (
    <Profiler id="issueTable" onRender={profiler}>
      <MainTable
        data={data}
        storeFunctions={storeFunctions}
        options={options}
      />
    </Profiler>
  );
};

export default IssueMainTable;
