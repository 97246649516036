import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { Button } from "@sdge-components";
import { StyledPropsWithTheme } from "../../utils/globalTypes";

const MassUpdateOptionsWrapper = styled.form<StyledPropsWithTheme>`
  width: 400px;
  max-height: 300px;
  min-height: 190px;
  background: ${({ theme }) => theme.palette.tables?.main?.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  row-gap: 5px;
  padding: 15px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  position: relative;
`;

const MassUpdateOptionsList = styled(Box)<StyledPropsWithTheme>`
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 6px;
  row-gap: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 5px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    opacity: 0.15;
  }
`;
export const AddFieldWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddItemButton = styled(Button)<StyledPropsWithTheme>`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;
const DecorateButton = styled.button`
  display: none;
`;
const MassUpdateTitle = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Fallback = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
`;

const TitleLeft = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const LoadingLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(143, 143, 143, 0.37);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Styled = {
  MassUpdateOptionsWrapper,
  MassUpdateOptionsList,
  AddFieldWrapper,
  AddItemButton,
  DecorateButton,
  MassUpdateTitle,
  Fallback,
  TitleLeft,
  LoadingLayer,
};
