import React, { SyntheticEvent } from "react";
const UploadFolder: React.FC<{
  handleOnChange: (event: SyntheticEvent) => Promise<void>;
  uploadFolder: any;
  handleClick: any;
}> = ({ handleOnChange, uploadFolder, handleClick }) => {
  return (
    <input
      webkitdirectory="webkitdirectory"
      hidden
      type="file"
      ref={uploadFolder}
      onChange={handleOnChange}
      multiple
      onClick={handleClick}
    />
  );
};

export default UploadFolder;
