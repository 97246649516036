import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import Slider from "react-slick";

const OverlappingPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: all 0.3s ease 0s;
`;

const TableGroup = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
  > * {
    flex: 1 1 223px;
  }
`;

const SliderWrapper = styled(Box)<StyledPropsWithTheme>`
  .slick-slide {
    padding: 0 15px;
  }
  .slick-dots {
    .slick-active {
      button::before {
        color: ${({ theme }) => theme.palette.primary.light} !important;
      }
    }
    button::before {
      color: white;
    }
  }
  padding: 0 15px;
`;
const TilesSlider = styled(Slider)`
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`;
export const Styled = {
  OverlappingPageWrapper,
  TableGroup,
  SliderWrapper,
  TilesSlider,
};
