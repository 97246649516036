import useCustomerTileContentCreator from "./useCustomerTileContentCreateor";
import { Grid, Box } from "@mui/material";
import { TileSection } from "../TileSection";
import React from "react";
import { Typography } from "@material-ui/core";
import { useAppSelector } from "../../app/hooks";
import RowItem from "./RowItem";
import { CustomerTileContentPropsType } from "./CustomerTileContent.types";
import { useLocation } from "react-router-dom";
import { DeleteIcon } from "../../icons";
import { deleteCustomerByGroup } from "@sdge-store/construction/construction.async";
import { useDispatch } from "react-redux";
import { usePermission } from "@sdge-hooks";

const CustomerTileContent: React.FC<CustomerTileContentPropsType> = ({
  issueTracker,
}) => {
  const contentItems = useCustomerTileContentCreator(issueTracker) || [];
  const { requested, cardsLoading } = useAppSelector(
    (state) => state.issueTrackerSlice
  );
  const { constructionCustomersList } = useAppSelector(
    (state) => state.constructionSlice
  );
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  const { pathname } = useLocation();
  const { getPermission } = usePermission();
  // const constractionPage = pathname.split("/").includes("construction");
  const issueTrackerPage = pathname.split("/").includes("issue_tracker");
  const hasPermissions =
    getPermission("delCustomerConstPage") ||
    (getPermission("delCustomerIssuePage") && issueTrackerPage) ||
    getPermission("del_customer");
  const dispatch = useDispatch();

  const removeCustomer = (id: string) => {
    const index = constructionCustomersList.indexOf(
      constructionCustomersList.filter(
        (item: any) => item.accountNumber === id
      )[0]
    );
    dispatch(deleteCustomerByGroup({ id, index }));
  };
  if (cardsLoading) {
    return <> </>;
  }
  return (
    <>
      {!contentItems.length && requested && (
        <Grid container justifyContent="center">
          <Box mt="20%" width="70%">
            <Typography align="center">
              We can not find any customers for this issue/pole
            </Typography>
          </Box>
        </Grid>
      )}
      {contentItems?.map((item: any, i: number) => {
        return (
          <Box key={i}>
            {programInfo.bmcdTier3 !== "Y" && hasPermissions ? (
              <TileSection
                title=""
                Icon={DeleteIcon}
                handleAction={() => {
                  removeCustomer(
                    item.filter((item: any) => item.name === "accountNumber")[0]
                      .content
                  );
                }}
              />
            ) : (
              <TileSection title="" />
            )}

            {item.map(({ id, title, content, icon, name }: any) => {
              return (
                <RowItem
                  title={title}
                  key={id}
                  content={content}
                  icon={icon}
                  issueTracker={issueTracker}
                  name={name}
                  id={item[11]?.customerId || ""}
                />
              );
            })}
          </Box>
        );
      })}
    </>
  );
};

export default CustomerTileContent;
