import {
  FilterItemType,
  ColumnDefsType,
  SortItemType,
  CurrentColumnsType,
  QuickViewType,
  MassUpdateItemType,
} from "./filterHelpers.types";

const generateFilterArr = (
  filter: FilterItemType[],
  field: string,
  currentFilter: FilterItemType[]
): FilterItemType[] => {
  let newFilter;
  if (currentFilter.length === 0) {
    newFilter = {
      operation: "equals",
      negate: false,
      fieldName: field,
      value: "",
      errorMessage: "You should provide the value",
    };
  } else {
    newFilter = {
      operation: "equals",
      negate: false,
      fieldName: field,
      value: "",
      relation: "AND",
      errorMessage: "You should provide the value",
    };
  }

  return [...filter, newFilter];
};

const generateCurrentInfo = (
  advancedOptions: boolean | undefined,
  columnDefs: any[],
  field: string
): any => {
  return advancedOptions
    ? columnDefs
        .map(({ children }: any) => {
          const newItems = children.filter((item1: any) => {
            if (item1.filterField) {
              return item1.filterField === field;
            } else {
              return item1.field === field;
            }
          });
          return { ...newItems };
        })
        .filter((item: any) => {
          if (item.hasOwnProperty(0)) {
            return item;
          }
        })
    : columnDefs.filter((item) => {
        if (item.filterField) {
          return item.filterField === field;
        } else {
          return item.field === field;
        }
      });
};

const generateFilteringCols = (
  advancedOptions: boolean | undefined,
  columnDefs: any[],
  unfilteredCols: any[]
): any => {
  return advancedOptions
    ? columnDefs.map((item: any) => {
        return {
          ...item,
          children: item.children
            .map((item: any, index: any) => {
              return {
                id: index,
                value: item.filterField || item.field,
                label: item.headerName,
              };
            })
            .sort((a: any, b: any) => a.label.localeCompare(b.label)),
        };
      })
    : unfilteredCols
        .map((item, index) => {
          return {
            id: index,
            value: item.filterField || item.field,
            label: item.headerName,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
};

const setInputFilter = (
  filters: FilterItemType[],
  value: string,
  property: string,
  currFilterInfo: FilterItemType[],
  index: number
): FilterItemType[] => {
  if (!value) {
    const newFilterEl = {
      ...currFilterInfo[0],
      errorMessage: "You should provide the value",
    };
    const filtersInfo = [...filters];
    filtersInfo[index] = newFilterEl;
    return filtersInfo;
  }
  const newFilterEl = { ...currFilterInfo[0] };
  if (newFilterEl.errorMessage) {
    delete newFilterEl.errorMessage;
  }
  const filtersInfo = [...filters];
  newFilterEl[property] = value;
  filtersInfo[index] = newFilterEl;
  return filtersInfo;
};

const setMassUpdateInput = (
  filters: FilterItemType[],
  value: string,
  property: string,
  currFilterInfo: FilterItemType[],
  colInfo: ColumnDefsType[]
): FilterItemType[] => {
  const newFilterEl = { ...currFilterInfo[0] };
  const filtersInfo = [...filters];
  const foundIndex = filtersInfo.findIndex(
    (item) => item.field === colInfo[0].field
  );
  newFilterEl[property] = value;
  filtersInfo[foundIndex] = newFilterEl;
  return filtersInfo;
};

const setFilter = (
  filters: FilterItemType[],
  value: string,
  property: string | string[],
  currFilterInfo: FilterItemType[],
  index: number
): FilterItemType[] => {
  const newFilterEl = { ...currFilterInfo[0] };
  const filtersInfo = [...filters];
  if (typeof property === "string") {
    newFilterEl[property] = value;
  } else {
    const newValuesArr = value.split(",");
    newFilterEl[property[0]] = newValuesArr[0];
    newFilterEl[property[1]] = JSON.parse(newValuesArr[1]);
  }
  filtersInfo[index] = newFilterEl;
  return filtersInfo;
};

const setMassSelect = (
  filters: FilterItemType[],
  value: string,
  property: string,
  currFilterInfo: FilterItemType[],
  currentField?: string
): FilterItemType[] => {
  const newFilterEl = { ...currFilterInfo[0] };
  const filtersInfo = [...filters];
  if (value.includes("Date") || property === "field") {
    newFilterEl["value"] = "";
  }
  newFilterEl[property] = value;
  const foundIndex = filtersInfo.findIndex(
    (item) => item.field === currentField
  );
  filtersInfo[foundIndex] = newFilterEl;

  return filtersInfo;
};

const generateSearchString = (
  input: string,
  sortArray: SortItemType[]
): string | undefined => {
  const result = sortArray.map(
    ({ property, direction }) => `&sort=${property},${direction}`
  );
  const sortString = result.join("");
  const searchQuery = `&searchText=${input}`;
  if (input.length === 0 && sortArray.length === 0) {
    return "";
  }
  if (input.length === 0 && sortArray.length > 0) {
    return sortString;
  }
  if (input.length > 0 && sortArray.length === 0) {
    return searchQuery;
  }
  if (input.length > 0 && sortArray.length > 0) {
    return `${searchQuery}${sortString}`;
  }
};

const generateFilterDelete = (
  filterArray: FilterItemType[],
  pageName?: string
): FilterItemType[] => {
  const arr = [...filterArray];
  if (pageName === "overlapping") {
    if (arr.length > 0) {
      if (arr[0].hasOwnProperty("relation")) {
        const firstItem = arr
          .filter((item) => arr.indexOf(item) === 0)
          .map((item) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { relation, ...others } = item;
            return others;
          });
        arr[0] = firstItem[0];
        return arr;
      }
    }
  } else {
    if (arr.length > 0) {
      const firstItem = arr.filter((item) => arr.indexOf(item) === 0);
      arr[0] = firstItem[0];
      return arr;
    }
  }
  return filterArray;
};

const generateColumnDefs = (
  currentAllColumns: CurrentColumnsType[]
): ColumnDefsType[] => {
  const userDefs = currentAllColumns.map((item) => item.userProvidedColDef);
  return userDefs;
};
const generateColumnOrder = (
  currentAllColumns: CurrentColumnsType[],
  page: "construction" | "overlapping" | "issueTracker" | "customer"
): string[] => {
  const userDefs = currentAllColumns.map((item) => item.userProvidedColDef);
  let columnFields;
  switch (page) {
    case "overlapping":
      columnFields = userDefs.slice(2).map((item) => item.field);
      return columnFields;
    case "issueTracker":
      columnFields = userDefs.slice(3).map((item) => item.field);
      return columnFields;
    case "customer":
      columnFields = userDefs.slice(1).map((item) => item.field);
      return columnFields;
    case "construction":
      columnFields = userDefs.slice(2).map((item) => item.field);
      return columnFields;
  }
};
const getHidddenColumns = (columnDefs: ColumnDefsType[]): string[] => {
  const hiddenCols = columnDefs.filter((item) => item.hide === true);
  const hiddenFields = hiddenCols.map((item) => item.field);
  return hiddenFields;
};
const getPinnedColumns = (columnDefs: ColumnDefsType[]): string[] => {
  const pinnedColumns = columnDefs.filter((item) => item.pinned === "left");
  const pinnedFields = pinnedColumns.map((item) => item.field);
  return pinnedFields;
};

const generateAddQuickView = (
  columnsOrder: (string | undefined)[],
  filters: FilterItemType[],
  hiddenColumns: string[],
  frozenColumns: string[],
  name: string,
  orders: SortItemType[],
  privacy?: boolean,
  id?: string
): QuickViewType => {
  const body = {
    columnsOrder: columnsOrder,
    filters: filters,
    hiddenColumns: hiddenColumns,
    frozenColumns: frozenColumns,
    name: name,
    orders: orders,
    ...(privacy !== undefined ? { privacy } : {}),
    ...(id !== undefined ? { id } : {}),
  };
  return body;
};

const generateMassUpdateArr = (
  field: string,
  currentArr: MassUpdateItemType[]
): MassUpdateItemType[] => {
  const newParam = { field: field, value: "" };
  return [...currentArr, newParam];
};

const generateExportBody = (
  colDefs: ColumnDefsType[],
  filteringRules: FilterItemType[],
  bmcdTierVisibility?: boolean
): FilterItemType[] => {
  const exportFields = filteringRules.map((item) => item.fieldName);

  const filterdCols = colDefs.filter((item) => {
    const ifExists = exportFields.some((field) => field === item.filterField);
    if (ifExists) {
      return item;
    } else {
      return null;
    }
  });

  const exportRules: FilterItemType[] = [];
  filterdCols.forEach((data) => {
    filteringRules.map((item) => {
      if (data.filterField === item.fieldName) {
        exportRules.push({
          ...item,
          fieldName: data.filterField || data.field,
        });
      }
    });
  });

  if (!bmcdTierVisibility) {
    exportRules.push({
      fieldName: "bmcd_tier_3",
      negate: true,
      operation: "equals",
      relation: "AND",
      value: "Y",
    });
  }

  return exportRules;
};

const logicals = [
  { id: 1, label: "and", value: "AND" },
  { id: 2, label: "or", value: "OR" },
];
const overlappingConditions = [
  { id: 4, label: "equals", value: ["equals", false] },
  { id: 2, label: "ending with", value: ["endingWith", false] },
  { id: 3, label: "contains", value: ["contains", false] },
  { id: 1, label: "starting from", value: ["startingFrom", false] },
  { id: 5, label: "not starting from", value: ["startingFrom", true] },
  { id: 6, label: "not ending with", value: ["endingWith", true] },
  { id: 7, label: "does not contain", value: ["contains", true] },
  { id: 8, label: "not equals", value: ["equals", true] },
];
const issueConditions = [
  { id: 4, label: "equals", value: ["equals", false] },
  { id: 2, label: "ending with", value: ["endingWith", false] },
  { id: 3, label: "contains", value: ["contains", false] },
  { id: 11, label: "not contains", value: ["contains", true] },
  { id: 12, label: "range", value: ["range", false] },
  { id: 1, label: "starting from", value: ["startingFrom", false] },
  { id: 5, label: "not starting from", value: ["startingFrom", true] },
  { id: 6, label: "not ending with", value: ["endingWith", true] },
  { id: 7, label: "does not contain", value: ["contains", true] },
  { id: 8, label: "not equals", value: ["equals", true] },
  { id: 9, label: "is empty", value: ["empty", false] },
  { id: 10, label: "not empty", value: ["empty", true] },
];
const dateFilters = [
  "date",
  "interaction",
  "start",
  "end",
  "projected_ifc",
  "actual_ifc",
  "forecasted_ifc",
];
export {
  generateFilterArr,
  logicals,
  overlappingConditions,
  setInputFilter,
  setFilter,
  generateSearchString,
  generateFilterDelete,
  generateColumnDefs,
  generateColumnOrder,
  getHidddenColumns,
  generateAddQuickView,
  generateMassUpdateArr,
  setMassSelect,
  setMassUpdateInput,
  dateFilters,
  generateExportBody,
  getPinnedColumns,
  generateCurrentInfo,
  generateFilteringCols,
  issueConditions,
};
