import { FC } from "react";
import { Styled } from "./Overlapping.styled";
import { useHistory } from "react-router-dom";
import { IconPathType } from "@sdge-utils/globalTypes";

const Overlapping: FC<IconPathType> = ({ path }) => {
  const history = useHistory();
  return (
    <Styled.OverlappingLogo
      isActive={path ? history.location.pathname.includes(path) : false}
    />
  );
};

export default Overlapping;
