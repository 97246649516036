import * as React from "react";
import { Button, Grid } from "@material-ui/core";
import { Styled } from "./ExportMenu.styled";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import useGenerateExportMenuItems from "./useGenerateExportMenuItems";

const ExportMenu: React.FC<any> = ({
  buttonRef,
  setExportType,
  handleExportSubmit,
}) => {
  const exportMenuItems = useGenerateExportMenuItems();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setExportType("");
  };

  const handleChange = (e: any) => {
    setExportType(e.target.value);
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      />
      <Styled.layersMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChange}
            defaultValue="grid"
          >
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              direction="column"
            >
              {exportMenuItems.map(({ label, id, value }) => {
                return (
                  <Styled.LayersMenuItem key={id}>
                    <Grid item>
                      <FormControlLabel
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    </Grid>
                  </Styled.LayersMenuItem>
                );
              })}
              <Grid container item justifyContent="center">
                <Button variant="outlined" onClick={handleExportSubmit}>
                  Export
                </Button>
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Styled.layersMenu>
    </div>
  );
};

export default ExportMenu;
