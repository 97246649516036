import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const ColumnFilterWrapper = styled(Box)<StyledPropsWithTheme>`
  background: ${({ theme }) => theme.palette.tables?.main?.default};
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px 2px rgba(97, 97, 97, 0.1),
    0px 16px 32px 2px rgba(97, 97, 97, 0.1);
  border-radius: 8px;
  width: 193px;
  //height: 216px;
`;

export const Styled = { ColumnFilterWrapper };
