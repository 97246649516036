import { useAppSelector } from "../../../../app/hooks";
import { useLocation } from "react-router-dom";

const useGenerateExportMenuItems = () => {
  const {} = useAppSelector((state) => state.constructionSlice);
  const { pathname } = useLocation();
  const constractionPage = pathname.split("/").includes("construction");

  const exportMenuItems = [
    {
      label: "Export Grid",
      value: "grid",
      id: 1,
    },
    {
      label: "Export Grid And Customers",
      value: "gridAndCustomers",
      id: 2,
    },
    {
      label: "Export Grid, Customers and Interactions",
      value: "gridAndCustomersInteractions",
      id: 3,
    },
    {
      label: "Export Package Parcel Data",
      value: "parcel",
      id: 4,
    },
    {
      label: "Export Package Permit Data",
      value: "permit",
      id: 5,
    },
  ];

  const exportAssetsMenuItems = [
    {
      label: "Export Assets",
      value: "assets",
      id: 1,
    },
    {
      label: "Export Assets Parcel Data",
      value: "parcel",
      id: 4,
    },
    {
      label: "Export Assets and Interactions",
      value: "assetsAndInteractions",
      id: 3,
    },
  ];

  return constractionPage ? exportMenuItems : exportAssetsMenuItems;
};

export default useGenerateExportMenuItems;
