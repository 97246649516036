const customer = (card: string) => [
  {
    field: "account_number",
    filtering: true,
    sortField: "account_number",
    headerName: "Account Number",
    filterField: `${card}account_number`,
  },
  {
    field: "ap_info",
    filtering: true,
    sortField: "ap_info",
    headerName: "Ap Info",
    filterField: `${card}ap_info`,
  },
  {
    field: "apn",
    filtering: true,
    sortField: "apn",
    headerName: "Apn",
    filterField: `${card}apn`,
  },
  {
    field: "apn_address_1",
    filtering: true,
    sortField: "apn_address_1",
    headerName: "Apn address 1",
    filterField: `${card}apn_address_1`,
  },
  {
    field: "apn_address_2",
    filtering: true,
    sortField: "apn_address_2",
    headerName: "Apn address 2",
    filterField: `${card}apn_address_2`,
  },
  {
    field: "customer_id",
    filtering: true,
    sortField: "customer_id",
    headerName: "Customer Id",
    filterField: `${card}customer_id`,
  },
  {
    field: "customer_name",
    filtering: true,
    sortField: "customer_name",
    headerName: "Customer Name",
    filterField: `${card}customer_name`,
  },
  {
    field: "email",
    filtering: true,
    sortField: "email",
    headerName: "Email",
    filterField: `${card}email`,
  },
  {
    field: "email",
    filtering: true,
    sortField: "email",
    headerName: "Email",
    filterField: `${card}email`,
  },
  {
    field: "facilityid",
    filtering: true,
    sortField: "facilityid",
    headerName: "Facility Id",
    filterField: `${card}facilityid`,
  },
  {
    field: "group_name",
    filtering: true,
    sortField: "group_name",
    headerName: "Group Name",
    filterField: `${card}group_name`,
  },
  {
    field: "meter_number",
    filtering: true,
    sortField: "meter_number",
    headerName: "Meter Number",
    filterField: `${card}meter_number`,
  },
  {
    field: "notes",
    filtering: true,
    sortField: "notes",
    headerName: "Notes",
    filterField: `${card}notes`,
  },
  {
    field: "phone_numbers",
    filtering: true,
    sortField: "phone_numbers",
    headerName: "Phone Numbers",
    filterField: `${card}phone_numbers`,
  },
  // {
  //   field: "sensitive_customer",
  //   filtering: true,
  //   sortField: "sensitive_customer",
  //   headerName: "Sensitive Customer",
  //   filterField: `${card}sensitive_customer`,
  // },
];

export default customer("customerConstruction.");
