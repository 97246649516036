import styled from "@emotion/styled";
import { Button } from "@sdge-components";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const SortingButton = styled(Button)<
  StyledPropsWithTheme<{ disabled?: boolean }>
>`
  width: 100%;
  border-radius: 8px;
  justify-content: flex-start;
  font-size: 16px;
  pointer-events: ${({ disabled }) => disabled && "none"};
  opacity: ${({ disabled }) => disabled && "0.4"};
  > svg {
    margin-right: 10px;
    color: ${({ theme }) => theme.palette.button.primary.color[200]};
  }
  &:hover {
    background: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.button.primary.color[200]};
    > svg {
      path {
        fill: ${({ theme }) => theme.palette.button.primary.color[200]};
      }
    }
  }
`;
export const Styled = { SortingButton };
