import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryInstance } from "@sdge-api/config";
import { errorHandler } from "@sdge-api/utils";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { RootState } from "@sdge-store/store";
import { SnackbarDataTypes } from "@sdge-store/snackbar/snackbar.types";
import { fetchOverlapsData } from "@sdge-store/overlaps/overlaps.async";
import { DeleteInteractionTypes } from "@sdge-store/drawer/drawer.types";

export const fetchConstructionData: any = createAsyncThunk(
  "construction/fetchConstructionData",
  async (sortingRules: any, { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      // add rules to the sorting rules filter property
      const updatedSortingRules = [
        sortingRules[0], // page
        sortingRules[1], // sort
        [...sortingRules[2], ...rules], // filter
        sortingRules[3], // isQuickViews
      ];

      try {
        let response;
        if (updatedSortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/construction?page=${updatedSortingRules[0]}${updatedSortingRules[1]}`,
            { timeout: 120000 }
          );
        } else {
          response = await queryInstance.post(
            `/construction?page=${updatedSortingRules[0]}${updatedSortingRules[1]}`,
            updatedSortingRules[2]
          );
        }
        if (updatedSortingRules[3] === true) {
          return {
            ...response?.data,
            sortingRules: updatedSortingRules[1],
            isQuickViews: true,
          };
        }
        return {
          ...response?.data,
          sortingRules: updatedSortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/construction?page=${sortingRules[0]}${sortingRules[1]}`,
            {
              timeout: 120000,
            }
          );
        } else {
          response = await queryInstance.post(
            `/construction?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
        }
        if (sortingRules[3] === true) {
          return {
            ...response?.data,
            sortingRules: sortingRules[1],
            isQuickViews: true,
          };
        }
        return {
          ...response?.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const sortConstructionData: any = createAsyncThunk(
  "construction/sorting",
  async (sortingRules: any, { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.post(
            `/construction?page=${sortingRules[0]}${sortingRules[1]}`,
            rules,
            {
              timeout: 120000,
            }
          );
        } else {
          response = await queryInstance.post(
            `/construction?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
        }
        return {
          ...response?.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/construction?page=${sortingRules[0]}${sortingRules[1]}`,
            {
              timeout: 120000,
            }
          );
        } else {
          response = await queryInstance.post(
            `/construction?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
        }
        return {
          ...response?.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const filterConstructionData: any = createAsyncThunk(
  "issueTracker/filtering",
  async (sortingRules: any, { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      const transformedSortingRules = sortingRules[2].map((item: any) => {
        if (
          item.fieldName === "%23_of_poles" ||
          item.fieldName === "%23_of_poles_replacement"
        ) {
          return {
            ...item,
            fieldName: item.fieldName.replace("%23", "#"),
            value: +item.value,
          };
        }
        return item;
      });
      const updatedSortingRules = [
        sortingRules[0], // page
        sortingRules[1], // sort
        [...transformedSortingRules, ...rules], // filter
      ];

      try {
        const { data } = await queryInstance.post(
          `/construction?page=${sortingRules[0]}${sortingRules[1]}`,
          updatedSortingRules[2]
        );
        return {
          ...data,
          sortingRules: updatedSortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      const transformedSortingRules = sortingRules[2].map((item: any) => {
        if (
          item.fieldName === "%23_of_poles" ||
          item.fieldName === "%23_of_poles_replacement"
        ) {
          return {
            ...item,
            fieldName: item.fieldName.replace("%23", "#"),
            value: +item.value,
          };
        }
        return item;
      });
      try {
        const { data } = await queryInstance.post(
          `/construction?page=${sortingRules[0]}${sortingRules[1]}`,
          transformedSortingRules
        );
        return {
          ...data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const getTilesInfo: any = createAsyncThunk(
  "construction/getTilesInfo",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/construction/${payload}`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const fetchConstructionQuickViews: any = createAsyncThunk(
  "quick-views/construction",
  async () => {
    const response = await queryInstance.get("/quick-views/construction", {
      timeout: 120000,
    });
    return response.data.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
  }
);

export const addConstructionQuickView: any = createAsyncThunk(
  "quick-views/construction/post",
  async (body: any, { dispatch }) => {
    try {
      const response = await queryInstance.post(
        "/quick-views/construction",
        body
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was created",
        })
      );
      return response.data;
    } catch (error) {
      errorHandler(error, dispatch);
    }
  }
);
export const updateConstructionQuickView: any = createAsyncThunk(
  "quick-views/construction/put",
  async (body: any, { dispatch }) => {
    try {
      const response = await queryInstance.put(
        "/quick-views/construction",
        body
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was updated",
        })
      );
      return response.data;
    } catch (error) {
      errorHandler(error, dispatch);
    }
  }
);

export const updateFilteredRows: any = createAsyncThunk(
  "construction/construction-filters",
  async (payload: any, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const searchText = state.constructionSlice.searchInputValue;
      const searchItems = searchText.substring(1).split("=");
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        `/construction/info-filters`,
        { searchText: searchItems[1], ...payload },
        { headers }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Mass Update was made",
        })
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateSelectedRows: any = createAsyncThunk(
  "construction/construction",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        "/construction/info",
        {
          ...payload,
        },
        { headers }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Mass Update was made",
        })
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

// export const getConstructionCustomersList: any = createAsyncThunk(
//   "construction/getConstructionCustomersList",
//   async (payload: any, { dispatch }) => {
//     try {
//       const { data } = await queryInstance.get(
//         `/construction/${payload}/customers`
//       );
//       return data;
//     } catch (e) {
//       errorHandler(e, dispatch);
//     }
//   }
// );

export const getConstructionCustomersList: any = createAsyncThunk(
  "construction/getConstructionCustomersList",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/construction/group-customers?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getConstructionOverlapping: any = createAsyncThunk(
  "construction/getConstructionOverlapping",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/construction/overlapping?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getSapTileContentByGroup: any = createAsyncThunk(
  "construction/getSapTileContentByGroup",

  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/construction/sap?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getPermits: any = createAsyncThunk(
  "construction/getPermits",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/construction/${payload}/permits`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const CreateIssue: any = createAsyncThunk(
  "construction/createIssue",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        `/construction/issue`,
        payload,
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The issue is created",
        })
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updatePermits: any = createAsyncThunk(
  "construction/updatePermits",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/construction/${payload.id}/permits`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The permit is updated",
        })
      );
      dispatch(getPermits(payload.id));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateConstructionSap: any = createAsyncThunk(
  "construction/updateConstructionSap",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/construction/${payload.id}/sap`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The value is updated",
        })
      );
      await dispatch(getSapTileContentByGroup(payload.groupName));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateConstructionCustomers: any = createAsyncThunk(
  "construction/updateCustomers",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/construction/update/customer`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The value is updated",
        })
      );
      await dispatch(getConstructionCustomersList(payload.id));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

// export const createCustomer: any = createAsyncThunk(
//   "construction/createCustomer",
//   async (payload: any, { dispatch }) => {
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//       };
//       const { data } = await queryInstance.post(
//         `/construction/${payload.id}/customers`,
//         { ...payload.body },
//         {
//           headers,
//         }
//       );
//       dispatch(
//         handleChangeSnackbarState({
//           isOpen: true,
//           alertColor: "success",
//           message: "The customer is created",
//         })
//       );
//       dispatch(getConstructionCustomersList(payload.id));
//       return data;
//     } catch (e) {
//       errorHandler(e, dispatch);
//     }
//   }
// );

export const createConstructionCustomer: any = createAsyncThunk(
  "construction/createConstructionCustomer",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        `/construction/${payload.id}/customers`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The customer is created",
        })
      );
      dispatch(getConstructionCustomersList(payload.groupName));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteQuickView: any = createAsyncThunk(
  "construction/deleteQuickView",
  async (payload: any, { dispatch }) => {
    try {
      await queryInstance.delete(`/quick-views/construction/${payload}`);
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was deleted",
        })
      );
      return payload;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getPackageSummary: any = createAsyncThunk(
  "construction/getPackageSummary",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/env-permit/summary/package`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getPoleSummary: any = createAsyncThunk(
  "construction/getPoleSummary",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/env-permit/summary/pole`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getEnvPermitsConstruction: any = createAsyncThunk(
  "construction/getEnvPermitsConstruction",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/construction/env-permit?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getMuniPermitsConstruction: any = createAsyncThunk(
  "construction/getMuniPermitsConstrution",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/construction/muni-permit?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateEnvPermitsConstruction: any = createAsyncThunk(
  "construction/updateEnvPermitsConstruction",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/construction/env-permit`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Env Permit is updated",
        })
      );
      await dispatch(getEnvPermitsConstruction(payload.groupName));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteEnvPermitConstruction: any = createAsyncThunk(
  "construction/deleteEnvPermitConstruction",
  async (payload: any, { dispatch }) => {
    try {
      let resultData: any;
      if (payload.id) {
        const { data } = await queryInstance.delete(
          `/construction/env-permit?id=${payload.id}`
        );
        resultData = data;
      } else {
        const { data } = await queryInstance.delete(
          `/construction/env-permit?groupName=${payload.activeIssueId}`
        );
        resultData = data;
      }

      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Env Permit is deleted",
        })
      );
      await dispatch(getEnvPermitsConstruction(payload.activeIssueId));
      return resultData;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteMuniPermitConstruction: any = createAsyncThunk(
  "construction/deleteMuniPermit",
  async (payload: any, { dispatch }) => {
    try {
      let resultData: any;
      if (payload.id) {
        const { data } = await queryInstance.delete(
          `/construction/muni-permit?id=${payload.id}`
        );
        resultData = data;
      } else {
        const { data } = await queryInstance.delete(
          `/construction/muni-permit?groupName=${payload.activeIssueId}`
        );
        resultData = data;
      }
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Muni Permit is deleted",
        })
      );
      await dispatch(getMuniPermitsConstruction(payload.activeIssueId));
      return resultData;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateMuniPermitsConstruction: any = createAsyncThunk(
  "construction/updateMuniPermitsConstruction",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/construction/muni-permit`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Muni Permit is updated",
        })
      );
      await dispatch(getMuniPermitsConstruction(payload.groupName));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

// export const getInteractionByGroup: any = createAsyncThunk(
//   "construction/getInteractionByGroup",
//   async (payload: any, { dispatch }) => {
//     try {
//       const { data }: { data: any[] } = await queryInstance.get(
//         `/interactions/group?groupName=${payload}`
//       );
//       return data;
//     } catch (e) {
//       errorHandler(e, dispatch);
//     }
//   }
// );

export const getInteractionByGroup: any = createAsyncThunk(
  "construction/getInteractionByGroup",
  async (payload: any, { dispatch }) => {
    try {
      const { data }: { data: any[] } = await queryInstance.post(
        `interactions/group`,
        {
          groupName: payload.groupName,
          customerIds: payload.customerIds || [],
          interactionTypes: payload.interactionTypes || [],
        }
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const setInteractionByGroupName: any = createAsyncThunk(
  "construction/setInteractionByGroupName",
  async (body: any, { getState, dispatch }) => {
    const state: RootState | any = getState();
    const groupName = state.constructionSlice.activeConstructionGroupName;
    const page = state.overlapsSlice.page;
    try {
      const formData = new FormData();
      formData.append("interactionType", body.interactionType);
      formData.append("date", body.date);
      formData.append("event_owner", body.event_owner);
      formData.append("comment", body.comment);
      formData.append("groupName", groupName);
      formData.append("attachment", body.file);
      formData.append("description", body.description);
      body.emails && formData.append("emails", body.emails?.join(","));

      const { data }: any = await queryInstance.post("/interactions", formData);
      const snackbarData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "success",
        message: "interaction is added",
      };
      dispatch(handleChangeSnackbarState(snackbarData));
      dispatch(fetchOverlapsData(page));
      dispatch(getInteractionByGroup({ groupName }));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateInteractionByGroupName: any = createAsyncThunk(
  "construction/updateInteractionByGroupName",
  async ({ body, id }: { body: any; id: string }, { dispatch, getState }) => {
    const state: RootState | any = getState();
    const groupName = state.constructionSlice.activeConstructionGroupName;
    try {
      const formData = new FormData();
      formData.append("interactionType", body.interactionType);
      formData.append("date", body.date);
      formData.append("event_owner", body.event_owner);
      formData.append("comment", body.comment);
      formData.append("groupName", groupName);
      formData.append("description", body.description);
      formData.append("attachment", body.file);
      formData.append("attachmentAction", body.file ? "update" : "remove");
      body.emails && formData.append("emails", body.emails?.join(","));

      const { data } = await queryInstance.put(`/interactions/${id}`, formData);
      const snackbarData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "info",
        message: "interaction is updated",
      };
      dispatch(handleChangeSnackbarState(snackbarData));
      dispatch(getInteractionByGroup({ groupName }));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteInteractionByGroup: any = createAsyncThunk(
  "construction/deleteInteractionByGroup",
  async ({ index, id }: DeleteInteractionTypes, { dispatch }) => {
    try {
      await queryInstance.delete(`/interactions/${id}`);
      const snackbarData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "info",
        message: "interaction is deleted",
      };
      dispatch(handleChangeSnackbarState(snackbarData));
      return { index };
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteCustomerByGroup: any = createAsyncThunk(
  "construction/deleteCustomerByGroup",
  async ({ index, id }: any, { dispatch }) => {
    try {
      await queryInstance.delete(`construction/${id}/customer`);
      const snackbarData: SnackbarDataTypes = {
        isOpen: true,
        alertColor: "info",
        message: "Customer is deleted",
      };
      dispatch(handleChangeSnackbarState(snackbarData));
      return { index };
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getFacilityIdList: any = createAsyncThunk(
  "construction/getFacilityIdList",
  async (payload: any, { dispatch }) => {
    try {
      const { data }: { data: any[] } = await queryInstance.get(
        `/construction/facility-ids?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getStatusValues: any = createAsyncThunk(
  "construction/getStatusValues",
  async (payload: string, { dispatch }) => {
    try {
      const { data }: { data: any[] } = await queryInstance.get(
        `/construction/status?groupName=${payload}`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateContructionState: any = createAsyncThunk(
  "construction/updateContructionState",
  async (payload, { getState, dispatch }) => {
    try {
      const state: any = getState();
      const groupName = state.constructionSlice.activeConstructionGroupName;
      const { data }: any = await queryInstance.put(
        `/construction/status?groupName=${groupName}`,
        payload
      );

      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Status is updated",
        })
      );
      await dispatch(getStatusValues(groupName));

      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getHoldStatus: any = createAsyncThunk(
  "construction/getHoldStatus",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/construction/on-hold-status`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
