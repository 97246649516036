import { Styled } from "./CloseIcon.styled";

const ColseIcon = ({ size = 24, ...rest }) => {
  return (
    <Styled.CloseIcon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g opacity="0.6">
        <path
          d="M18 6L6 18"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Styled.CloseIcon>
  );
};

export default ColseIcon;
