import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { handleHiddenColumn } from "@sdge-store/construction/construction.slice";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { HiddenOptionContainer } from "./components";

const ConstructionHiddenOptionsMenu: FC = () => {
  const { columnDefs } = useAppSelector(selectConstruction);
  const dispatch = useAppDispatch();

  const switchHidden = (field: string) => {
    dispatch(handleHiddenColumn(field));
  };

  return (
    <HiddenOptionContainer
      columnDefs={columnDefs}
      switchHidden={switchHidden}
    />
  );
};

export default ConstructionHiddenOptionsMenu;
