const overlapping = (card: string) => [
  {
    field: "approved_at",
    filtering: true,
    sortField: "approved_at",
    headerName: "Approved At",
    filterField: `${card}approved_at`,
  },
  {
    field: "assesment_registry_id",
    filtering: true,
    sortField: "assesment_registry_id",
    headerName: "Assesment Registry Id",
    filterField: `${card}assesment_registry_id`,
  },
  {
    field: "circuit_id",
    filtering: true,
    sortField: "circuit_id",
    headerName: "Circuit Id",
    filterField: `${card}circuit_id`,
  },
  // {
  //   field: "cmp_inspection_date",
  //   filtering: true,
  //   sortField: "cmp_inspection_date",
  //   headerName: "Cmp Inspection Date",
  //   filterField: `${card}cmp_inspection_date`,
  // },
  {
    field: "descope",
    filtering: true,
    sortField: "descope",
    headerName: "Descope",
    filterField: `${card}descope`,
  },
  {
    field: "env_permit_time_frame",
    filtering: true,
    sortField: "env_permit_time_frame",
    headerName: "Env Permit Time Frame",
    filterField: `${card}env_permit_time_frame`,
  },
  {
    field: "environmental_release_date_submitted",
    filtering: true,
    sortField: "environmental_release_date_submitted",
    headerName: "Environmental Release Date Submitted",
    filterField: `${card}environmental_release_date_submitted`,
  },
  {
    field: "environmental_release_issued_date",
    filtering: true,
    sortField: "environmental_release_issued_date",
    headerName: "Environmental Release Issued Date",
    filterField: `${card}environmental_release_issued_date`,
  },
  {
    field: "event",
    filtering: true,
    sortField: "event",
    headerName: "Event",
    filterField: `${card}event`,
  },
  {
    field: "flown",
    filtering: true,
    sortField: "flown",
    headerName: "Flown",
    filterField: `${card}flown`,
  },
  {
    field: "flown_date",
    filtering: true,
    sortField: "flown_date",
    headerName: "Flown Date",
    filterField: `${card}flown_date`,
  },
  {
    field: "latitude",
    filtering: true,
    sortField: "latitude",
    headerName: "Latitude",
    filterField: `${card}latitude`,
  },
  {
    field: "longitude",
    filtering: true,
    sortField: "longitude",
    headerName: "Longitude",
    filterField: `${card}longitude`,
  },
  {
    field: "material",
    filtering: true,
    sortField: "material",
    headerName: "Material",
    filterField: `${card}material`,
  },
  {
    field: "notes",
    filtering: true,
    sortField: "notes",
    headerName: "Notes",
    filterField: `${card}notes`,
  },
  {
    field: "pole_id",
    filtering: true,
    sortField: "pole_id",
    headerName: "Pole Id",
    filterField: `${card}pole_id`,
  },
  {
    field: "psps_end",
    filtering: true,
    sortField: "psps_end",
    headerName: "Psps End",
    filterField: `${card}psps_end`,
  },
  {
    field: "psps_start",
    filtering: true,
    sortField: "psps_start",
    headerName: "Psps Start",
    filterField: `${card}psps_start`,
  },
  {
    field: "release_expiration_date",
    filtering: true,
    sortField: "release_expiration_date",
    headerName: "Release Expiration Date",
    filterField: `${card}release_expiration_date`,
  },
  {
    field: "tie_line",
    filtering: true,
    sortField: "tie_line",
    headerName: "Tie Line",
    filterField: `${card}tie_line`,
  },
  {
    field: "transformer",
    filtering: true,
    sortField: "transformer",
    headerName: "Transformer",
    filterField: `${card}transformer`,
  },
  // {
  //   field: "vendor_upload_date",
  //   filtering: true,
  //   sortField: "vendor_upload_date",
  //   headerName: "Vendor Upload Date",
  //   filterField: `${card}vendor_upload_date`,
  // },
  // {
  //   field: "#1_permit_date_submitted",
  //   filtering: true,
  //   sortField: "#1_permit_date_submitted",
  //   headerName: "#1 Permit Date Submitted",
  //   filterField: `${card}#1_permit_date_submitted`,
  // },
  // {
  //   field: "#1_permit_expiration_date",
  //   filtering: true,
  //   sortField: "#1_permit_expiration_date",
  //   headerName: "#1 Permit Expiration Date",
  //   filterField: `${card}#1_permit_expiration_date`,
  // },
  // {
  //   field: "#1_permit_issued_date",
  //   filtering: true,
  //   sortField: "#1_permit_issued_date",
  //   headerName: "#1 Permit Issued Date",
  //   filterField: `${card}#1_permit_issued_date`,
  // },
  // {
  //   field: "#1_permit_time_frame",
  //   filtering: true,
  //   sortField: "#1_permit_time_frame",
  //   headerName: "#1 Permit Time Frame",
  //   filterField: `${card}#1_permit_time_frame`,
  // },
  // {
  //   field: "#2_permit_expiration_date",
  //   filtering: true,
  //   sortField: "#2_permit_expiration_date",
  //   headerName: "#2 Permit Expiration Date",
  //   filterField: `${card}#2_permit_expiration_date`,
  // },
  // {
  //   field: "#2_permit_issued_date",
  //   filtering: true,
  //   sortField: "#2_permit_issued_date",
  //   headerName: "#2 Permit Issued Date",
  //   filterField: `${card}#2_permit_issued_date`,
  // },
  // {
  //   field: "#2_permit_submitted_date",
  //   filtering: true,
  //   sortField: "#2_permit_submitted_date",
  //   headerName: "#2 Permit Submitted Date",
  //   filterField: `${card}#2_permit_submitted_date`,
  // },
  // {
  //   field: "#2_permit_time_frame",
  //   filtering: true,
  //   sortField: "#2_permit_time_frame",
  //   headerName: "#2 Permit Time Frame",
  //   filterField: `${card}#2_permit_time_frame`,
  // },
];
export default overlapping("overlapping.");
