export const paths = Object.freeze({
  login: "login",
  overlapping: "overlapping",
  preflight: "flight_operations",
  issue_tracker: "issue_tracker",
  customers: "customers",
  environmental: "environmental",
  construction: "construction",
  settings: "settings",
  table: "table",
  map: "map",
  summary: "summary",
  components: "components",
  summary_pole: "summary_pole",
  summary_package: "summary_package",
});

export type Paths = typeof paths;
