import { FC } from "react";
import { Controller } from "react-hook-form";
import { DatePickerRangeProps } from "./DatePickerRange.types";
import DatePickerRangeWithoutController from "./DatePickerRangeWithoutController";

const DatePickerInput: FC<DatePickerRangeProps> = ({
  control,
  label,
  name,
  errorMessage,
  readOnly,
  changeSubmit,
  parseFormat,
  defaultValue = "",
}) => {
  const handleInputChangeSubmit = () => {
    if (changeSubmit) {
      const button = document.createElement("button");

      button.type = "submit";

      changeSubmit.current.appendChild(button);
      button.click();
      button?.parentNode?.removeChild(button);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <DatePickerRangeWithoutController
            value={value}
            onChange={(value) => {
              onChange(value);
              handleInputChangeSubmit();
            }}
            label={label}
            errorMessage={errorMessage}
            readOnly={readOnly}
            parseFormat={parseFormat}
          />
        );
      }}
    />
  );
};

export default DatePickerInput;
