import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@sdge-store/store";

export const selectAutoComplete = createSelector(
  (state: RootState) => ({
    uniqueValues: state.autoComplete.uniqueValues,
    filteredValues: state.autoComplete.filteredValues,
  }),
  (state) => state
);
