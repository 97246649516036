import {
  addIssuesTrackerQuickView,
  fetchIssueData,
  fetchIssuesTrackerQuickViews,
  deleteQuickView,
  updateIssuesTrackerQuickView,
} from "@sdge-store/issueTracker/issueTracker.async";
import { Constants } from "@sdge-store/issueTracker/constants/issueTracker.constants";
import { selectIssueTracker } from "@sdge-store/issueTracker/issueTracker.selector";
import {
  handleColumnChoosen,
  handleInputText,
  handlePrivacy,
  handleQuickViewId,
  handleQuickViewsModal,
  handleQuickViewsParams,
  handleSearch,
} from "@sdge-store/issueTracker/issueTracker.slice";
import {
  assignFrozenColumns,
  assignHiddenCols,
  assignSelect,
} from "@sdge-utils/helpers/quickViewsHelpers/quickViewsHelpers";
import { generateSortingString } from "@sdge-utils/helpers/sortHelpers/sortHelpers";
import React, { useEffect, FC } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AddViewMenu, QuickViews } from "./components";
import { generateAddQuickView } from "@sdge-utils/helpers/filterHelpers/filterHelpers";

const IssueTrackerQuickViewsOptions: FC<any> = ({ columnApi }) => {
  const {
    columnOrder,
    filteringRules,
    hiddenColumns,
    sort,
    quickViews,
    isQuickVeiwsModalOpen,
    frozenColumns,
    initialColumnDefs,
    initialColumnsOrder,
    currentFilter,
    selectedQuickVewName,
    selectedQuickViewPrivacy,
    selectedQuickViewId,
  } = useAppSelector(selectIssueTracker);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (quickViews.length === 0) {
      dispatch(fetchIssuesTrackerQuickViews());
    }
  }, [dispatch, quickViews.length]);

  const AddMenuViewsWithProps = () => (
    <AddViewMenu
      quickViews={quickViews}
      columnsOrder={columnOrder}
      filters={filteringRules}
      hiddenColumns={hiddenColumns}
      frozenColumns={frozenColumns}
      orders={sort}
      setClose={handleQuickViewsModal}
      addColumnAction={addIssuesTrackerQuickView}
    />
  );
  const fetchView = (
    sort: any[],
    filter: any[],
    id: string,
    quickViews: any[],
    columnDefs: any[],
    hiddenColumns: string[],
    frozenColumns: string[],
    columnsOrder: string[],
    selectedName: string
  ) => {
    dispatch(handleColumnChoosen(assignSelect(quickViews, id)));
    const sortingRules = generateSortingString(sort);
    dispatch(fetchIssueData([0, sortingRules, filter, true]));
    const hiddenColsFilter = assignHiddenCols(columnDefs, hiddenColumns);
    const pinnedColsFilter = assignFrozenColumns(
      hiddenColsFilter,
      frozenColumns
    );
    const firstTwoElements = pinnedColsFilter.slice(0, 3);
    const newColumnsInfo = pinnedColsFilter.slice(3).sort((a, b) => {
      return columnsOrder.indexOf(a.field) - columnsOrder.indexOf(b.field);
    });
    const newColumnOrder = newColumnsInfo.map((item) => item.field);
    const newColumns = [...firstTwoElements, ...newColumnsInfo];

    const newColumnParams = {
      columnDefs: newColumns,
      hiddenColumns: hiddenColumns,
      frozenColumns: frozenColumns,
      columnOrder: columnsOrder,
      sort: sort,
      filter: filter,
      filteringRules: filter,
      selectedName: selectedName,
    };
    dispatch(handleQuickViewsParams(newColumnParams));
    dispatch(handleSearch(""));
    dispatch(handleInputText(""));
    columnApi.moveColumns(newColumnOrder, 3);
  };

  const resetViews = (quickViews: any[]) => {
    const newQuickVews = quickViews.map((item) => {
      return { ...item, selected: false };
    });
    const resetedParams = {
      columnDefs: initialColumnDefs,
      hiddenColumns: [],
      frozenColumns: [],
      columnOrder: initialColumnsOrder,
      sort: [],
      filter: [],
      filteringRules: [],
      selectedName: "",
    };
    dispatch(handleColumnChoosen(newQuickVews));
    dispatch(fetchIssueData([0, "", [], true]));
    dispatch(handleQuickViewsParams(resetedParams));
    dispatch(handleSearch(""));
    dispatch(handleInputText(""));

    columnApi.moveColumns(initialColumnsOrder, 3);
  };

  const handleUpdate = (selectedname: string) => {
    dispatch(
      updateIssuesTrackerQuickView(
        generateAddQuickView(
          columnOrder,
          currentFilter,
          hiddenColumns,
          frozenColumns,
          selectedname,
          sort,
          selectedQuickViewPrivacy,
          selectedQuickViewId
        )
      )
    );
  };

  const handleChange = (e: any) => {
    dispatch(handlePrivacy(e.target.checked));
  };

  const handleSetPrivacy = (privacy: boolean) => {
    dispatch(handlePrivacy(privacy));
  };

  const handleSetId = (id: string) => {
    dispatch(handleQuickViewId(id));
  };

  return (
    <QuickViews
      selectedName={selectedQuickVewName}
      resetViews={resetViews}
      component={AddMenuViewsWithProps}
      setOpen={handleQuickViewsModal}
      open={isQuickVeiwsModalOpen}
      quickViews={quickViews}
      columnDefs={Constants.columnDefs}
      fetchView={fetchView}
      deleteView={deleteQuickView}
      updateView={handleUpdate}
      handleChange={handleChange}
      handleSetPrivacy={handleSetPrivacy}
      handleSetId={handleSetId}
    />
  );
};

export default IssueTrackerQuickViewsOptions;
