import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { FC, useEffect, useRef } from "react";
import { Styled } from "./Table.styled";
import { TablePropsType } from "@sdge-utils/globalTypes";

import { useTheme } from "@material-ui/core/styles";
import { BodyScrollEvent, RowNode } from "ag-grid-community";
import { useAppDispatch } from "../../app/hooks";
import { useLocation } from "react-router-dom";

const Table: FC<TablePropsType> = ({
  gridOptions,
  rowData,
  columnDefs,
  defaultColDef,
  page,
  totalPages,
  isPaginated,
  handleLoadedPagesChange,
  valueSetter,
  selectedRows,
  selecedRowsIssueIds,
}): JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const issueTrackerPage = pathname.split("/").includes("issue_tracker");

  const onBodyScroll = (params: BodyScrollEvent) => {
    const bottom_px = gridOptions?.api?.getVerticalPixelRange().bottom;
    if (gridOptions) {
      const grid_height =
        gridOptions.api &&
        gridOptions?.api?.getDisplayedRowCount() *
          gridOptions?.api?.getSizesForCurrentTheme().rowHeight;

      if (
        grid_height &&
        bottom_px &&
        (bottom_px === grid_height || bottom_px >= grid_height - 50)
      ) {
        handleLoadedPagesChange && dispatch(handleLoadedPagesChange());
        if (page && totalPages && page + 1 < totalPages) {
          params.api.applyTransaction({ add: [{}] });
        }
      }
    }
  };

  const colDefs = valueSetter
    ? { ...defaultColDef, valueSetter }
    : defaultColDef;
  const tableFillTimeOut = useRef<any>(null);
  const table = useRef<any>(null);
  useEffect(() => {
    if (table.current) {
      tableFillTimeOut.current = setTimeout(() => {
        table.current.api.setRowData(rowData);
        if (selectedRows) {
          table.current.api.forEachNode((node: RowNode) => {
            const selectIssueNode = selecedRowsIssueIds?.some((id: string) => {
              return id === node.data.id;
            });

            const selectNode = selectedRows?.some((id) => {
              return id === node.rowIndex;
            });

            const tableRow = issueTrackerPage ? selectIssueNode : selectNode;

            if (tableRow) {
              node.setSelected(true);
            }
          });
        }
      }, 100);
    }
    return () => {
      clearTimeout(tableFillTimeOut.current);
    };
  }, [rowData]);
  return (
    <Styled.Table className={theme.palette.tables?.theme}>
      <AgGridReact
        ref={table}
        gridOptions={gridOptions}
        onBodyScroll={onBodyScroll}
        columnDefs={columnDefs}
        suppressScrollOnNewData={isPaginated}
        defaultColDef={colDefs}
        debounceVerticalScrollbar={true}
        rowBuffer={50}
      />
    </Styled.Table>
  );
};

export default Table;
