import React, { useState } from "react";
import { Switch } from "@mui/material";
import { ToggleTypes } from "@sdge-assets/Toggle/Toggle.types";
import FormControlLabel from "@mui/material/FormControlLabel";

const Toggle: React.FC<ToggleTypes> = ({ onChange }) => {
  const [isChecked, setIsChecked] = useState<any>(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <FormControlLabel
      control={
        <Switch checked={isChecked} onChange={handleToggle} color="info" />
      }
      label={"BMCD Data"}
    />
  );
};

export default Toggle;
