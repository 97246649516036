import { Box, Grid } from "@mui/material";
import { Styled } from "./TileRow.styled";
import React from "react";

const TileRow: React.FC<any> = ({ title, content }) => {
  return (
    <Box pb="10px" pt="10px">
      <Grid container wrap="nowrap" justifyContent="space-between">
        <Box width="55%">
          <Styled.Title>{title}</Styled.Title>
        </Box>

        <Styled.Content>{content}</Styled.Content>
      </Grid>
    </Box>
  );
};

export default TileRow;
