import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { Button, LoadingWrapper } from "@sdge-components";
import { Styled } from "../../CellInfo.styled";
import { camelCaseTransform } from "@sdge-utils/dataTransform";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { setAssetsInfo } from "@sdge-store/drawer/drawer.async";
import EditInput from "../EditInput";
import { Constants } from "./CustomerInfo.constants";
import { usePermission } from "@sdge-hooks";
import { CircularProgress } from "@material-ui/core";
import { parseRowDateString } from "@sdge-utils/dateParser";

const CustomerInfo: FC = () => {
  const [isChanged, setIsChanged] = useState(false);
  const { handleSubmit, control, reset } = useForm();
  const { getPermission } = usePermission();

  const dispatch = useAppDispatch();
  const {
    programInfo,
    isLoaded,
    programInfoLoading,
    rightSideBarUpdateLoading,
  } = useAppSelector((state) => state.drawerState.programData);

  const dataExistent = !!Object.keys(programInfo).length;

  const defaultValue = (data: string) => {
    return data;
  };

  const handleFormSubmit = (data: any) => {
    dispatch(setAssetsInfo(data));
    setIsChanged(false);
  };

  const isPreflightRole =
    getPermission("customerPageEdit") && programInfo.bmcdTier3 !== "Y";

  useEffect(() => {
    reset({ ...programInfo });
    setIsChanged(false);
    return () => {
      reset();
    };
  }, [programInfo]);

  return (
    <LoadingWrapper loading={programInfoLoading}>
      {rightSideBarUpdateLoading && (
        <Styled.LoadingLayer>
          <CircularProgress color="inherit" />
        </Styled.LoadingLayer>
      )}
      {dataExistent && isLoaded ? (
        <Styled.CellInfoForm
          onSubmit={(e) => {
            e.preventDefault();
            setIsChanged(true);
          }}
        >
          {isPreflightRole && (
            <Button
              onClick={handleSubmit(handleFormSubmit)}
              title="Update Customer"
              isActive={true}
              disabled={!isChanged}
            />
          )}
          {Object.keys(programInfo).map((item) => {
            const dateItem = ["flownDate", "vnfDate"];
            return (
              <Styled.Row key={item}>
                <Styled.Title>{camelCaseTransform(item)}</Styled.Title>
                <Styled.Content>
                  {Object.keys(Constants.permissions).includes(item) &&
                  isPreflightRole ? (
                    <EditInput
                      defaultValue={defaultValue(programInfo[item])}
                      control={control}
                      name={item}
                      permission={
                        !(
                          item === "deprioritizeNotes" ||
                          getPermission(Constants.permissions[item].access)
                        )
                      }
                      autoComplete={Constants.permissions[item].autoComplete}
                      onSubmit={() => setIsChanged(true)}
                    />
                  ) : (
                    defaultValue(
                      dateItem.includes(item) &&
                        parseRowDateString(programInfo[item])
                        ? parseRowDateString(programInfo[item])
                        : programInfo[item]
                    )
                  )}
                </Styled.Content>
              </Styled.Row>
            );
          })}
        </Styled.CellInfoForm>
      ) : (
        <Styled.NoInfoWrapper>No information for pole</Styled.NoInfoWrapper>
      )}
    </LoadingWrapper>
  );
};

export default CustomerInfo;
