import React from "react";
import { Redirect } from "react-router-dom";

import { AuthLayout, MainLayout } from "../../layouts";
import { paths } from "../../utils";
import { asyncLoading } from "./Async";
import { CustomRoutes } from "./routes.types";

const IssueTracker = asyncLoading(() => import("@sdge-pages/IssueTracker"));
const OverlappingPage = asyncLoading(
  () => import("@sdge-pages/OverlappingPage")
);
const PreflightPage = asyncLoading(() => import("@sdge-pages/PreflightPage"));
const LoginPage = asyncLoading(() => import("@sdge-pages/LoginPage"));
const CustomersPage = asyncLoading(() => import("@sdge-pages/CustomersPage"));
const ConstructionPage = asyncLoading(
  () => import("@sdge-pages/ConstructionPage")
);

export const routes: CustomRoutes = Object.freeze([
  {
    path: "",
    type: "page",
    layout: AuthLayout,
    component: function redirect() {
      return <Redirect to={paths.login} />;
    },
  },
  {
    path: paths.login,
    type: "page",
    layout: AuthLayout,
    component: LoginPage,
  },
  {
    path: paths.overlapping,
    type: "page",
    layout: MainLayout,
    component: OverlappingPage,
    contains: [
      {
        path: paths.summary,
        type: "page",
        layout: MainLayout,
        component: OverlappingPage,
      },
      {
        path: paths.map,
        type: "page",
        layout: MainLayout,
        component: OverlappingPage,
      },
      {
        path: paths.table,
        type: "page",
        layout: MainLayout,
        component: OverlappingPage,
      },
    ],
  },
  {
    path: paths.preflight,
    type: "page",
    layout: MainLayout,
    component: PreflightPage,
  },
  {
    path: paths.customers,
    type: "page",
    layout: MainLayout,
    component: CustomersPage,
    contains: [
      {
        path: paths.map,
        type: "page",
        layout: MainLayout,
        component: CustomersPage,
      },
      {
        path: paths.table,
        type: "page",
        layout: MainLayout,
        component: CustomersPage,
      },
    ],
  },
  {
    path: paths.environmental,
    type: "page",
    layout: MainLayout,
    component: OverlappingPage,
  },
  {
    path: paths.construction,
    type: "page",
    layout: MainLayout,
    component: ConstructionPage,
    contains: [
      {
        path: paths.map,
        type: "page",
        layout: MainLayout,
        component: ConstructionPage,
      },
      {
        path: paths.table,
        type: "page",
        layout: MainLayout,
        component: ConstructionPage,
      },
    ],
  },
  {
    path: paths.issue_tracker,
    type: "page",
    layout: MainLayout,
    component: IssueTracker,
    contains: [
      {
        path: paths.summary_package,
        type: "page",
        layout: MainLayout,
        component: IssueTracker,
      },
      {
        path: paths.summary_pole,
        type: "page",
        layout: MainLayout,
        component: IssueTracker,
      },
      {
        path: paths.map,
        type: "page",
        layout: MainLayout,
        component: IssueTracker,
      },
      {
        path: paths.table,
        type: "page",
        layout: MainLayout,
        component: IssueTracker,
      },
    ],
  },
  {
    path: paths.settings,
    type: "page",
    layout: MainLayout,
    component: OverlappingPage,
  },
] as any[]);
