import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const Form = styled.form`
  background-color: #181e34;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
`;

const Text = styled(Box)<StyledPropsWithTheme<{ weight?: number }>>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ weight }) => weight};
  color: ${({ theme }) => theme.palette.formItems.textColor[100]};
  letter-spacing: 0.2688px;
`;

const Row = styled(Box)<StyledPropsWithTheme<{ gap?: boolean }>>`
  display: flex;
  gap: ${({ gap }) => (gap ? "12px" : "0")};
`;

const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Styled: any = {
  Form,
  TextWrapper,
  Text,
  Row,
};
