import { SelectInput } from "@sdge-components";
import { FC } from "react";

const SelectPermissionQualifier: FC<any> = (props) => {
  const { optionsData, control, name, defaultValue, changeSubmit, permission } =
    props;
  if (permission) {
    return <>{defaultValue}</>;
  }
  return (
    <SelectInput
      optionsData={optionsData}
      control={control}
      name={name}
      defaultValue={defaultValue}
      changeSubmit={changeSubmit}
    />
  );
};
export default SelectPermissionQualifier;
