import { Box, Grid, IconButton } from "@mui/material";
import { Styled } from "./CustomerTileContent.styled";
import { Button } from "@material-ui/core";
import { EditIcon } from "../../icons";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { RowItemPropsType } from "./CustomerTileContent.types";
import { updateCustomers } from "@sdge-store/issueTracker/issueTracker.async";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { TextInputWithController } from "@sdge-components";
import { useLocation } from "react-router-dom";
import { updateConstructionCustomers } from "@sdge-store/construction/construction.async";
import { usePermission } from "@sdge-hooks";

const RowItem: React.FC<RowItemPropsType> = ({
  title,
  content,
  icon,
  issueTracker,
  name,
  id,
}) => {
  const [editable, setEditable] = useState(false);
  const { pathname } = useLocation();
  const { getPermission } = usePermission();
  const constractionPage = pathname.split("/").includes("construction");
  const { issueTrackerCustomersList, activeIssueId } = useAppSelector(
    (state) => state.issueTrackerSlice
  );
  const { activeConstructionGroupName, constructionCustomersList } =
    useAppSelector((state) => state.constructionSlice);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );

  useEffect(() => {
    setEditable(false);
  }, [activeConstructionGroupName, activeIssueId]);

  useEffect(() => {
    setEditable(false);
  }, [activeConstructionGroupName, activeIssueId]);

  const hasPermissions =
    (getPermission("editCustomerIssuePage") && issueTracker) ||
    getPermission("editCustomerOnlyConstPage") ||
    getPermission("edit_customer");
  // (getPermission("editCustomerBesideConstPage") && constractionPage);

  const dispatch = useDispatch();
  const { handleSubmit, control, reset } = useForm();

  const handleEdit = (values: any) => {
    const activeItem = constractionPage
      ? constructionCustomersList.filter((item: any) => item.id === id)[0]
      : issueTrackerCustomersList.filter((item: any) => item.id === id)[0];

    constractionPage
      ? dispatch(
          updateConstructionCustomers({
            id: activeConstructionGroupName,
            body: { ...activeItem, [name]: values[name] },
            customerId: activeItem?.id,
          })
        )
      : dispatch(
          updateCustomers({
            id: activeIssueId,
            body: { ...activeItem, [name]: values[name] },
            customerId: activeItem?.id,
          })
        );
    setEditable(false);
    reset();
  };

  const handleSetEdit = () => {
    setEditable(true);
  };

  return (
    <Box pb="11x" pt="11px">
      <form onSubmit={handleSubmit(handleEdit)}>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Box width="40%" mr="10px">
            <Styled.Title>{title}</Styled.Title>
          </Box>

          {editable ? (
            <TextInputWithController
              name={name as `${string}`}
              defaultValue={content}
              control={control}
              multiline={true}
            />
          ) : (
            <>
              {icon ? (
                <img src={content} />
              ) : (
                <Styled.Content noWrap={false}>{content}</Styled.Content>
              )}
            </>
          )}
          <>
            {programInfo.bmcdTier3 !== "Y" &&
            hasPermissions &&
            name !== "isSensitive" &&
            name !== "facilityId" &&
            name !== "id" ? (
              <>
                {editable ? (
                  <Button
                    type="submit"
                    endIcon={<EditIcon size={14} />}
                  ></Button>
                ) : (
                  <IconButton onClick={handleSetEdit}>
                    <EditIcon size={14} />
                  </IconButton>
                )}
              </>
            ) : (
              <Box></Box>
            )}
          </>
        </Grid>
      </form>
    </Box>
  );
};

export default RowItem;
