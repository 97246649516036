const parcel = (card: string) => [
  {
    field: "apn",
    filtering: true,
    sortField: "apn",
    headerName: "Apn",
    filterField: `${card}apn`,
  },
  {
    field: "apn_address_1",
    filtering: true,
    sortField: "apn_address_1",
    headerName: "Apn Address 1",
    filterField: `${card}apn_address_1`,
  },
  {
    field: "apn_address_2",
    filtering: true,
    sortField: "apn_address_2",
    headerName: "Apn Address 2",
    filterField: `${card}apn_address_2`,
  },
  {
    field: "email",
    filtering: true,
    sortField: "email",
    headerName: "Email",
    filterField: `${card}email`,
  },
  {
    field: "facilityid",
    filtering: true,
    sortField: "facilityid",
    headerName: "Facilityid",
    filterField: `${card}facilityid`,
  },
  {
    field: "owner_name",
    filtering: true,
    sortField: "owner_name",
    headerName: "Owner Name",
    filterField: `${card}owner_name`,
  },
  {
    field: "phone_number",
    filtering: true,
    sortField: "phone_number",
    headerName: "Phone Number",
    filterField: `${card}phone_number`,
  },
  {
    field: "sensitive_customer",
    filtering: true,
    sortField: "sensitive_customer",
    headerName: "Sensitive Customer",
    filterField: `${card}sensitive_customer`,
  },
];

export default parcel("parsel.");
