import styled from "@emotion/styled";
import { Box } from "@material-ui/core";

const ErrorBoundaryWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Styled = { ErrorBoundaryWrapper };
