import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { Styled } from "./Tile.styled";
import { BellButton } from "@sdge-components/index";
import AddCustomerModal from "./AddCustomerModal";
import { useAppSelector } from "../../app/hooks";
import AddEnvPermitsModal from "./AddEnvPermitsModal";
import AddMuniPermitModal from "./AddMuniPermitModal";
import { usePermission } from "@sdge-hooks";
import { selectConstruction } from "@sdge-store/construction/construction.selector";

const Tile: React.FC<any> = ({
  title,
  button,
  bellButton,
  children,
  width,
  isIssueTrackerPage,
  isConstructionPage,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const { activeRowId } = useAppSelector((state) => state.customersSlice);
  const { activeIssueId } = useAppSelector((state) => state.issueTrackerSlice);
  const { activeConstructionGroupName } = useAppSelector(selectConstruction);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );

  const { getPermission } = usePermission();

  const isButtonVisible =
    (button === "Add Env Permit" || button === "Add Muni Permit") &&
    ((activeIssueId && isIssueTrackerPage) ||
      (activeConstructionGroupName && isConstructionPage) ||
      (activeRowId && !isIssueTrackerPage));
  const isActiveEnd =
    activeIssueId || activeConstructionGroupName || activeRowId;

  const hasPermissions =
    (getPermission("add_tile_ENVC") && activeRowId) ||
    (getPermission("add_tile_ENVC") &&
      activeIssueId &&
      button === "Add Customer") ||
    (getPermission("add_tile_ENVC") &&
      activeConstructionGroupName &&
      button === "Add Customer") ||
    (getPermission("add_tile_EnD") && isActiveEnd) ||
    (getPermission("add_tile_const") && isButtonVisible) ||
    (getPermission("add_tile_ENVP") && isButtonVisible) ||
    (getPermission("add_tile_DocCtrl") && isButtonVisible) ||
    (getPermission("add_tile_DocCtrl") && button === "Add Customer") ||
    (getPermission("add_pol_customer") &&
      activeIssueId &&
      button === "Add Customer") ||
    (getPermission("add_pol_customer") &&
      activeConstructionGroupName &&
      button === "Add Customer") ||
    (getPermission("add_pol_customer") && button === "Add Customer");

  return (
    <>
      {button && button === "Add Customer" ? (
        <AddCustomerModal open={open} setOpen={setOpen} />
      ) : (
        <>
          {button === "Add Env Permit" && programInfo.bmcdTier3 !== "Y" ? (
            <AddEnvPermitsModal
              open={open}
              setOpen={setOpen}
              isIssueTrackerPage={isIssueTrackerPage}
              isConstructionPage={isConstructionPage}
            />
          ) : (
            <AddMuniPermitModal
              open={open}
              setOpen={setOpen}
              isIssueTrackerPage={isIssueTrackerPage}
              isConstructionPage={isConstructionPage}
            />
          )}
        </>
      )}
      <Styled.TileContainer
        bgcolor="#222840"
        width={width}
        borderRadius="4px"
        p="16px"
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Styled.TileName>{title}</Styled.TileName>
          {programInfo.bmcdTier3 !== "Y" && button && hasPermissions && (
            <Styled.TileButton variant="outlined" onClick={handleOpenModal}>
              {getPermission("addPoleCustomerTile") &&
              button === "Add Customer" &&
              isIssueTrackerPage
                ? "Add Pole Customer"
                : button}
            </Styled.TileButton>
          )}
          {bellButton && <BellButton count={2} />}
        </Grid>
        {children}
      </Styled.TileContainer>
    </>
  );
};

export default Tile;
