import MuiAlert from "@mui/material/Alert";
import React from "react";
import { Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { handleChangeSnackbarState } from "../../app/store/snackbar/snackbar.slice";

const SnackbarComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, alertColor, message } = useAppSelector(
    (state) => state.snackbar.data
  );

  const handleClose = (event?: any, reason?: string) => {
    if (reason !== "clickaway") {
      const snackbarCloseData = {
        isOpen: false,
        message: "",
      };
      dispatch(handleChangeSnackbarState(snackbarCloseData));
    }
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MuiAlert
        onClose={handleClose}
        severity={alertColor}
        elevation={6}
        variant="filled"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarComponent;
