import { useAppSelector } from "../../app/hooks";
import { parseRowDateString } from "@sdge-utils/dateParser";

const useIssueTrackerOverlappingContentCreator = (
  constructionPage?: boolean
) => {
  const { issueTrackerOverlapping } = useAppSelector(
    (state) => state.issueTrackerSlice
  );
  const { constructionOverlapping } = useAppSelector(
    (state) => state.constructionSlice
  );

  const content = constructionPage
    ? constructionOverlapping
    : issueTrackerOverlapping;

  const dateItem = [
    "MFB",
    "Flown Date",
    "Psps End",
    "Psps Start",
    "IFC Actual",
    "Completion Date",
  ];
  return content?.reduce((acc: any, item: any) => {
    const programDataKeys = Object.keys(item?.programData);
    const newData = programDataKeys?.map((key) => ({
      title: key,
      content:
        dateItem.includes(key) && parseRowDateString(item?.programData[key])
          ? parseRowDateString(item?.programData[key], "MM/DD/YYYY")
          : item?.programData[key],
    }));
    const newObj = {
      name: item?.name,
      data: newData,
    };

    return [...acc, newObj].sort((a, b) =>
      (a.name || "").toString().localeCompare((b.name || "").toString())
    );
  }, []);
};
export default useIssueTrackerOverlappingContentCreator;
