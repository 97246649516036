import { ColumnDefsType, QuickViewType } from "./quickVewsHelpers.types";

const assignSelect = (
  quickViews: QuickViewType[],
  id: string
): QuickViewType[] => {
  return quickViews.map((item) => {
    if (item.id === id) {
      return { ...item, selected: true };
    } else {
      return { ...item, selected: false };
    }
  });
};

const assignHiddenCols = (
  columnDefs: ColumnDefsType[],
  hiddenColumns: string[]
): ColumnDefsType[] => {
  return columnDefs.map((item) => {
    if (hiddenColumns.includes(item.field)) {
      return { ...item, hide: true };
    } else {
      return { ...item, hide: false };
    }
  });
};

const assignFrozenColumns = (
  columnDefs: ColumnDefsType[],
  frozenColumns: string[]
): ColumnDefsType[] => {
  const result = columnDefs.map((item) => {
    if (item.colId) {
      return { ...item, pinned: "left" };
    }
    if (frozenColumns) {
      if (frozenColumns.includes(item.field)) {
        return { ...item, pinned: "left" };
      } else {
        return { ...item, pinned: null };
      }
    }
    return item;
  });
  return result;
};

export { assignSelect, assignHiddenCols, assignFrozenColumns };
