import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Styled } from "../OverlappingPage/OverlappingPage.styled";
import {
  ConstructionMainTable,
  EnvPermitTileContent,
  InfoTileContent,
  Map,
  MuniPermitTileContent,
  SapTileContent,
  StatusTileContent,
  Tile,
  CustomerTileContent,
} from "@sdge-components";
import { handleRouterChange } from "@sdge-store/drawer/drawer.slice";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import { useAppSelector } from "../../app/hooks";
import Loader from "../../app/Router/Async/Loader";
import { setActiveIssueId } from "@sdge-store/construction/construction.slice";
import { usePermission } from "@sdge-hooks";

const ConstructionPage = () => {
  const { pathname } = useLocation();
  const map = pathname.split("/").includes("map");
  const dispatch = useDispatch();
  const {
    activeConstructionGroupName,
    constructionLoading,
    constructionExportLoading,
  } = useAppSelector((state) => state.constructionSlice);
  const { getPermission } = usePermission();

  useEffect(() => {
    dispatch(
      handleRouterChange({
        parentPath: RightDrawerParentPaths.CONSTRUCTION_TABLE,
      })
    );
    dispatch(setActiveIssueId(""));
  }, []);

  const hasPermissions =
    (getPermission("add_tile_ENVC") && activeConstructionGroupName) ||
    getPermission("add_pol_customer") ||
    (getPermission("add_tile_DocCtrl") && activeConstructionGroupName);
  const hasPermissoinsForPermit =
    activeConstructionGroupName &&
    (getPermission("add_tile_ENVP") ||
      getPermission("add_tile_DocCtrl") ||
      getPermission("add_tile_const"));

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    swipe: false,
    copy: true,
  };
  return (
    <Styled.OverlappingPageWrapper>
      {(constructionLoading || constructionExportLoading) && <Loader />}
      {map && <Map key="AIzaSyD3jpcQHDLYnj-ulGUJMtLiXIqKpEi2FNo" />}
      <ConstructionMainTable />
      <Box py="20px">
        <Styled.SliderWrapper>
          <Styled.TilesSlider {...settings}>
            <Tile title="Sap" isConstructionPage>
              <SapTileContent constructionPage />
            </Tile>
            <Tile
              title="Customer"
              button={hasPermissions ? "Add Customer" : ""}
            >
              <CustomerTileContent issueTracker={false} />
            </Tile>
            <Tile title="Overlapping">
              <InfoTileContent constructionPage />
            </Tile>
            <Tile
              title="Environmental Permits"
              button={hasPermissoinsForPermit ? "Add Env Permit" : ""}
              isConstructionPage
            >
              <EnvPermitTileContent constructionPage />
            </Tile>
            <Tile
              title="Muni Permits"
              button={hasPermissoinsForPermit ? "Add Muni Permit" : ""}
              isConstructionPage
            >
              <MuniPermitTileContent constructionPage />
            </Tile>
            <Tile title="Status">
              <StatusTileContent />
            </Tile>
          </Styled.TilesSlider>
        </Styled.SliderWrapper>
      </Box>
    </Styled.OverlappingPageWrapper>
  );
};

export default ConstructionPage;
