import { ColumnApi, GridApi } from "ag-grid-community";
import { parseRowDateString } from "@sdge-utils/dateParser";

const issueItemsDateConvert = (items: any) => {
  return items.map((item: any) => {
    const newGoalDate = parseRowDateString(item.goalDate) || item.goalDate;
    const newIdentifiedDate =
      parseRowDateString(item.identifiedDate) || item.identifiedDate;
    const newInitiationCompleteDate =
      parseRowDateString(item.initiationCompleteDate) ||
      item.initiationCompleteDate;
    const newResolvedDate =
      parseRowDateString(item.resolvedDate) || item.resolvedDate;
    const newApprovedDate =
      parseRowDateString(item.approvedDate) || item.approvedDate;
    const newForecastedVegClearDate =
      parseRowDateString(item.forecastedVegClearDate) ||
      item.forecastedVegClearDate;
    const newConstructionDate =
      parseRowDateString(item.constructionDate) || item.constructionDate;
    const newCordoba90completionDate =
      parseRowDateString(item.cordoba90completionDate) ||
      item.cordoba90completionDate;
    return {
      ...item,
      goalDate: newGoalDate,
      identifiedDate: newIdentifiedDate,
      initiationCompleteDate: newInitiationCompleteDate,
      resolvedDate: newResolvedDate,
      approvedDate: newApprovedDate,
      forecastedVegClearDate: newForecastedVegClearDate,
      cordoba90completionDate: newCordoba90completionDate,
      constructionDate: newConstructionDate,
    };
  });
};

const constructionItemsDateConvert = (items: any) => {
  return items.map((item: any) => {
    const newForecastedIfc =
      parseRowDateString(item.forecastedIfc) || item.forecastedIfc;
    const newProjectedIfc =
      parseRowDateString(item.projectedIfc) || item.projectedIfc;
    const newDeferralNotificationDate =
      parseRowDateString(item.deferralNotificationDate) ||
      item.deferralNotificationDate;
    const newDeferralGoalDate =
      parseRowDateString(item.deferralGoalDate) || item.deferralGoalDate;
    const newAwardedDate =
      parseRowDateString(item.awardedDate) || item.awardedDate;
    const newActualIfc = parseRowDateString(item.actualIfc) || item.actualIfc;

    return {
      ...item,
      forecastedIfc: newForecastedIfc,
      projectedIfc: newProjectedIfc,
      deferralNotificationDate: newDeferralNotificationDate,
      deferralGoalDate: newDeferralGoalDate,
      awardedDate: newAwardedDate,
      actualIfc: newActualIfc,
    };
  });
};
const overlappingItemsDateConvert = (items: any) => {
  return items.map((item: any) => {
    const newGoalDate = parseRowDateString(item.goalDate) || item.goalDate;
    const newIssueApprovedDate =
      parseRowDateString(item.issueApprovedDate) || item.issueApprovedDate;
    const newIssueIdentifiedDate =
      parseRowDateString(item.issueIdentifiedDate) || item.issueIdentifiedDate;
    const newJobNotificationOpenDate =
      parseRowDateString(item.jobNotificationOpenDate) ||
      item.jobNotificationOpenDate;
    return {
      ...item,
      goalDate: newGoalDate,
      issueApprovedDate: newIssueApprovedDate,
      issueIdentifiedDate: newIssueIdentifiedDate,
      jobNotificationOpenDate: newJobNotificationOpenDate,
    };
  });
};

const customerItemsDateConvert = (items: any) => {
  return items.map((item: any) => {
    const newVnfDate = parseRowDateString(item.vnfDate) || item.vnfDate;
    const newFlownDate = parseRowDateString(item.flownDate) || item.flownDate;
    return {
      ...item,
      vnfDate: newVnfDate,
      flownDate: newFlownDate,
    };
  });
};

const getLength = (itemsArrayLength: number): number => {
  if (itemsArrayLength > 1) {
    return 88 + itemsArrayLength * 32;
  } else {
    return 130;
  }
};
const setHeight = (
  height: number,
  gridApi: GridApi,
  columnApi: ColumnApi,
  value: string,
  isVisible: boolean
): void => {
  gridApi.forEachNode((rowNode) => {
    if (rowNode.data && rowNode.data.groupName === value) {
      if (isVisible === false) {
        rowNode.setRowHeight(height);
      } else {
        rowNode.setRowHeight(40);
      }
    }
  });
  gridApi.onRowHeightChanged();
};

export {
  getLength,
  setHeight,
  issueItemsDateConvert,
  constructionItemsDateConvert,
  customerItemsDateConvert,
  overlappingItemsDateConvert,
};
