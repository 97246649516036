import React, { FC } from "react";
import SDGE from "../../assets/logo.png";
import logoSvg from "../../assets/e2dataio.svg";
const Main: FC = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <img
        src={SDGE}
        style={{
          height: "100%",
          objectFit: "contain",
        }}
        alt="SCGE icon"
      />
      <img
        src={logoSvg}
        style={{
          height: "65px",
          alignSelf: "flex-end",
          width: "130px",
        }}
        alt="Logo"
      />
    </div>
  );
};

export default Main;
