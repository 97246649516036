import { Link } from "@sdge-components";
import { RowIcon } from "../../../components/MainTable/components";

const columnDefs = [
  {
    cellRendererFramework: (params: any) => <RowIcon data={params.data} />,
    suppressMenu: true,
    sorting: true,
    filtering: true,
    suppressMovable: true,
    lockPosition: true,
    colId: "event",
    lockPinned: true,
    minWidth: 90,
    suppressNavigable: true,
    pinned: true,
  },
  {
    maxWidth: 50,
    suppressMenu: true,
    suppressMovable: true,
    lockPosition: true,
    lockPinned: true,
    colId: "checkbox",
    pinned: true,
    resizable: false,
    suppressNavigable: true,
  },
  {
    headerName: "Pole #",
    field: "poleId",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Circuit #",
    field: "circuit",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "District Name",
    field: "district",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Condition Code id",
    field: "conditionCodeId",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Group Name",
    field: "groupName",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Ball in Court",
    field: "ballInCourt",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Status",
    field: "issueStatus",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Priority",
    field: "priority",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Event type",
    field: "programNames",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    cellRenderer: (params: any) => {
      const value = params.value;
      return `Overlapping Program: ${value && value.join(", ")}`;
    },
  },
  {
    headerName: "First Interaction",
    field: "firstInteraction",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Last Interaction",
    field: "lastInteraction",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Days Lapsed With No Reply",
    field: "daysWithNoReply",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Flown Status",
    field: "flownStatus",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Assessed Flag",
    field: "assessedFlag",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "IssueId",
    field: "issueId",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Latitude",
    field: "latitude",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Longitude",
    field: "longitude",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Issue Description",
    field: "issueDescription",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Classification",
    field: "classification",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Issue Identified Date",
    field: "issueIdentifiedDate",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Issue Approved Date",
    field: "issueApprovedDate",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Goal Date",
    field: "goalDate",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Pole Replacement",
    field: "poleReplacement",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Crossarm Replacement",
    field: "crossarmReplacement",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Issue Type",
    field: "issueType",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Cross Check",
    field: "crossCheck",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Complexity Calc",
    field: "complexityCalc",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Assigned",
    field: "assigned",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "KMZ URL",
    field: "kmzLink",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: false,
    cellRendererFramework: ({ data }: any) => {
      return (
        <>
          {data.kmzLink === "N/A" ? (
            "N/A"
          ) : !data.kmzLink ? (
            ""
          ) : (
            <Link
              link={data.kmzLink}
              name={data.kmzLink === "N/A" ? "N/A" : "KMZ Link"}
            />
          )}
        </>
      );
    },
  },
  {
    headerName: "Project Number",
    field: "projectNumber",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Job Number",
    field: "jobNumber",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Contractor Number",
    field: "contractorNumber",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Job Notification Open Date",
    field: "jobNotificationOpenDate",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
];
const columnsOrder = columnDefs.slice(2).map((item) => item.field);
export const Constants = {
  columnDefs,
  columnsOrder,
};
