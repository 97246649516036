import { Styled } from "./ParcelTileContent.styled";
import { Grid, Box } from "@mui/material";
import useParcelTileContentCreator from "./useParcelTileContentCreator";
import { TileSection } from "../TileSection";
import React from "react";

const ParcelTileContent = () => {
  const parcelData = useParcelTileContentCreator();
  return (
    <>
      {parcelData?.map((item: any, i: number) => {
        return (
          <div key={item.id + i}>
            <TileSection title="" />
            {item.map(({ id, title, content, icon }: any) => {
              return (
                <Box pb="20px" key={id} pt="20px">
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box width="55%">
                      <Styled.Title>{title}</Styled.Title>
                    </Box>
                    {icon ? (
                      <img src={content} />
                    ) : (
                      <Styled.Content>{content}</Styled.Content>
                    )}
                  </Grid>
                </Box>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default ParcelTileContent;
