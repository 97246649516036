import { Theme } from "@material-ui/core";
import { GridOptions } from "ag-grid-community";
import { AgGridReactProps } from "ag-grid-react";
import { Icon } from "react-feather";
import { AnyAction } from "redux";

export type StyledPropsWithTheme<T = void | unknown> = { theme?: Theme } & T;

export type isActiveType = { isActive?: boolean };

export type IconPathType = { path?: string };

export type TablePropsType = {
  fetchLink?: string;
  gridOptions?: GridOptions;
  autoSize?: boolean;
  rowData?: any;
  onGridReady?: (params: AgGridReactProps) => void;
  columnDefs?: any;
  defaultColDef?: any;
  handleLoadedPagesChange?: () => AnyAction;
  page?: number;
  totalPages?: number;
  isPaginated?: boolean;
  valueSetter?: any;
  selectedRows?: number[];
  issue?: boolean;
  selecedRowsIssueIds?: any;
};

export type ButtonType = {
  title?: string;
  icon?: Icon;
  isActive?: boolean;
  iconRight?: boolean;
  outlined?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement> | undefined) => void;
  disabled?: boolean;
};

/////////////////////////// ENUMS ////////////////////////

export type LoginTypes = {
  username: string;
  password: string;
};

export enum DownloadLinks {
  OVERLAPPING_PROGRAMS = "/overlapping_programs/csv",
  ISSUE_TRACKER = "/issue-tracker/csv",
  // ASSETS = "/assets/csv",
  CONSTRUCTION_GRID = "/construction/csv-construction",
  CONSTRUCTION_CUSTOMERS = "/construction/csv-construction-customer",
  CONSTRUCTION_CUSTOMERS_INTERACTIONS = "/construction/csv-construction-customer-interaction",
  CONSTRUCTION_PARCEL = "/construction/csv-construction-parcel",
  CONSTRUCTION_PERMIT = "/construction/csv-construction-permit",
  ASSETS = "/assets/csv-asset",
  ASSETS_PARCEL = "/assets/csv-asset-parcel",
  ASSETS_INTERACTIONS = "/assets/csv-asset-interaction",
}

/////////////////////// DRAWER TYPES ///////////////////////////

export enum RightDrawerPaths {
  NOTIFICATION = "/",
  OVERLAPPING = "overlapping",
  QUICK_ACTION = "quick_action",
}

export enum RightDrawerParentPaths {
  NOTIFICATION = "notification",
  OVERLAPPING_TABLE = "overlapping_table",
  CUSTOMER_TABLE = "customer_table",
  CONSTRUCTION_TABLE = "construction_table",
  ISSUE_TRACKER_TABLE = "issue_tracker_table",
  NOTIFICATION_INFO = "notification_info",
  QUICK_ACTION = "quick_action",
}
