import { Box, Grid, IconButton } from "@mui/material";
import { Styled } from "./MuniPermitTileContent.styled";
import { CloseIcon, EditIcon } from "../../icons";
import React, { useEffect, useState } from "react";
import {
  TextInputWithController,
  DatePickerInput,
  SelectInput,
} from "@sdge-components";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { updateMuniPermits } from "@sdge-store/issueTracker/issueTracker.async";

import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { muniPermitOptions } from "./MuniPermitTile.constants";
import { updateMuniPermitsAssets } from "@sdge-store/customers/customers.async";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { updateMuniPermitsConstruction } from "@sdge-store/construction/construction.async";
import { usePermission } from "@sdge-hooks";

const RowItem: React.FC<{
  title: string;
  content: string;
  name: string;
  date: boolean;
  actualPermitId: any;
  customerPage?: boolean;
  constructionPage?: boolean;
}> = ({
  title,
  content,
  name,
  date,
  actualPermitId,
  customerPage,
  constructionPage,
}) => {
  const [editable, setEditable] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();

  const { getPermission } = usePermission();
  const { muniPermitsAssets, activeRowId } = useAppSelector(
    (state) => state.customersSlice
  );
  const {
    activeIssueId,
    muniPermits,
    activeIssuefacilityId,
    activeIssueGroupName,
  } = useAppSelector((state) => state.issueTrackerSlice);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  const { muniPermitsConstruction, activeConstructionGroupName } =
    useAppSelector(selectConstruction);

  useEffect(() => {
    setEditable(false);
  }, [activeConstructionGroupName, activeIssueId, activeRowId]);

  const notEditableNames =
    (name !== "sharepointLink" || (name == "sharepointLink" && customerPage)) &&
    name !== "permitId";

  const hasPermissions =
    (getPermission("editMuniBesideConstPage") && notEditableNames) ||
    (getPermission("editMuniPermitTile") && notEditableNames) ||
    (getPermission("edit_tile_const") && constructionPage);

  const handleSetEdit = () => {
    reset();
    setEditable(true);
  };

  const handleFormSubmit = (values: any) => {
    const permit = customerPage
      ? muniPermitsAssets.filter(
          (item: any) => item.permitId === actualPermitId
        )[0]
      : constructionPage
      ? muniPermitsConstruction.filter(
          (item: any) => item.permitId === actualPermitId
        )[0]
      : muniPermits.filter((item: any) => item.permitId === actualPermitId)[0];
    const body = {
      ...(customerPage ? {} : { groupName: activeIssueGroupName }),
      ...permit,
      muniPermitType: permit.permitType,
      muniPermitDateSubmitted: permit.permitDateSubmitted,
      miniPermitTimeFrame: permit.permitTimeFrame,
      muniPermitIssuedDate: permit.permitIssuedDate,
      [name]: values[name],
    };
    customerPage
      ? dispatch(
          updateMuniPermitsAssets({
            body,
            facilityId: activeRowId,
          })
        )
      : constructionPage
      ? dispatch(
          updateMuniPermitsConstruction({
            body: { ...body, groupName: activeConstructionGroupName },
            groupName: activeConstructionGroupName,
          })
        )
      : dispatch(
          updateMuniPermits({
            id: activeIssueId,
            body,
            facilityId: activeIssuefacilityId,
          })
        );
    setEditable(false);
  };

  return (
    <Box pb="11px" pt="11px">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Box width="55%" mr="10px">
            <Styled.Title>{title}</Styled.Title>
          </Box>
          {editable && name !== "facilityId" ? (
            <>
              {date ? (
                <DatePickerInput
                  name={name as `${string}`}
                  control={control}
                  readOnly
                  parseFormat="YYYY-MM-DD"
                />
              ) : (
                <>
                  {name === "permitType" || name === "muniPermitType" ? (
                    <SelectInput
                      optionsData={muniPermitOptions}
                      control={control}
                      defaultValue={""}
                      name={customerPage ? "muniPermitType" : "permitType"}
                      label="Muni Permit Type # *"
                    />
                  ) : (
                    <TextInputWithController
                      defaultValue={content}
                      name={name as `${string}`}
                      control={control}
                      multiline={true}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <Styled.Content noWrap={false}>{content}</Styled.Content>
          )}
          <>
            {programInfo.bmcdTier3 !== "Y" &&
            hasPermissions &&
            name !== "facilityId" ? (
              <>
                {editable ? (
                  <>
                    {date && (
                      <IconButton
                        onClick={() => {
                          setValue(name as `${string}`, "");
                        }}
                      >
                        <CloseIcon size={14} />
                      </IconButton>
                    )}
                    <Button
                      type="submit"
                      endIcon={<EditIcon size={14} />}
                    ></Button>
                  </>
                ) : (
                  <IconButton onClick={handleSetEdit}>
                    <EditIcon size={14} />
                  </IconButton>
                )}
              </>
            ) : (
              <Box></Box>
            )}
          </>
        </Grid>
      </form>
    </Box>
  );
};

export default RowItem;
