import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../utils/globalTypes";

const SmallTableWrapper = styled(Box)<StyledPropsWithTheme>`
  background: ${({ theme }) => theme.palette.primary.main};
  width: 380px;
  height: 331px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
`;

export const Styled = { SmallTableWrapper };
