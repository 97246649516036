import React, { FC, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { MassUpdateInput, MassUpdateSelect } from "..";
import {
  massUpdateAdditionalSelect,
  massUpdateSelect,
} from "./MassUpdate.constants";
import { Styled } from "./MassUpdateItem.styled";
import {
  setMassSelect,
  setMassUpdateInput,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import { MassUpdateItemType } from "./MassUpdateItem.types";
import { Button, DatePickerInput } from "@sdge-components";
import { DeleteIcon } from "../../../../icons";
import { Icon } from "react-feather";
import { useForm } from "react-hook-form";
import { parseDateString } from "@sdge-utils/dateParser";
import {
  generateUniqueValues,
  selectItems,
} from "@sdge-utils/helpers/editHelpers/editHelpers";
import { selectAutoComplete } from "@sdge-store/autoComplete/autoComplete.selector";
import { dataTransforming } from "@sdge-utils/dataTransform";
import { OptionsData } from "@sdge-assets/SelectInput/SelectInput.types";
import { usePermission } from "@sdge-hooks";

const MassUpdateItem: FC<MassUpdateItemType> = ({
  columnDefs,
  field,
  currentMassUpdate,
  handleSelect,
}) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit } = useForm();
  const colInfo = columnDefs.filter((item) => item.field === field);
  const { uniqueValues } = useAppSelector(selectAutoComplete);
  const { getPermission } = usePermission();
  const hasEroPermissions = getPermission("eroMassEdit");
  const hasVegPermissions = getPermission("vegMassEdit");
  const hasDocCtrlPermissions = getPermission("docCtrlMassEdit");
  const hasFirmPrimePermissions = getPermission("firmPrimeMassUpdate");
  const hasCityWorksPermissions = getPermission("cityWorksMassUpdate");

  const transformedUniqueValues: { [key: string]: OptionsData[] } =
    useMemo(() => {
      const newValue: any = dataTransforming(uniqueValues);
      newValue["issueStatus"] = newValue.status;
      newValue["groupName"] = newValue.group_name;
      return newValue;
    }, [uniqueValues]);

  const itemIndex = currentMassUpdate.findIndex((item) => item.field === field);

  const onChange = (e: any) => {
    const value = typeof e === "string" ? e : e.target.value;
    dispatch(
      handleSelect(
        setMassUpdateInput(
          currentMassUpdate,
          value,
          "value",
          currSelectInfo,
          colInfo
        )
      )
    );
  };

  const onDateChange = (date: any) => {
    if (!date.date) {
      return;
    } else {
      dispatch(
        handleSelect(
          setMassUpdateInput(
            currentMassUpdate,
            parseDateString(date.date),
            "value",
            currSelectInfo,
            colInfo
          )
        )
      );
    }
  };

  const unAddedCols = columnDefs
    .filter(({ edit }) => edit === true)
    .filter((o1) => !currentMassUpdate.some((o2) => o1.field === o2.field));

  const selectableCols = unAddedCols.map((item, index) => {
    return { id: index, value: item.field, label: item.headerName };
  });

  const unAddedEroCols = columnDefs
    .filter(({ eroEdit }) => eroEdit === true)
    .filter((o1) => !currentMassUpdate.some((o2) => o1.field === o2.field));

  const selectableEroCols = unAddedEroCols.map((item, index) => {
    return { id: index, value: item.field, label: item.headerName };
  });

  const unAddedVegCols = columnDefs
    .filter(({ vegEdit }) => vegEdit === true)
    .filter((o1) => !currentMassUpdate.some((o2) => o1.field === o2.field));

  const selectableVegCols = unAddedVegCols.map((item, index) => {
    return { id: index, value: item.field, label: item.headerName };
  });

  const unAddedDocCols = columnDefs
    .filter(({ docEdit }) => docEdit === true)
    .filter((o1) => !currentMassUpdate.some((o2) => o1.field === o2.field));
  const selectableDocCols = unAddedDocCols.map((item, index) => {
    return { id: index, value: item.field, label: item.headerName };
  });

  const unAddedFirmPrime = columnDefs
    .filter(({ firmPrimeEdit }) => firmPrimeEdit === true)
    .filter((o1) => !currentMassUpdate.some((o2) => o1.field === o2.field));
  const selectablFirmPrimeCols = unAddedFirmPrime.map((item, index) => {
    return { id: index, value: item.field, label: item.headerName };
  });

  const unAddedCityWorksCols = columnDefs
    .filter(({ cityWorksEdit }) => cityWorksEdit === true)
    .filter((o1) => !currentMassUpdate.some((o2) => o1.field === o2.field));
  const selectablCityWorksCols = unAddedCityWorksCols.map((item, index) => {
    return { id: index, value: item.field, label: item.headerName };
  });

  const currSelectInfo = currentMassUpdate.filter(
    (item) => item.field === field
  );
  const currInputValue = currSelectInfo[0].value;

  const deleteMassUpdateItem = (field: string) => {
    const newItems = currentMassUpdate.filter((item) => item.field !== field);
    return newItems;
  };

  const blankHandle = () => {
    dispatch(
      handleSelect(
        setMassSelect(
          currentMassUpdate,
          "",
          "value",
          currSelectInfo,
          currSelectInfo[0].field
        )
      )
    );
    return massUpdateSelect(currSelectInfo[0].field)[0].value;
  };

  return (
    <Styled.MassUpdateOptionsWrapper>
      <MassUpdateSelect
        currentMassUpdate={currentMassUpdate}
        handleSelect={handleSelect}
        optionsData={
          hasEroPermissions
            ? selectableEroCols
            : hasVegPermissions
            ? selectableVegCols
            : hasDocCtrlPermissions
            ? selectableDocCols
            : hasFirmPrimePermissions
            ? selectablFirmPrimeCols
            : hasCityWorksPermissions
            ? selectablCityWorksCols
            : selectableCols
        }
        property={"field"}
        currentSelect={colInfo[0].headerName}
        currentField={colInfo[0].field}
        currSelectInfo={currSelectInfo}
      />
      {currSelectInfo[0].field.includes("Date") ||
      currSelectInfo[0].field.includes("actualIfc") ||
      currSelectInfo[0].field.includes("forecastedIfc") ? (
        <Styled.PickerWrapper onClick={handleSubmit(onDateChange)}>
          <DatePickerInput
            name="date"
            readOnly
            defaultValue={currInputValue}
            control={control}
          />
        </Styled.PickerWrapper>
      ) : selectItems.includes(currSelectInfo[0].field) ? (
        <MassUpdateSelect
          currentMassUpdate={currentMassUpdate}
          handleSelect={handleSelect}
          optionsData={
            generateUniqueValues(
              transformedUniqueValues[currSelectInfo[0].field],
              massUpdateAdditionalSelect(currSelectInfo[0].field)
            ) ??
            massUpdateSelect(currSelectInfo[0].field).filter(
              (item: any) => item.label !== currentMassUpdate[itemIndex].value
            )
          }
          property={"value"}
          currentSelect={
            massUpdateSelect(currSelectInfo[0].field)?.some(
              (item: any) => item.value === currentMassUpdate[itemIndex].value
            ) || transformedUniqueValues[currSelectInfo[0].field]
              ? currentMassUpdate[itemIndex].value
              : blankHandle()
          }
          currentField={currSelectInfo[0].field}
          currSelectInfo={currSelectInfo}
        />
      ) : (
        <MassUpdateInput
          onTextChange={onChange}
          value={currInputValue}
          autoComplete={
            field === "groupName"
              ? "group_name"
              : currSelectInfo[0].field.toLowerCase()
          }
        />
      )}
      <Button
        icon={DeleteIcon as Icon}
        onClick={() => {
          dispatch(handleSelect(deleteMassUpdateItem(field)));
        }}
      />
    </Styled.MassUpdateOptionsWrapper>
  );
};

export default MassUpdateItem;
