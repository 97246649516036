import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { handleHiddenColumn } from "../../app/store/overlaps/overlaps.slice";
import { HiddenOptionContainer } from "./components";
import { selectOverlaps } from "@sdge-store/overlaps/overlaps.selector";

const OverlapsHideOptionsMenu: FC = () => {
  const { columnDefs } = useAppSelector(selectOverlaps);
  const dispatch = useAppDispatch();

  const switchHidden = (field: string) => {
    dispatch(handleHiddenColumn(field));
  };

  return (
    <HiddenOptionContainer
      columnDefs={columnDefs}
      switchHidden={switchHidden}
    />
  );
};

export default OverlapsHideOptionsMenu;
