import { createAsyncThunk } from "@reduxjs/toolkit";
import queryInstance from "@sdge-api/config/queryInstance";
import { errorHandler } from "@sdge-api/utils";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";

export const fetchOverlapsData: any = createAsyncThunk(
  "overlapping_programs",
  async (sortingRules: any, { dispatch }) => {
    try {
      let response;
      if (sortingRules[2].length === 0) {
        response = await queryInstance.get(
          `/overlapping_programs?page=${sortingRules[0]}${sortingRules[1]}`,
          {
            timeout: 120000,
          }
        );
      } else {
        response = await queryInstance.post(
          `/overlapping_programs?page=${sortingRules[0]}${sortingRules[1]}`,
          sortingRules[2]
        );
      }
      if (sortingRules[3] === true) {
        return {
          ...response?.data,
          sortingRules: sortingRules[1],
          isQuickViews: true,
        };
      }
      return {
        ...response?.data,
        sortingRules: sortingRules[1],
      };
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const filterOverlapsData: any = createAsyncThunk(
  "overlapping_programs/filtering",
  async (sortingRules: any, { dispatch }) => {
    try {
      const response = await queryInstance.post(
        `/overlapping_programs?page=${sortingRules[0]}${sortingRules[1]}`,
        sortingRules[2]
      );
      return {
        ...response.data,
        sortingRules: sortingRules[1],
      };
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const sortOverlapsData: any = createAsyncThunk(
  "overlapping_programs/sorting",
  async (sortingRules: any, { dispatch }) => {
    try {
      let response;
      if (sortingRules[2].length === 0) {
        response = await queryInstance.get(
          `/overlapping_programs?page=${sortingRules[0]}${sortingRules[1]}`,
          {
            timeout: 120000,
          }
        );
        return {
          ...response.data,
          sortingRules: sortingRules[1],
        };
      } else {
        response = await queryInstance.post(
          `/overlapping_programs?page=${sortingRules[0]}${sortingRules[1]}`,
          sortingRules[2]
        );
        return {
          ...response.data,
          sortingRules: sortingRules[1],
        };
      }
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const fetchOverlapsQuickViews: any = createAsyncThunk(
  "quick-views/overlapping_programs",
  async (_, { dispatch }) => {
    try {
      const response = await queryInstance.get(
        "/quick-views/overlapping_programs",
        {
          timeout: 120000,
        }
      );
      return response.data.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const addOverlapsQuickView: any = createAsyncThunk(
  "quick-views/overlapping_programs/post",
  async (body: any, { dispatch }) => {
    try {
      const response = await queryInstance.post(
        "/quick-views/overlapping_programs",
        body
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was created",
        })
      );
      return response.data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateOverlapsQuickView: any = createAsyncThunk(
  "quick-views/overlapping_programs/put",
  async (body: any, { dispatch }) => {
    try {
      const response = await queryInstance.put(
        "/quick-views/overlapping_programs",
        body
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was updated",
        })
      );
      return response.data;
    } catch (error) {
      errorHandler(error, dispatch);
    }
  }
);

export const deleteQuickView: any = createAsyncThunk(
  "overlapping_programs/deleteQuickView",
  async (payload: any, { dispatch }) => {
    try {
      await queryInstance.delete(
        `/quick-views/overlapping_programs/${payload}`
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was deleted",
        })
      );
      return payload;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
