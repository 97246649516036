import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constants } from "./constants/construction.constants";
import { ConstructionSliceState } from "./construction.types";
import {
  addConstructionQuickView,
  CreateIssue,
  deleteQuickView,
  fetchConstructionData,
  fetchConstructionQuickViews,
  filterConstructionData,
  getEnvPermitsConstruction,
  getConstructionCustomersList,
  getConstructionOverlapping,
  getMuniPermitsConstruction,
  getPackageSummary,
  getPermits,
  getPoleSummary,
  getTilesInfo,
  sortConstructionData,
  updateFilteredRows,
  updateConstructionQuickView,
  updatePermits,
  updateConstructionSap,
  updateSelectedRows,
  getInteractionByGroup,
  deleteInteractionByGroup,
  getSapTileContentByGroup,
  createConstructionCustomer,
  getFacilityIdList,
  deleteCustomerByGroup,
  getStatusValues,
  updateConstructionCustomers,
  updateEnvPermitsConstruction,
  updateMuniPermitsConstruction,
  getHoldStatus,
  updateContructionState,
  deleteMuniPermitConstruction,
  deleteEnvPermitConstruction,
} from "./construction.async";
import {
  getHidddenColumns,
  getPinnedColumns,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";
// import { getConstructionInfo } from "@sdge-store/drawer/drawer.async";

const initialConstructionState: ConstructionSliceState = {
  construction: [],
  columnDefs: Constants.columnDefs,
  initialColumnDefs: Constants.columnDefs,
  areQuickVeiwsOptionsOpen: false,
  loading: false,
  page: 0,
  massUpdateItems: [],
  massUpdateItemsRequest: [],
  totalElements: 0,
  loadedElements: 0,
  totalPages: 1,
  isLast: false,
  isPaginated: true,
  isLoading: true,
  createCustomerLoading: false,
  selectedRows: [],
  areHiddenFieldsOpen: false,
  areSortOptionsOpen: false,
  areFilterOptionsOpen: false,
  selectedQuickVewName: "",
  sortingRules: "",
  sort: [],
  filteringRules: [],
  filter: [],
  searchInputValue: "",
  programs: [],
  pole: null,
  permit: null,
  environmental: null,
  columnOrder: [],
  initialColumnOrder: Constants.columnsOrder,
  hiddenColumns: [],
  frozenColumns: [],
  quickViews: [],
  isQuickVeiwsModalOpen: false,
  constructionCustomersList: [],
  constructionOverlapping: [],
  sapTileContentConstruction: [],
  areMassUpdateOptionsOpen: false,
  newIssue: null,
  requested: false,
  permits: [],
  sapIsUpdated: false,
  buttonDisabled: false,
  selectedQuickViewPrivacy: false,
  selectedQuickViewId: "",
  poleSummary: null,
  packageSummary: null,
  envPermitsConstruction: [],
  muniPermits: [],
  getPackageSumLoad: false,
  getPoleSumLoad: false,
  inputText: "",
  activeConstructionFacilityId: "",
  activeConstructionGroupName: "",
  activeConstructionId: "",
  constructionInteractions: [],
  massUpdateLoading: false,
  facilityIdList: [],
  constructionInfo: null,
  customerIds: [],
  interactionTypes: [],
  selectedRowsGroupNames: [],
  constructionLoading: false,
  onHoldStatusList: [],
  constructionExportLoading: false,
};

const constructionSlice = createSlice({
  name: "construction",
  initialState: initialConstructionState,
  reducers: {
    handleColumnDefs: (state, action) => {
      state.columnDefs = action.payload;
    },
    handleChangeRequested: (state, action) => {
      state.requested = action.payload;
    },
    handleColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    handlePrivacy: (state, action) => {
      state.selectedQuickViewPrivacy = action.payload;
    },
    handleQuickViewId: (state, action) => {
      state.selectedQuickViewId = action.payload;
    },
    quickViewsOptions: (state, action) => {
      state.areQuickVeiwsOptionsOpen = action.payload;
    },
    massUpdateOptions: (state, action) => {
      state.areMassUpdateOptionsOpen = action.payload;
    },
    handleLoadedPagesChange: (state, action) => {
      const isDropToFirstPage = action?.payload?.isDropToFirstPage ?? false;

      if (isDropToFirstPage) {
        state.isPaginated = true;
        state.page = 0;
        return;
      }

      if (state.page < state.totalPages && state.isLoading) {
        state.isPaginated = true;
        state.page = state.page + 1;
      }
    },
    handleCurrentPage: (state, action) => {
      state.page = action.payload;
    },
    handleHiddenColumn: (state, action) => {
      const foundIndex = state.columnDefs.findIndex(
        (item) => item.field === action.payload
      );
      if (state.columnDefs[foundIndex].hide === true) {
        state.columnDefs[foundIndex].hide = false;
      } else {
        state.columnDefs[foundIndex].hide = true;
      }
      state.hiddenColumns = getHidddenColumns(state.columnDefs);
    },
    handlePinnedColumn: (state, action) => {
      const foundIndex = state.columnDefs.findIndex(
        (item) => item.field === action.payload
      );
      if (state.columnDefs[foundIndex].pinned === null) {
        state.columnDefs[foundIndex].pinned = "left";
      } else {
        state.columnDefs[foundIndex].pinned = null;
      }
      state.frozenColumns = getPinnedColumns(state.columnDefs);
    },
    handleSort: (state, action) => {
      state.sort = action.payload;
    },
    handleFilter: (state, action) => {
      if (action.payload.length === 1) {
        action.payload[0] = {
          ...action.payload[0],
          relation: "AND",
        };
      } else if (action.payload.length > 1) {
        action.payload[0] = {
          ...action.payload[0],
          relation: action.payload[1].relation,
        };
      }
      state.filter = action.payload;
    },
    handleFilteringRules: (state, action) => {
      state.filteringRules = action.payload;
    },

    fieldsHiddenOptions: (state, action) => {
      state.areHiddenFieldsOpen = action.payload;
    },

    sortOptions: (state, action) => {
      state.areSortOptionsOpen = action.payload;
    },

    filterOptions: (state, action) => {
      state.areFilterOptionsOpen = action.payload;
    },

    handleSearch: (state, action) => {
      state.searchInputValue = action.payload;
    },
    handleColumnChoosen: (state, action) => {
      state.quickViews = action.payload;
    },
    handleQuickViewsModal: (state, action) => {
      state.isQuickVeiwsModalOpen = action.payload;
    },
    handleQuickViewsParams: (state, action) => {
      state.columnDefs = action.payload.columnDefs;
      state.hiddenColumns = action.payload.hiddenColumns;
      state.frozenColumns = action.payload.frozenColumns;
      state.columnOrder = action.payload.columnOrder;
      state.sort = action.payload.sort;
      state.filter = action.payload.filter;
      state.selectedQuickVewName = action.payload.selectedName;
      state.filteringRules = action.payload.filteringRules;
    },
    handleCellEdit: (state, action) => {
      if (action.payload.oldValue !== action.payload.newValue) {
        const field = action.payload.colDef.field;
        const row = { ...action.payload.data };
        const index = action.payload.rowIndex;
        const issuesCopy = [...state.construction];
        row[field] = action.payload.newValue;
        issuesCopy[index] = row;
        state.construction = issuesCopy;
      }
    },
    handleSelectedRows: (state, action) => {
      if (action.payload.length < state.selectedRows.length) {
        state.selectedRows = action.payload;
      } else {
        state.selectedRows = [
          ...(new Set([...state.selectedRows, ...action.payload]) as any),
        ];
      }
    },
    handleSelectedRowsGroupNames: (state, action) => {
      state.selectedRowsGroupNames = action.payload;
    },
    handleMassUpdateItems: (state, action) => {
      state.massUpdateItems = action.payload;
    },
    setActiveIssueId: (state, action) => {
      state.activeConstructionId = action.payload.id;
      state.activeConstructionFacilityId = action.payload.facilityId;
      state.activeConstructionGroupName = action.payload.groupName;
    },
    handleSapUpdate: (state, action) => {
      state.sapIsUpdated = action.payload;
    },
    handleInputText: (state, action) => {
      state.inputText = action.payload;
    },
    handleConstructionExport: (state, action) => {
      state.constructionExportLoading = action.payload;
    },
    handleClearConstructionGrid: (state, action) => {
      if (action.payload !== "/construction") {
        state.construction = [];
      }
    },
  },
  extraReducers: {
    [fetchConstructionData.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      if (action.payload) {
        if (state.page === 0) {
          state.construction = action.payload.content;
          state.loadedElements = action.payload.elementsOnPage;
        } else {
          if (action.payload.isQuickViews) {
            state.construction = action.payload.content;
            state.loadedElements = action.payload.elementsOnPage;
            state.page = 0;
          } else {
            state.construction = [
              ...state.construction,
              ...action.payload.content,
            ];
            state.loadedElements =
              state.loadedElements + action.payload.elementsOnPage;
          }
        }
        state.sortingRules = action.payload.sortingRules;
        state.totalElements = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
      }
      state.isLoading = true;
    },
    [fetchConstructionData.pending]: (state) => {
      state.isLoading = false;
    },
    [sortConstructionData.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.construction = action.payload.content;
        state.loadedElements = action.payload.elementsOnPage;
        state.totalElements = action.payload.totalElements;
        state.page = action.payload.pageNumber;
        state.sortingRules = action.payload.sortingRules;
        state.totalPages = action.payload.totalPages;
        state.constructionCustomersList = [];
        state.envPermitsConstruction = [];
        state.muniPermits = [];
        state.constructionOverlapping = [];
        state.sapTileContentConstruction = [];
        state.constructionInfo = null;
      }
      state.loading = false;
      state.isPaginated = false;
    },
    [sortConstructionData.pending]: (state: ConstructionSliceState) => {
      state.loading = true;
    },
    [sortConstructionData.rejected]: (state: ConstructionSliceState) => {
      state.loading = false;
    },
    [filterConstructionData.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.construction = action.payload.content;
        state.loadedElements = action.payload.elementsOnPage;
        state.sortingRules = action.payload.sortingRules;
        state.page = action.payload.pageNumber;
        state.totalElements = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
        state.constructionCustomersList = [];
        state.envPermitsConstruction = [];
        state.muniPermits = [];
        state.constructionInfo = [];
        state.constructionOverlapping = [];
        state.sapTileContentConstruction = [];
      }
      state.loading = false;
      state.isPaginated = false;
    },
    [filterConstructionData.pending]: (state: ConstructionSliceState) => {
      state.loading = true;
    },
    [filterConstructionData.rejected]: (state: ConstructionSliceState) => {
      state.loading = false;
    },
    [getTilesInfo.fulfilled]: (state: ConstructionSliceState, action: any) => {
      if (action.payload) {
        state.environmental = action.payload.environmental;
        state.permit = action.payload.permit;
        state.pole = action.payload.pole;
        state.programs = action.payload.programs;
      }
    },
    [fetchConstructionQuickViews.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.quickViews = action.payload.map((item: any) => {
        return { ...item, selected: false };
      });
      state.loading = false;
    },
    [fetchConstructionQuickViews.pending]: (state: ConstructionSliceState) => {
      state.loading = true;
    },
    // [fetchConstructionQuickViews.fulfilled]: (
    //   state: ConstructionSliceState,
    //   action: any
    // ) => {
    //   if (action.payload) {
    //     state.quickViews = [...state.quickViews, action.payload].sort((a, b) =>
    //       a.name.localeCompare(b.name, undefined, {
    //         numeric: true,
    //         sensitivity: "base",
    //       })
    //     );
    //   }
    // },
    [addConstructionQuickView.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.quickViews = [...state.quickViews, action.payload].sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
      }
    },

    [updateConstructionQuickView.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      if (action.payload) {
        const newquickViews = state.quickViews.map((item) => {
          if (item) {
            if (item.name === action.payload.name) {
              return { ...action.payload, selected: true };
            } else {
              return item;
            }
          }
        });
        state.quickViews = newquickViews;
      }
    },
    [getConstructionCustomersList.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.constructionCustomersList = action.payload;
    },
    [getConstructionOverlapping.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.constructionOverlapping = action.payload;
    },
    [getFacilityIdList.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.facilityIdList = action.payload;
    },
    [getStatusValues.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      if (action.payload.groupName) {
        state.constructionInfo = action.payload;
      } else {
        state.constructionInfo = null;
      }
    },
    [getSapTileContentByGroup.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.sapTileContentConstruction = action.payload;
    },
    // [getConstructionInfo.fulfilled]: (
    //   state: ConstructionSliceState,
    //   action: any
    // ) => {
    //   state.sapTileContentConstruction = action.payload.sap;
    //   state.constructionCustomersList = action.payload.customer;
    //   state.envPermitsConstruction = action.payload.envPermit;
    //   state.muniPermits = action.payload.muniPermit;
    //   state.constructionInfo = action.payload.status || null;
    //   state.constructionOverlapping = action.payload.overlapping;
    // },
    [getEnvPermitsConstruction.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.envPermitsConstruction = action.payload;
    },
    [getMuniPermitsConstruction.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.muniPermits = action.payload;
    },
    [getPackageSummary.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.packageSummary = action.payload;
      state.getPackageSumLoad = false;
    },
    [getPackageSummary.pending]: (state: ConstructionSliceState, _) => {
      state.getPackageSumLoad = true;
    },
    [getPackageSummary.rejected]: (state: ConstructionSliceState, _) => {
      state.getPackageSumLoad = false;
    },
    [getPoleSummary.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.poleSummary = action.payload;
      state.getPoleSumLoad = false;
    },
    [getPoleSummary.pending]: (state: ConstructionSliceState, _) => {
      state.getPoleSumLoad = true;
    },
    [getPoleSummary.rejected]: (state: ConstructionSliceState, _) => {
      state.getPoleSumLoad = false;
    },
    [CreateIssue.fulfilled]: (state: ConstructionSliceState, action: any) => {
      state.newIssue = action.payload;
    },
    [getPermits.fulfilled]: (state: ConstructionSliceState, action: any) => {
      state.permits = [action.payload];
    },
    [updateConstructionSap.fulfilled]: (state: ConstructionSliceState, _) => {
      state.sapIsUpdated = true;
      state.constructionLoading = false;
    },
    [updateConstructionSap.rejected]: (state: ConstructionSliceState, _) => {
      state.sapIsUpdated = true;
      state.constructionLoading = false;
    },
    [updateConstructionSap.pending]: (state: ConstructionSliceState, _) => {
      state.sapIsUpdated = true;
      state.constructionLoading = true;
    },
    [updatePermits.pending]: (state: ConstructionSliceState, _) => {
      state.sapIsUpdated = true;
      state.constructionLoading = true;
    },
    [updatePermits.rejected]: (state: ConstructionSliceState, _) => {
      state.sapIsUpdated = true;
      state.constructionLoading = false;
    },
    [updatePermits.fulfilled]: (state: ConstructionSliceState, _) => {
      state.sapIsUpdated = true;
      state.constructionLoading = false;
    },
    [getHoldStatus.fulfilled]: (state: ConstructionSliceState, action: any) => {
      if (action.payload.length && action.payload[0]) {
        state.onHoldStatusList = action.payload;
      } else {
        state.onHoldStatusList = [];
      }
    },
    [createConstructionCustomer.fulfilled]: (
      state: ConstructionSliceState,
      _
    ) => {
      state.createCustomerLoading = false;
    },
    [createConstructionCustomer.pending]: (
      state: ConstructionSliceState,
      _
    ) => {
      state.createCustomerLoading = true;
    },
    [createConstructionCustomer.rejected]: (
      state: ConstructionSliceState,
      _
    ) => {
      state.createCustomerLoading = false;
    },
    [CreateIssue.fulfilled]: (state: ConstructionSliceState) => {
      state.buttonDisabled = false;
      state.constructionLoading = false;
    },
    [CreateIssue.pending]: (state: ConstructionSliceState) => {
      state.buttonDisabled = true;
      state.constructionLoading = true;
    },
    [CreateIssue.rejected]: (state: ConstructionSliceState) => {
      state.buttonDisabled = false;
      state.constructionLoading = false;
    },
    [getInteractionByGroup.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.constructionInteractions = action.payload;
      if (action.payload?.length) {
        state.customerIds = action.payload.reduce((acc: any, item: any) => {
          acc = [...acc, ...item.customerIds];
          return acc;
        }, []);
        state.interactionTypes = action.payload.map(
          (item: any) => item.interactionType
        );
      } else {
        state.customerIds = [];
        state.interactionTypes = [];
      }
    },
    [deleteInteractionByGroup.fulfilled]: (
      state: ConstructionSliceState,
      action: PayloadAction<{ index: number }>
    ) => {
      if (action.payload)
        state.constructionInteractions.splice(action.payload.index, 1);
    },
    [deleteCustomerByGroup.fulfilled]: (
      state: ConstructionSliceState,
      action: PayloadAction<{ index: number }>
    ) => {
      if (action.payload)
        state.constructionCustomersList.splice(action.payload.index, 1);
    },
    [updateSelectedRows.pending]: (state: ConstructionSliceState) => {
      // state.loading = true;
      state.massUpdateLoading = true;
      state.constructionLoading = true;
    },
    [updateSelectedRows.fulfilled]: (state: ConstructionSliceState) => {
      state.massUpdateLoading = false;
      state.selectedRows = [];
      state.selectedRowsGroupNames = [];
      state.massUpdateItemsRequest = [];
      state.constructionLoading = false;
      state.areMassUpdateOptionsOpen = false;
    },
    [updateFilteredRows.pending]: (state: ConstructionSliceState) => {
      // state.loading = true;
      state.massUpdateLoading = true;
      state.constructionLoading = true;
    },
    [updateFilteredRows.fulfilled]: (state: ConstructionSliceState) => {
      // state.loading = false;
      state.massUpdateLoading = false;
      state.constructionLoading = false;
      state.selectedRows = [];
      state.selectedRowsGroupNames = [];
      state.massUpdateItemsRequest = [];
      state.areMassUpdateOptionsOpen = false;
    },
    [deleteQuickView.fulfilled]: (
      state: ConstructionSliceState,
      action: any
    ) => {
      state.quickViews = state.quickViews
        .filter((item) => item.id !== action.payload)
        .filter((item) => {
          if (item.name === state.selectedQuickVewName) {
            return { ...item, selected: true };
          } else {
            return { item };
          }
        });
    },
    //construction customers update loading handler
    [updateConstructionCustomers.pending]: (state: ConstructionSliceState) => {
      state.constructionLoading = true;
    },
    [updateConstructionCustomers.fulfilled]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = false;
    },
    [updateConstructionCustomers.rejected]: (state: ConstructionSliceState) => {
      state.constructionLoading = false;
    },

    //construction customers env permits update loading handler
    [updateEnvPermitsConstruction.pending]: (state: ConstructionSliceState) => {
      state.constructionLoading = true;
    },
    [updateEnvPermitsConstruction.fulfilled]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = false;
    },
    [updateEnvPermitsConstruction.rejected]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = false;
    },

    //construction customers muni permits update loading handler
    [deleteMuniPermitConstruction.pending]: (state: ConstructionSliceState) => {
      state.constructionLoading = true;
    },
    [deleteMuniPermitConstruction.rejected]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = false;
    },
    [deleteMuniPermitConstruction.fulfilled]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = false;
    },
    [deleteEnvPermitConstruction.pending]: (state: ConstructionSliceState) => {
      state.constructionLoading = true;
    },
    [deleteEnvPermitConstruction.rejected]: (state: ConstructionSliceState) => {
      state.constructionLoading = false;
    },
    [deleteEnvPermitConstruction.fulfilled]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = false;
    },
    [updateMuniPermitsConstruction.pending]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = true;
    },
    [updateMuniPermitsConstruction.fulfilled]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = false;
    },
    [updateMuniPermitsConstruction.rejected]: (
      state: ConstructionSliceState
    ) => {
      state.constructionLoading = false;
    },
    [updateContructionState.pending]: (state: ConstructionSliceState) => {
      state.constructionLoading = true;
    },
    [updateContructionState.fulfilled]: (state: ConstructionSliceState) => {
      state.constructionLoading = false;
    },
    [updateContructionState.rejected]: (state: ConstructionSliceState) => {
      state.constructionLoading = false;
    },
  },
});

export const {
  handleLoadedPagesChange,
  handleSelectedRowsGroupNames,
  handleCurrentPage,
  handleSort,
  handleFilter,
  handleFilteringRules,
  fieldsHiddenOptions,
  sortOptions,
  handleHiddenColumn,
  handlePinnedColumn,
  filterOptions,
  handleSearch,
  quickViewsOptions,
  handleColumnOrder,
  handleQuickViewsModal,
  handleColumnChoosen,
  handleQuickViewsParams,
  handleCellEdit,
  handleColumnDefs,
  handleSelectedRows,
  massUpdateOptions,
  handleMassUpdateItems,
  handleChangeRequested,
  setActiveIssueId,
  handleSapUpdate,
  handlePrivacy,
  handleQuickViewId,
  handleInputText,
  handleClearConstructionGrid,
  handleConstructionExport,
} = constructionSlice.actions;

export default constructionSlice.reducer;
