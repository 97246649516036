import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { Button } from "@sdge-components";
import { StyledPropsWithTheme } from "../../utils/globalTypes";

const FilterOptionsWrapper = styled.form<StyledPropsWithTheme>`
  width: 600px;
  max-height: 200px;
  background: ${({ theme }) => theme.palette.tables?.main?.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  row-gap: 5px;
  padding: 15px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

const FilterOptionsList = styled(Box)<StyledPropsWithTheme>`
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 6px;
  row-gap: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 5px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    opacity: 0.15;
  }
`;

const AddFilterWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterButton = styled(Button)<
  StyledPropsWithTheme<{ disabled?: boolean; onKeyPress?: any }>
>`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  background-color: ${({ disabled }) => disabled && "grey"};
`;
const DecorateButton = styled.button`
  display: none;
`;
const ListBottom = styled(Box)<{ ref: any }>``;
export const Styled = {
  FilterOptionsWrapper,
  FilterOptionsList,
  AddFilterWrapper,
  FilterButton,
  DecorateButton,
  ListBottom,
};
