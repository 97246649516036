import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarDataTypes, SnackbarStateTypes } from "./snackbar.types";

const initialSnackbarState: SnackbarStateTypes = {
  data: {
    isOpen: false,
    alertColor: undefined,
    message: "",
  },
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: initialSnackbarState,
  reducers: {
    handleChangeSnackbarState: (
      state,
      action: PayloadAction<SnackbarDataTypes>
    ) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { handleChangeSnackbarState } = snackbarSlice.actions;
export default snackbarSlice.reducer;
