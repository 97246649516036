import { Tab } from "@sdge-components/TabControls/TabControls.types";

export const tabs: { [key: string]: Tab[] } = {
  notification: [
    {
      id: "allNotifications",
      label: "All",
      path: "allNotifications",
    },
    {
      id: "userNotifications",
      label: "User’s Editing",
      path: "userNotifications",
    },
    {
      id: "poleNotifications",
      label: "Imported Poles",
      path: "poleNotifications",
    },
  ],
  overlapping_table: [
    {
      id: "Info",
      label: "Info",
      path: "info",
    },
    {
      id: "Acknowledgements",
      label: "Interaction",
      path: "acknowledgements",
    },
  ],
  notification_info: [
    {
      id: "Info",
      label: "Info",
      path: "info",
    },
    {
      id: "Acknowledgements",
      label: "Interaction",
      path: "acknowledgements",
    },
    {
      id: "History",
      label: "History",
      path: "history",
    },
  ],
  map_markers: [
    {
      id: "Info",
      label: "Info",
      path: "info",
    },
    {
      id: "History",
      label: "Interaction",
      path: "History",
    },
  ],
  customer_table: [
    {
      id: "Info",
      label: "Info",
      path: "info",
    },
    {
      id: "Acknowledgements",
      label: "Interaction",
      path: "acknowledgements",
    },
    {
      id: "History",
      label: "History",
      path: "poleHistory",
    },
    {
      id: "Core",
      label: "Core",
      path: "core",
    },
  ],
  issue_tracker_table: [
    {
      id: "Info",
      label: "Info",
      path: "info",
    },
    {
      id: "Acknowledgements",
      label: "Interaction",
      path: "acknowledgements",
    },
    {
      id: "History",
      label: "History",
      path: "poleHistory",
    },
  ],
  construction_table: [
    {
      id: "Info",
      label: "Info",
      path: "info",
    },
    {
      id: "Acknowledgements",
      label: "Interaction",
      path: "acknowledgements",
    },
    {
      id: "History",
      label: "History",
      path: "poleHistory",
    },
  ],
};
