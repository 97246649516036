const overlapping = (card: string) => [
  {
    field: "activity_name",
    filtering: true,
    sortField: "activity_name",
    headerName: "Activity Name",
    filterField: `${card}activity_name`,
  },
  {
    field: "activity_status",
    filtering: true,
    sortField: "activity_status",
    headerName: "Activity Status",
    filterField: `${card}activity_status`,
  },
  {
    field: "completion_date",
    filtering: true,
    sortField: "completion_date",
    headerName: "Completion Date",
    filterField: `${card}completion_date`,
  },
  {
    field: "compliancedate",
    filtering: true,
    sortField: "compliancedate",
    headerName: "Compliance Date",
    filterField: `${card}compliancedate`,
  },
  {
    field: "const_finish",
    filtering: true,
    sortField: "const_finish",
    headerName: "Const Finish",
    filterField: `${card}const_finish`,
  },
  {
    field: "constfin",
    filtering: true,
    sortField: "constfin",
    headerName: "Const Fin",
    filterField: `${card}constfin`,
  },
  {
    field: "constructiondate",
    filtering: true,
    sortField: "constructiondate",
    headerName: "Construction Date",
    filterField: `${card}constructiondate`,
  },
  {
    field: "constructionstatus",
    filtering: true,
    sortField: "constructionstatus",
    headerName: "Construction Status",
    filterField: `${card}constructionstatus`,
  },
  {
    field: "equipment",
    filtering: true,
    sortField: "equipment",
    headerName: "Equipment",
    filterField: `${card}equipment`,
  },
  {
    field: "esh_budget",
    filtering: true,
    sortField: "esh_budget",
    headerName: "Esh Budget",
    filterField: `${card}esh_budget`,
  },
  {
    field: "esh_mfb",
    filtering: true,
    sortField: "esh_mfb",
    headerName: "Esh Mfb",
    filterField: `${card}esh_mfb`,
  },
  {
    field: "Flown",
    filtering: true,
    sortField: "flown",
    headerName: "Flown",
    filterField: `${card}flown`,
  },
  {
    field: "Flown Date",
    filtering: true,
    sortField: "flown_date",
    headerName: "Flown Date",
    filterField: `${card}flown_date`,
  },
  {
    field: "Hftd",
    filtering: true,
    sortField: "hftd",
    headerName: "Hftd",
    filterField: `${card}hftd`,
  },
  {
    field: "ifc_finish",
    filtering: true,
    sortField: "ifc_finish",
    headerName: "Ifc Finish",
    filterField: `${card}ifc_finish`,
  },
  {
    field: "ifcactual",
    filtering: true,
    sortField: "ifcactual",
    headerName: "Ifc Actual",
    filterField: `${card}ifcactual`,
  },
  {
    field: "ifcdate",
    filtering: true,
    sortField: "ifcdate",
    headerName: "Ifc Date",
    filterField: `${card}ifcdate`,
  },
  {
    field: "job_type",
    filtering: true,
    sortField: "job_type",
    headerName: "Job Type",
    filterField: `${card}job_type`,
  },
  {
    field: "lidar_polescope",
    filtering: true,
    sortField: "lidar_polescope",
    headerName: "Lidar Polescope",
    filterField: `${card}lidar_polescope`,
  },
  {
    field: "lidar_program",
    filtering: true,
    sortField: "lidar_program",
    headerName: "Lidar Program",
    filterField: `${card}lidar_program`,
  },
  {
    field: "newpoleconductordate",
    filtering: true,
    sortField: "newpoleconductordate",
    headerName: "New Pole Conductor Date",
    filterField: `${card}newpoleconductordate`,
  },
  {
    field: "oldpolenoconductordate",
    filtering: true,
    sortField: "oldpolenoconductordate",
    headerName: "Old Pole Conductor Date",
    filterField: `${card}oldpolenoconductordate`,
  },
  {
    field: "pmr_notification_description",
    filtering: true,
    sortField: "pmr_notification_description",
    headerName: "Pmr Notification Description",
    filterField: `${card}pmr_notification_description`,
  },
  {
    field: "pmr_req_end_date",
    filtering: true,
    sortField: "pmr_req_end_date",
    headerName: "Pmr Req End Date",
    filterField: `${card}pmr_req_end_date`,
  },
  {
    field: "pole_id",
    filtering: true,
    sortField: "pole_id",
    headerName: "Pole Id",
    filterField: `${card}pole_id`,
  },
  {
    field: "poleholedugdate",
    filtering: true,
    sortField: "poleholedugdate",
    headerName: "Pole Hole Dug Date",
    filterField: `${card}poleholedugdate`,
  },
  {
    field: "polemodifieddate",
    filtering: true,
    sortField: "polemodifieddate",
    headerName: "Pole Modified Date",
    filterField: `${card}polemodifieddate`,
  },
  {
    field: "poleremoveddate",
    filtering: true,
    sortField: "poleremoveddate",
    headerName: "Pole Removed Date",
    filterField: `${card}poleremoveddate`,
  },
  {
    field: "polesetdate",
    filtering: true,
    sortField: "polesetdate",
    headerName: "Pole Set Date",
    filterField: `${card}polesetdate`,
  },
  {
    field: "poletoppeddate",
    filtering: true,
    sortField: "poletoppeddate",
    headerName: "Pole Topped Date",
    filterField: `${card}poletoppeddate`,
  },
  {
    field: "prime_esh_program",
    filtering: true,
    sortField: "prime_esh_program",
    headerName: "Prime Esh Program",
    filterField: `${card}prime_esh_program`,
  },
  {
    field: "program_names",
    filtering: true,
    sortField: "program_names",
    headerName: "Program Names",
    filterField: `${card}program_names`,
  },
  {
    field: "psps_end",
    filtering: true,
    sortField: "psps_end",
    headerName: "Psps End",
    filterField: `${card}psps_end`,
  },
  {
    field: "psps_start",
    filtering: true,
    sortField: "psps_start",
    headerName: "Psps Start",
    filterField: `${card}psps_start`,
  },
  {
    field: "reliability_budget",
    filtering: true,
    sortField: "reliability_budget",
    headerName: "Reliability Budget",
    filterField: `${card}reliability_budget`,
  },
  {
    field: "reliability_ifc_actual",
    filtering: true,
    sortField: "reliability_ifc_actualt",
    headerName: "Reliability Ifc Actual",
    filterField: `${card}reliability_ifc_actual`,
  },
  {
    field: "reliability_mfb",
    filtering: true,
    sortField: "reliability_mfb",
    headerName: "Reliability Mfb",
    filterField: `${card}reliability_mfb`,
  },
  {
    field: "req_end_date",
    filtering: true,
    sortField: "req_end_date",
    headerName: "Req End Date",
    filterField: `${card}req_end_date`,
  },
  {
    field: "req_start_date",
    filtering: true,
    sortField: "req_start_date",
    headerName: "Req Start Date",
    filterField: `${card}req_start_date`,
  },
  {
    field: "status",
    filtering: true,
    sortField: "status",
    headerName: "Status",
    filterField: `${card}status`,
  },
  {
    field: "ted_ifc_actual",
    filtering: true,
    sortField: "ted_ifc_actual",
    headerName: "Ted Ifc Actual",
    filterField: `${card}ted_ifc_actual`,
  },
  {
    field: "wise_polescope",
    filtering: true,
    sortField: "wise_polescope",
    headerName: "Wise Polescope",
    filterField: `${card}wise_polescope`,
  },
  {
    field: "work_order_number",
    filtering: true,
    sortField: "work_order_number",
    headerName: "Work Order Number",
    filterField: `${card}work_order_number`,
  },
];
export default overlapping("overlapping.");
