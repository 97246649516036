import * as yup from "yup";
export const latitudeRegExp = /^[A-Za-z\d@$!%*?&_=()+|{}":';.,/`#^-]*$/;
export const longitudeRegExp = /^[A-Za-z\d@$!%*?&_=()+|{}":';.,/`#^-]*$/;

const editIssueValidationSchema = yup.object().shape({
  latitude: yup.string().matches(latitudeRegExp, "The latitude is not valid"),
  longitude: yup
    .string()
    .matches(longitudeRegExp, "The longitude is not valid"),
  error: yup.boolean().oneOf([false], "Identified Date have to be earlier"),
});

export default editIssueValidationSchema;
