import { StyledPropsWithTheme } from "../../utils/globalTypes";
import { Box } from "@material-ui/core";
import styled from "@emotion/styled";

const MainTableWrapper = styled(Box)<StyledPropsWithTheme>`
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  transition: all 0.3s ease 0s;
`;

const OverlappingMainTable = styled(Box)<StyledPropsWithTheme>`
  height: 50vh;
`;

const CustomerMainTable = styled(Box)<StyledPropsWithTheme<{ isMap: boolean }>>`
  height: ${({ isMap }) => (isMap ? "40vh" : "45vh")};
`;

const IssueMainTable = styled(Box)<StyledPropsWithTheme<{ isTab: boolean }>>`
  height: ${({ isTab }) => (isTab ? "40vh" : "45vh")};
`;

const ConstructionMainTable = styled(Box)<
  StyledPropsWithTheme<{ isMap: boolean }>
>`
  height: ${({ isMap }) => (isMap ? "40vh" : "45vh")};
`;

const MainTableHeader = styled(Box)`
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
`;

const MainTableTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const TitleLeftSection = styled(Box)`
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TableName = styled(Box)<StyledPropsWithTheme>`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

const TitleOptions = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MainTableOptions = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const OptionsLeft = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: 10px;
    margin-bottom: 8px;
  }
`;

const OptionsRight = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: 10px;
    margin-bottom: 8px;
  }
`;

const MainTableBottom = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 7px 16px 16px 16px;
  color: ${({ theme }) => theme.palette.tables?.header?.default};
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

const ZoomButtons = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  align-items: center;
  justify-content: space-between;
`;

export const Styled = {
  MainTableWrapper,
  OverlappingMainTable,
  MainTableHeader,
  MainTableTitle,
  MainTableOptions,
  TitleLeftSection,
  TableName,
  TitleOptions,
  OptionsLeft,
  OptionsRight,
  MainTableBottom,
  ZoomButtons,
  CustomerMainTable,
  IssueMainTable,
  ConstructionMainTable,
};
