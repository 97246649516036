import { FC } from "react";
import { Styled } from "./NotificationItems.styled";
import { DotIcon, ArrowRight } from "../../../../icons";
import { useDispatch } from "react-redux";
import {
  handleChangeDrawerOptions,
  handleRouterChange,
  handleSetNotification,
} from "@sdge-store/drawer/drawer.slice";
import { useAppSelector } from "../../../../app/hooks";
import { parseDateString } from "@sdge-utils/dateParser";
import WithLoading from "@sdge-components/WithLoading/LoadingWrapper";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { userNotifications } from "./NotificationItems.constants";
import { Box, Typography } from "@material-ui/core";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";

const UserNotification: FC<{ isUser?: boolean; isAll?: boolean }> = ({
  isUser,
  isAll,
}) => {
  const dispatch = useDispatch();
  const {
    router,
    notificationsData: { notifications, notificationLoading },
  } = useAppSelector(selectRightDrawer);

  const filteredUserNotifications = notifications.filter((item) =>
    userNotifications.includes(item?.eventType)
  );
  const filteredPoleNotifications = notifications.filter(
    (item) => !userNotifications.includes(item?.eventType)
  );

  const filteredNotifications = isUser
    ? filteredUserNotifications
    : filteredPoleNotifications;

  const handlerItemClick = (item: any) => {
    const drawerRouterOptions = {
      parentPath: RightDrawerParentPaths.NOTIFICATION_INFO,
      backArrow: true,
      prevPath: router.prevPath ?? "/",
      newPath: "overlapping",
      subPath: "acknowledgements",
    };
    const drawerOptions = {
      title: item.poleId,
      cardId: item.poleId,
    };
    dispatch(handleRouterChange(drawerRouterOptions));
    dispatch(handleChangeDrawerOptions(drawerOptions));
    dispatch(handleSetNotification(item));
  };
  return (
    <WithLoading loading={notificationLoading}>
      {filteredNotifications?.length ? (
        <Styled.NotificationItemsLayout>
          {filteredNotifications?.map((item: any, index) => {
            const {
              eventType,
              systemName,
              status,
              changeTime,
              poleId,
              acknowledged,
              notificationId,
            } = item;

            return (
              <Styled.NotificationItemWrapper
                onClick={() => handlerItemClick(item)}
                key={notificationId + index}
              >
                <Styled.Dot>{!acknowledged && <DotIcon />}</Styled.Dot>
                <Styled.Body>
                  <Styled.Row>
                    <Styled.Text weight={700}>{eventType}</Styled.Text>&nbsp;
                    <Styled.Text weight={400}>
                      {systemName && (systemName === "core" ? "in" : "with")}
                    </Styled.Text>
                    &nbsp;
                    <Styled.Text weight={700}>{systemName}</Styled.Text>
                  </Styled.Row>
                  <Styled.Row>
                    <Styled.Text>{status}</Styled.Text>
                    &nbsp;at&nbsp;
                    <Styled.Text>
                      {parseDateString(changeTime, "MM/DD/YYYY")}
                    </Styled.Text>
                  </Styled.Row>
                  <Styled.Text weight={600}>{poleId}</Styled.Text>
                </Styled.Body>
                <Styled.ArrowWrapper>
                  <ArrowRight />
                </Styled.ArrowWrapper>
              </Styled.NotificationItemWrapper>
            );
          })}
        </Styled.NotificationItemsLayout>
      ) : (
        (isAll && !notifications?.length) ||
        (!isAll && (
          <Box pt="100px">
            <Typography align="center">No notification</Typography>
          </Box>
        ))
      )}
    </WithLoading>
  );
};

export default UserNotification;
