const envPermits = (card: string) => [
  // {
  //   field: "date_submitted",
  //   filtering: true,
  //   sortField: "date_submitted",
  //   headerName: "Date Submitted",
  //   filterField: `${card}date_submitted`,
  // },
  // {
  //   field: "env_permit_time_frame",
  //   filtering: true,
  //   sortField: "env_permit_time_frame",
  //   headerName: "Env Permit Time Frame",
  //   filterField: `${card}env_permit_time_frame`,
  // },
  {
    field: "environmental_release_date_submitted",
    filtering: true,
    sortField: "environmental_release_date_submitted",
    headerName: "Environmental Release Date Submitted",
    filterField: `${card}environmental_release_date_submitted`,
  },
  {
    field: "environmental_release_issued_date",
    filtering: true,
    sortField: "environmental_release_issued_date",
    headerName: "Environmental Release Issued Date",
    filterField: `${card}environmental_release_issued_date`,
  },
  {
    field: "ets_#",
    filtering: true,
    sortField: "ets_#",
    headerName: "Ets #",
    filterField: `${card}ets_#`,
  },
  // {
  //   field: "environmental_release_needed",
  //   filtering: true,
  //   sortField: "environmental_release_needed",
  //   headerName: "Environmental Release Needed",
  //   filterField: `${card}environmental_release_needed`,
  // },
  {
    field: "facilityid",
    filtering: true,
    sortField: "facilityid",
    headerName: "Facility Id",
    filterField: `${card}facilityid`,
  },
  {
    field: "id",
    filtering: true,
    sortField: "id",
    headerName: "Id",
    filterField: `${card}id`,
  },
  {
    field: "issue_id",
    filtering: true,
    sortField: "issue_id",
    headerName: "Issue Id",
    filterField: `${card}issue_id`,
  },
  {
    field: "permit_id",
    filtering: true,
    sortField: "permit_id",
    headerName: "Permit Id",
    filterField: `${card}permit_id`,
  },
  {
    field: "quick_release",
    filtering: true,
    sortField: "quick_release",
    headerName: "Quick Release",
    filterField: `${card}quick_release`,
  },
  // {
  //   field: "release_expiration_date",
  //   filtering: true,
  //   sortField: "release_expiration_date",
  //   headerName: "Release Expiration Date",
  //   filterField: `${card}release_expiration_date`,
  // },
  {
    field: "special_requirements",
    filtering: true,
    sortField: "special_requirements",
    headerName: "Environmental Constraints",
    filterField: `${card}special_requirements`,
  },
];

export default envPermits("envPermitConstruction.");
