import { Tab } from "@sdge-components/TabControls/TabControls.types";

export const quickActionPermissionList: string[] = ["start_aggregation"];

const overlappingTabs: Tab[] = [
  {
    id: "Summary",
    label: "Summary",
    path: "/summary",
  },
  {
    id: "Map",
    label: "Map",
    path: "/map",
  },
];

const customerTabs: Tab[] = [
  {
    id: "Table",
    label: "Table",
    path: "/table",
  },
  {
    id: "Map",
    label: "Map",
    path: "/map",
  },
];

const issueTabs: Tab[] = [
  {
    id: "Table",
    label: "Table",
    path: "/table",
  },
  {
    id: "Map",
    label: "Map",
    path: "/map",
  },
  {
    id: "PackageSummary",
    label: "Package Summary",
    path: "/summary_package",
  },
  {
    id: "PoleSummary",
    label: "Pole Summary",
    path: "/summary_pole",
  },
];

export const tabs = {
  overlapping: overlappingTabs,
  flight_operations: customerTabs,
  issue_tracker: issueTabs,
  environmental: customerTabs,
  customers: customerTabs,
  construction: customerTabs,
  settings: customerTabs,
};

export const pageTitles = {
  overlapping: "Pole Infraction Overlaps with Other SDG&E Programs",
  flight_operations: "Flight Operation",
  issue_tracker: "Issue Tracker",
  environmental: "Environmental and Permits",
  customers: "Customer Outreach",
  construction: "Construction",
  settings: "Settings",
};

export const dummyUser = {
  fullName: "Elon Musk",
  email: "elonmusk@gmail.com",
  src: "",
};

export const drawerWidth = 279;
