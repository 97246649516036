import React, { useEffect, useRef, Fragment, useContext } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { getSessionExpiryTime } from "@sdge-hooks/useAuth/useAuth.utils";
import { PortalContext } from "../../layouts";
dayjs.extend(duration);

const FIVE_MINUTE_MILLISECONDS = 5 * 60000;

let sessionExpiryTime = 0;

const SessionTimeout = () => {
  const { setIsActive } = useContext(PortalContext);
  const sessionExpireTimeOut = useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  const storageChangeInterval = useRef<
    ReturnType<typeof setInterval> | undefined
  >();

  const setSessionExpiryTimeOut = () => {
    const sessionExpiry = getSessionExpiryTime();
    if (sessionExpireTimeOut.current) {
      clearTimeout(sessionExpireTimeOut.current);
    }
    if (sessionExpiry) {
      sessionExpireTimeOut.current = setTimeout(() => {
        if (+sessionExpiry - Date.now() < FIVE_MINUTE_MILLISECONDS) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      }, +sessionExpiry - FIVE_MINUTE_MILLISECONDS - Date.now());
    }
  };

  const handleClearTimeout = () => {
    sessionExpireTimeOut.current && clearTimeout(sessionExpireTimeOut.current);
  };

  const onStorageChange = () => {
    const sessionExpiry = getSessionExpiryTime();
    if (sessionExpiry && +sessionExpiry !== sessionExpiryTime) {
      if (sessionExpireTimeOut.current) {
        clearTimeout(sessionExpireTimeOut.current);
      }
      sessionExpiryTime = +sessionExpiry;
      setSessionExpiryTimeOut();
    }
  };

  useEffect(() => {
    const sessionExpiry = getSessionExpiryTime();
    if (sessionExpiry) {
      sessionExpiryTime = +sessionExpiry;
      setSessionExpiryTimeOut();
    }
    handleClearTimeout();
    return () => {
      handleClearTimeout();
    };
  }, []);

  useEffect(() => {
    storageChangeInterval.current = setInterval(() => {
      onStorageChange();
    }, 1000);
    return () => {
      storageChangeInterval.current &&
        clearInterval(storageChangeInterval.current);
      handleClearTimeout();
    };
  }, []);

  return <Fragment />;
};

export default React.memo(SessionTimeout);
