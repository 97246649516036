import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@sdge-store/store";

export const selectIssueTracker = createSelector(
  (state: RootState) => ({
    issues: state.issueTrackerSlice.issues,
    loading: state.issueTrackerSlice.loading,
    page: state.issueTrackerSlice.page,
    totalElements: state.issueTrackerSlice.totalElements,
    loadedElements: state.issueTrackerSlice.loadedElements,
    sortingRules: state.issueTrackerSlice.sortingRules,
    sort: state.issueTrackerSlice.sort,
    totalPages: state.issueTrackerSlice.totalPages,
    isLastPage: state.issueTrackerSlice.isLast,
    isPaginated: state.issueTrackerSlice.isPaginated,
    columnDefs: state.issueTrackerSlice.columnDefs,
    currentFilter: state.issueTrackerSlice.filter,
    filteringRules: state.issueTrackerSlice.filteringRules,
    areSortOptionsOpen: state.issueTrackerSlice.areSortOptionsOpen,
    areFilterOptionsOpen: state.issueTrackerSlice.areFilterOptionsOpen,
    areHiddenFieldsOpen: state.issueTrackerSlice.areHiddenFieldsOpen,
    searchInputValue: state.issueTrackerSlice.searchInputValue,
    areQuickVeiwsOptionsOpen: state.issueTrackerSlice.areQuickVeiwsOptionsOpen,
    hiddenColumns: state.issueTrackerSlice.hiddenColumns,
    columnOrder: state.issueTrackerSlice.columnOrder,
    quickViews: state.issueTrackerSlice.quickViews,
    isQuickVeiwsModalOpen: state.issueTrackerSlice.isQuickVeiwsModalOpen,
    selectedRows: state.issueTrackerSlice.selectedRows,
    selecedRowsIssueIds: state.issueTrackerSlice.selecedRowsIssueIds,
    areMassUpdateOptionsOpen: state.issueTrackerSlice.areMassUpdateOptionsOpen,
    massUpdateItems: state.issueTrackerSlice.massUpdateItems,
    newIssue: state.issueTrackerSlice.newIssue,
    massUpdateItemsRequest: state.issueTrackerSlice.massUpdateItemsRequest,
    frozenColumns: state.issueTrackerSlice.frozenColumns,
    initialColumnDefs: state.issueTrackerSlice.initialColumnDefs,
    initialColumnsOrder: state.issueTrackerSlice.initialColumnOrder,
    selectedQuickVewName: state.issueTrackerSlice.selectedQuickVewName,
    selectedQuickViewPrivacy: state.issueTrackerSlice.selectedQuickViewPrivacy,
    selectedQuickViewId: state.issueTrackerSlice.selectedQuickViewId,
    inputText: state.issueTrackerSlice.inputText,
    massUpdateLoading: state.issueTrackerSlice.massUpdateLoading,
    bmcdTierVisibility: state.issueTrackerSlice.bmcdTierVisibility,
  }),
  (state) => state
);
