import { useEffect, useRef, useState } from "react";
import { CallBackType } from "@sdge-hooks/useDebounce/useDebounce.types";

const useDebounce = <P, R = void>(timeOut = 1000) => {
  const [value, setValue] = useState(0);
  const callBackRef = useRef<CallBackType<P, R> | null>(null);
  const interval = useRef<any>(null);
  useEffect(() => {
    clearTimeout(interval.current);
    interval.current = setTimeout(() => {
      if (callBackRef.current) callBackRef.current();
    }, timeOut);
    return () => clearTimeout(interval.current);
  }, [value]);

  const debounce = (callback: CallBackType<P, R>) => {
    setValue((state) => state + 1);
    callBackRef.current = callback;
  };
  return { debounce };
};

export default useDebounce;
