import { createSlice } from "@reduxjs/toolkit";
import { Constants } from "./constants/issueTracker.constants";
import { IssueSliceState } from "./issueTracker.types";
import {
  addIssuesTrackerQuickView,
  CreateIssue,
  UpdateManualIssue,
  createIssueCustomer,
  deleteEnvPermit,
  deleteMuniPermit,
  deleteQuickView,
  fetchIssueData,
  fetchIssuesTrackerQuickViews,
  filterIssueData,
  getEnvPermits,
  getIssueTrackerCustomersList,
  getIssueTrackerOverlapping,
  getMuniPermits,
  getPackageSummary,
  getPermits,
  getPoleSummary,
  getSapTileContent,
  getTilesInfo,
  sortIssueData,
  updateCustomers,
  updateEnvPermits,
  updateFilteredRows,
  updateIssuesTrackerQuickView,
  updateMuniPermits,
  updatePermits,
  updateSap,
  updateSelectedRows,
} from "./issueTracker.async";
import {
  getHidddenColumns,
  getPinnedColumns,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";

const initialIssueState: IssueSliceState = {
  issues: [],
  columnDefs: Constants.columnDefs,
  initialColumnDefs: Constants.columnDefs,
  areQuickVeiwsOptionsOpen: false,
  loading: false,
  page: 0,
  massUpdateItems: [],
  massUpdateItemsRequest: [],
  totalElements: 0,
  loadedElements: 0,
  totalPages: 1,
  isLast: false,
  isPaginated: true,
  isLoading: true,
  createCustomerLoading: false,
  createIssueLoading: false,
  updateIssueLoading: false,
  selectedRows: [],
  selecedRowsIssueIds: [],
  areHiddenFieldsOpen: false,
  areSortOptionsOpen: false,
  areFilterOptionsOpen: false,
  selectedQuickVewName: "",
  sortingRules: "",
  sort: [],
  filteringRules: [],
  filter: [],
  searchInputValue: "",
  programs: [],
  pole: null,
  permit: null,
  environmental: null,
  columnOrder: [],
  initialColumnOrder: Constants.columnsOrder,
  hiddenColumns: [],
  frozenColumns: [],
  quickViews: [],
  isQuickVeiwsModalOpen: false,
  issueTrackerCustomersList: [],
  issueTrackerOverlapping: [],
  sapTileContent: [],
  areMassUpdateOptionsOpen: false,
  newIssue: null,
  requested: false,
  permits: [],
  activeIssueId: "",
  activeIssuefacilityId: "",
  activeIssueGroupName: "",
  sapIsUpdated: false,
  buttonDisabled: false,
  selectedQuickViewPrivacy: false,
  selectedQuickViewId: "",
  poleSummary: null,
  packageSummary: null,
  envPermits: [],
  muniPermits: [],
  getPackageSumLoad: false,
  getPoleSumLoad: false,
  inputText: "",
  massUpdateLoading: false,
  cardsLoading: false,
  bmcdTierVisibility: false,
};

const issueSlice = createSlice({
  name: "issueTracker",
  initialState: initialIssueState,
  reducers: {
    handleToggleBMCD: (state, action) => {
      state.bmcdTierVisibility = action.payload;
    },
    handleColumnDefs: (state, action) => {
      state.columnDefs = action.payload;
    },
    handleChangeRequested: (state, action) => {
      state.requested = action.payload;
    },
    handleColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    handlePrivacy: (state, action) => {
      state.selectedQuickViewPrivacy = action.payload;
    },
    handleQuickViewId: (state, action) => {
      state.selectedQuickViewId = action.payload;
    },
    quickViewsOptions: (state, action) => {
      state.areQuickVeiwsOptionsOpen = action.payload;
    },
    massUpdateOptions: (state, action) => {
      state.areMassUpdateOptionsOpen = action.payload;
    },
    handleLoadedPagesChange: (state, action) => {
      const isDropToFirstPage = action?.payload?.isDropToFirstPage ?? false;

      if (isDropToFirstPage) {
        state.isPaginated = true;
        state.page = 0;
        return;
      }

      if (state.page < state.totalPages && state.isLoading) {
        state.isPaginated = true;
        state.page = state.page + 1;
      }
    },
    handleCurrentPage: (state, action) => {
      state.page = action.payload;
    },
    handleHiddenColumn: (state, action) => {
      const foundIndex = state.columnDefs.findIndex(
        (item) => item.field === action.payload
      );
      if (state.columnDefs[foundIndex].hide === true) {
        state.columnDefs[foundIndex].hide = false;
      } else {
        state.columnDefs[foundIndex].hide = true;
      }
      state.hiddenColumns = getHidddenColumns(state.columnDefs);
    },
    handlePinnedColumn: (state, action) => {
      const foundIndex = state.columnDefs.findIndex(
        (item) => item.field === action.payload
      );
      if (state.columnDefs[foundIndex].pinned === null) {
        state.columnDefs[foundIndex].pinned = "left";
      } else {
        state.columnDefs[foundIndex].pinned = null;
      }
      state.frozenColumns = getPinnedColumns(state.columnDefs);
    },

    handleSort: (state, action) => {
      state.sort = action.payload;
    },

    handleFilter: (state, action) => {
      if (action.payload.length === 1) {
        action.payload[0] = {
          ...action.payload[0],
          relation: "AND",
        };
      } else if (action.payload.length > 1) {
        action.payload[0] = {
          ...action.payload[0],
          relation: action.payload[1].relation,
        };
      }
      state.filter = action.payload;
    },
    handleFilteringRules: (state, action) => {
      state.filteringRules = action.payload;
    },

    fieldsHiddenOptions: (state, action) => {
      state.areHiddenFieldsOpen = action.payload;
    },

    sortOptions: (state, action) => {
      state.areSortOptionsOpen = action.payload;
    },

    filterOptions: (state, action) => {
      state.areFilterOptionsOpen = action.payload;
    },

    handleSearch: (state, action) => {
      state.searchInputValue = action.payload;
    },
    handleColumnChoosen: (state, action) => {
      state.quickViews = action.payload;
    },
    handleQuickViewsModal: (state, action) => {
      state.isQuickVeiwsModalOpen = action.payload;
    },
    handleQuickViewsParams: (state, action) => {
      state.columnDefs = action.payload.columnDefs;
      state.hiddenColumns = action.payload.hiddenColumns;
      state.frozenColumns = action.payload.frozenColumns;
      state.columnOrder = action.payload.columnOrder;
      state.sort = action.payload.sort;
      state.filter = action.payload.filter;
      state.selectedQuickVewName = action.payload.selectedName;
      state.filteringRules = action.payload.filteringRules;
    },
    handleCellEdit: (state, action) => {
      if (action.payload.oldValue !== action.payload.newValue) {
        const field = action.payload.colDef.field;
        const row = { ...action.payload.data };
        const index = action.payload.rowIndex;
        const issuesCopy = [...state.issues];
        row[field] = action.payload.newValue;
        issuesCopy[index] = row;
        state.issues = issuesCopy;
      }
    },
    handleSelectedRows: (state, action) => {
      if (action.payload.length < state.selectedRows.length) {
        state.selectedRows = action.payload;
      } else {
        state.selectedRows = [
          ...(new Set([...state.selectedRows, ...action.payload]) as any),
        ];
      }
    },
    handleSelecedRowsIssueIds: (state, action) => {
      if (action.payload.length < state.selecedRowsIssueIds.length) {
        state.selecedRowsIssueIds = action.payload;
      } else {
        state.selecedRowsIssueIds = [
          ...(new Set([
            ...state.selecedRowsIssueIds,
            ...action.payload,
          ]) as any),
        ];
      }
    },
    handleMassUpdateItems: (state, action) => {
      state.massUpdateItems = action.payload;
    },
    setActiveIssueId: (state, action) => {
      state.activeIssueId = action.payload.id;
      state.activeIssuefacilityId = action.payload.facilityId;
      state.activeIssueGroupName = action.payload.groupName;
    },
    handleSapUpdate: (state, action) => {
      state.sapIsUpdated = action.payload;
    },
    handleInputText: (state, action) => {
      state.inputText = action.payload;
    },
    handleClearIssuesGrid: (state, action) => {
      if (action.payload !== "/issue_tracker") {
        state.issues = [];
      }
    },
  },
  extraReducers: {
    [fetchIssueData.fulfilled]: (state: IssueSliceState, action: any) => {
      if (action.payload) {
        if (state.page === 0) {
          state.issues = action.payload.content;
          state.loadedElements = action.payload.elementsOnPage;
          state.selecedRowsIssueIds = [];
          state.selectedRows = [];
        } else {
          if (action.payload.isQuickViews) {
            state.issues = action.payload.content;
            state.loadedElements = action.payload.elementsOnPage;
            state.page = 0;
          } else {
            state.issues = [...state.issues, ...action.payload.content];
            state.loadedElements =
              state.loadedElements + action.payload.elementsOnPage;
          }
        }
        state.sortingRules = action.payload.sortingRules;
        state.totalElements = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
      }
      state.isLoading = true;
    },
    [fetchIssueData.pending]: (state) => {
      state.isLoading = false;
    },
    [sortIssueData.fulfilled]: (state: IssueSliceState, action: any) => {
      if (action.payload) {
        state.issues = action.payload.content;
        state.loadedElements = action.payload.elementsOnPage;
        state.totalElements = action.payload.totalElements;
        state.page = action.payload.pageNumber;
        state.sortingRules = action.payload.sortingRules;
        state.totalPages = action.payload.totalPages;
        state.issueTrackerCustomersList = [];
        state.sapTileContent = [];
        state.muniPermits = [];
        state.envPermits = [];
        state.issueTrackerOverlapping = [];
      }
      state.loading = false;
      state.isPaginated = false;
    },
    [sortIssueData.pending]: (state: IssueSliceState) => {
      state.loading = true;
    },
    [sortIssueData.rejected]: (state: IssueSliceState) => {
      state.loading = false;
    },
    [filterIssueData.fulfilled]: (state: IssueSliceState, action: any) => {
      if (action.payload) {
        state.issues = action.payload.content;
        state.loadedElements = action.payload.elementsOnPage;
        state.sortingRules = action.payload.sortingRules;
        state.page = action.payload.pageNumber;
        state.totalElements = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
        state.issueTrackerCustomersList = [];
        state.sapTileContent = [];
        state.muniPermits = [];
        state.envPermits = [];
        state.issueTrackerOverlapping = [];
      }
      state.loading = false;
      state.isPaginated = false;
    },
    [filterIssueData.pending]: (state: IssueSliceState) => {
      state.loading = true;
    },
    [filterIssueData.rejected]: (state: IssueSliceState) => {
      state.loading = false;
    },
    [getTilesInfo.fulfilled]: (state: IssueSliceState, action: any) => {
      if (action.payload) {
        state.environmental = action.payload.environmental;
        state.permit = action.payload.permit;
        state.pole = action.payload.pole;
        state.programs = action.payload.programs;
      }
    },
    [fetchIssuesTrackerQuickViews.fulfilled]: (
      state: IssueSliceState,
      action: any
    ) => {
      state.quickViews = action.payload.map((item: any) => {
        return { ...item, selected: false };
      });
      state.loading = false;
    },
    [fetchIssuesTrackerQuickViews.pending]: (state: IssueSliceState) => {
      state.loading = true;
    },
    [addIssuesTrackerQuickView.fulfilled]: (
      state: IssueSliceState,
      action: any
    ) => {
      if (action.payload) {
        state.quickViews = [...state.quickViews, action.payload].sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
      }
    },
    [updateIssuesTrackerQuickView.fulfilled]: (
      state: IssueSliceState,
      action: any
    ) => {
      if (action.payload) {
        const newquickViews = state.quickViews.map((item) => {
          if (item) {
            if (item.name === action.payload.name) {
              return { ...action.payload, selected: true };
            } else {
              return item;
            }
          }
        });
        state.quickViews = newquickViews;
      }
    },
    [getIssueTrackerCustomersList.fulfilled]: (
      state: IssueSliceState,
      action: any
    ) => {
      state.issueTrackerCustomersList = action.payload;
      state.cardsLoading = false;
    },
    [getIssueTrackerCustomersList.pending]: (state: IssueSliceState, _) => {
      state.cardsLoading = true;
    },
    [getIssueTrackerCustomersList.rejected]: (state: IssueSliceState, _) => {
      state.cardsLoading = false;
    },
    [getIssueTrackerOverlapping.fulfilled]: (
      state: IssueSliceState,
      action: any
    ) => {
      state.issueTrackerOverlapping = action.payload;
      state.cardsLoading = false;
    },
    [getIssueTrackerOverlapping.pending]: (state: IssueSliceState, _) => {
      state.cardsLoading = true;
    },
    [getIssueTrackerOverlapping.rejected]: (state: IssueSliceState, _) => {
      state.cardsLoading = false;
    },
    [getSapTileContent.fulfilled]: (state: IssueSliceState, action: any) => {
      state.sapTileContent = [action.payload];
      state.cardsLoading = false;
    },
    [getSapTileContent.pending]: (state: IssueSliceState, _) => {
      state.cardsLoading = true;
    },
    [getSapTileContent.rejected]: (state: IssueSliceState, _) => {
      state.cardsLoading = false;
    },
    [getEnvPermits.fulfilled]: (state: IssueSliceState, action: any) => {
      state.envPermits = action.payload;
      state.cardsLoading = false;
    },
    [getEnvPermits.pending]: (state: IssueSliceState, _) => {
      state.cardsLoading = true;
    },
    [getEnvPermits.rejected]: (state: IssueSliceState, _) => {
      state.cardsLoading = false;
    },
    [getMuniPermits.fulfilled]: (state: IssueSliceState, action: any) => {
      const isIncludeActiveIssue = action.payload
        .map((item: any) => item.issueId)
        .includes(state.activeIssueId);
      if (isIncludeActiveIssue) {
        state.muniPermits = action.payload;
      } else {
        state.muniPermits = [];
      }
      state.cardsLoading = false;
    },
    [getMuniPermits.pending]: (state: IssueSliceState, _) => {
      state.cardsLoading = true;
    },
    [getMuniPermits.rejected]: (state: IssueSliceState, _) => {
      state.cardsLoading = false;
    },
    [getPackageSummary.fulfilled]: (state: IssueSliceState, action: any) => {
      state.packageSummary = action.payload;
      state.getPackageSumLoad = false;
    },
    [getPackageSummary.pending]: (state: IssueSliceState, _) => {
      state.getPackageSumLoad = true;
    },
    [getPackageSummary.rejected]: (state: IssueSliceState, _) => {
      state.getPackageSumLoad = false;
    },
    [getPoleSummary.fulfilled]: (state: IssueSliceState, action: any) => {
      state.poleSummary = action.payload;
      state.getPoleSumLoad = false;
    },
    [getPoleSummary.pending]: (state: IssueSliceState, _) => {
      state.getPoleSumLoad = true;
    },
    [getPoleSummary.rejected]: (state: IssueSliceState, _) => {
      state.getPoleSumLoad = false;
    },
    [CreateIssue.fulfilled]: (state: IssueSliceState, action: any) => {
      state.newIssue = action.payload;
    },
    [UpdateManualIssue.pending]: (state: IssueSliceState, _) => {
      state.updateIssueLoading = true;
    },
    [UpdateManualIssue.rejected]: (state: IssueSliceState, _) => {
      state.updateIssueLoading = false;
    },
    [UpdateManualIssue.fulfilled]: (state: IssueSliceState, _) => {
      state.updateIssueLoading = false;
    },
    [getPermits.fulfilled]: (state: IssueSliceState, action: any) => {
      state.permits = [action.payload];
    },
    [updateSap.fulfilled]: (state: IssueSliceState, _) => {
      state.sapIsUpdated = true;
      state.createIssueLoading = false;
    },
    [updateSap.rejected]: (state: IssueSliceState, _) => {
      state.sapIsUpdated = true;
      state.createIssueLoading = false;
    },
    [updateSap.pending]: (state: IssueSliceState, _) => {
      state.sapIsUpdated = true;
      state.createIssueLoading = true;
    },
    [updatePermits.pending]: (state: IssueSliceState, _) => {
      state.sapIsUpdated = true;
      state.createIssueLoading = true;
    },
    [updatePermits.rejected]: (state: IssueSliceState, _) => {
      state.sapIsUpdated = true;
      state.createIssueLoading = false;
    },
    [updatePermits.fulfilled]: (state: IssueSliceState, _) => {
      state.sapIsUpdated = true;
      state.createIssueLoading = false;
    },
    [createIssueCustomer.fulfilled]: (state: IssueSliceState, _) => {
      state.createCustomerLoading = false;
    },
    [createIssueCustomer.pending]: (state: IssueSliceState, _) => {
      state.createCustomerLoading = true;
    },
    [createIssueCustomer.rejected]: (state: IssueSliceState, _) => {
      state.createCustomerLoading = false;
    },
    [CreateIssue.fulfilled]: (state: IssueSliceState) => {
      state.buttonDisabled = false;
      state.createIssueLoading = false;
    },
    [CreateIssue.pending]: (state: IssueSliceState) => {
      state.buttonDisabled = true;
      state.createIssueLoading = true;
    },
    [CreateIssue.rejected]: (state: IssueSliceState) => {
      state.buttonDisabled = false;
      state.createIssueLoading = false;
    },
    [updateSelectedRows.pending]: (state: IssueSliceState) => {
      // state.loading = true;
      state.massUpdateLoading = true;
      state.createIssueLoading = true;
    },
    [updateSelectedRows.fulfilled]: (state: IssueSliceState, action: any) => {
      state.massUpdateLoading = false;
      state.selectedRows = [];
      state.selecedRowsIssueIds = [];
      state.massUpdateItemsRequest = action.payload;
      state.createIssueLoading = false;
      state.areMassUpdateOptionsOpen = false;
    },
    [updateFilteredRows.pending]: (state: IssueSliceState) => {
      // state.loading = true;
      state.massUpdateLoading = true;
      state.createIssueLoading = true;
    },
    [updateFilteredRows.fulfilled]: (state: IssueSliceState, action: any) => {
      // state.loading = false;
      state.massUpdateLoading = false;
      state.selectedRows = [];
      state.selecedRowsIssueIds = [];
      state.massUpdateItemsRequest = action.payload;
      state.createIssueLoading = false;
      state.areMassUpdateOptionsOpen = false;
    },
    [deleteQuickView.fulfilled]: (state: IssueSliceState, action: any) => {
      state.quickViews = state.quickViews
        .filter((item) => item.id !== action.payload)
        .filter((item) => {
          if (item.name === state.selectedQuickVewName) {
            return { ...item, selected: true };
          } else {
            return { item };
          }
        });
    },
    // update Env Permits handling
    [updateEnvPermits.pending]: (state: IssueSliceState) => {
      state.buttonDisabled = true;
      state.createIssueLoading = true;
    },
    [updateEnvPermits.fulfilled]: (state: IssueSliceState) => {
      state.buttonDisabled = false;
      state.createIssueLoading = false;
    },
    [updateEnvPermits.rejected]: (state: IssueSliceState) => {
      state.buttonDisabled = false;
      state.createIssueLoading = false;
    },

    //update Muni Permits handling
    [updateMuniPermits.pending]: (state: IssueSliceState) => {
      state.buttonDisabled = true;
      state.createIssueLoading = true;
    },
    [updateMuniPermits.fulfilled]: (state: IssueSliceState) => {
      state.buttonDisabled = false;
      state.createIssueLoading = false;
    },
    [updateMuniPermits.rejected]: (state: IssueSliceState) => {
      state.buttonDisabled = false;
      state.createIssueLoading = false;
    },
    [deleteMuniPermit.pending]: (state: IssueSliceState) => {
      state.createIssueLoading = true;
    },
    [deleteMuniPermit.rejected]: (state: IssueSliceState) => {
      state.createIssueLoading = false;
    },
    [deleteMuniPermit.fulfilled]: (state: IssueSliceState) => {
      state.createIssueLoading = false;
    },
    [deleteEnvPermit.pending]: (state: IssueSliceState) => {
      state.createIssueLoading = true;
    },
    [deleteEnvPermit.rejected]: (state: IssueSliceState) => {
      state.createIssueLoading = false;
    },
    [deleteEnvPermit.fulfilled]: (state: IssueSliceState) => {
      state.createIssueLoading = false;
    },
    //update Customers card handling
    [updateCustomers.pending]: (state: IssueSliceState) => {
      state.buttonDisabled = true;
      state.createIssueLoading = true;
    },
    [updateCustomers.fulfilled]: (state: IssueSliceState) => {
      state.buttonDisabled = false;
      state.createIssueLoading = false;
    },
    [updateCustomers.rejected]: (state: IssueSliceState) => {
      state.buttonDisabled = false;
      state.createIssueLoading = false;
    },
  },
});

export const {
  handleLoadedPagesChange,
  handleCurrentPage,
  handleSort,
  handleFilter,
  handleFilteringRules,
  fieldsHiddenOptions,
  sortOptions,
  handleHiddenColumn,
  handlePinnedColumn,
  filterOptions,
  handleSearch,
  quickViewsOptions,
  handleColumnOrder,
  handleQuickViewsModal,
  handleColumnChoosen,
  handleQuickViewsParams,
  handleCellEdit,
  handleColumnDefs,
  handleSelectedRows,
  massUpdateOptions,
  handleMassUpdateItems,
  handleChangeRequested,
  setActiveIssueId,
  handleSapUpdate,
  handleSelecedRowsIssueIds,
  handlePrivacy,
  handleQuickViewId,
  handleInputText,
  handleClearIssuesGrid,
  handleToggleBMCD,
} = issueSlice.actions;

export default issueSlice.reducer;
