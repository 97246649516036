import React, { FC } from "react";
import { Styled } from "./Link.styled";

const Link: FC<{ link: string; name: string }> = ({ link, name }) => {
  return (
    <Styled.Link target="_blank" href={link}>
      {name}
    </Styled.Link>
  );
};

export default Link;
