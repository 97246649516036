import styled from "@emotion/styled";
import { Search } from "react-feather";
import { StyledPropsWithTheme } from "../../utils/globalTypes";

const SearchLogo = styled(Search)<StyledPropsWithTheme>`
  height: 21px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Styled = { SearchLogo };
