import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const ExtendingButtonWrapper = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  flex-direction: column;
`;

const ListWrapper = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const ButtonWrapper = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  align-items: center;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const IconWrapper = styled(Box)<StyledPropsWithTheme>`
  margin-left: 2px;
  display: flex;
  align-items: center;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  tr,
  th {
    border: 0.5px solid #dddddd;
    text-align: left;
    padding: 1px;
    line-height: 30px;
  }
`;

export const Styled = {
  ExtendingButtonWrapper,
  ListWrapper,
  ButtonWrapper,
  IconWrapper,
  Table,
};
