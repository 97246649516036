import { Styled } from "./BellButton.styled";
import BellIcon from "../../icons/BellIcon";
import { FC } from "react";
import { BellButtonTypes } from "@sdge-components/BellButton/BellButton.types";

const BellButton: FC<BellButtonTypes> = ({ count, onClick }) => {
  return (
    <Styled.BadgeWrapper
      badgeContent={count}
      color="secondary"
      onClick={onClick}
      id="bellButton"
    >
      <BellIcon />
    </Styled.BadgeWrapper>
  );
};

export default BellButton;
