import React, { FC } from "react";
import { Styled } from "./ExtendingButton.styled";

const ButtonTable: FC<{ rowData: any }> = ({ rowData }) => {
  return (
    <Styled.Table>
      <tr>
        <th>Facility Id</th>
        <th>Cross check</th>
        <th>Complexity Calc</th>
      </tr>
      {rowData.map((item: any, index: any) => (
        <tr key={index}>
          <th>{item.facilityId}</th>
          <th>{item.crossCheck.join("")}</th>
          <th>{item.complexityCalc.join("")}</th>
        </tr>
      ))}
    </Styled.Table>
  );
};

export default ButtonTable;
