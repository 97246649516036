import {
  filterConstructionData,
  sortConstructionData,
} from "@sdge-store/construction/construction.async";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import {
  handleFilter,
  handleFilteringRules,
} from "@sdge-store/construction/construction.slice";
import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FilterOptionsItem } from "./components";
import { AddIcon } from "../../icons";

import { Styled } from "./FilterOptionsMenu.styled";
import { generateFilterArr } from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import { Constants } from "@sdge-store/construction/constants/construction.constants";

const ConstructionFilterOptionsMenu: FC = () => {
  const [error, setError] = useState(false);
  const { columnDefs, currentFilter, sortingRules, filteringRules } =
    useAppSelector(selectConstruction);
  useEffect(() => {
    if (
      currentFilter.some(
        (item) => item.errorMessage && item.operation !== "empty"
      )
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [currentFilter]);
  const dispatch = useAppDispatch();
  const listEnd = useRef<any>();

  const filterigColumnDefs = [
    ...columnDefs,
    ...Constants.additionalConstructionColumnDefs,
  ];

  const scrollToBottom = () => {
    listEnd.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [currentFilter.length]);

  const validColumns = filterigColumnDefs
    .filter(({ field }) => field)
    .filter(({ filtering }) => filtering);

  const applyFilterHandler = () => {
    if (currentFilter.length > 0) {
      const trimmedCurrentFilter = currentFilter.map((item) => {
        return {
          ...item,
          value: item.operation !== "empty" ? item.value.trim() : "",
        };
      });
      dispatch(filterConstructionData([0, sortingRules, trimmedCurrentFilter]));
      dispatch(handleFilteringRules(trimmedCurrentFilter));
    }
  };

  return (
    <Styled.FilterOptionsWrapper
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Styled.FilterOptionsList>
        {currentFilter.map((item, index) => (
          <FilterOptionsItem
            key={index}
            field={item.fieldName}
            index={index}
            relation={item.relation}
            negate={item.negate}
            operation={item.operation}
            columnDefs={Constants.filteringOptions}
            currentFilter={currentFilter}
            filteringRules={filteringRules}
            sortingRules={sortingRules}
            handleFilter={handleFilter}
            filterData={filterConstructionData}
            sortData={sortConstructionData}
            handleFilteringRules={handleFilteringRules}
            advancedOptions
          />
        ))}
        <Styled.ListBottom ref={listEnd}></Styled.ListBottom>
      </Styled.FilterOptionsList>
      <Styled.AddFilterWrapper>
        <Styled.FilterButton
          icon={AddIcon}
          title="Add filter"
          onClick={() =>
            dispatch(
              handleFilter(
                generateFilterArr(
                  currentFilter,
                  validColumns[0].filterField || validColumns[0].field,
                  currentFilter
                )
              )
            )
          }
        />
        {currentFilter.length > 0 && (
          <>
            <Styled.FilterButton
              title="Apply filter"
              isActive
              onClick={applyFilterHandler}
              disabled={error}
            />
            <Styled.DecorateButton
              onClick={(e) => {
                e.preventDefault();
                applyFilterHandler();
              }}
              type="submit"
            />
          </>
        )}
      </Styled.AddFilterWrapper>
    </Styled.FilterOptionsWrapper>
  );
};

export default ConstructionFilterOptionsMenu;
