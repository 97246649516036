import React, { Component } from "react";
import { Styled } from "./ErrorBoundary.styled";

export default class ErrorBoundary extends Component<
  any,
  { hasError: boolean; message: string }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, message: "" };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Styled.ErrorBoundaryWrapper>
          Oops. That is not good, error was occured
        </Styled.ErrorBoundaryWrapper>
      );
    }
    return this.props.children;
  }
}
