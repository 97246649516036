import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { ListItemIcon } from "@material-ui/core";
import { Styled } from "./MenuItem.styled";
import { MenuItemType } from "./MenuItem.types";
import { useAppDispatch } from "../../app/hooks";
import { handleRightDrawerClose } from "@sdge-store/drawer/drawer.slice";
import { handleClearConstructionGrid } from "@sdge-store/construction/construction.slice";
import { handleClearCustomersGrid } from "@sdge-store/customers/customers.slice";
import { handleClearOverlappingGrid } from "@sdge-store/overlaps/overlaps.slice";
import { handleClearIssuesGrid } from "@sdge-store/issueTracker/issueTracker.slice";

const MenuItem: FC<MenuItemType> = ({ path, text, icon: Icon }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleItemClick = () => {
    dispatch(handleRightDrawerClose());
    dispatch(handleClearConstructionGrid(path));
    dispatch(handleClearCustomersGrid(path));
    dispatch(handleClearOverlappingGrid(path));
    dispatch(handleClearIssuesGrid(path));
    history.push(path);
  };
  return (
    <Styled.ListItemWrapper
      isActive={history.location.pathname.includes(path)}
      role="list-item"
      key={text}
      button
      onClick={handleItemClick}
    >
      <ListItemIcon>
        <Icon path={path} />
      </ListItemIcon>
      <Styled.ListItemText>{text}</Styled.ListItemText>
    </Styled.ListItemWrapper>
  );
};

export default MenuItem;
