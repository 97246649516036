import { setTimeOuts, setToken } from "@sdge-hooks/useAuth/useAuth.utils";
import { refreshProperty } from "@sdge-utils/login.property";
import axios from "axios";

const refreshLoginPropertyData = refreshProperty();

const isTokenExpired = () => {
  const expiryTime = localStorage.getItem("expiry_time");
  const refresh_token = localStorage.getItem("refresh_token");
  const now = new Date();
  if (refresh_token && Number(expiryTime) - now.getTime() < 45 * 60000) {
    return true;
  }
};

const countExpiryTime = (expires_in: number) => {
  const expiryTime = Date.now() + expires_in * 1000;
  return String(expiryTime);
};

const refreshLogin = async () => {
  const params = new URLSearchParams();
  params.append("grant_type", refreshLoginPropertyData.grant_type);
  params.append("client_id", refreshLoginPropertyData.client_id);
  params.append("refresh_token", refreshLoginPropertyData.refresh_token);
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  try {
    const { data }: any = await axios.post(
      refreshLoginPropertyData.get_token_url,
      params,
      config
    );
    const expiryTime = countExpiryTime(data.expires_in);

    const tokens = {
      access_token: data.access_token,
      id_token: data.id_token,
      refresh_token: refreshLoginPropertyData.refresh_token,
    };
    const expiryTimes = {
      expiry_time: expiryTime,
    };

    setToken(tokens);
    setTimeOuts(expiryTimes);
  } catch (e) {}
};
export { isTokenExpired, countExpiryTime, refreshLogin };
