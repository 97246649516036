import styled from "@emotion/styled";
import { Tabs, Tab } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { TabsProps } from "@material-ui/core/Tabs/Tabs";

const TabControlsWrapper = styled(Tabs)<StyledPropsWithTheme<any>>`
  border-radius: 14px;
  background-color: ${({ theme }) =>
    theme.palette.tabControl.tabControlBackground[100]};
  box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
    inset 0px 2px 4px rgba(97, 97, 97, 0.2);
  &.MuiTabs-root {
    min-height: fit-content;
  }
  & .MuiTabs-indicator {
    display: none;
  }
  & .MuiTabs-flexContainer {
    padding: 4px;
  }
`;

const TabWrapper = styled(Tab)<StyledPropsWithTheme<TabsProps>>`
  &.MuiTab-root {
    padding: 4px 12px;
    min-height: fit-content;
    min-width: fit-content;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.tabControl.tabControlText[100]};
    text-transform: none;
  }
  &.Mui-selected {
    color: ${({ theme }) =>
      theme.palette.tabControl.tabControlSelectedText[100]};
    background-color: ${({ theme }) =>
      theme.palette.tabControl.tabBackground[100]};
    border-radius: 12px;
    box-shadow: 0px 1px 2px rgba(97, 97, 97, 0.2),
      0px 2px 4px rgba(97, 97, 97, 0.2);
  }
`;

export const Styled = { TabControlsWrapper, TabWrapper };
