import React, { FC } from "react";
import { Styled } from "./SortOptionsItem.styled";

import { SortOptionItemTypes } from "./SortOptionsItem.types";
import { Icon } from "react-feather";
import { Button } from "@sdge-components";
import { useAppDispatch } from "../../../../app/hooks";
import {
  generateSortArr,
  generateSortingString,
  isLongitude,
  sortRequest,
} from "@sdge-utils/helpers/sortHelpers/sortHelpers";
import { SelectSortItem } from "..";
import { DeleteIcon } from "../../../../icons";

const SortOptionsItem: FC<SortOptionItemTypes> = ({
  index,
  field,
  order,
  columnDefs,
  sort,
  filteringRules,
  searchInputValue,
  handleSort,
  sortData,
}) => {
  const dispatch = useAppDispatch();
  const colInfo = columnDefs.filter(
    (item) => item.sortField === field || item.field === field
  );

  const deleteSortItem = (field: string, searchInput: string) => {
    const newArray = sort.filter((item) => item.property !== field);
    dispatch(handleSort(newArray));
    dispatch(
      sortData([
        0,
        `${searchInput}${generateSortingString(newArray)}`,
        filteringRules,
      ])
    );
  };

  const unsortedCols = columnDefs
    .filter(({ headerName }) => headerName)
    .filter(({ sorting }) => sorting)
    .filter(
      (o1) =>
        !sort.some(
          (o2) => o1.sortField === o2.property || o1.field === o2.property
        )
    );

  const sortingCols = unsortedCols
    .map((item, index) => {
      return {
        id: index,
        value: item.sortField || item.field,
        label: item.headerName,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  let direction;
  return (
    <Styled.SortItem>
      <Styled.SortingField>
        {index === 0 ? "Sort By " : "then by "}
        <SelectSortItem
          currentSelect={colInfo[0].headerName}
          optionsData={sortingCols}
          order={order}
          currentField={colInfo[0].sortField || colInfo[0].field}
          sort={sort}
          filteringRules={filteringRules}
          searchInputValue={searchInputValue}
          handleSort={handleSort}
          sortData={sortData}
        />
      </Styled.SortingField>
      <Styled.SortingOrder>
        from
        <Styled.SortingOrderButton
          title="A → Z"
          isActive={order === isLongitude(field, "ASC")}
          onClick={() => {
            direction = isLongitude(field, "ASC");
            dispatch(handleSort(generateSortArr(field, direction, sort)));
            dispatch(
              sortData([
                0,
                sortRequest(field, direction, sort, searchInputValue),
                filteringRules,
              ])
            );
          }}
        />
        <Styled.SortingOrderButton
          title="Z → A"
          isActive={order === isLongitude(field, "DESC")}
          onClick={() => {
            direction = isLongitude(field, "DESC");
            dispatch(handleSort(generateSortArr(field, direction, sort)));
            dispatch(
              sortData([
                0,
                sortRequest(field, direction, sort, searchInputValue),
                filteringRules,
              ])
            );
          }}
        />
        <Button
          icon={DeleteIcon as Icon}
          onClick={() => {
            deleteSortItem(field, searchInputValue);
          }}
        />
      </Styled.SortingOrder>
    </Styled.SortItem>
  );
};

export default SortOptionsItem;
