import { RowIcon } from "@sdge-components/MainTable/components";
import customer from "./constants/customer";
import overlapping from "./constants/overlapping";
import parcel from "./constants/parcel";
import React from "react";

export const columnDefs = [
  {
    cellRendererFramework: (params: any) => <RowIcon data={params.data} />,
    suppressMenu: true,
    sorting: true,
    filtering: true,
    suppressMovable: true,
    colId: "event",
    lockPosition: true,
    lockPinned: true,
    pinned: true,
    minWidth: 90,
    suppressNavigable: true,
  },
  {
    maxWidth: 50,
    suppressMenu: true,
    suppressMovable: true,
    lockPosition: true,
    lockPinned: true,
    colId: "checkbox",
    pinned: true,
    resizable: false,
    suppressNavigable: true,
  },
  {
    headerName: "Id #",
    field: "id",
    filterField: "pole_id",
    sortField: "pole_id.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "First Permit Date Submitted",
  //   field: "firstPermitDateSubmitted",
  //   filterField: "permit_date_submitted_1",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "First Permit Expiration Date",
  //   field: "firstPermitExpirationDate",
  //   filterField: "permit_expiration_date_1",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "First Permit Time Frame",
  //   field: "firstPermitTimeFrame",
  //   filterField: "permit_time_frame_1",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "First Permit Issued Date",
  //   field: "firstPermitIssuedDate",
  //   filterField: "permit_issued_date_1",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Assessed",
    field: "assessed",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "assessed",
    sortField: "assessed.keyword",
  },
  {
    headerName: "Circuit #",
    field: "circuit",
    filterField: "circuit_id",
    sortField: "circuit_id.keyword",
    edit: true,
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    field: "bmcdTier3",
    filtering: true,
    sorting: true,
    hide: false,
    pinned: null,
    headerName: "BMCD Tier-3",
    sortField: "bmcd_tier_3.keyword",
    filterField: "bmcd_tier_3",
  },
  {
    headerName: "Circuit Risk Rank",
    field: "circuitRiskRank",
    filterField: "circuit_risk_rank",
    sortField: "circuit_risk_rank.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "District Name",
    field: "district",
    filterField: "district",
    sortField: "district.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Descope",
    field: "descope",
    filterField: "descope",
    sortField: "descope.keyword",
    hide: false,
    edit: true,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Descope Notes",
    field: "descopeNotes",
    filterField: "descope_notes",
    sortField: "descope_notes.keyword",
    hide: false,
    edit: true,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Deprioritize Notes",
    field: "deprioritizeNotes",
    filterField: "deprioritize_notes",
    sortField: "deprioritize_notes.keyword",
    edit: true,
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Flown",
    field: "flown",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "flown",
    sortField: "flown.keyword",
  },
  {
    headerName: "Flown Date",
    field: "flownDate",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "flown_date",
    sortField: "flown_date.keyword",
  },
  {
    headerName: "Notification Description",
    field: "notificationDescription",
    filterField: "notification_description",
    sortField: "notification_description.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Priority",
  //   field: "priority",
  //   filterField: "priority",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Psps End",
  //   field: "pspsEnd",
  //   filterField: "psps_end",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Psps Start",
  //   field: "pspsStart",
  //   filterField: "psps_start",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Airport",
    field: "airport",
    filterField: "airport",
    sortField: "airport.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Release Expiration Date",
  //   field: "releaseExpirationDate",
  //   filterField: "release_expiration_date",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Airspace",
    field: "airspace",
    filterField: "airspace",
    sortField: "airspace.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Аpproved At",
  //   field: "approvedAt",
  //   filterField: "approved_at",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Аssessment Registry Id",
  //   field: "assessmentRegistryId",
  //   filterField: "assesment_registry_id",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Blm",
    field: "blm",
    filterField: "blm",
    sortField: "blm.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "City Park",
    field: "cityPark",
    filterField: "citypark",
    sortField: "citypark.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Cmp Inspection Date",
  //   field: "cmpInspectionDate",
  //   filterField: "cmp_inspection_date",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Cnf",
    field: "cnf",
    filterField: "cnf",
    sortField: "cnf.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "County Land",
    field: "countyLand",
    filterField: "countyland",
    sortField: "countyland.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "County Park",
    field: "countyPark",
    filterField: "countypark",
    sortField: "countypark.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "DOD",
    field: "dod",
    filterField: "dod",
    sortField: "dod.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "DOD Name",
    field: "dodName",
    filterField: "dodname",
    sortField: "dodname.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Eagle Buffe",
    field: "eagleBuffe",
    filterField: "eaglebuffe",
    sortField: "eaglebuffe.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Environmental",
    field: "environmental",
    filterField: "environmental",
    sortField: "environmental.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Environmental Release Issued Date",
  //   field: "environmentalReleaseIssuedDate",
  //   filterField: "environmental_release_issued_date",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Environmental Release Date Submitted",
  //   field: "environmentalReleaseDateSubmitted",
  //   filterField: "environmental_release_date_submitted",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Event",
  //   field: "event",
  //   filterField: "event",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "ENV Permit Time Frame",
  //   field: "envPermitTimeFrame",
  //   filterField: "env_permit_time_frame",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Flown",
  //   field: "flown",
  //   filterField: "flown",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Flown Cnf",
    field: "flownCnf",
    filterField: "flown_cnf",
    sortField: "flown_cnf.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Flown Date",
  //   field: "flownDate",
  //   filterField: "flown_date",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Htfd_Tier ",
    field: "hftdTier",
    filterField: "htfd_tier",
    sortField: "htfd_tier.keyword",
    edit: true,
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "In Scope",
    field: "inScope",
    filterField: "in_scope",
    sortField: "in_scope.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Mission",
    field: "mission",
    filterField: "mission",
    edit: true,
    sortField: "mission.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Notes",
  //   field: "notes",
  //   filterField: "notes",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Latitude",
    field: "latitude",
    filterField: "latitude",
    sortField: "latitude.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Longitude",
    field: "longitude",
    filterField: "longitude",
    sortField: "longitude.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Material",
    field: "material",
    filterField: "material",
    sortField: "material.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Park Name",
    field: "parkName",
    filterField: "parkname",
    sortField: "parkname.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Pilot Name",
    field: "pilotName",
    filterField: "pilot_name",
    sortField: "pilot_name.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Req End Date",
  //   field: "reqEndDate",
  //   filterField: "req_end_date",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Req Start Date",
  //   field: "reqStartDate",
  //   filterField: "req_start_date",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Road Row",
    field: "roadRow",
    filterField: "road_row",
    sortField: "road_row.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Sheep",
    field: "sheep",
    filterField: "sheep",
    sortField: "sheep.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Second Permit Date Submitted",
  //   field: "secondPermitDateSubmitted",
  //   filterField: "permit_submitted_date_2",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Second Permit Expiration Date",
  //   field: "secondPermitExpirationDate",
  //   filterField: "permit_expiration_date_2",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Second Permit Issued Date",
  //   field: "secondPermitIssuedDate",
  //   filterField: "permit_issued_date_2",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Second Permit Time Frame",
  //   field: "secondPermitTimeFrame",
  //   filterField: "permit_time_frame_2",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "State Dept",
    field: "stateDept",
    filterField: "statedept",
    sortField: "statedept.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "State Land",
    field: "stateLand",
    filterField: "stateland",
    sortField: "stateland.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "State Parks",
    field: "stateParks",
    filterField: "stateparks",
    sortField: "stateparks.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "State Unit",
    field: "stateUnit",
    filterField: "stateunit",
    sortField: "stateunit.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Substation",
    field: "substation",
    filterField: "substation",
    sortField: "substation.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "tieLine",
  //   field: "tieLine",
  //   filterField: "tie_line",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Sunrise Ma",
    field: "sunriseMa",
    filterField: "sunrisema",
    sortField: "sunrisema.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Тran/Dist",
  //   field: "tranDist",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  //   filterField: "tran_dist",
  // },
  {
    headerName: "Tran/Dist",
    field: "tranDist",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    filterField: "tran_dist",
    sortField: "tran_dist.keyword",
  },
  {
    headerName: "Tl #",
    field: "tlId",
    filterField: "tlid",
    edit: true,
    sortField: "tlid.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Triballand",
    field: "triballand",
    filterField: "triballand",
    sortField: "triballand.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Trbe Name",
    field: "tribeName",
    filterField: "tribename",
    sortField: "tribename.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Usfs",
    field: "usfs",
    filterField: "usfs",
    sortField: "usfs.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Vendor Upload Date",
  //   field: "vendorUploadDate",
  //   filterField: "vendor_upload_date",
  //   sortField: "vendor_upload_date.keyword",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Transformer",
  //   field: "transformer",
  //   filterField: "transformer",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  {
    headerName: "Vnf",
    field: "vnf",
    filterField: "vnf",
    sortField: "vnf.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Vnf Date",
    field: "vnfDate",
    filterField: "vnf_date",
    sortField: "vnf_date.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Vnf Reason",
    field: "vnfReason",
    filterField: "vnf_reason",
    sortField: "vnf_reason.keyword",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  // {
  //   headerName: "Descope Notes",
  //   field: "descopeNotes",
  //   filterField: "descope_notes",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
  // {
  //   headerName: "Descope Date",
  //   field: "descopeDate",
  //   filterField: "descope_date",
  //   hide: false,
  //   pinned: null,
  //   sorting: true,
  //   filtering: true,
  // },
];

const additionalCustomersColumnsDefs = [
  {
    field: "poleId",
    filtering: true,
    headerName: "Submitted Date",
    filterField: "pole_id",
    sortField: "pole_id.keyword",
  },
  {
    field: "ownerName",
    filtering: true,
    headerName: "Owner Name",
    filterField: "owner_name",
    sortField: "owner_name.keyword",
  },
  {
    field: "apn",
    filtering: true,
    headerName: "Apn",
    filterField: "apn",
    sortField: "apn.keyword",
  },
  {
    field: "isSensitive",
    filtering: true,
    headerName: "Sensitive Customer",
    filterField: "sensitive_customer",
    sortField: "sensitive_customer.keyword",
  },
  {
    field: "apnAddress1",
    filtering: true,
    headerName: "Apn Address1",
    filterField: "apn_address_1",
    sortField: "apn_address_1.keyword",
  },
  {
    field: "apnAddress2",
    filtering: true,
    headerName: "Apn Address2",
    filterField: "apn_address_2",
    sortField: "apn_address_2.keyword",
  },
  {
    field: "email",
    filtering: true,
    headerName: "Email",
    filterField: "email",
    sortField: "email.keyword",
  },
  {
    field: "phoneNumbers",
    filtering: true,
    headerName: "Phone Numbers",
    filterField: "phone_numbers",
    sortField: "phone_numbers.keyword",
  },
  {
    field: "customerId",
    filtering: true,
    headerName: "Customer Id",
    filterField: "customer_id",
    sortField: "customer_id.keyword",
  },
  {
    field: "name",
    filtering: true,
    headerName: "Name",
    filterField: "customer_name",
    sortField: "customer_name.keyword",
  },
  {
    field: "apInfo",
    filtering: true,
    headerName: "Ap Info",
    filterField: "ap_info",
    sortField: "ap_info.keyword",
  },
  {
    field: "accountNumber",
    filtering: true,
    headerName: "Account Number",
    filterField: "account_number",
    sortField: "account_number.keyword",
  },
  {
    field: "meterNumber",
    filtering: true,
    headerName: "Meter Number",
    filterField: "meter_number",
    sortField: "meter_number.keyword",
  },
  {
    field: "phoneNumbers",
    filtering: true,
    headerName: "Phone Numbers",
    filterField: "phone_numbers",
    sortField: "phone_numbers.keyword",
  },
  {
    field: "parcelId",
    filtering: true,
    headerName: "Parcel Id",
    filterField: "parcel_id",
    sortField: "parcel_id.keyword",
  },
];

const columnsOrder = columnDefs.slice(1).map((item) => item.field);
const filteringOptions = [
  {
    headerName: "Grid Values",
    filtering: true,
    children: columnDefs.filter((item) => item.headerName),
  },
  {
    headerName: "Customer",
    filtering: true,
    children: customer,
  },
  {
    headerName: "Overlapping",
    filtering: true,
    children: overlapping,
  },
  {
    headerName: "Parcel",
    filtering: true,
    children: parcel,
  },
];
export const Constants = {
  columnDefs,
  additionalCustomersColumnsDefs,
  columnsOrder,
  filteringOptions,
};
