import { FC } from "react";
import { Styled } from "./SmallTable.styled";
import { TablePropsType } from "@sdge-utils/globalTypes";
// import { AgGridReactProps } from "ag-grid-react";
// import axios from "axios";

// TODO-1111
const SmallTable: FC<TablePropsType> = (
  {
    // fetchLink
  }
): JSX.Element => {
  // const onGridReady = (params: AgGridReactProps) => {
  //   params.columnApi?.autoSizeAllColumns();
  //   fetchLink &&
  //     axios.get(fetchLink).then((response) => {
  //       params.api?.applyTransaction({ add: response.data });
  //     });
  // };
  return <Styled.SmallTableWrapper></Styled.SmallTableWrapper>;
};

export default SmallTable;
