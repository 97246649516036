import styled from "@emotion/styled";
import { Box, MenuItem } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const InteractionItemWrapper = styled(Box)`
  display: flex;
  gap: 7px;
`;

const CheckMark = styled(Box)``;

const IconWrapper = styled(Box)`
  cursor: pointer;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

const Row = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const File = styled(Box)<StyledPropsWithTheme>`
  color: ${({ theme }) => theme.palette.primary.light};
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  white-space: normal;
`;

const Text = styled(Box)<
  StyledPropsWithTheme<{ weight?: number; dark?: boolean }>
>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ weight }) => weight};
  color: ${({ dark, theme }) =>
    !dark
      ? theme.palette.formItems.textColor[100]
      : theme.palette.formItems.labelColor[100]};
`;

const DropDownItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    display: flex;
    gap: 8px;
  }
`;

export const Styled: any = {
  InteractionItemWrapper,
  IconWrapper,
  CheckMark,
  Text,
  File,
  Row,
  Body,
  DropDownItem,
};
