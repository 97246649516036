import styled from "@emotion/styled/";
import { TextField } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { Box } from "@mui/material";

const TextWrapper = styled(Box)<{ isOverflow: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: inherit;
  width: 100%;
  ${({ isOverflow }) => isOverflow && `position: relative;`}
`;

const TextInput = styled(TextField)<StyledPropsWithTheme>`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.formItems.borderColor[100]};
    }
  }
  & label.Mui-focused {
    color: ${({ theme }) => theme.palette.formItems.labelColor[100]};
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.formItems.borderColor[100]};
    border-width: 1px;
  }
`;

const Error = styled.p<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.formItems.errorColor[100]};
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 5px 0 -5px;
  padding: 0;
`;

export const Styled: any = {
  TextWrapper,
  TextInput,
  Error,
};
