import React from "react";
import { Box, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { ModalWindowProps } from "./ModalWindow.types";

import { Styled } from "./ModalWindow.styled";
import { useQsQueryParams } from "../../hooks/useQueryParams";

// TODO rename to modalDialog
const ModalWindow: React.FC<ModalWindowProps> = ({
  isOpen,
  onClose,
  title,
  children,
  modalName,
  modalKey = "modal",
  fullWidth,
  maxWidth,
  ...props
}) => {
  const { params, deleteParams } = useQsQueryParams<Record<string, string>>();

  const isDialogOpen =
    isOpen ??
    Object.entries(params).some(
      ([field, value]) => field === modalKey && value === modalName
    );

  const handleOnClose = () => {
    if (!onClose) {
      deleteParams([modalKey]);
    }

    onClose?.();
  };

  return (
    <Styled.Modal
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={isDialogOpen}
      onClose={handleOnClose}
      aria-labelledby="customized-dialog-title"
      disableEnforceFocus
      {...props}
    >
      <Box paddingRight="1rem">
        <DialogTitle id="draggable-dialog-title">{title}</DialogTitle>
      </Box>
      <Styled.CloseIconButton aria-label="close" onClick={handleOnClose}>
        <CloseIcon />
      </Styled.CloseIconButton>
      {children}
    </Styled.Modal>
  );
};

export default ModalWindow;
