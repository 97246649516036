import { queryInstance } from "@sdge-api/config";
import { handleExport } from "@sdge-store/customers/customers.slice";
import { useDispatch } from "react-redux";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { handleConstructionExport } from "@sdge-store/construction/construction.slice";

export const useExport = (
  construction?: string
): ((url: string, body: any[], fileName: string) => void) => {
  const dispatch = useDispatch();
  async function exportFilteredFile(
    url: string,
    body: any[],
    fileName: string
  ) {
    construction
      ? dispatch(handleConstructionExport(true))
      : dispatch(handleExport(true));
    try {
      const data = await queryInstance.post(url, body, { timeout: 120000 });
      const file = data.data;
      const fileExtensionArray = data.headers["content-type"].split("/");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];

      const localUrl = window.URL.createObjectURL(new Blob([file]));

      const link = document.createElement("a");

      link.href = localUrl;
      link.setAttribute("download", `${fileName}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      construction
        ? dispatch(handleConstructionExport(false))
        : dispatch(handleExport(false));
    } catch (e) {
      if (e.response) {
        if (e.response.status === 504) {
          dispatch(
            handleChangeSnackbarState({
              isOpen: true,
              alertColor: "warning",
              message:
                "Other user or process is using the requested resource. Try again in a couple of minutes.",
            })
          );
        } else {
          dispatch(
            handleChangeSnackbarState({
              isOpen: true,
              alertColor: "error",
              message: e.response.data.error,
            })
          );
        }
      } else {
        dispatch(
          handleChangeSnackbarState({
            isOpen: true,
            alertColor: "warning",
            message:
              "Other user or process is using the requested resource. Try again in a couple of minutes.",
          })
        );
      }

      construction
        ? dispatch(handleConstructionExport(false))
        : dispatch(handleExport(false));
    } finally {
      construction
        ? dispatch(handleConstructionExport(false))
        : dispatch(handleExport(false));
    }
  }

  return exportFilteredFile;
};
