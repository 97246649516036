import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { format } from "date-fns";

dayjs.extend(utc);

function parseDateString(
  originalValue: string | number | Date,
  parseFormat = "YYYY-MM-DD"
) {
  return dayjs(originalValue).format(parseFormat);
}

function parseRowDateString(
  originalValue: string | number | Date,
  parseFormat = "MM/DD/YYYY"
) {
  if (dayjs(originalValue).format(parseFormat) === "Invalid Date") return false;
  return dayjs(originalValue).format(parseFormat);
}
function parsePoleHistoryDate(
  originalValue: string | number | Date,
  parseFormat = "MM/DD/YYYY"
) {
  if (dayjs(originalValue).format(parseFormat) === "Invalid Date")
    return originalValue;
  return dayjs(originalValue).format(parseFormat);
}
function convertTZ(date: any) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
  );
}

const toTimestamp = (strDate: any) => {
  const date = new Date(strDate);
  return convertTZ(date).toLocaleString();
};

const normalizedSeconds = (seconds: number) => {
  return format(new Date(seconds * 1000), "mm:ss");
};

const toLocalTimeZone = (data: string) => {
  return new Date(dayjs.utc(data).format().toLocaleString()).toLocaleString();
};
export {
  parseDateString,
  toTimestamp,
  toLocalTimeZone,
  parseRowDateString,
  parsePoleHistoryDate,
  normalizedSeconds,
};
