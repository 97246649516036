import { DatePickerInput, ModalWindow } from "../../components";
import React from "react";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { Styled } from "./Tile.styled";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../app/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import CreateEnvPermitsValidationSchema from "./CreateEnvPermitsValidationSchema";
import { updateEnvPermits } from "@sdge-store/issueTracker/issueTracker.async";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { CloseIcon } from "../../icons";
import { updateEnvPermitsAssets } from "@sdge-store/customers/customers.async";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { updateEnvPermitsConstruction } from "@sdge-store/construction/construction.async";

const AddEnvPermitsModal: React.FC<any> = ({
  open,
  setOpen,
  isIssueTrackerPage,
  isConstructionPage,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateEnvPermitsValidationSchema),
    mode: "onChange",
  });
  const { buttonDisabled, activeRowId } = useAppSelector(
    (state) => state.customersSlice
  );
  const dispatch = useDispatch();
  const { activeIssueId, activeIssuefacilityId, activeIssueGroupName } =
    useAppSelector((state) => state.issueTrackerSlice);
  const { activeConstructionGroupName } = useAppSelector(selectConstruction);

  const handleCloseModal = () => {
    setOpen(false);
    reset();
  };

  const formSubmit = (values: any) => {
    const body = {
      ...(isIssueTrackerPage
        ? { groupName: activeIssueGroupName, envReleaseNeeded: "No" }
        : isConstructionPage
        ? { groupName: activeConstructionGroupName }
        : {}),
      permitId: "",
      ...values,
    };
    isIssueTrackerPage
      ? dispatch(
          updateEnvPermits({
            id: activeIssueId,
            body,
            facilityId: activeIssuefacilityId,
          })
        )
      : isConstructionPage
      ? dispatch(
          updateEnvPermitsConstruction({
            body,
            groupName: activeConstructionGroupName,
          })
        )
      : dispatch(
          updateEnvPermitsAssets({
            body,
            facilityId: activeRowId,
          })
        );
    reset();
    setOpen(false);
  };

  const handleEdit = (name: string) => {
    setValue(name as `${string}`, "");
  };

  return (
    <ModalWindow isOpen={open} onClose={handleCloseModal}>
      <Box width="500px" p="20px">
        <Box mb="20px">
          <Typography>New Environmental Permits</Typography>
        </Box>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Quick Release # *"
              control={control}
              name="quickRelease"
              errorMessage={errors.quickRelease?.message}
            />
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="ETS  # *"
              control={control}
              name="ets"
              errorMessage={errors.ets?.message}
            />
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name="envReleaseDateSubmitted"
                errorMessage={errors.envReleaseDateSubmitted?.message}
                label="Environmental Release Date Submitted #"
                defaultValue=""
                parseFormat="YYYY-MM-DD"
              />
              <IconButton onClick={() => handleEdit("envReleaseDateSubmitted")}>
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name="envReleaseIssuedDate"
                errorMessage={errors.envReleaseIssuedDate?.message}
                label="Environmental Release Issued Date #"
                defaultValue=""
                parseFormat="YYYY-MM-DD"
              />
              <IconButton onClick={() => handleEdit("envReleaseIssuedDate")}>
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name="envReleaseExpirationDate"
                errorMessage={errors.envReleaseExpirationDate?.message}
                label="Environmental Release Expiration Date #"
                defaultValue=""
                parseFormat="YYYY-MM-DD"
              />
              <IconButton onClick={() => handleEdit("envReleaseIssuedDate")}>
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Environmental Constraints # *"
              control={control}
              name="specialRequirements"
              errorMessage={errors.specialRequirements?.message}
            />
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name="envReleaseExpirationDate"
                errorMessage={errors.envReleaseExpirationDate?.message}
                label="Environmental Release Expiration Date #"
                defaultValue=""
                parseFormat="YYYY-MM-DD"
              />
              <IconButton onClick={() => handleEdit("envReleaseIssuedDate")}>
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item>
              <Styled.AddButton
                variant="contained"
                type="submit"
                disabled={buttonDisabled}
              >
                Add Environmental Permits
              </Styled.AddButton>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ModalWindow>
  );
};

export default AddEnvPermitsModal;
