import { FC, ChangeEvent } from "react";
import { Styled } from "./FilterInput.styled";

const FilterInput: FC<{
  onFilterTextChange: (
    event: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  value: string;
  autoComplete?: string;
  onSubmit?: () => void;
  setInputValue?: (value: string) => void;
  sidebar?: boolean;
  currentFilter?: any;
  errorMessage?: string;
}> = ({
  onFilterTextChange,
  value,
  autoComplete,
  onSubmit,
  setInputValue,
  sidebar,
  currentFilter,
  errorMessage,
}) => {
  const numberType =
    currentFilter?.length &&
    (currentFilter[0]?.fieldName === "%23_of_poles" ||
      currentFilter[0]?.fieldName === "%23_of_poles_replacement");
  return (
    <Styled.FilterInputWrapper>
      <Styled.Filternput
        onChange={onFilterTextChange}
        value={value}
        autoComplete={autoComplete}
        onSubmit={onSubmit}
        setValue={setInputValue}
        sidebar={sidebar}
        type={numberType ? "number" : "text"}
        errorMessage={errorMessage}
      />
    </Styled.FilterInputWrapper>
  );
};

export default FilterInput;
