import { Styled } from "./SelectInput.styled";
import FormControl from "@mui/material/FormControl";
import { FC } from "react";
import { OptionsData, SelectInputTypes } from "./SelectInput.types";
import { Controller } from "react-hook-form";

const SelectInput: FC<SelectInputTypes> = ({
  optionsData,
  label = undefined,
  name,
  control,
  defaultValue,
  errorMessage,
  changeSubmit,
}) => {
  const handleInputChangeSubmit = () => {
    if (changeSubmit) {
      const button = document.createElement("button");

      button.type = "submit";

      changeSubmit.current.appendChild(button);
      button.click();
      button?.parentNode?.removeChild(button);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          {label && (
            <Styled.Label id="demo-simple-select-label" error={!!errorMessage}>
              {label}
            </Styled.Label>
          )}
          <Styled.SelectStyled
            labelId="demo-simple-select-label"
            onChange={(data) => {
              onChange(data);
              handleInputChangeSubmit();
            }}
            value={value}
            label={label}
            defaultValue={defaultValue}
            error={!!errorMessage}
          >
            {optionsData.map((item: OptionsData) => {
              return (
                <Styled.SelectItem value={item.value} key={item.id}>
                  {item.label}
                </Styled.SelectItem>
              );
            })}
          </Styled.SelectStyled>
          {errorMessage && <Styled.Error>{errorMessage}</Styled.Error>}
        </FormControl>
      )}
    />
  );
};

export default SelectInput;
