import { Box, Typography, Grid, TextField } from "@material-ui/core";
import React from "react";
import { Styled } from "./InteractionFilter.styled";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { useAppSelector } from "../../../../app/hooks";
import { getInteractionByGroup } from "@sdge-store/construction/construction.async";
import { useDispatch } from "react-redux";

const InteractionFilter = () => {
  const { customerIds, interactionTypes, activeConstructionGroupName } =
    useAppSelector((state) => state.constructionSlice);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const typesList = [...Array.from(new Set(interactionTypes))];
  const customersIdsList = [...Array.from(new Set(customerIds))];

  const formSubmit = (values: any) => {
    dispatch(
      getInteractionByGroup({
        groupName: activeConstructionGroupName,
        interactionTypes: values.interactionTypes && [values.interactionTypes],
        customerIds: values.customerIds && [values.customerIds],
      })
    );
  };

  return (
    <Box p="16px">
      <Box mb="20px">
        <Typography>Add filters for interactions</Typography>
      </Box>
      <form onSubmit={handleSubmit(formSubmit)}>
        {customerIds?.length ? (
          <Box mb="20px">
            <Controller
              name="customerIds"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    value={value}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                    }}
                    id="controllable-states-demo"
                    options={customersIdsList}
                    sx={{ width: 400 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer Ids"
                        variant="outlined"
                        error={errors.customerIds?.message}
                      />
                    )}
                  />
                );
              }}
            />
          </Box>
        ) : (
          <div></div>
        )}
        {interactionTypes?.length ? (
          <Box mb="20px">
            <Controller
              name="interactionTypes"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    value={value}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                    }}
                    id="controllable-states-demo"
                    options={typesList}
                    sx={{ width: 400 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Interaction Types"
                        variant="outlined"
                        error={errors.interactionTypes?.message}
                      />
                    )}
                  />
                );
              }}
            />
          </Box>
        ) : (
          <div></div>
        )}
        {(interactionTypes.length || customerIds.length) && (
          <Grid container spacing={2}>
            <Grid item>
              <Styled.AddButton variant="contained" type="submit">
                Apply Filter
              </Styled.AddButton>
            </Grid>
          </Grid>
        )}
      </form>
    </Box>
  );
};

export default InteractionFilter;
