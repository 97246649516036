import React, { FC } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { AddIcon } from "../../../../icons";
import { Styled } from "./AddItemButton.styled";
import { AddItemButtonType } from "./AddItemButton.types";
import { generateMassUpdateArr } from "@sdge-utils/helpers/filterHelpers/filterHelpers";

const AddItemButton: FC<AddItemButtonType> = ({
  selecedRowsIssueIds,
  allItems,
  massUpdateItems,
  validColumns,
  handleMassUpdateItems,
  fieldToGenerate,
}) => {
  const dispatch = useAppDispatch();
  return (
    ((selecedRowsIssueIds.length > 0 || allItems) &&
      massUpdateItems.length < validColumns.length && (
        <Styled.AddItemButton
          title="Add Item"
          icon={AddIcon}
          onClick={() =>
            dispatch(
              handleMassUpdateItems(
                generateMassUpdateArr(fieldToGenerate, massUpdateItems)
              )
            )
          }
        />
      )) ||
    null
  );
};

export default AddItemButton;
