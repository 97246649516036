import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@sdge-store/store";

export const selectOverlaps = createSelector(
  (state: RootState) => ({
    filters: state.overlapsSlice.filter,
    overlapping_programs: state.overlapsSlice.overlapping_programs,
    loading: state.overlapsSlice.loading,
    page: state.overlapsSlice.page,
    totalElements: state.overlapsSlice.totalElements,
    loadedElements: state.overlapsSlice.loadedElements,
    sortingRules: state.overlapsSlice.sortingRules,
    sort: state.overlapsSlice.sort,
    totalPages: state.overlapsSlice.totalPages,
    isLastPage: state.overlapsSlice.isLast,
    isPaginated: state.overlapsSlice.isPaginated,
    columnDefs: state.overlapsSlice.columnDefs,
    currentFilter: state.overlapsSlice.filter,
    filteringRules: state.overlapsSlice.filteringRules,
    areSortOptionsOpen: state.overlapsSlice.areSortOptionsOpen,
    areFilterOptionsOpen: state.overlapsSlice.areFilterOptionsOpen,
    areHiddenFieldsOpen: state.overlapsSlice.areHiddenFieldsOpen,
    searchInputValue: state.overlapsSlice.searchInputValue,
    areQuickVeiwsOptionsOpen: state.overlapsSlice.areQuickVeiwsOptionsOpen,
    hiddenColumns: state.overlapsSlice.hiddenColumns,
    columnOrder: state.overlapsSlice.columnOrder,
    quickViews: state.overlapsSlice.quickViews,
    isQuickVeiwsModalOpen: state.overlapsSlice.isQuickVeiwsModalOpen,
    selectedRows: state.overlapsSlice.selectedRows,
    frozenColumns: state.overlapsSlice.frozenColumns,
    initialColumnDefs: state.overlapsSlice.initialColumnDefs,
    initialColumnsOrder: state.overlapsSlice.initialColumnsOrder,
    selectedQuickVewName: state.overlapsSlice.selectedQuickVewName,
    selectedQuickViewPrivacy: state.overlapsSlice.selectedQuickViewPrivacy,
    selectedQuickViewId: state.overlapsSlice.selectedQuickViewId,
    inputText: state.overlapsSlice.inputText,
  }),
  (state) => state
);
