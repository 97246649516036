const SubMenuIcon = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#394059"
      />
      <circle cx="12" cy="6" r="2" fill="#AFB1BA" />
      <circle cx="12" cy="12" r="2" fill="#AFB1BA" />
      <circle cx="12" cy="18" r="2" fill="#AFB1BA" />
    </svg>
  );
};

export default SubMenuIcon;
