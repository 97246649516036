import React, { FC } from "react";
import { SelectFilterItemTypes, OptionsData } from "./SelectFilterItem.types";
import { Styled } from "./SelectFilter.styled";
import { useAppDispatch } from "../../../../app/hooks";
import { setFilter } from "../../../../utils/helpers/filterHelpers/filterHelpers";

const SelectFilterItem: FC<SelectFilterItemTypes> = ({
  optionsData,
  property,
  width,
  currentSelect,
  currFilterInfo,
  currentField,
  defaultValue,
  handleFilter,
  currentFilter,
  index,
  advancedOptions,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Styled.SelectFilterWrapper
      native
      defaultValue={defaultValue}
      onChange={(e) => {
        dispatch(
          handleFilter(
            setFilter(
              currentFilter,
              e.target.value as string,
              property,
              currFilterInfo,
              index
            )
          )
        );
      }}
      width={width}
    >
      {advancedOptions ? (
        <>
          {currentSelect && <option value="">{currentSelect}</option>}
          {optionsData.map(({ headerName, children }: any, index: any) => (
            <optgroup key={index} label={headerName}>
              {children
                .filter((item: any) => item.value !== currentField)
                .map(({ id, value, label }: OptionsData) => (
                  <option key={id} value={value}>
                    {label}
                  </option>
                ))}
            </optgroup>
          ))}
        </>
      ) : (
        <>
          {currentSelect && <option value="">{currentSelect}</option>}
          {optionsData
            .filter((item: any) => item.value !== currentField)
            .map(({ id, value, label }: OptionsData) => (
              <option key={id} value={value}>
                {label}
              </option>
            ))}
        </>
      )}
    </Styled.SelectFilterWrapper>
  );
};

export default SelectFilterItem;
