import { FC } from "react";
import { TextInputTypesWithController } from "./TextInput.types";
import { Controller } from "react-hook-form";
import { TextInput } from "@sdge-components";
import { parseRowDateString } from "@sdge-utils/dateParser";

const TextInputWithController: FC<TextInputTypesWithController> = ({
  name,
  control,
  defaultValue,
  label,
  errorMessage,
  type,
  autoComplete,
  isDisabled,
  multiline,
  isDate,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <TextInput
            onChange={onChange}
            value={
              isDate && parseRowDateString(value)
                ? parseRowDateString(value, "MM/DD/YYYY")
                : value
            }
            label={label}
            errorMessage={errorMessage}
            type={type}
            autoComplete={autoComplete}
            isDisabled={isDisabled}
            multiline={multiline}
          />
        );
      }}
    />
  );
};

export default TextInputWithController;
