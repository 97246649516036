import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@sdge-store/store";

export const selectCustomers = createSelector(
  (state: RootState) => ({
    assets: state.customersSlice.assets,
    loading: state.customersSlice.loading,
    page: state.customersSlice.page,
    totalElements: state.customersSlice.totalElements,
    loadedElements: state.customersSlice.loadedElements,
    sortingRules: state.customersSlice.sortingRules,
    sort: state.customersSlice.sort,
    totalPages: state.customersSlice.totalPages,
    isLastPage: state.customersSlice.isLast,
    isPaginated: state.customersSlice.isPaginated,
    columnDefs: state.customersSlice.columnDefs,
    currentFilter: state.customersSlice.filter,
    filteringRules: state.customersSlice.filteringRules,
    areSortOptionsOpen: state.customersSlice.areSortOptionsOpen,
    areFilterOptionsOpen: state.customersSlice.areFilterOptionsOpen,
    areHiddenFieldsOpen: state.customersSlice.areHiddenFieldsOpen,
    searchInputValue: state.customersSlice.searchInputValue,
    areQuickVeiwsOptionsOpen: state.customersSlice.areQuickVeiwsOptionsOpen,
    hiddenColumns: state.customersSlice.hiddenColumns,
    frozenColumns: state.customersSlice.frozenColumns,
    columnOrder: state.customersSlice.columnOrder,
    quickViews: state.customersSlice.quickViews,
    isQuickVeiwsModalOpen: state.customersSlice.isQuickVeiwsModalOpen,
    selectedRows: state.customersSlice.selectedRows,
    initialColumnDefs: state.customersSlice.initialColumnDefs,
    initialColumnsOrder: state.customersSlice.initialColumnsOrder,
    selectedQuickVewName: state.customersSlice.selectedQuickVewName,
    selectedQuickViewPrivacy: state.customersSlice.selectedQuickViewPrivacy,
    selectedQuickViewId: state.customersSlice.selectedQuickViewId,
    inputText: state.customersSlice.inputText,
    massUpdateItems: state.customersSlice.massUpdateItems,
    selectedRowsIds: state.customersSlice.selectedRowsIds,
    massUpdateLoading: state.customersSlice.massUpdateLoading,
    massUpdateItemsRequest: state.customersSlice.massUpdateItemsRequest,
    areMassUpdateOptionsOpen: state.customersSlice.areMassUpdateOptionsOpen,
    bmcdTierVisibility: state.issueTrackerSlice.bmcdTierVisibility,
  }),
  (state) => state
);
