import { useAppSelector } from "../../app/hooks";
import { parseDateString } from "@sdge-utils/dateParser";

const useStatusTileContent = () => {
  const { constructionInfo } = useAppSelector(
    (state) => state.constructionSlice
  );
  const programInfoArr: any = constructionInfo ? [constructionInfo] : [];

  return programInfoArr.length
    ? programInfoArr?.map((item: any) => {
        return [
          {
            id: 6,
            title: "Аssigned Contractor",
            content: item?.assignedContractor || "-",
            name: "assignedContractor",
          },
          {
            id: 7,
            title: "Construction Mbf Date",
            content: item?.constructionMbfDate
              ? parseDateString(item?.constructionMbfDate, "MM/DD/YYYY")
              : "-",
            name: "constructionMbfDate",
          },
          {
            id: 8,
            title: "Days Until Mfb Date",
            content: item?.daysUntilMfbDate ? item?.daysUntilMfbDate : "-",
            name: "daysUntilMfbDate",
          },
          {
            id: 9,
            title: "Days Past Goal",
            content: item?.daysPastGoal || "-",
            name: "daysPastGoal",
          },
          {
            id: 10,
            title: "Estimated Start Date",
            content: item?.estimatedStartDate
              ? parseDateString(item?.estimatedStartDate, "MM/DD/YYYY")
              : "-",
            name: "estimatedStartDate",
          },
          {
            id: 11,
            title: "Estimated Completion Date",
            content: item?.estimatedCompletionDate
              ? parseDateString(item?.estimatedCompletionDate, "MM/DD/YYYY")
              : "-",
            name: "estimatedCompletionDate",
          },
          {
            id: 12,
            title: "Construction Percent Complete",
            content: item?.constructionPercentComplete || "-",
            name: "constructionPercentComplete",
          },
          {
            id: 13,
            title: "Construction Start",
            content: item?.constructionStart
              ? parseDateString(item?.constructionStart, "MM/DD/YYYY")
              : "-",
            name: "constructionStart",
          },
          {
            id: 14,
            title: "Construction Completion",
            content: item?.constructionCompletion || "-",
            name: "constructionCompletion",
          },
          {
            id: 14,
            title: "As Builts Submitted",
            content: item?.asbuiltsSubmitted || "-",
            name: "asbuiltsSubmitted",
          },
          {
            id: 15,
            title: "As Builts Accepted",
            content: item?.asbuiltsAccepted || "-",
            name: "asbuiltsAccepted",
          },
          {
            id: 1,
            title: "Deferral Notification Date",
            content: item?.deferralNotificationDate
              ? parseDateString(item?.deferralNotificationDate, "MM/DD/YYYY")
              : "-",
            name: "deferralNotificationDate",
            date: true,
          },
          {
            id: 2,
            title: "Deferral Reason",
            content: item?.deferralReason || "-",
            name: "deferralReason",
          },
          {
            id: 3,
            title: "Deferral Goal Date",
            content: item?.deferralGoalDate
              ? parseDateString(item?.deferralGoalDate, "MM/DD/YYYY")
              : "-",
            name: "deferralGoalDate",
            date: true,
          },
          {
            id: 4,
            title: "Delay Deferred To",
            content: item?.delayDeferredTo || "-",
            name: "delayDeferredTo",
          },
          {
            id: 5,
            title: "Delay Notes",
            content: item?.delayNotes || "-",
            name: "delayNotes",
          },
          {
            id: 17,
            title: "Sub Status",
            content: item?.jobStatus || "-",
            name: "jobStatus",
          },
          {
            id: 17,
            title: "Construction Postcard",
            content: item?.constructionPostcard || "-",
            name: "constructionPostcard",
          },
        ];
      })
    : [];
};

export default useStatusTileContent;
