import styled from "@emotion/styled";

const QuickActionWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  & * {
    margin: 0;
    padding: 0;
  }
`;

export const Styled = {
  QuickActionWrapper,
  TimerWrapper,
};
