import { Checkbox, Divider, FormControlLabel, Grid } from "@material-ui/core";
import { Button, Options } from "@sdge-components";
import { ErrorBoundary } from "@sdge-pages";
import React, { FC, useState } from "react";
import { Icon } from "react-feather";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { AddIcon, CheckMark, DeleteIcon, UpdateIcon } from "../../../../icons";
import { Styled } from "./QuickViews.styled";
import { QuickViewsType } from "./QuickViews.types";
import { usePermission } from "@sdge-hooks";
import { selectUser } from "@sdge-store/user/user.selector";
import { Constants } from "@sdge-store/user/constants/user.constants";

const QuickViews: FC<QuickViewsType> = ({
  component,
  setOpen,
  open,
  quickViews,
  columnDefs,
  fetchView,
  deleteView,
  resetViews,
  updateView,
  selectedName,
  handleChange,
  handleSetPrivacy,
  handleSetId,
}) => {
  const dispatch = useAppDispatch();
  const { getPermission } = usePermission();
  const { roles } = useAppSelector(selectUser);
  const hasPermissions =
    getPermission("customerSingleEdit") || getPermission("readonly");
  const isDiarAdminRole =
    roles.includes(Constants.roleAdminDev) ||
    roles.includes(Constants.roleAdminProd);
  const [localPrivacy, setLocalPrivacy] = useState(false); // TODO: refactor

  return (
    <Styled.QuickViewsOptionsWrapper>
      <Styled.OptionButtons>
        <Options
          field="Save as new view"
          component={component}
          icon={AddIcon}
          setOpen={setOpen}
          open={open}
        />
        {quickViews.some((item) => item.selected) && (
          <Button
            isActive={true}
            title="Reset View"
            onClick={() => resetViews(quickViews)}
          />
        )}
      </Styled.OptionButtons>
      {quickViews.some((item) => item.selected) && (
        <Button
          title="Update Current View"
          onClick={() => updateView(selectedName)}
          icon={UpdateIcon}
          disabled={isDiarAdminRole ? false : !localPrivacy}
        />
      )}
      {quickViews.length > 0 && <Divider />}
      <Styled.QuickViewsOptionsList>
        {quickViews &&
          quickViews.map(
            ({
              id,
              name,
              selected,
              orders,
              filters,
              hiddenColumns,
              frozenColumns,
              columnsOrder,
              privacy,
            }) => (
              <Styled.QuickViewsButtonWrapper key={id} isActive={selected}>
                <ErrorBoundary>
                  <Styled.QuickViewsButton
                    isActive={selected}
                    title={name}
                    icon={CheckMark as Icon}
                    onClick={() => {
                      fetchView(
                        orders,
                        filters,
                        id,
                        quickViews,
                        columnDefs,
                        hiddenColumns,
                        frozenColumns,
                        columnsOrder,
                        name
                      );
                      handleSetPrivacy && handleSetPrivacy(!!privacy);
                      handleSetId && handleSetId(id);
                      setLocalPrivacy(privacy);
                    }}
                  />
                </ErrorBoundary>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={hasPermissions || !isDiarAdminRole}
                          defaultChecked={privacy}
                          name="private"
                          onChange={handleChange}
                        />
                      }
                      label="Private"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={
                        isDiarAdminRole
                          ? false
                          : (hasPermissions && !privacy) ||
                            (!isDiarAdminRole && !privacy)
                      }
                      icon={DeleteIcon as Icon}
                      onClick={() => {
                        dispatch(deleteView(id));
                        if (selected) {
                          resetViews(quickViews);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Styled.QuickViewsButtonWrapper>
            )
          )}
      </Styled.QuickViewsOptionsList>
    </Styled.QuickViewsOptionsWrapper>
  );
};

export default QuickViews;
