import styled from "@emotion/styled";
import { MenuItem, Menu, Box } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const LayersMenuItem = styled(MenuItem)<StyledPropsWithTheme>`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const layersMenu = styled(Menu)<StyledPropsWithTheme>`
  z-index: 1350;
  .css-1hgte3m-MuiButtonBase-root-MuiRadio-root.Mui-checked,
  .css-e2wm36.Mui-checked {
    color: ${({ theme }) => theme.palette.formItems.textColor[100]};
  }
`;

const ExportTypeContainer = styled(Box)`
  .MuiGrid-item {
    padding: 10px 10px;
  }
  position: absolute;
  right: 50px;
  padding: 25px;
`;

export const Styled = {
  LayersMenuItem,
  layersMenu,
  ExportTypeContainer,
};
