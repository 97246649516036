import {
  DatePickerInput,
  ModalWindow,
  SelectInput,
  StandardButton,
  Button,
} from "@sdge-components";
import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import editIssueValidationSchema from "./EditIssueModal.validation";
import { Styled } from "./CreateIssueModal.styled";
import { UpdateManualIssue } from "@sdge-store/issueTracker/issueTracker.async";
import { useDispatch } from "react-redux";
import {
  assignedConstants,
  classificationValues,
  priority,
  statusConstants,
  replacement,
  ballInCourtConstants,
} from "./IssueTracker.constants";
import { useAppSelector } from "../../app/hooks";
import { IconButton } from "@mui/material";
import { CloseIcon } from "../../icons";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initData: any;
};

const EditIssueModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  initData,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editIssueValidationSchema),
    mode: "onChange",
  });
  const { buttonDisabled } = useAppSelector((state) => state.issueTrackerSlice);
  const watchAllFields = watch();

  useEffect(() => {
    const identifiedDateValues = watchAllFields?.identifiedDate?.split("-");
    const initiationCompleteDateValues =
      watchAllFields?.initiationCompleteDate?.split("-");
    if (
      identifiedDateValues?.length == 3 &&
      initiationCompleteDateValues?.length == 3
    ) {
      if (initiationCompleteDateValues[0] < identifiedDateValues[0]) {
        setError("identifiedDate", {
          message: "Identified Date have to be earlier",
        });
        setValue("error", true);
      } else if (
        initiationCompleteDateValues[0] === identifiedDateValues[0] &&
        initiationCompleteDateValues[1] < identifiedDateValues[1]
      ) {
        setError("identifiedDate", {
          message: "Identified Date have to be earlier",
        });
        setValue("error", true);
      } else if (
        initiationCompleteDateValues[0] === identifiedDateValues[0] &&
        initiationCompleteDateValues[1] === identifiedDateValues[1] &&
        initiationCompleteDateValues[2] < identifiedDateValues[2]
      ) {
        setError("identifiedDate", {
          message: "Identified Date have to be earlier",
        });
        setValue("error", true);
      } else {
        setError("identifiedDate", {
          message: "",
        });
        setValue("error", false);
      }
    }
  }, [watchAllFields.identifiedDate, watchAllFields?.initiationCompleteDate]);

  useEffect(() => {
    if (initData) {
      reset({
        approvedDate: initData.approvedDate,
        assessmentReportURL: initData.assessmentReportURL,
        assigned: initData.assigned,
        ballInCourt: initData.ballInCourt,
        circuitId: initData.circuitId,
        classification: initData.classification,
        conditionCodeId: initData.conditionCodeId,
        crossarmReplacement: initData.crossarmReplacement,
        description: initData.description,
        districtName: initData.districtName,
        facilityId: initData.facilityId,
        groupName: initData.groupName,
        identifiedDate: initData.identifiedDate,
        initiationCompleteDate: initData.initiationCompleteDate,
        kmzURL: initData.kmzURL,
        latitude: initData.latitude ? initData.latitude.toString() : "",
        longitude: initData.longitude ? initData.longitude.toString() : "",
        poleReplacement: initData.poleReplacement,
        priority: initData.priority,
        resolvedDate: initData.resolvedDate,
        status: initData.status,
        thomasBros: initData.thomasBros,
        tlId: initData.tlId,
        type: initData.type,
        wo: initData.wo,
      });
    }

    return () => {
      reset();
    };
  }, [initData, reset]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const formSubmit = (values: any) => {
    const tempData = JSON.parse(JSON.stringify(values));

    dispatch(
      UpdateManualIssue({
        id: initData.id,
        ...tempData,
      })
    );
    reset();
    onClose();
  };

  const handleEdit = (name: string) => {
    setValue(name as `${string}`, "");
    setValue("error", false);
    setError("identifiedDate", {
      message: "",
    });
  };

  return (
    <ModalWindow isOpen={isOpen} onClose={handleClose}>
      <Styled.ModalContainer width="550px" p="20px">
        <Box mb="20px">
          <Typography>Edit Issue</Typography>
        </Box>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name="approvedDate"
                label="Approve Date"
                defaultValue=""
              />
              <IconButton onClick={() => handleEdit("approvedDate")}>
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Assessment Report URL"
              control={control}
              name="assessmentReportURL"
            />
          </Box>
          <Box mb="20px" width="475px">
            <SelectInput
              optionsData={assignedConstants}
              control={control}
              defaultValue={""}
              name="assigned"
              label="Assigned"
            />
          </Box>
          <Box mb="20px" width="475px">
            <SelectInput
              optionsData={ballInCourtConstants}
              control={control}
              defaultValue={""}
              name="ballInCourt"
              label="Ball In Court # "
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Circuit Id # "
              name="circuitId"
              control={control}
              errorMessage={errors.circuitId?.message}
            />
          </Box>
          <Box mb="20px" width="475px">
            <SelectInput
              optionsData={classificationValues}
              control={control}
              defaultValue={""}
              name="classification"
              label="Classification"
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Condition Code Id"
              name="conditionCodeId"
              control={control}
            />
          </Box>
          <Box mb="20px" width="475px">
            <SelectInput
              optionsData={replacement}
              defaultValue={""}
              label="Crossarm Replacement"
              name="crossarmReplacement"
              control={control}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Description"
              name="description"
              control={control}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="District Name"
              name="districtName"
              control={control}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Facility Id"
              name="facilityId"
              control={control}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Group Name"
              name="groupName"
              control={control}
            />
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                readOnly
                control={control}
                name="identifiedDate"
                errorMessage={
                  errors.identifiedDate?.message ?? errors.error?.message
                }
                label="Identified Date"
                defaultValue=""
              />
              <IconButton onClick={() => handleEdit("identifiedDate")}>
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                control={control}
                name="initiationCompleteDate"
                label="Initiation Complete Date"
                readOnly
                defaultValue=""
              />
              <IconButton onClick={() => handleEdit("initiationCompleteDate")}>
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="kmz URL"
              name="kmzURL"
              control={control}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Latitude # "
              name="latitude"
              control={control}
              defaultValue={0}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Longitude # "
              name="longitude"
              control={control}
              defaultValue={0}
            />
          </Box>
          <Box mb="20px" width="475px">
            <SelectInput
              optionsData={replacement}
              control={control}
              defaultValue={""}
              label="Pole Replacement"
              name="poleReplacement"
            />
          </Box>
          <Box mb="20px" width="475px">
            <SelectInput
              optionsData={priority}
              control={control}
              defaultValue={""}
              name={"priority"}
              label="Priority"
            />
          </Box>
          <Box mb="20px">
            <Grid container wrap="nowrap">
              <DatePickerInput
                control={control}
                name="resolvedDate"
                label="Resolved Date"
                readOnly
                defaultValue=""
              />
              <IconButton onClick={() => handleEdit("resolvedDate")}>
                <CloseIcon size={14} />
              </IconButton>
            </Grid>
          </Box>
          <Box mb="20px" width="475px">
            <SelectInput
              optionsData={statusConstants}
              control={control}
              defaultValue={""}
              name={"status"}
              label="Status"
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Thomas Bros"
              name="thomasBros"
              control={control}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="TL Id"
              name="tlId"
              control={control}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput
              label="Type # "
              name="type"
              control={control}
              errorMessage={errors.type?.message}
            />
          </Box>
          <Box mb="20px" width="475px">
            <Styled.CustomersInput label="Wo" name="wo" control={control} />
          </Box>
          <Grid container spacing={2}>
            <Grid item>
              <StandardButton
                title="UPDATE ISSUE"
                type="submit"
                disabled={buttonDisabled}
              />
            </Grid>
            <Grid item>
              <Button title="CANCEL" onClick={handleClose} outlined />
            </Grid>
          </Grid>
        </form>
      </Styled.ModalContainer>
    </ModalWindow>
  );
};

export default EditIssueModal;
