import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectCustomers } from "@sdge-store/customers/customers.selector";
import {
  handleMassUpdateItems,
  handleSelectedRows,
  handleSelectedIds,
} from "@sdge-store/customers/customers.slice";
import {
  updateFilteredRows,
  updateSelectedRows,
} from "@sdge-store/customers/customers.async";
import MassUpdateOptions from "./MassUpdateOptions";

const CustomerMassUpdateOptions = () => {
  const {
    selectedRows,
    massUpdateItems,
    columnDefs,
    selectedRowsIds,
    filteringRules,
    totalElements,
    massUpdateLoading,
  } = useAppSelector(selectCustomers);

  const data = {
    selectedRows,
    massUpdateItems,
    columnDefs,
    selecedRowsIssueIds: selectedRowsIds,
    filteringRules,
    totalElements,
    massUpdateLoading,
  };

  const functions = {
    updateFilteredRows,
    updateSelectedRows,
    handleMassUpdateItems,
    handleSelectedRows,
    handleSelectedIssueIds: handleSelectedIds,
  };

  return (
    <div>
      <MassUpdateOptions data={data} functions={functions} page="assets" />
    </div>
  );
};

export default CustomerMassUpdateOptions;
