import * as yup from "yup";
import {
  emailRegexp,
  NARegexp,
  naRegexp,
  phoneRegExp,
} from "./AddCustomerModal.constants";

export const validationSchemaForCustomers = (isConstructionPage?: boolean) => {
  return yup.object().shape({
    ...(isConstructionPage
      ? { facilityId: yup.string().nullable().required() }
      : {}),
    accountNumber: yup.string().required(),
    apInfo: yup.string().required(),
    apnAddress1: yup.string().required(),
    apnAddress2: yup.string().required(),
    email: yup
      .string()
      .test("test-name", "The value is not valid", (value) => {
        if (!value) return false;
        return (
          emailRegexp.test(value) ||
          naRegexp.test(value) ||
          NARegexp.test(value)
        );
      })
      .required(),
    meterNumber: yup.string().required(),
    name: yup.string().required(),
    notes: yup.string().required(),
    phoneNumbers: yup
      .string()
      .test("test-name", "The value is not valid", (value) => {
        if (!value) return false;
        return (
          phoneRegExp.test(value) ||
          naRegexp.test(value) ||
          NARegexp.test(value)
        );
      })
      .required(),
  });
};
