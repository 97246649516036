export const muniPermitOptions = [
  {
    id: "No TCP or Permits Required",
    value: "No TCP or Permits Required",
    label: "No TCP or Permits Required",
  },
  {
    id: "TCP Only",
    value: "TCP Only",
    label: "TCP Only",
  },
  {
    id: "Permits Only",
    value: "Permits Only",
    label: "Permits Only",
  },
  {
    id: "TCP and Permits",
    value: "TCP and Permits",
    label: "TCP and Permits",
  },
];
