import styled from "@emotion/styled";
import { Button } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const ButtonWrapper = styled(Button)<
  StyledPropsWithTheme<{ pallet: "primary" | "secondary" }>
>`
  &.MuiButton-root {
    text-transform: none;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.formItems.textColor[100]};
    box-shadow: none;
    border: 1px solid;
    width: 100%;
    height: 100%;
    background-color: ${({ theme, pallet }) =>
      theme.palette.button[pallet].background[100]};
    border-color: ${({ theme, pallet }) =>
      theme.palette.button[pallet].borderColor[100]};
    color: ${({ theme, pallet }) => theme.palette.button[pallet].color[100]};
  }
`;

export const Styled = {
  ButtonWrapper,
};
