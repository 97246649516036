import React, { FC } from "react";
import { handleHiddenColumn } from "@sdge-store/issueTracker/issueTracker.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { HiddenOptionContainer } from "./components";
import { selectIssueTracker } from "@sdge-store/issueTracker/issueTracker.selector";

const IssueTrackerHideOptionsMenu: FC = () => {
  const { columnDefs } = useAppSelector(selectIssueTracker);
  const dispatch = useAppDispatch();

  const switchHidden = (field: string) => {
    dispatch(handleHiddenColumn(field));
  };

  return (
    <HiddenOptionContainer
      columnDefs={columnDefs}
      switchHidden={switchHidden}
    />
  );
};

export default IssueTrackerHideOptionsMenu;
