import { Styled } from "./QuickAction.styled";
import QuickActionIcon from "../../icons/QuickActionIcon";
import { FC } from "react";
import { QuickActionTypes } from "./QuickAction.types";

const QuickAction: FC<QuickActionTypes> = ({ onClick }) => {
  return (
    <Styled.QuickActionWrapper
      color="secondary"
      onClick={onClick}
      id="quickAction"
    >
      <QuickActionIcon />
    </Styled.QuickActionWrapper>
  );
};

export default QuickAction;
