import styled from "@emotion/styled";
import { MenuItem, Box, Menu } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const LayersMenuItem = styled(MenuItem)<StyledPropsWithTheme>`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const LayersContainer = styled(Box)`
  position: absolute;
  top: 30px;
`;

const layersMenu = styled(Menu)`
  z-index: 1350;
`;

export const Styled = {
  LayersMenuItem,
  LayersContainer,
  layersMenu,
};
