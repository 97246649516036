import { FC } from "react";
import { Styled } from "./StandardButton.styled";
import { StandardButtonTypes } from "./StandardButton.types";

const StandardButton: FC<StandardButtonTypes> = ({
  title,
  type = "button",
  variant,
  pallet = "primary",
  onClick,
  disabled,
}) => {
  return (
    <Styled.ButtonWrapper
      type={type}
      variant={variant}
      color="primary"
      pallet={disabled ? "secondary" : pallet}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Styled.ButtonWrapper>
  );
};

export default StandardButton;
