import { handleHiddenColumn } from "@sdge-store/customers/customers.slice";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { HiddenOptionContainer } from "./components";
import { selectCustomers } from "@sdge-store/customers/customers.selector";

const CustomerHideOptionsMenu: FC = () => {
  const { columnDefs } = useAppSelector(selectCustomers);
  const dispatch = useAppDispatch();

  const switchHidden = (field: string) => {
    dispatch(handleHiddenColumn(field));
  };

  return (
    <HiddenOptionContainer
      columnDefs={columnDefs}
      switchHidden={switchHidden}
    />
  );
};

export default CustomerHideOptionsMenu;
