import useInfoTileContentCreator from "./useInfoTileContentCreateor";
import React from "react";
import { TileSection } from "../TileSection";
import { TileRow } from "../TileRow";
import useProgramsContentCreator from "./useProgramsContentCreator";
import { useLocation } from "react-router-dom";
import useIssueTrackerOverlappingContentCreator from "./useIssueTrackerOverlappingTileCreator";
import { Box, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useAppSelector } from "../../app/hooks";

const InfoTileContent: React.FC<{ constructionPage?: boolean }> = ({
  constructionPage,
}) => {
  const contentItems = useInfoTileContentCreator();
  const programsContent = useProgramsContentCreator();
  const { pathname } = useLocation();
  const issueTracker = pathname.split("/").includes("issue_tracker");
  const issueTrackerContent =
    useIssueTrackerOverlappingContentCreator(constructionPage);
  const { requested, cardsLoading } = useAppSelector(
    (state) => state.issueTrackerSlice
  );
  if (cardsLoading) {
    return <> </>;
  }

  return (
    <>
      {issueTracker || constructionPage ? (
        <>
          {!issueTrackerContent?.length && requested && (
            <Grid container justifyContent="center">
              <Box mt="20%" width="70%">
                <Typography align="center">
                  No overlapping for this pole
                </Typography>
              </Box>
            </Grid>
          )}
          {issueTrackerContent?.map((item: any, i: number) => {
            return (
              <Box key={i}>
                <TileSection title={item.name} />
                {item?.data.map(({ title, content }: any, index: number) => {
                  return (
                    <TileRow
                      key={content + index}
                      title={title}
                      content={content}
                    />
                  );
                })}
              </Box>
            );
          })}
        </>
      ) : (
        <>
          <TileSection title="POLE" />
          {contentItems.map(({ id, title, content }) => {
            return <TileRow key={id} title={title} content={content} />;
          })}
          <TileSection title="PROGRAMS" />
          {programsContent.map((item: any) => {
            return item.map(({ id, title, content }: any) => {
              return <TileRow key={id} title={title} content={content} />;
            });
          })}
        </>
      )}
    </>
  );
};

export default InfoTileContent;
