import styled from "@emotion/styled";
import { Box } from "@mui/material";

const Form = styled.form`
  background-color: #181e34;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
`;

const Row = styled(Box)<{ gap?: boolean }>`
  display: flex;
  gap: ${({ gap }) => (gap ? "12px" : "0")};
`;

export const Styled: any = {
  Form,
  Row,
};
