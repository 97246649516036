const muniPermits = (card: string) => [
  {
    field: "id",
    filtering: true,
    sortField: "id",
    headerName: "Id",
    filterField: `${card}id`,
  },
  {
    field: "k2_reference_#",
    filtering: true,
    sortField: "k2_reference_#",
    headerName: "K2 Reference #",
    filterField: `${card}k2_reference_#`,
  },
  {
    field: "permit_date_submitted",
    filtering: true,
    sortField: "permit_date_submitted",
    headerName: "Permit Date Submitted",
    filterField: `${card}permit_date_submitted`,
  },
  {
    field: "permit_expiration_date",
    filtering: true,
    sortField: "permit_expiration_date",
    headerName: "Permit Expiration Date",
    filterField: `${card}permit_expiration_date`,
  },
  {
    field: "permit_id",
    filtering: true,
    sortField: "permit_id",
    headerName: "Permit Id",
    filterField: `${card}permit_id`,
  },
  {
    field: "permit_issued_date",
    filtering: true,
    sortField: "permit_issued_date",
    headerName: "Permit Issued Date",
    filterField: `${card}permit_issued_date`,
  },
  {
    field: "permit_time_frame",
    filtering: true,
    sortField: "permit_time_frame",
    headerName: "Muni Constraints",
    filterField: `${card}permit_time_frame`,
  },
  {
    field: "permit_type",
    filtering: true,
    sortField: "permit_type",
    headerName: "Permit Type",
    filterField: `${card}permit_type`,
  },
];

export default muniPermits("muniPermit.");
