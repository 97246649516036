import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import styled from "@emotion/styled";
import { Box } from "@material-ui/core";

const EditableWrapper = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  width: 100%;
`;
const DefaultValueWrapper = styled(Box)<StyledPropsWithTheme>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 200px;
`;

export const Styled = { EditableWrapper, DefaultValueWrapper };
