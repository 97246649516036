import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const AutoCompleteWrapper = styled(Box)<
  StyledPropsWithTheme<{
    left: number;
    top: number;
    width: number;
    isOverflow: boolean;
    sidebar?: boolean;
  }>
>`
  width: ${({ width }) => width + "px"};
  max-height: 150px;
  background-color: #424242;
  position: absolute;
  z-index: 5;
  border: 1px solid ${({ theme }) => theme.palette.formItems.borderColor[100]};
  border-top: none;
  border-radius: ${({ sidebar }) =>
    sidebar
      ? `
       4px 4px 0 0;
      `
      : `
      0 0 4px 4px;
  `};
  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: 2px;

  ${({ isOverflow, left, top }) =>
    isOverflow
      ? `
        left: 0;
        top: 40px;
      `
      : `
      inset: 0px auto auto 0px;
      transform: translate(${left}px,${top + 38}px);
  `}

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const AutoCompleteItem = styled(Box)<StyledPropsWithTheme>`
  padding: 2px 14px;

  color: inherit;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 16px;
  font-weight: inherit;
  cursor: pointer;

  &:hover {
    background-color: #252c47;
  }
`;

export const Styled = {
  AutoCompleteWrapper,
  AutoCompleteItem,
};
