export const dataTransforming = (data: { [key: string]: string[] }) => {
  return Object.keys(data).reduce((acc, valueKey: string) => {
    const array: string[] = Array.from(new Set(data[valueKey]));
    const values = array.map((item: string) => ({
      id: item.split(" ").join("_"),
      value: item,
      label: item,
    }));
    return {
      ...acc,
      [valueKey]: [
        {
          id: "BLANK",
          value: "",
          label: "BLANK",
        },
        ...values,
      ],
    };
  }, {});
};

export const camelCaseTransform = (data: string) => {
  const transform = data.replace(/([A-Z])/g, " $1");
  const result = transform.charAt(0).toUpperCase() + transform.slice(1);

  return result;
};
