import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserInfo } from "./user.async";
import { UserStateTypes } from "./user.types";

const initialUserState: UserStateTypes = {
  info: {
    name: "",
    surname: "",
    email: "",
    roles: [],
    writePermissions: [],
  },
  location: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    getLocation: (state, action) => {
      state.location = action.payload;
    },
  },
  extraReducers: {
    [getUserInfo.fulfilled]: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.info.name = action.payload.name;
        state.info.surname = action.payload.surname;
        state.info.email = action.payload.email;
        state.info.roles = action.payload.roles;
        state.info.writePermissions = action.payload.writePermissions;
      }
    },
  },
});
export const { getLocation } = userSlice.actions;

export default userSlice.reducer;
