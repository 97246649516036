import React, { FC, useRef, useEffect } from "react";
import { sortIssueData } from "@sdge-store/issueTracker/issueTracker.async";
import { handleSort } from "@sdge-store/issueTracker/issueTracker.slice";
import { useAppSelector } from "../../app/hooks";
import { SelectSort, SortOptionsItem } from "./components";
import { Styled } from "./SortOptionsMenu.styled";
import { selectIssueTracker } from "@sdge-store/issueTracker/issueTracker.selector";

const IssueTrackerSortOptionsMenu: FC = () => {
  const { sort, columnDefs, filteringRules, searchInputValue } =
    useAppSelector(selectIssueTracker);
  const listEnd = useRef<any>();
  const scrollToBottom = () => {
    listEnd.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [sort.length]);
  const unsortedCols = (columnDefs as any[])
    .filter(({ headerName }) => headerName)
    .filter(({ sorting }) => sorting)
    .filter((o1) => !sort.some((o2: any) => o1.sortField === o2.property));
  const sortingCols = unsortedCols
    .map((item, index) => {
      return { id: index, value: item.sortField, label: item.headerName };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Styled.SortOptionsWrapper>
      <Styled.SortOptionsList>
        {(sort as any[]).map((item, index) => (
          <SortOptionsItem
            key={item.property}
            field={item.property}
            order={item.direction}
            index={index}
            columnDefs={columnDefs}
            sort={sort}
            filteringRules={filteringRules}
            searchInputValue={searchInputValue}
            handleSort={handleSort}
            sortData={sortIssueData}
          />
        ))}
        <Styled.ListBottom ref={listEnd}></Styled.ListBottom>
      </Styled.SortOptionsList>
      <Styled.SelectSortWrapper>
        {sortingCols.length > 0 && (
          <SelectSort
            placeHolder="Add field to sort by"
            optionsData={sortingCols}
            order="ASC"
            sort={sort}
            filteringRules={filteringRules}
            searchInputValue={searchInputValue}
            handleSort={handleSort}
            sortData={sortIssueData}
          />
        )}
      </Styled.SelectSortWrapper>
    </Styled.SortOptionsWrapper>
  );
};

export default IssueTrackerSortOptionsMenu;
