import { Styled } from "./CellHistory.styled";
import usePreflightInfoCreator from "./usePreflightInfoCreator";
import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Link } from "@sdge-components";
import { TileSection } from "../../../TileSection";

const PreflightInfo = () => {
  const infoList = usePreflightInfoCreator();

  return (
    <Styled.HistoryWrapper>
      {infoList?.map((item: any, i: number) => {
        return (
          <Box key={i}>
            <Box pb="10px">
              <TileSection title="" />
            </Box>

            {item?.map(({ title, id, value, link }: any) => {
              return (
                <Box mb={"30px"} key={id} width="90%">
                  <Grid container wrap="nowrap" justifyContent="space-between">
                    <Grid item>
                      <Styled.Title>{title}</Styled.Title>
                    </Grid>
                    <Grid item>
                      {link ? (
                        <Box whiteSpace="normal" px={"10px"}>
                          <Link link={link} name={link} />
                        </Box>
                      ) : (
                        <Styled.Content noWrap={false}>{value}</Styled.Content>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Styled.HistoryWrapper>
  );
};

export default PreflightInfo;
