import { Styled } from "./OverlappingDrawer.styled";
import {
  CellAcknowledgements,
  CellHistory,
  CellInfo,
  PoleHistory,
  PreflightInfo,
} from "./Components";
import { TabControls } from "../../components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import {
  getAssetsInfo,
  getConstructionInfo,
  getInteraction,
  getIssueInfo,
  getProgramInfo,
} from "@sdge-store/drawer/drawer.async";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { tabs } from "../../layouts/RightDrawer/RightDrawer.constants";
import { ErrorBoundary } from "@sdge-pages";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import { getInteractionByGroup } from "@sdge-store/construction/construction.async";
import { getPreflightCore } from "@sdge-store/customers/customers.async";
import { useDebounce } from "@sdge-hooks";

const CellDrawerRouter = () => {
  const {
    router: { subPath: tabRoute },
  } = useAppSelector(selectRightDrawer);
  switch (tabRoute) {
    case "info":
      return (
        <ErrorBoundary>
          <CellInfo />
        </ErrorBoundary>
      );
    case "history":
      return <CellHistory />;
    case "poleHistory":
      return <PoleHistory />;
    case "core":
      return <PreflightInfo />;
    default:
      return <CellAcknowledgements />;
  }
};

const OverlappingDrawer = () => {
  const dispatch = useAppDispatch();
  const {
    cardId,
    isOpen,
    issueId,
    router: { parentPath },
  } = useAppSelector(selectRightDrawer);

  const { activeConstructionGroupName } = useAppSelector(
    (state) => state.constructionSlice
  );
  const { debounce } = useDebounce(500);
  useEffect(() => {
    if (cardId && isOpen) {
      switch (parentPath) {
        case RightDrawerParentPaths.CUSTOMER_TABLE:
          dispatch(getAssetsInfo());
          dispatch(getInteraction());
          dispatch(getPreflightCore(cardId));
          break;
        case RightDrawerParentPaths.ISSUE_TRACKER_TABLE:
          dispatch(getIssueInfo());
          dispatch(getInteraction());
          break;
        case RightDrawerParentPaths.CONSTRUCTION_TABLE:
          if (activeConstructionGroupName) {
            dispatch(getConstructionInfo());
            debounce(() =>
              dispatch(
                getInteractionByGroup({
                  groupName: activeConstructionGroupName,
                })
              )
            );
          }
          break;
        case RightDrawerParentPaths.OVERLAPPING_TABLE:
        default:
          dispatch(getProgramInfo());
          dispatch(getInteraction());
          break;
      }
      // dispatch(getInteraction());
    }
  }, [cardId, isOpen, issueId, activeConstructionGroupName]);

  return (
    <Styled.CellDrawer>
      <TabControls tabs={tabs[parentPath]} isDrawer />
      <CellDrawerRouter />
    </Styled.CellDrawer>
  );
};

export default OverlappingDrawer;
