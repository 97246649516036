import { ModalWindow } from "../../components";
import React from "react";
import { Box, Typography, Grid, Button, TextField } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { Styled } from "./Tile.styled";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createCustomer } from "@sdge-store/customers/customers.async";
import { useAppSelector } from "../../app/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { createIssueCustomer } from "@sdge-store/issueTracker/issueTracker.async";
import { useLocation } from "react-router-dom";
import { createConstructionCustomer } from "@sdge-store/construction/construction.async";
import { validationSchemaForCustomers } from "./AddCustomer.validation";
import { usePermission } from "@sdge-hooks";

const AddCustomerModal: React.FC<any> = ({ open, setOpen }) => {
  const { pathname } = useLocation();
  const constractionPage = pathname.split("/").includes("construction");
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaForCustomers(constractionPage)),
    mode: "onChange",
  });

  const { getPermission } = usePermission();

  const { activeRowId, buttonDisabled } = useAppSelector(
    (state) => state.customersSlice
  );

  const { activeIssueId, activeIssuefacilityId } = useAppSelector(
    (state) => state.issueTrackerSlice
  );

  const { activeConstructionGroupName, facilityIdList } = useAppSelector(
    (state) => state.constructionSlice
  );

  const items = facilityIdList || [];

  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setOpen(false);
    reset();
  };

  const formSubmit = (values: any) => {
    activeIssueId && !getPermission("addPoleCustomerTile")
      ? dispatch(
          createIssueCustomer({
            id: activeIssueId,
            body: { ...values, facilityId: activeIssuefacilityId },
          })
        )
      : activeIssueId && getPermission("addPoleCustomerTile")
      ? dispatch(createCustomer({ id: activeIssuefacilityId, body: values }))
      : constractionPage
      ? dispatch(
          createConstructionCustomer({
            id: values.facilityId,
            body: values,
            groupName: activeConstructionGroupName,
          })
        )
      : dispatch(createCustomer({ id: activeRowId, body: values }));
    reset();
    setOpen(false);
  };

  return (
    <ModalWindow isOpen={open} onClose={handleCloseModal}>
      <Box width="500px" p="20px">
        <Box mb="20px">
          <Typography>
            {getPermission("addPoleCustomerTile")
              ? "New Pole customer"
              : "New Customer"}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Customer Name # *"
              control={control}
              name="name"
              errorMessage={errors.name?.message}
            />
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Phone Numbers or N/A # *"
              control={control}
              name="phoneNumbers"
              errorMessage={errors.phoneNumbers?.message}
            />
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Emails or N/A # *"
              name="email"
              control={control}
              errorMessage={errors.email?.message}
            />
          </Box>
          {constractionPage && (
            <Box mb="20px">
              <Controller
                name="facilityId"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Autocomplete
                      value={value}
                      onChange={(_, newValue) => {
                        onChange(newValue);
                      }}
                      id="controllable-states-demo"
                      options={items}
                      sx={{ width: 455 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="FacilityId"
                          variant="outlined"
                          error={errors.facilityId?.message}
                        />
                      )}
                    />
                  );
                }}
              />
            </Box>
          )}

          <Box mb="20px">
            <Styled.CustomersInput
              label="APN Address 1 # *"
              control={control}
              name="apnAddress1"
              errorMessage={errors.apnAddress1?.message}
            />
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="APN Address 2 # *"
              control={control}
              name="apnAddress2"
              errorMessage={errors.apnAddress2?.message}
            />
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Account Number # *"
              control={control}
              name="accountNumber"
              errorMessage={errors.accountNumber?.message}
            />
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Ap Info # *"
              control={control}
              name="apInfo"
              errorMessage={errors.apInfo?.message}
            />
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Meter Number # *"
              control={control}
              name="meterNumber"
              errorMessage={errors.meterNumber?.message}
            />
          </Box>
          <Box mb="20px">
            <Styled.CustomersInput
              label="Notes # *"
              control={control}
              name="notes"
              errorMessage={errors.notes?.message}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item>
              <Styled.AddButton
                variant="contained"
                type="submit"
                disabled={buttonDisabled}
              >
                Add Customer
              </Styled.AddButton>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ModalWindow>
  );
};

export default AddCustomerModal;
