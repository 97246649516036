import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const CellInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const CellInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const Row = styled(Box)`
  display: flex;
  gap: 13px;
`;

const Title = styled(Box)<StyledPropsWithTheme>`
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  font-size: 14px;
  line-height: 19.88px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.palette.formItems.textColor};
  flex: 1;
`;

const Content = styled(Box)<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 14px;
  line-height: 19.88px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.palette.formItems.textColor};
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 200px;
`;
const Editable = styled.input<StyledPropsWithTheme>`
  background-color: inherit;
  font-size: 14px;
  color: white;
  border: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 14px;
  line-height: 19.88px;
  letter-spacing: 0;
  width: 100%;
  flex: 1;
  &:focus {
    outline: none;
  }
`;

const NoInfoWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OverlappingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 50px;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const OverlappingForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

const Hr = styled.div`
  border: 1px solid white;
  border-radius: 5px;
  margin-top: 15px;
  width: 100%;
`;

const LoadingLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(143, 143, 143, 0.37);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Styled = {
  CellInfoWrapper,
  CellInfoForm,
  Row,
  Title,
  Content,
  NoInfoWrapper,
  Editable,
  OverlappingWrapper,
  OverlappingForm,
  Hr,
  LoadingLayer,
};
