import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@sdge-store/store";

export const selectUser = createSelector(
  (state: RootState) => ({
    name: state.userSlice.info.name,
    surname: state.userSlice.info.surname,
    email: state.userSlice.info.email,
    roles: state.userSlice.info.roles,
    writePermissions: state.userSlice.info.writePermissions,
    location: state.userSlice.location,
  }),
  (state) => state
);
