import { Link } from "@sdge-components";
import React, { FC, useState } from "react";
import { Styled } from "../../CellInfo.styled";
import EditInput from "../EditInput";
import SelectPermissionQualifier from "../SelectPermissionQualifier";
import { DrawerRowItemType } from "./DrawerRowItem.types";
import { FilterInput } from "@sdge-components/Filtering/components";
import { usePermission } from "@sdge-hooks";

const DrawerRowItem: FC<DrawerRowItemType> = ({
  title,
  content,
  optionsData,
  formRef,
  control,
  name,
  permission,
  autoComplete,
  isEdit,
  isDate,
  onSubmit,
  reset,
  isLink,
  setValue,
}) => {
  const { getPermission } = usePermission();
  const isAutocompleteInput =
    (name === "groupNameEditable" && getPermission("groupNameEditable")) ||
    (name === "groupName" && getPermission("group_name"));

  const isEditAutocompleteInput = getPermission("group_name");
  const notEditableGroupName =
    name === "groupName" && !getPermission("groupNameEditable");

  const defaultValue = (data: string) => {
    return data ?? "";
  };
  const [onHoldValue, setOnHoldValue] = useState(defaultValue(content));
  const [groupName, setGroupName] = useState(defaultValue(content));
  const handleChange = (e: any) => {
    const value = typeof e === "string" ? e : e.target.value;
    name === "groupName" ? setGroupName(value) : setOnHoldValue(value);
  };
  const handleSetInputValue = (value: string) => {
    setValue && setValue(name, value);
  };

  return title && title.length > 0 ? (
    <Styled.Row>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Content>
        {optionsData ? (
          <SelectPermissionQualifier
            optionsData={optionsData}
            changeSubmit={formRef}
            control={control}
            name={name}
            defaultValue={content}
            permission={permission}
            autoComplete={autoComplete}
          />
        ) : isEdit && !notEditableGroupName ? (
          <>
            {isAutocompleteInput && isEditAutocompleteInput ? (
              <FilterInput
                onFilterTextChange={handleChange}
                value={name === "groupName" ? groupName : onHoldValue}
                autoComplete={
                  name === "groupName" ? "group_name" : "onHoldStatusComment"
                }
                onSubmit={() => onSubmit()}
                setInputValue={handleSetInputValue}
              />
            ) : (
              <EditInput
                defaultValue={defaultValue(content)}
                control={control}
                name={name}
                permission={permission}
                isDate={isDate}
                reset={reset}
                onSubmit={() => onSubmit()}
              />
            )}
          </>
        ) : isLink ? (
          content && <Link link={defaultValue(content)} name={title} />
        ) : (
          defaultValue(content)
        )}
      </Styled.Content>
    </Styled.Row>
  ) : null;
};

export default DrawerRowItem;
