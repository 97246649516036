import React, { useContext, useEffect, useRef, useState } from "react";
import { StandardButton } from "@sdge-components";
import { normalizedSeconds } from "@sdge-utils/dateParser";
import { setInterval } from "timers";
import {
  getSessionExpiryTime,
  setLocalSessionExpiryTime,
} from "@sdge-hooks/useAuth/useAuth.utils";
import { useAuth } from "@sdge-hooks";
import { PortalContext } from "../../layouts/PortalLayout";
import { refreshLogin } from "@sdge-utils/helpers/authHelpers/authHelpers";

const FIVE_MINUTE = 5 * 60000;

const SessionSecurityAlert = () => {
  const sessionExpiry = getSessionExpiryTime() ?? FIVE_MINUTE + Date.now();
  const timerInterval = useRef<ReturnType<typeof setTimeout>>();
  const expiry = +sessionExpiry - Date.now();
  const [timer, setTimer] = useState(expiry);
  const { isAuthenticated, handleLogout } = useAuth();
  const { setIsActive } = useContext(PortalContext);

  useEffect(() => {
    timerInterval.current = setInterval(() => {
      if (isAuthenticated && expiry <= FIVE_MINUTE) {
        setTimer((prevTime) => {
          if (prevTime <= 1000) {
            setIsActive(false);
            handleLogout();
            if (timerInterval.current) clearInterval(timerInterval.current);
          }
          return prevTime - 1000;
        });
      } else {
        setIsActive(false);
      }
    }, 1000);
    return () => {
      if (timerInterval.current) clearInterval(timerInterval.current);
    };
  }, []);

  const handleGetMoreTime = () => {
    refreshLogin();
    setLocalSessionExpiryTime();
    setIsActive(false);
    if (timerInterval.current) clearInterval(timerInterval.current);
  };

  if (expiry > FIVE_MINUTE) {
    return null;
  }

  return (
    <div>
      <h1>Your session will expired at {normalizedSeconds(timer / 1000)}</h1>
      <StandardButton title={"Get more time"} onClick={handleGetMoreTime} />
    </div>
  );
};

export default SessionSecurityAlert;
