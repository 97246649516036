import styled from "@emotion/styled";

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem 0;
  width: 100%;
`;

export const Styled = {
  LoadingWrapper,
};
