import styled from "@emotion/styled";
import { Box } from "@material-ui/core";

const CellAcknowledgementsWrapper = styled.div`
  padding: 8px;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const InteractionItemsWrapper = styled(Box)`
  padding: 8px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  width: 171px;
`;

export const Styled = {
  CellAcknowledgementsWrapper,
  InteractionItemsWrapper,
  ButtonWrapper,
};
