import { Box, Grid, IconButton } from "@mui/material";
import { Styled } from "./EnvPermitTileContent.styled";
import { CloseIcon, EditIcon } from "../../icons";
import React, { useEffect, useState } from "react";
import { TextInputWithController, DatePickerInput } from "@sdge-components";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { updateEnvPermits } from "@sdge-store/issueTracker/issueTracker.async";

import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { updateEnvPermitsAssets } from "@sdge-store/customers/customers.async";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { updateEnvPermitsConstruction } from "@sdge-store/construction/construction.async";
import { usePermission } from "@sdge-hooks";

const RowItem: React.FC<{
  title: string;
  content: string;
  name: string;
  date: boolean;
  actualPermitId: any;
  customerPage?: boolean;
  constructionPage?: boolean;
}> = ({
  title,
  content,
  name,
  date,
  customerPage,
  actualPermitId,
  constructionPage,
}) => {
  const [editable, setEditable] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();

  const { getPermission } = usePermission();
  const { activeRowId, envPermitsAssets } = useAppSelector(
    (state) => state.customersSlice
  );
  const {
    activeIssueId,
    envPermits,
    activeIssuefacilityId,
    activeIssueGroupName,
  } = useAppSelector((state) => state.issueTrackerSlice);

  const { envPermitsConstruction, activeConstructionGroupName } =
    useAppSelector(selectConstruction);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  useEffect(() => {
    setEditable(false);
  }, [activeConstructionGroupName, activeIssueId, activeRowId]);

  const notEditableNames =
    name !== "releaseExpirationDate" &&
    (name !== "sharepointLink" || (name == "sharepointLink" && customerPage)) &&
    name !== "permitId";

  const hasPermissions =
    (getPermission("editEnviBesideConstPage") && notEditableNames) ||
    (getPermission("editEnvPermitTile") && notEditableNames) ||
    (getPermission("edit_tile_const") && constructionPage);

  const handleSetEdit = () => {
    reset();
    setEditable(true);
  };

  const handleFormSubmit = (values: any) => {
    const permit = customerPage
      ? envPermitsAssets.filter(
          (item: any) => item.permitId === actualPermitId
        )[0]
      : constructionPage
      ? envPermitsConstruction.filter(
          (item: any) => item.permitId === actualPermitId
        )[0]
      : envPermits.filter((item: any) => item.permitId === actualPermitId)[0];

    const body = {
      ...(customerPage ? {} : { groupName: activeIssueGroupName }),
      ...permit,
      envReleaseDateSubmitted:
        permit.environmentalReleaseDateSubmitted ||
        permit.envReleaseDateSubmitted,
      envReleaseIssuedDate:
        permit.environmentalReleaseIssuedDate || permit.envReleaseIssuedDate,
      [name]: values[name],
    };

    customerPage
      ? dispatch(
          updateEnvPermitsAssets({
            body,
            facilityId: activeRowId,
          })
        )
      : constructionPage
      ? dispatch(
          updateEnvPermitsConstruction({
            body: { ...body, groupName: activeConstructionGroupName },
            groupName: activeConstructionGroupName,
          })
        )
      : dispatch(
          updateEnvPermits({
            id: activeIssueId,
            body,
            facilityId: activeIssuefacilityId,
          })
        );
    setEditable(false);
  };

  return (
    <Box pb="11px" pt="11px">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Box width="40%" mr="10px">
            <Styled.Title>{title}</Styled.Title>
          </Box>
          {editable && name !== "facilityId" && name ? (
            <>
              {date ? (
                <DatePickerInput
                  name={name as `${string}`}
                  control={control}
                  readOnly
                  parseFormat="YYYY-MM-DD"
                />
              ) : (
                <TextInputWithController
                  defaultValue={content}
                  name={name as `${string}`}
                  control={control}
                  multiline={true}
                />
              )}
            </>
          ) : (
            <Styled.Content noWrap={false}>{content}</Styled.Content>
          )}
          <>
            {programInfo.bmcdTier3 !== "Y" &&
            hasPermissions &&
            name !== "facilityId" ? (
              <>
                {editable ? (
                  <>
                    {date && (
                      <IconButton
                        onClick={() => {
                          setValue(name as `${string}`, "");
                        }}
                      >
                        <CloseIcon size={14} />
                      </IconButton>
                    )}
                    <Button
                      type="submit"
                      endIcon={<EditIcon size={14} />}
                    ></Button>
                  </>
                ) : (
                  <IconButton onClick={handleSetEdit}>
                    <EditIcon size={14} />
                  </IconButton>
                )}
              </>
            ) : (
              <Box></Box>
            )}
          </>
        </Grid>
      </form>
    </Box>
  );
};

export default RowItem;
