import { yupResolver } from "@hookform/resolvers/yup";
import { StandardButton, TextInputWithController } from "@sdge-components";
import { generateAddQuickView } from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import React, { FC } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { AddMenuType } from "./AddMenu.types";
import addViewValidationSchema from "./AddMenu.validation";
import { Styled } from "./AddViewMenu.styled";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { usePermission } from "@sdge-hooks";
import { selectUser } from "@sdge-store/user/user.selector";
import { Constants } from "@sdge-store/user/constants/user.constants";

const AddViewMenu: FC<AddMenuType> = ({
  columnsOrder,
  filters,
  hiddenColumns,
  addColumnAction,
  frozenColumns,
  setClose,
  orders,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addViewValidationSchema),
  });
  const dispatch = useAppDispatch();
  const { getPermission } = usePermission();
  const { roles } = useAppSelector(selectUser);
  const hasPermissions =
    getPermission("customerSingleEdit") || getPermission("readonly");
  const isDiarAdminRole =
    roles.includes(Constants.roleAdminDev) ||
    roles.includes(Constants.roleAdminProd);
  const formSubmit = (data: any) => {
    dispatch(setClose(false));
    dispatch(
      addColumnAction(
        generateAddQuickView(
          columnsOrder,
          filters,
          hiddenColumns,
          frozenColumns,
          data.name.trim(),
          orders,
          data.privacy
        )
      )
    );
  };

  return (
    <Styled.AddMenuWrapper onSubmit={handleSubmit(formSubmit)}>
      <TextInputWithController
        label="New View Name"
        control={control}
        name="name"
        errorMessage={errors.name?.message}
      />
      <FormControlLabel
        control={
          <Controller
            name="privacy"
            control={control}
            defaultValue={true}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                onChange={onChange}
                checked={value}
                disabled={hasPermissions || !isDiarAdminRole}
              />
            )}
          />
        }
        label="Private"
      />

      <Styled.Row gap>
        <StandardButton
          title="Add"
          type="submit"
          pallet="primary"
          onClick={handleSubmit(formSubmit)}
        />
        <StandardButton
          title="Cancel"
          type="button"
          pallet="secondary"
          onClick={() => dispatch(setClose(false))}
        />
      </Styled.Row>
    </Styled.AddMenuWrapper>
  );
};

export default AddViewMenu;
