import { FC, ChangeEvent } from "react";
import { Styled } from "./MassUpdateInput.styled";

const MassUpdateInput: FC<{
  onTextChange: (
    event: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  value: string;
  autoComplete?: string;
}> = ({ onTextChange, value, autoComplete }) => {
  return (
    <Styled.MassUpdateInputWrapper>
      <Styled.MassUpdateInput
        autoComplete={autoComplete}
        onChange={onTextChange}
        value={value}
      />
    </Styled.MassUpdateInputWrapper>
  );
};

export default MassUpdateInput;
