import { Link } from "@sdge-components";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { useAppSelector } from "../../app/hooks";
import { parseRowDateString } from "@sdge-utils/dateParser";

const useMuniPermitContentCreator = (
  customerPage: boolean | undefined,
  constructionPage: boolean | undefined
) => {
  const { muniPermits } = useAppSelector((state) => state.issueTrackerSlice);
  const { muniPermitsAssets } = useAppSelector((state) => state.customersSlice);
  const { muniPermitsConstruction } = useAppSelector(selectConstruction);

  const sortedArr = customerPage
    ? [...muniPermitsAssets]
    : constructionPage
    ? [...muniPermitsConstruction]
    : [...muniPermits];

  const content =
    sortedArr.length >= 2
      ? sortedArr.sort((a, b) =>
          (a.permitType || "")
            .toString()
            .localeCompare((b.permitType || "").toString())
        )
      : sortedArr;
  const regex = new RegExp(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
  );
  return content.length
    ? content?.map((item: any) => {
        const validateLink = regex.test(item?.sharepointLink);
        return [
          {
            id: 1,
            title: "Muni Permit Type",
            content: item?.permitType || "-",
            name: "permitType",
          },
          {
            id: 2,
            title: "Muni Permit Date Submitted",
            content:
              (item?.permitDateSubmitted &&
                parseRowDateString(item?.permitDateSubmitted)) ||
              "-",
            name: "permitDateSubmitted",
            date: true,
          },
          {
            id: 4,
            title: "Muni Permit Issued Date",
            content:
              (item?.permitIssuedDate &&
                parseRowDateString(item?.permitIssuedDate)) ||
              "-",
            name: "permitIssuedDate",
            date: true,
          },
          {
            id: 7,
            title: "Muni Constraints",
            content: item?.permitTimeFrame || "-",
            name: "permitTimeFrame",
          },
          {
            id: 5,
            title: "Muni Permit Expiration Date",
            content:
              (item?.permitExpirationDate &&
                parseRowDateString(item?.permitExpirationDate)) ||
              "-",
            name: "permitExpirationDate",
            date: true,
          },
          {
            id: 3,
            title: "K2 Reference",
            content: item?.k2Reference || "-",
            name: "k2Reference",
          },
          {
            id: 6,
            title: "Sharepoint Link",
            content:
              item?.sharepointLink && validateLink ? (
                <Link link={item.sharepointLink} name="Sharepont Link" />
              ) : (
                "-"
              ),
            name: "sharepointLink",
          },
          {
            id: 16,
            title: "",
            content: "",
            permitId: item?.permitId || "",
            name: "permitId",
          },
        ];
      })
    : [];
};

export default useMuniPermitContentCreator;
