import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DrawerRouterTypes,
  DrawerStateTypes,
  QuickActions,
} from "./drawer.types";
import {
  getInteraction,
  getNotifications,
  setInteraction,
  getProgramInfo,
  deleteInteraction,
  updateInteraction,
  getAssetsInfo,
  getIssueInfo,
  setAcknowledge,
  getPoleHistory,
  setProgramInfoIssueTracker,
  getConstructionInfo,
  setProgramInfoConstruction,
  setAssetsInfo,
  startAggregation,
  startGating,
} from "./drawer.async";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import {
  updateSelectedRows as updateSelectedRowsIssueTracker,
  updateFilteredRows as updateFilteredRowsIssueTracker,
} from "@sdge-store/issueTracker/issueTracker.async";

import {
  updateSelectedRows as updateSelectedRowsCustomer,
  updateFilteredRows as updateFilteredRowsCustomer,
} from "@sdge-store/customers/customers.async";
import {
  updateSelectedRows as updateSelectedRowsConstruction,
  updateFilteredRows as updateFilteredRowsConstruction,
} from "@sdge-store/construction/construction.async";

const initialDrawerState: DrawerStateTypes = {
  drawerOptions: {
    title: "Notifications",
    cardId: "",
    issueId: "",
    numberOfPoles: "",
    isOpen: false,
    manual: false,
  },
  notificationsData: {
    notificationPage: 0,
    notificationPaginationLoading: true,
    notificationLoading: false,
    notifications: [],
    notificationsCount: 0,
    lastPage: false,
  },
  interactionsData: {
    interactionsLoading: false,
    interactions: [],
  },
  programData: {
    programInfoLoading: false,
    isLoaded: true,
    programInfo: {},
    overlappingInfo: [],
    rightSideBarUpdateLoading: false,
  },
  poleHistory: [],
  selectedNotification: {},
  selectedInteraction: {},
  interactionFormKey: "formKey",
  router: {
    parentPath: RightDrawerParentPaths.NOTIFICATION,
    backArrow: false,
    prevPath: "/",
    newPath: "/",
    subPath: "acknowledgements",
  },
  quickActions: {
    aggregation: {
      loading: false,
      shouldWait: false,
      interval: 0,
    },
    aggregationConstructionTracker: {
      loading: false,
      shouldWait: false,
      interval: 0,
    },
  },
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: initialDrawerState,
  reducers: {
    handleChangeDrawerOptions: (
      state,
      action: PayloadAction<{ [key: string]: string | boolean }>
    ) => {
      if (action.payload) {
        state.drawerOptions = { ...state.drawerOptions, ...action.payload };
        if (
          (action.payload.cardId || action.payload.title) &&
          (state.drawerOptions.title !== action.payload.title ||
            state.drawerOptions.cardId !== action.payload.cardId)
        ) {
          state.programData.isLoaded = true;
        }
      }
    },
    handleChangeQuickActionInterval: (
      state: DrawerStateTypes,
      {
        payload,
      }: PayloadAction<{ intervalKey: QuickActions; interval: number }>
    ) => {
      const { interval, intervalKey } = payload;
      state.quickActions[intervalKey].interval = interval;
      if (!interval) {
        state.quickActions[intervalKey].shouldWait = false;
      }
    },
    handleRightDrawerClose: (state: DrawerStateTypes) => {
      const bmcdTier3 = state.programData.programInfo.bmcdTier3;
      const ballInCourt = state.programData.programInfo.ballInCourt;
      state.drawerOptions.title = "Notifications";
      state.drawerOptions.cardId = "";
      state.drawerOptions.issueId = "";
      state.drawerOptions.isOpen = false;
      state.interactionsData = {
        interactionsLoading: false,
        interactions: [],
      };
      state.programData = {
        programInfoLoading: false,
        isLoaded: true,
        programInfo: { ballInCourt, bmcdTier3 },
        overlappingInfo: [],
        rightSideBarUpdateLoading: false,
      };
      state.selectedNotification = {};
      state.selectedInteraction = {};
    },

    handleRouterChange: (
      state,
      action: PayloadAction<Partial<DrawerRouterTypes>>
    ) => {
      state.router = { ...state.router, ...action.payload };
    },
    handleSetNotification: (state, action: any) => {
      state.selectedNotification = action.payload;
    },
    setEditableInteraction: (state, action: any) => {
      state.interactionFormKey = state.interactionFormKey
        .split("")
        .reverse()
        .join("");
      state.selectedInteraction = action.payload;
    },
    handleClearDrawerState: (state: DrawerStateTypes) => {
      state.interactionsData = {
        interactionsLoading: false,
        interactions: [],
      };
      state.programData = {
        programInfoLoading: false,
        isLoaded: true,
        programInfo: {},
        overlappingInfo: [],
        rightSideBarUpdateLoading: false,
      };
      state.selectedNotification = {};
      state.selectedInteraction = {};
    },
    handleNotificationLoading: (
      state: DrawerStateTypes,
      action: PayloadAction<boolean>
    ) => {
      state.notificationsData.notificationPaginationLoading = action.payload;
    },
    resetNotifications: (state: DrawerStateTypes) => {
      state.notificationsData.notifications = [];
      state.notificationsData.notificationPage = 0;
      state.notificationsData.lastPage = false;
    },
    SetManual: (state: DrawerStateTypes, action: any) => {
      state.drawerOptions.manual = action.payload;
    },
  },
  extraReducers: {
    [setAcknowledge.fulfilled]: (state, action) => {
      if (action.payload || action.payload === 0) {
        state.notificationsData.notifications.splice(action.payload, 1);
      }
    },
    [getNotifications.pending]: (state: DrawerStateTypes) => {
      if (!state.notificationsData.notificationPage)
        state.notificationsData.notificationLoading = true;
      if (state.notificationsData.lastPage) {
        state.notificationsData.notificationPaginationLoading = false;
      }
    },
    [getNotifications.fulfilled]: (state: DrawerStateTypes, action: any) => {
      state.notificationsData.notificationPaginationLoading = true;
      if (!state.notificationsData.notificationPage)
        state.notificationsData.notificationLoading = false;

      if (action.payload) {
        state.notificationsData.notifications.push(...action.payload.content);
        state.notificationsData.notificationsCount =
          action.payload.totalElements;
        state.notificationsData.lastPage = action.payload.last;
        state.notificationsData.notificationPage += 1;
      }
    },
    [getInteraction.fulfilled]: (state: DrawerStateTypes, action: any) => {
      if (action.payload) state.interactionsData.interactions = action.payload;
      state.interactionsData.interactionsLoading = false;
    },
    [getInteraction.pending]: (state: DrawerStateTypes) => {
      state.interactionsData.interactionsLoading = true;
    },
    [setInteraction.fulfilled]: (state: DrawerStateTypes, action: any) => {
      if (action.payload)
        state.interactionsData.interactions = [
          ...state.interactionsData.interactions,
          action.payload,
        ];
    },
    [updateInteraction.fulfilled]: (state: DrawerStateTypes, action: any) => {
      if (action.payload)
        state.interactionsData.interactions.forEach((item, index) => {
          if (item.id === action.payload.id) {
            state.interactionsData.interactions[index] = action.payload;
          }
        });
    },
    [deleteInteraction.fulfilled]: (
      state: DrawerStateTypes,
      action: PayloadAction<{ index: number }>
    ) => {
      if (action.payload)
        state.interactionsData.interactions.splice(action.payload.index, 1);
    },
    [getProgramInfo.fulfilled]: (state: DrawerStateTypes, action: any) => {
      state.programData.programInfoLoading = false;
      if (action.payload) {
        state.programData.isLoaded = true;
        state.programData.overlappingInfo = action.payload;
      } else {
        state.programData.isLoaded = false;
        state.programData.overlappingInfo = [];
      }
    },
    [getProgramInfo.pending]: (state: DrawerStateTypes) => {
      state.programData.programInfoLoading = true;
      state.programData.isLoaded = false;
      state.programData.overlappingInfo = [];
    },
    [getAssetsInfo.fulfilled]: (state: DrawerStateTypes, action: any) => {
      state.programData.programInfoLoading = false;
      if (action.payload) {
        state.programData.isLoaded = true;
        state.programData.programInfo = action.payload;
      } else {
        state.programData.isLoaded = false;
        state.programData.programInfo = {};
      }
    },
    [getAssetsInfo.pending]: (state: DrawerStateTypes) => {
      state.programData.programInfoLoading = true;
      state.programData.isLoaded = false;
      state.programData.programInfo = {};
    },
    [getIssueInfo.fulfilled]: (state: DrawerStateTypes, action: any) => {
      state.programData.programInfoLoading = false;
      if (action.payload) {
        state.programData.isLoaded = true;
        state.programData.programInfo = action.payload;
      } else {
        state.programData.isLoaded = false;
        state.programData.programInfo = {};
      }
    },
    [getIssueInfo.pending]: (state: DrawerStateTypes) => {
      state.programData.programInfoLoading = true;
      state.programData.isLoaded = false;
      state.programData.programInfo = {};
    },
    [getConstructionInfo.fulfilled]: (state: DrawerStateTypes, action: any) => {
      state.programData.programInfoLoading = false;
      if (action.payload) {
        state.programData.isLoaded = true;
        state.programData.programInfo = action.payload;
      } else {
        state.programData.isLoaded = false;
        state.programData.programInfo = {};
      }
    },
    [getConstructionInfo.pending]: (state: DrawerStateTypes) => {
      state.programData.programInfoLoading = true;
      state.programData.isLoaded = false;
      state.programData.programInfo = {};
    },
    [getPoleHistory.fulfilled]: (state: DrawerStateTypes, action: any) => {
      if (action.payload) {
        state.poleHistory = action.payload;
      }
    },
    //freezing rightsidebar when issue tracker mass update
    [updateSelectedRowsIssueTracker.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [updateFilteredRowsIssueTracker.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [updateSelectedRowsIssueTracker.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateFilteredRowsIssueTracker.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateSelectedRowsIssueTracker.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateFilteredRowsIssueTracker.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    //freezing rightsidebar when customers mass update
    [updateSelectedRowsCustomer.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [updateFilteredRowsCustomer.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [updateSelectedRowsCustomer.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateFilteredRowsCustomer.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateSelectedRowsCustomer.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateFilteredRowsCustomer.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    //freezing rightsidebar when construction mass update
    [updateSelectedRowsConstruction.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [updateFilteredRowsConstruction.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [updateSelectedRowsConstruction.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateFilteredRowsConstruction.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateSelectedRowsConstruction.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [updateFilteredRowsConstruction.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },

    [setProgramInfoIssueTracker.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [setProgramInfoIssueTracker.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [setProgramInfoIssueTracker.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [setProgramInfoConstruction.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [setProgramInfoConstruction.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [setProgramInfoConstruction.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [setAssetsInfo.pending]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = true;
    },
    [setAssetsInfo.fulfilled]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    [setAssetsInfo.rejected]: (state: DrawerStateTypes) => {
      state.programData.rightSideBarUpdateLoading = false;
    },
    //////////////// QUICK ACTIONS /////////////////
    [startAggregation.pending]: (state: DrawerStateTypes) => {
      state.quickActions.aggregation.loading = true;
    },
    [startAggregation.fulfilled]: (state: DrawerStateTypes) => {
      state.quickActions.aggregation.loading = false;
      state.quickActions.aggregation.shouldWait = true;
    },
    [startGating.pending]: (state: DrawerStateTypes) => {
      state.quickActions.aggregationConstructionTracker.loading = true;
    },
    [startGating.fulfilled]: (state: DrawerStateTypes) => {
      state.quickActions.aggregationConstructionTracker.loading = false;
      state.quickActions.aggregationConstructionTracker.shouldWait = true;
    },
  },
});

export const {
  handleNotificationLoading,
  handleChangeDrawerOptions,
  handleRouterChange,
  handleSetNotification,
  setEditableInteraction,
  handleRightDrawerClose,
  handleClearDrawerState,
  resetNotifications,
  handleChangeQuickActionInterval,
  SetManual,
} = drawerSlice.actions;
export default drawerSlice.reducer;
