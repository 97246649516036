import { FC } from "react";
import { Styled } from "./Card.styled";
import { Box } from "@material-ui/core";
import { CardProps } from "./Card.types";

const Card: FC<CardProps> = ({ active, cardInfo, onClick }) => {
  return (
    <Styled.CardWrapper active={active} onClick={onClick}>
      <Styled.Row bold>
        <Box component="span">{cardInfo.program_name}</Box>
        <Box component="span">{cardInfo.total_overlap}</Box>
      </Styled.Row>
      <Styled.Row disabled={cardInfo.open_issues === 0}>
        <Box component="span">Open</Box>
        <Box component="span">{cardInfo.open_issues}</Box>
      </Styled.Row>
      <Styled.Row disabled={cardInfo.to_be_assessed === 0}>
        <Box component="span">Not Assessed</Box>
        <Box component="span">{cardInfo.to_be_assessed}</Box>
      </Styled.Row>
      <Styled.Row disabled={cardInfo.not_flown === 0}>
        <Box component="span">Not flown</Box>
        <Box component="span">{cardInfo.not_flown}</Box>
      </Styled.Row>
    </Styled.CardWrapper>
  );
};

export default Card;
