import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@sdge-store/store";

export const selectRightDrawer = createSelector(
  (state: RootState) => ({
    title: state.drawerState.drawerOptions.title,
    cardId: state.drawerState.drawerOptions.cardId,
    issueId: state.drawerState.drawerOptions.issueId,
    numberOfPoles: state.drawerState.drawerOptions.numberOfPoles,
    isOpen: state.drawerState.drawerOptions.isOpen,
    router: state.drawerState.router,
    poleHistory: state.drawerState.poleHistory,
    notificationsData: state.drawerState.notificationsData,
    interactionsData: state.drawerState.interactionsData,
    selectedInteraction: state.drawerState.selectedInteraction,
    selectedNotification: state.drawerState.selectedNotification,
    interactionFormKey: state.drawerState.interactionFormKey,
    quickAction: state.drawerState.quickActions,
  }),
  (state) => state
);
