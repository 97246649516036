import { getAccessToken } from "./useAuth.utils";
import { useAppDispatch } from "../../app/hooks";
import { login, logout } from "../../app/store/user/user.async";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { handleRightDrawerClose } from "@sdge-store/drawer/drawer.slice";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(handleRightDrawerClose());
    dispatch(logout());
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params?.code) {
      dispatch(login({ code: params.code, history }));
    }
  }, []);
  const isAuthenticated = !!getAccessToken();

  return { isAuthenticated, handleLogout };
};

export default useAuth;
