import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryInstance } from "@sdge-api/config";
import { errorHandler } from "@sdge-api/utils";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { parseDateString } from "@sdge-utils/dateParser";
import {
  handleRightDrawerClose,
  handleRouterChange,
} from "@sdge-store/drawer/drawer.slice";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";

export const fetchIssueData: any = createAsyncThunk(
  "issueTracker/fetchIssueData",
  async (sortingRules: any, { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      // add rules to the sorting rules filter property
      const updatedSortingRules = [
        sortingRules[0], // page
        sortingRules[1], // sort
        [...sortingRules[2], ...rules], // filter
        sortingRules[3], // isQuickViews
      ];

      try {
        let response;
        if (updatedSortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/issue-tracker?page=${updatedSortingRules[0]}${updatedSortingRules[1]}`,
            { timeout: 120000 }
          );
        } else {
          response = await queryInstance.post(
            `/issue-tracker?page=${updatedSortingRules[0]}${updatedSortingRules[1]}`,
            updatedSortingRules[2]
          );
        }
        if (updatedSortingRules[3] === true) {
          return {
            ...response?.data,
            sortingRules: updatedSortingRules[1],
            isQuickViews: true,
          };
        }
        return {
          ...response?.data,
          sortingRules: updatedSortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/issue-tracker?page=${sortingRules[0]}${sortingRules[1]}`,
            { timeout: 120000 }
          );
        } else {
          response = await queryInstance.post(
            `/issue-tracker?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
        }
        if (sortingRules[3] === true) {
          return {
            ...response?.data,
            sortingRules: sortingRules[1],
            isQuickViews: true,
          };
        }
        return {
          ...response?.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const sortIssueData: any = createAsyncThunk(
  "issueTracker/sorting",
  async (sortingRules: any, { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      const param =
        sortingRules[1].indexOf("#") > 0
          ? sortingRules[1].replace("#", "%23")
          : sortingRules[1];
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.post(
            `/issue-tracker?page=${sortingRules[0]}${param}`,
            rules,
            {
              timeout: 120000,
            }
          );
        } else {
          response = await queryInstance.post(
            `/issue-tracker?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
        }
        return {
          ...response?.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      const param =
        sortingRules[1].indexOf("#") > 0
          ? sortingRules[1].replace("#", "%23")
          : sortingRules[1];
      try {
        let response;
        if (sortingRules[2].length === 0) {
          response = await queryInstance.get(
            `/issue-tracker?page=${sortingRules[0]}${param}`,
            {
              timeout: 120000,
            }
          );
        } else {
          response = await queryInstance.post(
            `/issue-tracker?page=${sortingRules[0]}${sortingRules[1]}`,
            sortingRules[2]
          );
        }
        return {
          ...response?.data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const filterIssueData: any = createAsyncThunk(
  "issueTracker/filtering",
  async (sortingRules: any, { dispatch, getState }) => {
    const state: any = getState();
    const bmcd = state.issueTrackerSlice.bmcdTierVisibility;
    if (!bmcd) {
      const rules = [
        {
          fieldName: "bmcd_tier_3",
          negate: true,
          operation: "equals",
          relation: "AND",
          value: "Y",
        },
      ];
      const formatedSortingRules = sortingRules[2].map((item: any) => {
        if (
          item.operation !== "range" &&
          (item.fieldName === "issue_approved_date" ||
            item.fieldName === "goal_date" ||
            item.fieldName === "e2_initiation_complete_date" ||
            item.fieldName === "approvedat" ||
            item.fieldName === "sap_jn_form_submitted_date")
        ) {
          return { ...item, value: parseDateString(item.value) };
        }
        return item;
      });

      // add rules to the sorting rules
      const updatedSortingRules = [
        sortingRules[0], // page
        sortingRules[1], // sort
        [...formatedSortingRules, ...rules], // filter
      ];

      try {
        const { data } = await queryInstance.post(
          `/issue-tracker?page=${updatedSortingRules[0]}${updatedSortingRules[1]}`,
          updatedSortingRules[2]
        );
        return {
          ...data,
          sortingRules: updatedSortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    } else {
      const formatedSortingRules = sortingRules[2].map((item: any) => {
        if (
          item.operation !== "range" &&
          (item.fieldName === "issue_approved_date" ||
            item.fieldName === "goal_date" ||
            item.fieldName === "e2_initiation_complete_date" ||
            item.fieldName === "approvedat" ||
            item.fieldName === "sap_jn_form_submitted_date")
        ) {
          return { ...item, value: parseDateString(item.value) };
        }
        return item;
      });
      try {
        const { data } = await queryInstance.post(
          `/issue-tracker?page=${sortingRules[0]}${sortingRules[1]}`,
          formatedSortingRules
        );
        return {
          ...data,
          sortingRules: sortingRules[1],
        };
      } catch (e) {
        errorHandler(e, dispatch);
      }
    }
  }
);

export const getTilesInfo: any = createAsyncThunk(
  "issueTracker/getTilesInfo",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/issue-tracker/${payload}`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const fetchIssuesTrackerQuickViews: any = createAsyncThunk(
  "quick-views/issue-tracker",
  async () => {
    const response = await queryInstance.get("/quick-views/issue-tracker", {
      timeout: 120000,
    });
    return response.data.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
  }
);

export const addIssuesTrackerQuickView: any = createAsyncThunk(
  "quick-views/issue-tracker/post",
  async (body: any, { dispatch }) => {
    try {
      const response = await queryInstance.post(
        "/quick-views/issue-tracker",
        body
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was created",
        })
      );
      return response.data;
    } catch (error) {
      errorHandler(error, dispatch);
    }
  }
);
export const updateIssuesTrackerQuickView: any = createAsyncThunk(
  "quick-views/issue-tracker/put",
  async (body: any, { dispatch }) => {
    try {
      const response = await queryInstance.put(
        "/quick-views/issue-tracker",
        body
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was updated",
        })
      );
      return response.data;
    } catch (error) {
      errorHandler(error, dispatch);
    }
  }
);

export const updateFilteredRows: any = createAsyncThunk(
  "issue-tracker/issues-filters",
  async (payload: any, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const searchText = state.issueTrackerSlice.searchInputValue;
      const searchItems = searchText.substring(1).split("=");
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        `/issue-tracker/issues-filters`,
        { searchText: searchItems[1], ...payload },
        { headers }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Mass Update was made",
        })
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateSelectedRows: any = createAsyncThunk(
  "issue-tracker/issues",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        "/issue-tracker/issues",
        {
          ...payload,
        },
        { headers }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Mass Update was made",
        })
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getIssueTrackerCustomersList: any = createAsyncThunk(
  "issue-tracker/getIssueTrackerCustomersList",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/issue-tracker/${payload}/customers`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getIssueTrackerOverlapping: any = createAsyncThunk(
  "issue-tracker/getIssueTrackerOverlapping",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/issue-tracker/${payload}/overlapping`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getSapTileContent: any = createAsyncThunk(
  "issue-tracker/getSapTileContent",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/issue-tracker/${payload}/sap`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getPermits: any = createAsyncThunk(
  "issue-tracker/getPermits",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/issue-tracker/${payload}/permits`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const CreateIssue: any = createAsyncThunk(
  "issue-tracker/createIssue",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        `/issue-tracker/issue`,
        payload,
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The issue is created",
        })
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const UpdateManualIssue: any = createAsyncThunk(
  "issue-tracker/updateIssue",
  async (payload: any, { getState, dispatch }) => {
    const state: any = getState();
    const cellInfoCopy = { ...payload };
    delete cellInfoCopy.propagateFacilityChanges;
    delete cellInfoCopy.id;
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/issue-tracker/${payload.id}/manually`,
        cellInfoCopy,
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The issue is updated",
        })
      );
      const { sortingRules, filter } = state.issueTrackerSlice;
      dispatch(fetchIssueData([0, sortingRules, filter, true]));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updatePermits: any = createAsyncThunk(
  "issue-tracker/updatePermits",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/issue-tracker/${payload.id}/permits`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The permit is updated",
        })
      );
      dispatch(getPermits(payload.id));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateSap: any = createAsyncThunk(
  "issue-tracker/updateSap",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/issue-tracker/${payload.id}/sap`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The value is updated",
        })
      );
      await dispatch(getSapTileContent(payload.id));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
export const updateCustomers: any = createAsyncThunk(
  "issue-tracker/updateCustomers",
  async (payload: any, { dispatch, getState }) => {
    const state: any = getState();
    const { activeIssuefacilityId } = state.issueTrackerSlice;
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/issue-tracker/${payload.id}/customers/${payload.customerId}`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The value is updated",
        })
      );
      await dispatch(getIssueTrackerCustomersList(activeIssuefacilityId));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const createIssueCustomer: any = createAsyncThunk(
  "issue-tracker/createIssueCustomer",
  async (payload: any, { dispatch, getState }) => {
    const state: any = getState();
    const { activeIssuefacilityId } = state.issueTrackerSlice;
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.post(
        `/issue-tracker/${payload.id}/customers`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The customer is created",
        })
      );
      dispatch(getIssueTrackerCustomersList(activeIssuefacilityId));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteQuickView: any = createAsyncThunk(
  "issue_tracker/deleteQuickView",
  async (payload: any, { dispatch }) => {
    try {
      await queryInstance.delete(`/quick-views/issue-tracker/${payload}`);
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Quick View was deleted",
        })
      );
      return payload;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getPackageSummary: any = createAsyncThunk(
  "issue_tracker/getPackageSummary",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/env-permit/summary/package`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getPoleSummary: any = createAsyncThunk(
  "issue_tracker/getPoleSummary",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(`/env-permit/summary/pole`);
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getEnvPermits: any = createAsyncThunk(
  "issue-tracker/getEnvPermits",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/issue-tracker/${payload}/env-permit`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getMuniPermits: any = createAsyncThunk(
  "issue-tracker/getMuniPermits",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.get(
        `/issue-tracker/${payload}/muni-permit`
      );
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateEnvPermits: any = createAsyncThunk(
  "issue-tracker/updateEnvPermits",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/issue-tracker/${payload.id}/${payload.facilityId}/env-permit`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Env Permit is updated",
        })
      );
      await dispatch(getEnvPermits(payload.id));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteEnvPermit: any = createAsyncThunk(
  "issue-tracker/deleteEnvPermit",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.delete(
        `/issue-tracker/${payload.envPermitsId}/env-permit`
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Env Permit is deleted",
        })
      );
      await dispatch(getEnvPermits(payload.activeIssueId));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteIssue: any = createAsyncThunk(
  "issue-tracker/deleteIssue",
  async (payload: any, { getState, dispatch }) => {
    const state: any = getState();
    const { sortingRules, filter } = state.issueTrackerSlice;
    try {
      const { data } = await queryInstance.delete(
        `issue-tracker/${payload}/delete-issue`
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Issue was deleted",
        })
      );
      const routerOptions = {
        backArrow: false,
        newPath: "/",
        parentPath: RightDrawerParentPaths.ISSUE_TRACKER_TABLE,
        prevPath: "/",
        subPath: "acknowledgements",
      };
      dispatch(handleRightDrawerClose());
      dispatch(handleRouterChange(routerOptions));
      dispatch(fetchIssueData([0, sortingRules, filter, true]));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const deleteMuniPermit: any = createAsyncThunk(
  "issue-tracker/deleteMuniPermit",
  async (payload: any, { dispatch }) => {
    try {
      const { data } = await queryInstance.delete(
        `/issue-tracker/${payload.muniPermitsId}/muni-permit`
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Muni Permit is deleted",
        })
      );
      await dispatch(getMuniPermits(payload.activeIssueId));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const updateMuniPermits: any = createAsyncThunk(
  "issue-tracker/updateMuniPermits",
  async (payload: any, { dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const { data } = await queryInstance.put(
        `/issue-tracker/${payload.id}/${payload.facilityId}/muni-permit`,
        { ...payload.body },
        {
          headers,
        }
      );
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "The Muni Permit is updated",
        })
      );
      await dispatch(getMuniPermits(payload.id));
      return data;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
