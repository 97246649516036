import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const CellDrawer = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  & .MuiTabs-root {
    margin: 0 20px;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.background.default};
  }
  & .MuiTabs-flexContainer {
    padding: 4px 4px 8px;
  }
  & .Mui-selected {
    background-color: ${({ theme }) =>
      theme.palette.tables?.checkbox?.checkedBackground};
    box-shadow: none;
  }
`;

export const Styled = {
  CellDrawer,
};
