import { FC } from "react";
import { useAppSelector } from "../../../../app/hooks";
import {
  OverlappingInfo,
  CustomerInfo,
  IssueTrackerInfo,
  ConstructionInfo,
} from "./Components";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";

const CellInfo: FC = () => {
  const parentPath = useAppSelector(
    (state) => state.drawerState.router.parentPath
  );

  switch (parentPath) {
    case RightDrawerParentPaths.CUSTOMER_TABLE:
      return <CustomerInfo />;
    case RightDrawerParentPaths.ISSUE_TRACKER_TABLE:
      return <IssueTrackerInfo />;
    case RightDrawerParentPaths.CONSTRUCTION_TABLE:
      return <ConstructionInfo />;
    default:
      return <OverlappingInfo />;
  }
};
export default CellInfo;
