import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import themeReducer from "./theme/theme.slice";
import drawerReducer from "./drawer/drawer.slice";
import programsSlice from "./programs/programs.slice";
import overlapsSlice from "./overlaps/overlaps.slice";
import userReducer from "./user/user.slice";
import mapsReducer from "./Map/map.slice";
import issueTrackerSlice from "./issueTracker/issueTracker.slice";
import { snackbarReducer } from "./snackbar";
import customersSlice from "./customers/customers.slice";
import autoCompleteSlice from "./autoComplete/autoComplete.slice";
import constructionSlice from "./construction/construction.slice";
export const store = configureStore({
  reducer: {
    appTheme: themeReducer,
    programsSlice: programsSlice,
    drawerState: drawerReducer,
    overlapsSlice: overlapsSlice,
    customersSlice: customersSlice,
    issueTrackerSlice: issueTrackerSlice,
    constructionSlice: constructionSlice,
    userSlice: userReducer,
    maps: mapsReducer,
    snackbar: snackbarReducer,
    autoComplete: autoCompleteSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
