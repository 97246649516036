import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { menuItems } from "../../utils";
import { MenuItem } from "../../components";
import { DrawerPropsType, Direction } from "./MainLayout.types";
import { useTheme } from "@material-ui/core/styles";
import { Box, Divider, IconButton, List } from "@material-ui/core";
import { Styled } from "./MainLayout.styled";
import SDGE from "../../assets/logo.png";
import Logo from "../../assets/e2dataio.svg";

const Drawer: React.FC<DrawerPropsType> = ({ handleDrawerClose, isDark }) => {
  const { direction } = useTheme();

  return (
    <Box>
      <Styled.DrawerTop>
        <Styled.AppName>DIAR(Dev)</Styled.AppName>
        <IconButton onClick={handleDrawerClose}>
          {direction === Direction.LeftToRight ? (
            <ChevronLeft />
          ) : (
            <ChevronRight />
          )}
        </IconButton>
      </Styled.DrawerTop>
      <Divider />
      <Styled.MainIcon>
        {!isDark ? (
          <img src="/icon.png" alt="SCGE icon" />
        ) : (
          <>
            <img src={SDGE} alt="SCGE icon" />
            <img src={Logo} alt="SCGE icon" />
          </>
        )}
      </Styled.MainIcon>
      <Divider />
      <List>
        {menuItems.map(({ path, text, icon }) => (
          <MenuItem key={text} path={path} icon={icon} text={text} />
        ))}
      </List>
    </Box>
  );
};

export default Drawer;
