const status = (card: string) => [
  {
    field: "asbuilts_accepted",
    filtering: true,
    sortField: "asbuilts_accepted",
    headerName: "Asbuilts Accepted",
    filterField: `${card}asbuilts_accepted`,
  },
  {
    field: "asbuilts_submitted",
    filtering: true,
    sortField: "asbuilts_submitted",
    headerName: "Asbuilts Submitted",
    filterField: `${card}asbuilts_submitted`,
  },
  {
    field: "assigned_contractor",
    filtering: true,
    sortField: "assigned_contractor",
    headerName: "Assigned Contractor",
    filterField: `${card}assigned_contractor`,
  },
  {
    field: "construction_complete_in_percent",
    filtering: true,
    sortField: "construction_complete_in_percent",
    headerName: "Construction Complete in Percent",
    filterField: `${card}construction_complete_in_percent`,
  },
  {
    field: "construction_completion",
    filtering: true,
    sortField: "construction_completion",
    headerName: "Construction Completion",
    filterField: `${card}construction_completion`,
  },
  {
    field: "construction_mbf_date",
    filtering: true,
    sortField: "construction_mbf_date",
    headerName: "Construction Mbf Date",
    filterField: `${card}construction_mbf_date`,
  },
  {
    field: "construction_postcard",
    filtering: true,
    sortField: "construction_postcard",
    headerName: "Construction Postcard",
    filterField: `${card}construction_postcard.keyword`,
  },
  {
    field: "construction_start",
    filtering: true,
    sortField: "construction_start",
    headerName: "Construction Start",
    filterField: `${card}construction_start.keyword`,
  },
  {
    field: "days_past_goal",
    filtering: true,
    sortField: "days_past_goal",
    headerName: "Days Past Goal",
    filterField: `${card}days_past_goal`,
  },
  {
    field: "days_until_mfb_date",
    filtering: true,
    sortField: "days_until_mfb_date",
    headerName: "Days Until Mfb Date",
    filterField: `${card}days_until_mfb_date`,
  },
  {
    field: "deferral_goal_date",
    filtering: true,
    sortField: "deferral_goal_date",
    headerName: "Deferral Goal Date",
    filterField: `${card}deferral_goal_date`,
  },
  {
    field: "deferral_notification_date",
    filtering: true,
    sortField: "deferral_notification_date",
    headerName: "Deferral Notification Date",
    filterField: `${card}deferral_notification_date`,
  },
  {
    field: "deferral_reason",
    filtering: true,
    sortField: "deferral_reason",
    headerName: "Deferral Reason",
    filterField: `${card}deferral_reason`,
  },
  {
    field: "deferral_reason",
    filtering: true,
    sortField: "deferral_reason",
    headerName: "Delay Deferred To",
    filterField: `${card}delay_deferred_to`,
  },
  {
    field: "delay_notes",
    filtering: true,
    sortField: "delay_notes",
    headerName: "Delay Notes",
    filterField: `${card}delay_notes`,
  },
  {
    field: "estimated_completion_date",
    filtering: true,
    sortField: "estimated_completion_date",
    headerName: "Estimated Completion Date",
    filterField: `${card}estimated_completion_date`,
  },
  {
    field: "estimated_start_date",
    filtering: true,
    sortField: "estimated_start_date",
    headerName: "Estimated Start Date",
    filterField: `${card}estimated_start_date`,
  },
  {
    field: "job_status",
    filtering: true,
    sortField: "job_status",
    headerName: "Job Status",
    filterField: `${card}job_status`,
  },
];

export default status("status.");
