import { useAppSelector } from "../../app/hooks";

const useProgramsContentCreator = () => {
  const { programs } = useAppSelector((state) => state.customersSlice);
  return programs.map((item: any) => {
    return [
      {
        id: 1,
        title: "Name",
        content: item?.name || "-",
      },
      {
        id: 2,
        title: "User",
        content: item?.user || "-",
      },
      {
        id: 3,
        title: "Program data",
        content: "-",
      },
    ];
  });
};

export default useProgramsContentCreator;
