import styled from "@emotion/styled";
import { Box } from "@material-ui/core";

const ZoomButtonWrapper = styled(Box)`
  height: 28px;
  width: 28px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background: #bbc0d633;
  &:hover {
    cursor: pointer;
  }
`;

export const Styled = { ZoomButtonWrapper };
