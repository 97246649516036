import { Styled } from "./NotificationDrawer.styled";
import { TabControls } from "@sdge-components/index";
import { tabs } from "../../layouts/RightDrawer/RightDrawer.constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { NotificationItems } from "./components";
import { ErrorBoundary } from "@sdge-pages";
import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { getNotifications } from "@sdge-store/drawer/drawer.async";
import { resetNotifications } from "@sdge-store/drawer/drawer.slice";
import StandardButton from "@sdge-components/StandardButton";

const CellDrawerRouter = () => {
  const tabRoute = useAppSelector((state) => state.drawerState.router.subPath);
  const lastPage = useAppSelector(
    (state) => state.drawerState.notificationsData.lastPage
  );
  const data = useAppSelector(
    (state) => state.drawerState.notificationsData.notifications
  );
  const loading = useAppSelector(
    (state) => state.drawerState.notificationsData.notificationPaginationLoading
  );

  const dispatch = useAppDispatch();
  switch (tabRoute) {
    case "poleNotifications":
      return <NotificationItems />;

    case "userNotifications":
      return <NotificationItems isUser />;
      return (
        <Box pt="100px">
          <Typography align="center">No user notification</Typography>
        </Box>
      );
    default:
      return (
        <div id={"notif_container"}>
          <ErrorBoundary>
            <NotificationItems isUser isAll />
            <NotificationItems isAll />
            {!lastPage && data && (
              <Styled.Row>
                <StandardButton
                  title={!loading ? "Loading..." : "More"}
                  type="submit"
                  pallet="primary"
                  onClick={() => dispatch(getNotifications())}
                  disabled={!loading}
                />
              </Styled.Row>
            )}
          </ErrorBoundary>
        </div>
      );
  }
};

const NotificationDrawer = () => {
  const parentPath = useAppSelector(
    (state) => state.drawerState.router.parentPath
  );
  const data = useAppSelector(
    (state) => state.drawerState.notificationsData.notifications
  );

  const notificationWrapperRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetNotifications());
    };
  }, []);
  useEffect(() => {
    if (!data.length) dispatch(getNotifications());
  }, []);
  return (
    <Styled.NotificationDrawer>
      <TabControls tabs={tabs[parentPath]} isDrawer />
      <Styled.CellDrawerRouterWrapper ref={notificationWrapperRef}>
        <CellDrawerRouter />
      </Styled.CellDrawerRouterWrapper>
    </Styled.NotificationDrawer>
  );
};

export default NotificationDrawer;
