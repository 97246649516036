import styled from "@emotion/styled";
import { Badge } from "@material-ui/core";
import { StyledPropsWithTheme, ButtonType } from "@sdge-utils/globalTypes";

const Button = styled(Badge)<StyledPropsWithTheme<ButtonType>>`
  height: 36px;
  padding: 6px 8px 6px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.text.disabled};
  ${({ isActive, theme }) =>
    isActive && `background:${theme.palette.primary.light}`};
  > svg {
    ${({ iconRight }) =>
      iconRight ? "margin-left: 5px" : "margin-right: 5px"};
  }
  ${({ outlined, theme }) =>
    outlined &&
    `border:1px solid ${theme.palette.button.primary.color[100]}40`};
  &:hover {
    cursor: pointer;
  }
  ${({ disabled, theme }) =>
    disabled &&
    `pointer-events:none;
    background:${theme.palette.button.primary.background[200]};`}
`;

export const Styled = { Button };
