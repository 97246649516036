import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { Button } from "@sdge-components";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const SortItem = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`;
const SortingOrderButton = styled(Button)<StyledPropsWithTheme>`
  display: flex;
  height: 24px;
  width: 56px;
  align-items: center;
  font-size: 16px;
  padding: 1px;
  background: ${({ isActive }) => !isActive && "#464B60"};
`;
const SortingField = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;
const SortingOrder = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

export const Styled = {
  SortItem,
  SortingField,
  SortingOrder,
  SortingOrderButton,
};
