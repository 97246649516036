const sap = (card: string) => [
  {
    field: "mcu_order",
    filtering: true,
    sortField: "mcu_order",
    headerName: "Mcu Order",
    filterField: `${card}mcu_order`,
  },
  {
    field: "contractor_number",
    filtering: true,
    sortField: "contractor_number",
    headerName: "Contractor Number",
    filterField: `${card}contractor_number`,
  },
  {
    field: "facilityid",
    filtering: true,
    sortField: "facilityid",
    headerName: "Facility Id",
    filterField: `${card}facilityid`,
  },
  {
    field: "issue_id",
    filtering: true,
    sortField: "issue_id",
    headerName: "Issue Id",
    filterField: `${card}issue_id`,
  },
  {
    field: "job_notification_open_date",
    filtering: true,
    sortField: "job_notification_open_date",
    headerName: "Job Notification Open Date",
    filterField: `${card}job_notification_open_date`,
  },
  {
    field: "job_number",
    filtering: true,
    sortField: "job_number",
    headerName: "Job Number",
    filterField: `${card}job_number`,
  },
  {
    field: "project_number",
    filtering: true,
    sortField: "mcu_project_numberorder",
    headerName: "Project Number",
    filterField: `${card}project_number`,
  },
  {
    field: "sap_jn_form_submitted_date",
    filtering: true,
    sortField: "sap_jn_form_submitted_date",
    headerName: "Sap Jn Form Submitted Date",
    filterField: `${card}sap_jn_form_submitted_date`,
  },
  {
    field: "wo",
    filtering: true,
    sortField: "wo",
    headerName: "Wo",
    filterField: `${card}wo#53xxxx`,
  },
];

export default sap("sap.");
