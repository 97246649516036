import { useAppSelector } from "../../app/hooks";
import sensitiveIcon from "../../assets/sensitiveCustomerIcon.svg";
import { useLocation } from "react-router-dom";

const useCustomerTileContentCreator = (issueTracker: boolean) => {
  const { customersList: customerPageList } = useAppSelector(
    (state) => state.customersSlice
  );
  const { issueTrackerCustomersList } = useAppSelector(
    (state) => state.issueTrackerSlice
  );
  const { constructionCustomersList } = useAppSelector(
    (state) => state.constructionSlice
  );
  const { pathname } = useLocation();
  const constractionPage = pathname.split("/").includes("construction");
  const customersList = constractionPage
    ? constructionCustomersList
    : customerPageList;
  const sortedArr = [...issueTrackerCustomersList];

  const content =
    sortedArr.length >= 2
      ? sortedArr.sort((a, b) =>
          (a.name || "").toString().localeCompare((b.name || "").toString())
        )
      : sortedArr;

  const customers = (issueTracker ? content : customersList) || [];

  if (customers[0] !== "" && customers[0]) {
    return customers?.map((item: any) => {
      return [
        {
          id: 9,
          title: "Name",
          content: item?.name || "-",
          name: "name",
        },
        {
          id: 11,
          title: "Phone number",
          content: item?.phoneNumbers || "-",
          name: "phoneNumbers",
        },
        {
          id: 1,
          title: "Account number",
          content: item?.accountNumber || "-",
          name: "accountNumber",
        },
        {
          id: 7,
          title: "AP info",
          content: item?.apInfo || "-",
          name: "apInfo",
        },
        {
          id: 2,
          title: "Apn Address 1",
          content: item?.apnAddress1 || "-",
          name: "apnAddress1",
        },

        {
          id: 3,
          title: "Apn Address 2",
          content: item?.apnAddress2 || "-",
          name: "apnAddress2",
        },
        {
          id: 4,
          title: "FacilityId",
          content: item?.facilityId || "-",
          name: "facilityId",
        },
        {
          id: 8,
          title: "Email",
          content: item?.email || "-",
          name: "email",
        },
        {
          id: 12,
          title: "Sensitive customer",
          content: (item?.isSensitive && sensitiveIcon) || "-",
          icon: item?.isSensitive,
          name: "isSensitive",
        },
        {
          id: 5,
          title: "Meter Number",
          content: item?.meterNumber || "-",
          name: "meterNumber",
        },
        {
          id: 13,
          title: "Notes",
          content: item?.notes || "-",
          name: "notes",
        },
        {
          id: 16,
          title: "",
          content: "",
          customerId: item?.id || "",
          name: "id",
        },
      ];
    });
  } else {
    return [];
  }
};

export default useCustomerTileContentCreator;
