import { FC, useEffect, useRef } from "react";
import { Styled } from "./RightDrawer.styled";
import { ArrowLeft, CloseIcon } from "../../icons";
import { Box } from "@material-ui/core";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  handleRightDrawerClose,
  handleRouterChange,
  handleClearDrawerState,
  handleChangeDrawerOptions,
} from "@sdge-store/drawer/drawer.slice";
import { DrawerRouterTypes } from "@sdge-store/drawer/drawer.types";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { getParentPath } from "@sdge-utils/getParentPath";
import { useLocation } from "react-router-dom";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import { Maybe } from "yup/es/types";

const RightDrawer: FC = ({ children }) => {
  const { title, isOpen, router } = useAppSelector(selectRightDrawer);
  const path = router.newPath ?? "/";
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const drawerRef = useRef<Maybe<HTMLElement>>(null);

  const onBack = () => {
    const drawerRouterOptions: DrawerRouterTypes = {
      ...router,
      prevPath: "/",
      newPath: router.prevPath,
    };
    const drawerOptions = {
      title: "Notifications",
      cardId: "",
    };

    if (router.prevPath === "/") {
      dispatch(handleChangeDrawerOptions(drawerOptions));
      drawerRouterOptions.backArrow = false;
      drawerRouterOptions.parentPath = RightDrawerParentPaths.NOTIFICATION;
    }
    dispatch(handleClearDrawerState());
    dispatch(handleRouterChange(drawerRouterOptions));
  };

  const onClose = () => {
    const routerOptions = {
      parentPath: getParentPath(pathname) as RightDrawerParentPaths,
      backArrow: false,
      prevPath: "/",
      newPath: "/",
      subPath: "acknowledgements",
    };
    dispatch(handleRightDrawerClose());
    dispatch(handleRouterChange(routerOptions));
  };

  const handleClick = (event: MouseEvent) => {
    const root = document.getElementById("root");
    const bellButton = document.getElementById("bellButton");
    const quickAction = document.getElementById("quickAction");
    const googleMap = document.getElementById("googleMap");

    const isNotQuickAction =
      quickAction &&
      (event.target as HTMLElement) !== quickAction &&
      !quickAction.contains(event.target as HTMLElement);
    const isNotBellButton =
      bellButton &&
      (event.target as HTMLElement) !== bellButton &&
      !bellButton.contains(event.target as HTMLElement);

    const isNotGoogleMap = googleMap
      ? (event.target as HTMLElement) !== googleMap &&
        !googleMap.contains(event.target as HTMLElement)
      : true;
    if (
      isOpen &&
      drawerRef.current &&
      !drawerRef.current.contains(event.target as HTMLElement) &&
      root &&
      root.contains(event.target as HTMLElement) &&
      isNotQuickAction &&
      isNotBellButton &&
      isNotGoogleMap
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <Styled.RightDrawer
      anchor="right"
      variant="persistent"
      open={isOpen}
      onClose={onClose}
      ref={drawerRef}
    >
      <Styled.Header>
        <Styled.HeaderControl>
          {path !== "/" && router.backArrow && (
            <ArrowLeft pointer onClick={onBack} />
          )}
          <Box component="span">{title}</Box>
        </Styled.HeaderControl>
        <CloseIcon onClick={onClose} />
      </Styled.Header>
      {isOpen && <Styled.Body>{children}</Styled.Body>}
    </Styled.RightDrawer>
  );
};

export default RightDrawer;
