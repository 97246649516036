import { createAsyncThunk } from "@reduxjs/toolkit";
import queryInstance from "@sdge-api/config/queryInstance";
import { errorHandler } from "@sdge-api/utils";

export const fetchProgramsData: any = createAsyncThunk(
  "programs/summary",
  async (_, { dispatch }) => {
    try {
      const response = await queryInstance.get(
        "/overlapping_programs/summary",
        {
          timeout: 120000,
        }
      );

      const combinedResponse = response.data.map((item: any) => {
        return { ...item, program_code: item.program_code.toLowerCase() };
      });

      return combinedResponse;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
