import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setInteraction,
  updateInteraction,
} from "../../../../app/store/drawer/drawer.async";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  interactionByGroupNameValidationSchema,
  interactionValidationSchema,
} from "./InteractionForm.validation";
import { Constants } from "./InteractionForm.constants";
import { parseDateString } from "@sdge-utils/dateParser";
import { Styled } from "./InteractionForm.styled";

import {
  SelectInput,
  TextInputWithController,
  StandardButton,
  DatePickerInput,
} from "../../../";
import { MultiChipInputWithController } from "../../../MultiChipInput";
import { setEditableInteraction } from "@sdge-store/drawer/drawer.slice";
import { AttachButtonWithController } from "@sdge-components/AttachButton";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import {
  setInteractionByGroupName,
  updateInteractionByGroupName,
} from "@sdge-store/construction/construction.async";
import { usePermission } from "@sdge-hooks";

const InteractionForm: FC<any> = ({ setInteractionOpened }) => {
  const { selectedInteraction, router } = useAppSelector(selectRightDrawer);
  const constractionPage = router.parentPath === "construction_table";
  const { getPermission } = usePermission();

  const {
    interactionType,
    emails,
    comment,
    date,
    id,
    attachment,
    eventOwner,
    description,
  } = selectedInteraction;

  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      constractionPage
        ? interactionByGroupNameValidationSchema
        : interactionValidationSchema
    ),
  });

  const multiChipInputError: string | undefined =
    errors.emails?.message ||
    (Array.isArray(errors.emails) && errors.emails?.length > 0
      ? "invalid email"
      : undefined);

  const interactionIsEditable = !!Object.keys(selectedInteraction)?.length;

  const defaultFile: any = new Blob([""]);
  defaultFile["name"] = attachment?.name;
  const FileDefaultValue = attachment ? defaultFile : null;

  const formSubmit = (data: any) => {
    const emails = data.interactionType === "EMAIL" ? data.emails : [];

    const body = {
      ...data,
      emails,
      event_owner: eventOwner,
      date: parseDateString(data.date),
    };

    reset();
    setInteractionOpened(false);
    dispatch(setEditableInteraction({}));
    if (interactionIsEditable) {
      constractionPage
        ? dispatch(updateInteractionByGroupName({ body, id }))
        : dispatch(updateInteraction({ body, id }));
    } else {
      constractionPage
        ? dispatch(setInteractionByGroupName(body))
        : dispatch(setInteraction(body));
    }
  };

  const handleCloseForm = () => {
    setInteractionOpened(false);
    dispatch(setEditableInteraction({}));
  };

  useEffect(() => {
    if (!interactionType) setValue("interactionType", "EMAIL");
    if (interactionType === "EMAIL") setValue("interactionType", "EMAIL");
  }, []);
  const uniqueInteractionType =
    constractionPage && getPermission("addInteractionType")
      ? Constants.InteractionType.concat(Constants.addInteractionType)
      : Constants.InteractionType;
  return (
    <Styled.Form onSubmit={handleSubmit(formSubmit)}>
      <Styled.Row gap>
        <SelectInput
          optionsData={uniqueInteractionType}
          label="Type"
          name="interactionType"
          control={control}
          defaultValue={interactionType ?? "EMAIL"}
          errorMessage={errors.interactionType?.message}
        />
        <DatePickerInput
          control={control}
          name="date"
          label="Interaction Date"
          defaultValue={date}
          errorMessage={errors.interactionType?.message}
          readOnly
        />
      </Styled.Row>

      {watch("interactionType") === "EMAIL" && (
        <MultiChipInputWithController
          control={control}
          defaultValue={emails}
          name={"emails"}
          label="Contacted person"
          errorMessage={multiChipInputError}
        />
      )}
      <TextInputWithController
        label="Comment"
        control={control}
        name="comment"
        defaultValue={comment}
        errorMessage={errors.comment?.message}
      />
      {constractionPage && (
        <TextInputWithController
          label="Description"
          control={control}
          name="description"
          defaultValue={description}
          errorMessage={errors.description?.message}
        />
      )}
      <AttachButtonWithController
        control={control}
        name="file"
        defaultValue={FileDefaultValue}
      />
      <Styled.Row gap>
        <StandardButton
          title={
            interactionIsEditable ? "Update Interaction" : "Create Interaction"
          }
          type="submit"
          pallet="primary"
        />
        <StandardButton
          title="Cancel"
          type="button"
          pallet="secondary"
          onClick={handleCloseForm}
        />
      </Styled.Row>
    </Styled.Form>
  );
};

export default InteractionForm;
