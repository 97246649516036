import { Styled } from "../../CellInfo.styled";
import { LoadingWrapper } from "@sdge-components";
import { Constants } from "./OverlappingInfo.constatns";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { useForm } from "react-hook-form";
import { FC, useEffect, useRef } from "react";
import { setProgramInfo } from "@sdge-store/drawer/drawer.async";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import DrawerRowItem from "../DrawerRowItem";
import { parseRowDateString } from "@sdge-utils/dateParser";
// import {generateUniqueValues} from "@sdge-utils/helpers/editHelpers/editHelpers";
// import SelectPermissionQualifier from "../SelectPermissionQualifier";
// import { usePermission } from "@sdge-hooks";
// import { selectAutoComplete } from "@sdge-store/autoComplete/autoComplete.selector";
// import { dataTransforming } from "@sdge-utils/dataTransform";

const OverlappingForm: FC<any> = ({ programInfo }) => {
  const dispatch = useAppDispatch();
  // const { uniqueValues } = useAppSelector(selectAutoComplete);

  const { issueId } = programInfo;

  const { handleSubmit, reset } = useForm();
  // const { getPermission } = usePermission();
  const { cardId: poleId } = useAppSelector(selectRightDrawer);

  const formRef = useRef(null);

  const handleFormSubmit = (data: any) => {
    dispatch(
      setProgramInfo({
        data: { ...data, poleReplacement: data.poleReplacement === "Y" },
        issueId,
      })
    );
  };

  // const transformedUniqueValues: { [key: string]: string } = useMemo(() => {
  //   return dataTransforming(uniqueValues);
  // }, [uniqueValues]);

  // const uniqueBallInCourt = generateUniqueValues(
  //   transformedUniqueValues.ballincourt,
  //   Constatns.additionalBallInCourt
  // );
  useEffect(() => {
    return () => {
      reset();
    };
  }, [poleId]);

  return (
    <Styled.OverlappingForm
      onSubmit={handleSubmit(handleFormSubmit)}
      ref={formRef}
    >
      {Object.keys(programInfo).map((item) => {
        const dateItem = ["issueIdentifiedDate", "issueApprovedDate"];
        return (
          <DrawerRowItem
            key={item}
            title={Constants.overlappingDrawerTitles[item]}
            content={
              dateItem.includes(item) && parseRowDateString(programInfo[item])
                ? parseRowDateString(programInfo[item])
                : programInfo[item]
            }
          />
        );
      })}
    </Styled.OverlappingForm>
  );
};

const OverlappingInfo: FC = () => {
  const { overlappingInfo, isLoaded, programInfoLoading } = useAppSelector(
    (state) => state.drawerState.programData
  );
  const dataExistent = !!Object.keys(overlappingInfo)?.length;

  return (
    <LoadingWrapper loading={programInfoLoading}>
      {dataExistent && isLoaded ? (
        <Styled.OverlappingWrapper>
          {overlappingInfo.map((info, index) => {
            return (
              <div key={info.issueId + info.poleId}>
                <OverlappingForm programInfo={info} />
                {index !== overlappingInfo?.length - 1 && <Styled.Hr />}
              </div>
            );
          })}
        </Styled.OverlappingWrapper>
      ) : (
        <Styled.NoInfoWrapper>No information for pole</Styled.NoInfoWrapper>
      )}
    </LoadingWrapper>
  );
};

export default OverlappingInfo;
