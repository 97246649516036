import React, { FC, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@material-ui/core";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Styled } from "./Options.styled";
import { ClickAwayListener } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { useHistory } from "react-router";
import { OptionsType } from "./Options.types";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "none",
  },
}));

const Options: FC<OptionsType> = ({
  field,
  component: Component,
  icon: Icon,
  setOpen,
  open,
  isActive,
  iconRight,
  placement,
  columnApi,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      dispatch(setOpen(false));
    });
  }, [dispatch, history, setOpen]);

  const handleTooltipClose = () => {
    dispatch(setOpen(false));
  };

  const handleTooltipOpen = () => {
    dispatch(setOpen(true));
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <HtmlTooltip
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<Component columnApi={columnApi} />}
          placement={placement ? placement : "bottom"}
        >
          <Styled.SortOptionsButton
            isActive={isActive}
            onClick={handleTooltipOpen}
            iconRight={iconRight}
          >
            {!iconRight ? (
              <>
                <Icon /> {field}
              </>
            ) : (
              <>
                {field} <Icon />
              </>
            )}
          </Styled.SortOptionsButton>
        </HtmlTooltip>
      </Box>
    </ClickAwayListener>
  );
};
export default Options;
