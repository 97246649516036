import { Box, Grid, IconButton } from "@mui/material";
import { Styled } from "./StatusTileContent.styled";
import { CloseIcon, EditIcon } from "../../icons";
import React, { useState } from "react";
import { TextInputWithController, DatePickerInput } from "@sdge-components";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { RowItemPropsType } from "./StatusTileContent.types";
import { parseDateString } from "@sdge-utils/dateParser";
import { updateContructionState } from "@sdge-store/construction/construction.async";
import { usePermission } from "@sdge-hooks";

const RowItem: React.FC<RowItemPropsType> = ({
  title,
  content,
  name,
  date,
}) => {
  const [editable, setEditable] = useState(false);
  const { control, handleSubmit, setValue, reset } = useForm();
  const { constructionInfo } = useAppSelector(
    (state) => state.constructionSlice
  );
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  const dispatch = useDispatch();
  const { getPermission } = usePermission();
  const editableNames =
    name === "deferralNotificationDate" ||
    name === "deferralReason" ||
    name === "deferralGoalDate" ||
    name === "delayDeferredTo" ||
    name === "delayNotes";

  const hasPermissions = getPermission("editStatusTile") && editableNames;

  const handleSetEdit = () => {
    reset();
    setEditable(true);
  };

  const handleFormSubmit = (values: any) => {
    const formData = JSON.parse(JSON.stringify(values));

    const body = {
      deferralNotificationDate: constructionInfo.deferralNotificationDate,
      deferralReason: constructionInfo.deferralReason,
      deferralGoalDate: constructionInfo.deferralGoalDate,
      delayDeferredTo: constructionInfo.delayDeferredTo,
      delayNotes: constructionInfo.delayNotes,
    };
    const options = {
      ...body,
      ...(name === "deferralGoalDate" || name === "deferralNotificationDate"
        ? { [name]: parseDateString(new Date(formData[name]), "YYYY-MM-DD") }
        : { [name]: formData[name] }),
    };
    if (
      !options["deferralNotificationDate"] ||
      options["deferralNotificationDate"] === "Invalid Date"
    ) {
      options.deferralNotificationDate = "";
    }
    if (
      !options["deferralGoalDate"] ||
      options["deferralGoalDate"] === "Invalid Date"
    ) {
      options.deferralGoalDate = "";
    }

    dispatch(updateContructionState(options));
    setEditable(false);
  };

  return (
    <Box pb="11px" pt="11px">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Box width="40%" mr="10px">
            <Styled.Title>{title}</Styled.Title>
          </Box>
          {editable && name !== "facilityId" ? (
            <>
              {date ? (
                <DatePickerInput
                  name={name as `${string}`}
                  control={control}
                  readOnly
                  defaultValue={content === "-" ? "" : content}
                />
              ) : (
                <TextInputWithController
                  defaultValue={content}
                  name={name as `${string}`}
                  control={control}
                  multiline={true}
                />
              )}
            </>
          ) : (
            <Styled.Content noWrap={false}>{content}</Styled.Content>
          )}
          <>
            {programInfo.bmcdTier3 !== "Y" &&
            hasPermissions &&
            name !== "facilityId" ? (
              <>
                {editable ? (
                  <>
                    {date && (
                      <IconButton
                        onClick={() => {
                          setValue(name as `${string}`, "");
                        }}
                      >
                        <CloseIcon size={14} />
                      </IconButton>
                    )}
                    <Button
                      type="submit"
                      endIcon={<EditIcon size={14} />}
                    ></Button>
                  </>
                ) : (
                  <IconButton onClick={handleSetEdit}>
                    <EditIcon size={14} />
                  </IconButton>
                )}
              </>
            ) : (
              <Box></Box>
            )}
          </>
        </Grid>
      </form>
    </Box>
  );
};

export default RowItem;
