import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@sdge-store/store";

export const selectConstruction = createSelector(
  (state: RootState) => ({
    construction: state.constructionSlice.construction,
    loading: state.constructionSlice.loading,
    page: state.constructionSlice.page,
    totalElements: state.constructionSlice.totalElements,
    loadedElements: state.constructionSlice.loadedElements,
    sortingRules: state.constructionSlice.sortingRules,
    sort: state.constructionSlice.sort,
    totalPages: state.constructionSlice.totalPages,
    isLastPage: state.constructionSlice.isLast,
    isPaginated: state.constructionSlice.isPaginated,
    columnDefs: state.constructionSlice.columnDefs,
    currentFilter: state.constructionSlice.filter,
    filteringRules: state.constructionSlice.filteringRules,
    filter: state.constructionSlice.filter,
    areSortOptionsOpen: state.constructionSlice.areSortOptionsOpen,
    areFilterOptionsOpen: state.constructionSlice.areFilterOptionsOpen,
    areHiddenFieldsOpen: state.constructionSlice.areHiddenFieldsOpen,
    searchInputValue: state.constructionSlice.searchInputValue,
    areQuickVeiwsOptionsOpen: state.constructionSlice.areQuickVeiwsOptionsOpen,
    hiddenColumns: state.constructionSlice.hiddenColumns,
    columnOrder: state.constructionSlice.columnOrder,
    quickViews: state.constructionSlice.quickViews,
    isQuickVeiwsModalOpen: state.constructionSlice.isQuickVeiwsModalOpen,
    selectedRows: state.constructionSlice.selectedRows,
    areMassUpdateOptionsOpen: state.constructionSlice.areMassUpdateOptionsOpen,
    massUpdateItems: state.constructionSlice.massUpdateItems,
    newIssue: state.constructionSlice.newIssue,
    massUpdateItemsRequest: state.constructionSlice.massUpdateItemsRequest,
    frozenColumns: state.constructionSlice.frozenColumns,
    initialColumnDefs: state.constructionSlice.initialColumnDefs,
    initialColumnsOrder: state.constructionSlice.initialColumnOrder,
    selectedQuickVewName: state.constructionSlice.selectedQuickVewName,
    selectedQuickViewPrivacy: state.constructionSlice.selectedQuickViewPrivacy,
    selectedQuickViewId: state.constructionSlice.selectedQuickViewId,
    inputText: state.constructionSlice.inputText,
    activeConstructionFacilityId:
      state.constructionSlice.activeConstructionFacilityId,
    activeConstructionGroupName:
      state.constructionSlice.activeConstructionGroupName,
    activeConstructionId: state.constructionSlice.activeConstructionId,
    envPermitsConstruction: state.constructionSlice.envPermitsConstruction,
    muniPermitsConstruction: state.constructionSlice.muniPermits,
    massUpdateLoading: state.constructionSlice.massUpdateLoading,
    selectedRowsGroupNames: state.constructionSlice.selectedRowsGroupNames,
    constructionLoading: state.constructionSlice.constructionLoading,
    bmcdTierVisibility: state.issueTrackerSlice.bmcdTierVisibility,
  }),
  (state) => state
);
