import { camelCaseTransform } from "@sdge-utils/dataTransform";

export const constructionCustomersFields = [
  "accountNumber",
  "apInfo",
  "apn",
  "apnAddress1",
  "apnAddress2",
  "email",
  // "facilityId",
  "groupName",
  // "id",
  "isSensitive",
  "issueId",
  "meterNumber",
  "name",
  "notes",
  "phoneNumbers",
];

export const constructionCustomersHeaders = [
  "Account Number",
  "Ap Info",
  "Apn",
  "Apn Address 1",
  "Apn Address 2",
  "Email",
  // "FacilityId",
  "Group Name",
  // "Id",
  "Is Sensitive",
  "IssueId",
  "Meter Number",
  "Name",
  "Notes",
  "Phone Numbers",
];

export const constructionInteractionsHeaders = [
  "Attachment",
  "Comment",
  "Created",
  "CustomerIds",
  "Date",
  "Description",
  "Emails",
  "Event Owner",
  // "GroupName",
  // "Id",
  "Interaction Type",
  "PoleId",
  "Updated",
  "User",
];

export const constructionInteractionsFields = [
  "attachment",
  "comment",
  "created",
  "customerIds",
  "date",
  "description",
  "emails",
  "eventOwner",
  // "groupName",
  // "id",
  "interactionType",
  "poleId",
  "updated",
  "user",
];

export const constructionStatusFields = [
  "asbuiltsAccepted",
  "asbuiltsSubmitted",
  "assignedContractor",
  "constructionCompletion",
  "constructionMbfDate",
  "constructionPercentComplete",
  "constructionPostcard",
  "constructionStart",
  "daysPastGoal",
  "daysUntilMfbDate",
  "deferralGoalDate",
  "deferralNotificationDate",
  "deferralReason",
  "delayDeferredTo",
  "delayNotes",
  "estimatedCompletionDate",
  "estimatedStartDate",
  "groupName",
  "jobStatus",
];

export const constructionStatusHeaders = [
  "Asbuilts Accepted",
  "Asbuilts Submitted",
  "Assigned Contractor",
  "Construction Completion",
  "Construction Mbf Date",
  "Construction Percent Complete",
  "Construction Postcard",
  "Construction Start",
  "Days Past Goal",
  "Days Until Mfb Date",
  "Deferral Goal Date",
  "Deferral Notification Date",
  "Deferral Reason",
  "Delay Deferred To",
  "Delay Notes",
  "Estimated Completion Date",
  "Estimated Start Date",
  "GroupName",
  "Job Status",
];

export const constructionSapFields = [
  // "contractorNumber",
  "facilityId",
  // "id",
  // "jobNotificationOpenDate",
  // "jobNumber",
  "mcuOrder",
  // "projectNumber",
  // "sapJnFormSubmittedDate",
  "wo",
];

export const constructionSapHeaders = [
  // "Contractor Number",
  "FacilityId",
  // "Id",
  // "Job Notification Open Date",
  // "Job Number",
  "Mcu Order",
  // "Project Number",
  // "Sap Jn Form Submitted Date",
  "Wo",
];

export const sapFieldsForInteractions = constructionSapFields.filter(
  (item) => item !== "contractorNumber"
);
export const sapHeadersForInteractions = constructionSapHeaders.filter(
  (item) => item !== "Contractor Number"
);

export const parcelFields = [
  // "facilityId",
  "ownerName",
  "apn",
  "isSensitiveCustomer",
  "apnAddress1",
  "apnAddress2",
  "email",
  "phoneNumber",
];

export const parcelHeaders = [
  // "FacilityId",
  "Owner Name",
  "Apn",
  "Is SensitiveCustomer",
  "Apn Address 1",
  "Apn Address 2",
  "Email",
  "Phone Number",
];

export const constructionEnvPermitsFields = [
  "quickRelease",
  "ets",
  "envReleaseDateSubmitted",
  "envReleaseIssuedDate",
  "specialRequirements",
  // "releaseExpirationDate",
  "sharepointLink",
  "permitId",
];

export const constructionEnvPermitsHeaders = [
  "Quick Release",
  "Ets",
  "Env ReleaseDate Submitted",
  "Env ReleaseIssued Date",
  "Environmental Constraints",
  // "Release Expiration Date",
  "Sharepoint Link",
  "PermitId",
];

export const constructionMuniPermitsFields = [
  "permitType",
  "permitDateSubmitted",
  "k2Reference",
  "permitTimeFrame",
  "permitIssuedDate",
  // "permitExpirationDate",
  "sharepointLink",
  "permitId",
];

export const constructionMuniPermitsHeaders = [
  "Permit Type",
  "Permit Date Submitted",
  "K2 Reference",
  "PermitTime Frame",
  "Permit Issued Date",
  // "Permit Expiration Date",
  "Sharepoint Link",
  "PermitId",
];

export const assetsParcelFields = [
  "apn",
  "apnAddress1",
  "apnAddress2",
  "email",
  "facilityId",
  "isSensitiveCustomer",
  "ownerName",
  "phoneNumber",
];

export const assetsParcelHeaders = assetsParcelFields.map((item) =>
  camelCaseTransform(item)
);
