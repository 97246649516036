import { useAppSelector } from "../../app/hooks";

const useInfoTileContentCreator = () => {
  const { pole } = useAppSelector((state) => state.customersSlice);
  return [
    { id: 1, title: "Pole #", content: pole?.poleId || "-" },
    { id: 2, title: "Material", content: pole?.material || "-" },
    { id: 3, title: "Circuit Id #", content: pole?.circuitId || "-" },
    // {
    //   id: 4,
    //   title: "Vendor Upload Date",
    //   content: pole?.vendorUploadDate || "-",
    // },
    // {
    //   id: 5,
    //   title: "CMP Inspection Date",
    //   content: pole?.cmpIntersectionDate || "-",
    // },
    { id: 6, title: "Tie Line", content: pole?.tieLine || "-" },
    { id: 7, title: "Transformer", content: pole?.transformer || "-" },
    { id: 8, title: "Asset Registry #", content: pole?.assetRegistryId || "-" },
    { id: 9, title: "Assessment", content: pole?.linkToAssessment || "-" },
  ];
};

export default useInfoTileContentCreator;
