import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import MainTable from "./MainTable";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectOverlaps } from "@sdge-store/overlaps/overlaps.selector";

import {
  sortOptions,
  filterOptions,
  fieldsHiddenOptions,
  handleSearch,
  handlePinnedColumn,
  handleHiddenColumn,
  handleSort,
  handleLoadedPagesChange,
  handleFilter,
  quickViewsOptions,
  handleColumnDefs,
  handleColumnOrder,
  handleSelectedRows,
  handleInputText,
} from "@sdge-store/overlaps/overlaps.slice";
import {
  fetchOverlapsData,
  sortOverlapsData,
} from "@sdge-store/overlaps/overlaps.async";
import { GridOptions, SelectionChangedEvent } from "ag-grid-community";
import {
  generateColumnDefs,
  generateColumnOrder,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import { DownloadLinks, RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import {
  handleChangeDrawerOptions,
  handleRouterChange,
} from "@sdge-store/drawer/drawer.slice";
import { handleSetRowData } from "@sdge-store/Map/map.slice";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { exportFilteredFile } from "@sdge-utils/exportFile";
import {
  OverlapsFilterOptionsMenu,
  OverlapsHideOptionsMenu,
  OverlapsQuickViewsOptions,
  OverlapsSortOptionsMenu,
} from "@sdge-components";
import { overlappingItemsDateConvert } from "@sdge-utils/helpers/gridHelpers/gridHelpers";
import { usePermission } from "@sdge-hooks";

const isThirdColumn = (params: any) => {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  const indexOfButton = displayedColumns.findIndex(
    (item: any) => item.maxWidth === 50
  );
  const thisIsFirstColumn = displayedColumns[indexOfButton] == params.column;
  return thisIsFirstColumn;
};

const OverlapsMainTable: FC = () => {
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const [columnApi, setColumnApi] = useState<any>(null);
  const { getPermission } = usePermission();

  const hasPermissions = getPermission("readonly");

  const {
    overlapping_programs,
    totalElements,
    loading,
    page,
    sortingRules,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    areQuickVeiwsOptionsOpen,
    totalPages,
    selectedRows,
    inputText,
  } = useAppSelector(selectOverlaps);
  const selectionRef = useRef<any>(null);
  const getSelectedRowData = (params: SelectionChangedEvent) => {
    clearTimeout(selectionRef.current);
    selectionRef.current = setTimeout(() => {
      const selectedNodes = params.api.getSelectedNodes();
      const selectedRowsIndexes = selectedNodes.map((item) => item.rowIndex);
      dispatch(handleSelectedRows(selectedRowsIndexes));
    }, 300);
    return () => {
      clearTimeout(selectionRef.current);
    };
  };

  const getRowData = useCallback(({ data }: any) => {
    const drawerRouterOptions = {
      parentPath: RightDrawerParentPaths.OVERLAPPING_TABLE,
      backArrow: false,
      prevPath: "/",
      newPath: "overlapping",
      subPath: "info",
    };
    const drawerOptions = {
      isOpen: true,
      issueId: data.issueId,
      title: data.poleId,
      cardId: data.poleId,
    };
    dispatch(handleRouterChange(drawerRouterOptions));
    dispatch(handleChangeDrawerOptions(drawerOptions));
    dispatch(handleSetRowData(data));
  }, []);

  const gridOptions: GridOptions = useMemo(
    () => ({
      suppressDragLeaveHidesColumns: true,
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
      suppressPropertyNamesCheck: true,
      overlayNoRowsTemplate:
        ' <img src="https://www.ag-grid.com/example-assets/loading.gif" />',
      suppressMenuHide: true,
      rowHeight: 40,
      onGridReady: (params) => {
        if (!loading) {
          params.columnApi.autoSizeAllColumns();
        }
        setGridApi(params.api);
        setColumnApi(params.columnApi);
      },
      onDragStopped: (params) => {
        const allColumns: any = params.columnApi.getAllGridColumns();
        dispatch(handleColumnDefs(generateColumnDefs(allColumns)));
        dispatch(
          handleColumnOrder(generateColumnOrder(allColumns, "overlapping"))
        );
      },
      rowSelection: "multiple",
      onRowClicked: (rowInfo) => getRowData(rowInfo),
      onSelectionChanged: (params) => getSelectedRowData(params),
      rowMultiSelectWithClick: true,
    }),
    []
  );

  const handleExportClick = () => {
    dispatch(
      handleChangeSnackbarState({
        isOpen: true,
        alertColor: "success",
        message: "Download has started",
      })
    );
    if (selectedRows.length > 0) {
      gridApi.exportDataAsCsv({
        allColumns: true,
        onlySelected: true,
        fileName: "overlapping_programs",
      });
    } else {
      exportFilteredFile(
        DownloadLinks.OVERLAPPING_PROGRAMS,
        filteringRules,
        "overlapping_programs"
      ).catch((e) => {
        dispatch(
          handleChangeSnackbarState({
            isOpen: true,
            alertColor: "error",
            message: e.response.data.error,
          })
        );
      });
    }
  };
  const rowItems = useMemo(
    () => overlappingItemsDateConvert(overlapping_programs),
    [overlapping_programs]
  );
  const data = {
    items: rowItems,
    totalElements,
    loading,
    page,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    totalPages,
    sortingRules,
    areQuickVeiwsOptionsOpen,
    selectedRows,
    inputText,
    FilterOptionsMenu: OverlapsFilterOptionsMenu,
    HideOptionsMenu: OverlapsHideOptionsMenu,
    QuickViewsOptions: OverlapsQuickViewsOptions,
    SortOptionsMenu: OverlapsSortOptionsMenu,
    title: "Overlapping",
    hasPermissions,
  };

  const storeFunctions = {
    fieldsHiddenOptions,
    sortOptions,
    filterOptions,
    quickViewsOptions,
    handleSearch,
    handleLoadedPagesChange,
    sortData: sortOverlapsData,
    handleHiddenColumn,
    handlePinnedColumn,
    handleSort,
    handleFilter,
    fetchData: fetchOverlapsData,
    handleSelectedRows,
    handleInputText,
  };

  const options = {
    gridOptions,
    isValueSetter: true,
    storeSelector: selectOverlaps,
    handleExportClick,
    isThirdColumn,
    columnApi,
  };

  return (
    <MainTable data={data} storeFunctions={storeFunctions} options={options} />
  );
};

export default OverlapsMainTable;
