import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const portalRoot = document.getElementById("portal-root");

const PortalLayout: React.FC = ({ children }) => {
  const currentModal = useRef(document.createElement("div"));

  useEffect(() => {
    currentModal.current.setAttribute("id", "modal-content");
    portalRoot?.appendChild(currentModal.current);
    return () => {
      portalRoot?.removeChild(currentModal.current);
    };
  });

  return ReactDOM.createPortal(<div>{children}</div>, currentModal.current);
};

export default PortalLayout;
