import styled from "@emotion/styled";
import { Avatar, Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../utils/globalTypes";

const DrawerUserInfoWrapper = styled(Box)<StyledPropsWithTheme>`
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin: 20px;
  height: 45px;
`;

const PersonalInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const TextWrapper = styled(Box)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserAvatar = styled(Avatar)`
  border: 1px solid rgba(31, 65, 204, 0.5);
`;

export const Styled = {
  DrawerUserInfoWrapper,
  PersonalInfoWrapper,
  UserAvatar,
  TextWrapper,
};
