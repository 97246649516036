import { useAppSelector } from "../../app/hooks";
import { parseRowDateString } from "@sdge-utils/dateParser";

const useSapTileContentCreator = (constructionPage?: boolean) => {
  const { sapTileContent } = useAppSelector((state) => state.issueTrackerSlice);
  const { sapTileContentConstruction } = useAppSelector(
    (state) => state.constructionSlice
  );
  const content = constructionPage
    ? sapTileContentConstruction
      ? [...sapTileContentConstruction]
      : []
    : [...sapTileContent];

  const sortedContent =
    content.length >= 2
      ? content.sort((a, b) =>
          (a.contractorNumber || "")
            .toString()
            .localeCompare((b.contractorNumber || "").toString())
        )
      : content;

  return sortedContent?.map((item: any) => {
    return [
      {
        id: 1,
        title: "Contractor Name",
        content: item?.contractorNumber || "-",
        name: "contractorNumber",
      },
      {
        id: 2,
        title: "FacilityId",
        content: item?.facilityId || "-",
        name: "facilityId",
      },
      {
        id: 3,
        title: "Job Notification Open Date",
        content:
          (item?.jobNotificationOpenDate &&
            parseRowDateString(item?.jobNotificationOpenDate)) ||
          "-",
        name: "jobNotificationOpenDate",
        date: true,
      },
      {
        id: 4,
        title: "Job Number",
        content: item?.jobNumber || "-",
        name: "jobNumber",
      },
      {
        id: 5,
        title: "Mcu Order",
        content: item?.mcuOrder || "-",
        name: "mcuOrder",
      },
      {
        id: 6,
        title: "Project Number",
        content: item?.projectNumber || "-",
        name: "projectNumber",
      },
      {
        id: 7,
        title: "Submitted Date",
        content:
          (item?.sapJnFormSubmittedDate &&
            parseRowDateString(item?.sapJnFormSubmittedDate)) ||
          "-",
        name: "sapJnFormSubmittedDate",
        date: true,
      },
      {
        id: 8,
        title: "Wo",
        content: item?.wo || "-",
        name: "wo",
        itemId: item?.id,
      },
    ];
  });
};

export default useSapTileContentCreator;
