import React, { FC, useState } from "react";
import { Box } from "@material-ui/core";
import { OptionsData, SelectSortTypes } from "./SelectSort.types";
import { MenuDownIcon } from "../../../../icons";
import { Styled } from "./SelectSort.styled";
import { useAppDispatch } from "../../../../app/hooks";
import {
  generateSortArr,
  sortRequest,
} from "../../../../utils/helpers/sortHelpers/sortHelpers";

const SelectSort: FC<SelectSortTypes> = ({
  placeHolder,
  optionsData,
  order,
  sort,
  filteringRules,
  searchInputValue,
  handleSort,
  sortData,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  const handleClick = (e: any) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Styled.SortingButton
        title={placeHolder}
        aria-expanded={open ? "true" : undefined}
        onClick={open ? handleClose : handleClick}
        icon={MenuDownIcon}
        iconRight
      />
      <Styled.SelectMenuWrapper
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {optionsData.map(({ id, value, label }: OptionsData) => (
          <Styled.MenuItemWrapper
            value={value}
            key={id}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              dispatch(handleSort(generateSortArr(value, order, sort)));
              dispatch(
                sortData([
                  0,
                  sortRequest(value, order, sort, searchInputValue),
                  filteringRules,
                ])
              );
            }}
          >
            {label}
          </Styled.MenuItemWrapper>
        ))}
      </Styled.SelectMenuWrapper>
    </Box>
  );
};
export default SelectSort;
