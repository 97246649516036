const generateAutoCompleteField = (fieldName: string) => {
  const values: any = {
    facilityid: "facilityid",
    facility_id: "facilityid",
    tlid: "tlid",
    groupName: "group_name",
    issueType: "issue_type",
    priority: "priority",
    crossCheck: "crossCheck",
    classification: "classification",
    ballincourt: "ballincourt",
    district: "district_name",
    crossarmreplacement: "crossarm_replacement",
    polereplacement: "pole_replacement",
    circuit: "circuit_id",
    assigned: "assigned",
    conditioncodeid: "condition_code_id",
    status: "status",
    issueStatus: "issue_status",
    muniPermit: "permit_type",
    onHoldStatus: "onholdstatus",
  };
  if (values[fieldName]) return values[fieldName];
};

export const Constants = { generateAutoCompleteField };
