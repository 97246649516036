import { ThemeOptions } from "@material-ui/core";

const baseTheme: ThemeOptions = {
  typography: {
    fontFamily: "IBM Plex Sans",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
};

export const lightTheme: ThemeOptions = {
  ...baseTheme,
  overrides: {
    MuiDrawer: {
      paper: {
        background: `linear-gradient(165.78deg, #F4F6FA 31.31%, #DBEEFF 133.51%)`,
      },
    },
  },
  palette: {
    text: {
      primary: "#414141",
      secondary: "#414141",
    },
    background: {
      default: "#E5E5E5",
    },
    primary: {
      main: "#fefefe",
      light: "#607DF1",
    },
    yellow: {
      100: "#a8a8a8",
    },
    green: {
      100: "#151515",
    },

    tabControl: {
      tabControlBackground: {
        100: "rgba(224, 224, 224, 0.5)",
      },
      tabControlText: {
        100: "#616161",
      },
      tabControlSelectedText: {
        100: "#000000",
      },
      tabBackground: {
        100: "#ffffff",
      },
    },
    statusComponent: {
      passed: {
        100: "#26AE7D",
      },
      warning: {
        100: "#FFA800",
      },
      failure: {
        100: "#FF1414",
      },
    },
    bell: {
      background: {
        100: "#1f41cc",
      },
    },
    card: {
      background: {
        100: "#252c47",
      },
      backgroundActive: {
        100: "#202c5b",
      },
      borderActive: {
        100: "#607DF1",
      },
      text: {
        100: "#d7d8dc",
      },
      textDisabled: {
        100: "#6b7082",
      },
    },
    rightDrawer: {
      background: {
        100: "#252c47",
      },
    },
    notificationItem: {
      dot: {
        100: "#4C67D6",
      },
    },
    selectInput: {
      background: {
        100: "#32374d",
      },
      selected: {
        100: "#4c526c",
      },
      selectIcon: {
        100: "#afb1ba",
      },
      borderColor: {
        100: "#646878",
      },
    },
    button: {
      primary: {
        background: {
          100: "#344DAE",
        },
        color: {
          100: "#E3E4E7",
        },
        borderColor: {
          100: "transparent",
        },
      },
      secondary: {
        background: {
          100: "transparent",
        },
        color: {
          100: "#E3E4E7",
        },
        borderColor: {
          100: "#646878",
        },
      },
    },
    formItems: {
      borderColor: { 100: "#646878" },
      borderHover: { 100: "#ffffff" },
      textColor: { 100: "#e3e4e7" },
      labelColor: { 100: "#8c8f9b" },
      legendColor: { 100: "#c0c0c0" },
      errorColor: { 100: "#8d2931" },
    },
  },
};

export const darkTheme: ThemeOptions = {
  ...baseTheme,
  overrides: {
    MuiDrawer: {
      paper: {
        background: "#050C26",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "#C0C0C0",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        "&:hover": {
          color: "#1F41CC",
          backgroundColor: "transparent",
        },
      },
    },
    MuiSwitch: {
      thumb: {
        width: 13,
        height: 13,
        marginTop: 6,
        marginLeft: 6,
      },
      switchBase: {
        color: "#ccc",
      },
      colorSecondary: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&$checked": {
          color: "#ccc",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        backgroundColor: "#1F41CC",
        height: "19px",
        width: "36px",
        "$checked$checked + &": {
          // Controls checked color for the track
          backgroundColor: "#1F41CC",
          height: "19px",
          width: "36px",
        },
      },
    },
  },
  palette: {
    type: "dark",
    background: {
      default: "#11172C",
    },
    text: {
      secondary: "#8c8f9b",
      disabled: "#E3E4E7",
    },
    primary: {
      main: "#252C47",
      light: "#1F41CC",
    },
    yellow: {
      100: "#a8a8a8",
    },
    tables: {
      theme: "ag-theme-alpine-dark",
      scrollBar: { thumb: "#000000", thumbBorder: "#ffffff" },
      main: {
        default: "#32374d",
        light: "#505567",
        dark: "rgba(245, 247, 251, 0.1)",
      },
      checkbox: { checkedBackground: "#344dae", checkedColor: "#ffffff" },
      header: { default: "#8C8F9B", darkSorted: "#ffffff" },
      rowSelected: { main: "#344dae33" },
      rowOnHover: { main: "#373c53" },
    },
    green: {
      100: "#151515",
    },
    tabControl: {
      tabControlBackground: {
        100: "#000000",
      },
      tabControlText: {
        100: "#D7D8DC",
      },
      tabControlSelectedText: {
        100: "#ffffff",
      },
      tabBackground: {
        100: "#1f41cc",
      },
    },
    statusComponent: {
      passed: {
        100: "#26AE7D",
      },
      warning: {
        100: "#FFA800",
      },
      failure: {
        100: "#FF1414",
      },
    },

    bell: {
      background: {
        100: "#1f41cc",
      },
    },
    card: {
      background: {
        100: "#252c47",
      },
      backgroundActive: {
        100: "#202c5b",
      },
      borderActive: {
        100: "#607DF1",
      },
      text: {
        100: "#d7d8dc",
      },
      textDisabled: {
        100: "#6b7082",
      },
    },
    rightDrawer: {
      background: {
        100: "#252c47",
      },
    },
    notificationItem: {
      dot: {
        100: "#4C67D6",
      },
    },

    selectInput: {
      background: {
        100: "#32374d",
      },
      selected: {
        100: "#4c526c",
      },
      selectIcon: {
        100: "#afb1ba",
      },
      borderColor: {
        100: "#646878",
      },
    },
    button: {
      primary: {
        background: {
          100: "#344DAE",
          200: "#808080",
        },
        color: {
          100: "#E3E4E7",
        },
        borderColor: {
          100: "transparent",
        },
      },
      secondary: {
        background: {
          100: "transparent",
        },
        color: {
          100: "#E3E4E7",
        },
        borderColor: {
          100: "#646878",
        },
      },
    },
    formItems: {
      borderColor: { 100: "#646878" },
      borderHover: { 100: "#ffffff" },
      textColor: { 100: "#e3e4e7" },
      labelColor: { 100: "#8c8f9b" },
      legendColor: { 100: "#c0c0c0" },
      errorColor: { 100: "#8d2931" },
    },
  },
};
