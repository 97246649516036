import styled from "@emotion/styled";
import { Badge } from "@material-ui/core";
import { StyledPropsWithTheme, ButtonType } from "../../utils/globalTypes";

const SortOptionsButton = styled(Badge)<StyledPropsWithTheme<ButtonType>>`
  height: 36px;
  padding: 6px 8px 6px 10px;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  width: max-content;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.text.disabled};
  ${({ isActive, theme }) =>
    isActive && `background:${theme.palette.primary.light}`};
  > svg {
    ${({ iconRight }) =>
      iconRight ? "margin-left: 5px" : "margin-right: 5px"};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Styled = { SortOptionsButton };
