import React, { createContext, useState } from "react";

const portalInitialValue = {
  isActive: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsActive: () => {},
};

interface PortalContextTypes {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
}

export const PortalContext =
  createContext<PortalContextTypes>(portalInitialValue);

const PortalProvider: React.FC = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const value: PortalContextTypes = {
    isActive,
    setIsActive,
  };

  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
};

export default PortalProvider;
