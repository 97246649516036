import { IconPathType } from "@sdge-utils/globalTypes";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Styled } from "./City.styled";

const City: FC<IconPathType> = ({ path }) => {
  const history = useHistory();
  return (
    <Styled.CityLogo
      isActive={path ? history.location.pathname.includes(path) : false}
    />
  );
};

export default City;
