import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetMarkerInfoActionType, MapsStateTypes } from "./map.types";
import {
  getCoordinates,
  getFilteredGis,
  getLayers,
  getMarkerInfo,
  getMarkers,
} from "./map.async";

const initialMapsState: MapsStateTypes = {
  markers: [],
  groups: [],
  markerInfo: [],
  title: "",
  loading: false,
  layers: [],
  rowData: {},
  constructionPinsCenter: null,
  customersPolesCenter: null,
};

export const mapsSlice = createSlice({
  name: "maps",
  initialState: initialMapsState,
  reducers: {
    handleChangTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    handleSetRowData: (state, action: PayloadAction<string>) => {
      state.rowData = action.payload;
    },
    setCustomerPoleCenter: (state, action: PayloadAction<string>) => {
      state.customersPolesCenter = action.payload;
    },
  },
  extraReducers: {
    [getMarkers.fulfilled]: (state: any, action: any) => {
      state.markers = action.payload?.markers;
      state.groups = action.payload?.groups;
      state.loading = false;
    },
    [getMarkers.pending]: (state: any) => {
      state.loading = true;
    },
    [getMarkers.rejected]: (state: any) => {
      state.loading = false;
    },
    [getCoordinates.fulfilled]: (state: any, action: any) => {
      state.markers = action.payload?.markers;
      state.groups = action.payload?.groups;
      state.constructionPinsCenter = action.payload?.centerPoint;
    },
    [getFilteredGis.fulfilled]: (state: any, action: any) => {
      state.markers = action.payload?.data.markers;
      state.groups = action.payload?.data.groups;
      if (action.payload.center) {
        state.customersPolesCenter = action.payload?.data.centerPoint;
      }
    },
    [getCoordinates.pending]: (state: any) => {
      state.loading = true;
    },
    [getCoordinates.rejected]: (state: any) => {
      state.loading = false;
    },
    [getMarkerInfo.fulfilled]: (
      state: MapsStateTypes,
      action: PayloadAction<GetMarkerInfoActionType>
    ) => {
      state.markerInfo = action.payload;
      state.loading = false;
    },
    [getMarkerInfo.pending]: (state: MapsStateTypes) => {
      state.loading = true;
    },
    [getMarkerInfo.rejected]: (state: MapsStateTypes) => {
      state.loading = false;
    },
    [getLayers.fulfilled]: (state: MapsStateTypes, action: any) => {
      state.layers = action.payload;
      state.loading = false;
    },
    [getLayers.pending]: (state: MapsStateTypes) => {
      state.loading = true;
    },
    [getLayers.rejected]: (state: MapsStateTypes) => {
      state.loading = false;
    },
  },
});

export const { handleChangTitle, handleSetRowData, setCustomerPoleCenter } =
  mapsSlice.actions;
export default mapsSlice.reducer;
