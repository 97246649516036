import {
  isTokenExpired,
  refreshLogin,
} from "@sdge-utils/helpers/authHelpers/authHelpers";
import axios, { AxiosRequestConfig, AxiosPromise, AxiosResponse } from "axios";

import {
  getAccessToken,
  setLocalSessionExpiryTime,
} from "../../hooks/useAuth/useAuth.utils";

const makeQuery = () => {
  if (isTokenExpired()) {
    refreshLogin();
  }
  setLocalSessionExpiryTime();
  const queryInstanceOptions = {
    baseURL:
      localStorage.getItem("backend_url") ?? process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: "Bearer " + getAccessToken(),
    },
  };
  return axios.create(queryInstanceOptions);
};

export const createQueryInstance = {
  get: function (url: string, config?: AxiosRequestConfig): AxiosPromise {
    return makeQuery().get(url, config);
  },
  post: function <T>(
    url: string,
    body: T,
    config?: AxiosRequestConfig
  ): AxiosPromise {
    return makeQuery().post(url, body, config);
  },

  put: function <T>(
    url: string,
    body: T,
    config?: AxiosRequestConfig
  ): AxiosPromise {
    return makeQuery().put(url, body, config);
  },

  delete: function (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    return makeQuery().delete(url, config);
  },
};

export default createQueryInstance;
