import React, { FC } from "react";
import { MassUpdateSelectTypes, OptionsData } from "./MassUpdateSelect.types";
import { Styled } from "./MassUpdateSelect.styled";
import { useAppDispatch } from "../../../../app/hooks";
import { setMassSelect } from "@sdge-utils/helpers/filterHelpers/filterHelpers";

const MassUpdateSelect: FC<MassUpdateSelectTypes> = ({
  optionsData,
  property,
  width,
  currSelectInfo,
  currentSelect,
  currentField,
  defaultValue,
  handleSelect,
  currentMassUpdate,
}) => {
  const dispatch = useAppDispatch();
  return (
    <Styled.SelectMassUpdateWrapper
      native
      defaultValue={defaultValue}
      onChange={(e) => {
        dispatch(
          handleSelect(
            setMassSelect(
              currentMassUpdate,
              e.target.value as string,
              property,
              currSelectInfo,
              currentField
            )
          )
        );
      }}
      width={width}
    >
      {currentSelect && <option value="">{currentSelect}</option>}
      {optionsData
        .filter((item) => item.label !== currentSelect)
        .map(({ id, value, label }: OptionsData) => (
          <option key={id} value={value}>
            {label}
          </option>
        ))}
    </Styled.SelectMassUpdateWrapper>
  );
};

export default MassUpdateSelect;
