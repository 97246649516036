import React, { FC, useState } from "react";
import {
  MassUpdateOptionsData,
  MassUpdateFunctions,
} from "./MassUpdateOptions.types";
import {
  generateMassUpdate,
  genereateFilteredMassUpdate,
} from "@sdge-utils/helpers/editHelpers/editHelpers";
import { Styled } from "./MassUpdateOptions.styled";
import { useAppDispatch } from "../../app/hooks";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { MassUpdateItem } from "./components";
import AddItemButton from "./components/AddItemButton";
import { usePermission } from "@sdge-hooks";

const MassUpdateOptions: FC<{
  data: MassUpdateOptionsData;
  functions: MassUpdateFunctions;
  page: "construction" | "issueTracker" | "assets";
}> = ({ data, functions, page }) => {
  const {
    selectedRows,
    massUpdateItems,
    columnDefs,
    selecedRowsIssueIds,
    filteringRules,
    totalElements,
    massUpdateLoading,
  } = data;
  const {
    updateFilteredRows,
    updateSelectedRows,
    handleMassUpdateItems,
    handleSelectedRows,
    handleSelectedIssueIds,
  } = functions;

  const dispatch = useAppDispatch();
  const { getPermission } = usePermission();
  const [allItems, setAllItems] = useState(false);
  const hasEroPermissions = getPermission("eroMassEdit");
  const hasVegPermissions = getPermission("vegMassEdit");
  const hasDocCtrlPermissions = getPermission("docCtrlMassEdit");
  const hasFirmPrimePermissions = getPermission("firmPrimeMassUpdate");
  const hasCityWorksPermissions = getPermission("cityWorksMassUpdate");

  const validColumns = columnDefs.filter(({ edit }) => edit === true);
  const unAddedCols = validColumns.filter(
    (o1) => !massUpdateItems.some((o2) => o1.field === o2.field)
  );

  const validEroColumns = columnDefs.filter(({ eroEdit }) => eroEdit === true);
  const unAddedEroCols = validEroColumns.filter(
    (o1) => !massUpdateItems.some((o2) => o1.field === o2.field)
  );

  const validVegColumns = columnDefs.filter(({ vegEdit }) => vegEdit === true);
  const unAddedVegCols = validVegColumns.filter(
    (o1) => !massUpdateItems.some((o2) => o1.field === o2.field)
  );

  const validDocColumns = columnDefs.filter(({ docEdit }) => docEdit === true);
  const unAddedDocCols = validDocColumns.filter(
    (o1) => !massUpdateItems.some((o2) => o1.field === o2.field)
  );

  const validFirmPrimeColumns = columnDefs.filter(
    ({ firmPrimeEdit }) => firmPrimeEdit === true
  );
  const unAddedFirmPrimeColumns = validFirmPrimeColumns.filter(
    (o1) => !massUpdateItems.some((o2) => o1.field === o2.field)
  );

  const validCityWorksColumns = columnDefs.filter(
    ({ cityWorksEdit }) => cityWorksEdit === true
  );

  const unAddedCityWorksColumns = validCityWorksColumns.filter(
    (o1) => !massUpdateItems.some((o2) => o1.field === o2.field)
  );

  const massUpdateClick = () => {
    dispatch(handleSelectedRows([]));
    dispatch(handleSelectedIssueIds([]));
    dispatch(
      allItems
        ? updateFilteredRows(
            genereateFilteredMassUpdate(massUpdateItems, page, filteringRules)
          )
        : updateSelectedRows(
            generateMassUpdate(massUpdateItems, selecedRowsIssueIds, page)
          )
    );
  };

  return (
    <Styled.MassUpdateOptionsWrapper
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {massUpdateLoading && (
        <Styled.LoadingLayer>
          <CircularProgress color="inherit" />
        </Styled.LoadingLayer>
      )}
      <Styled.MassUpdateTitle>
        <Styled.TitleLeft>
          Mass Edit ({allItems ? totalElements : selectedRows.length} records)
          {filteringRules.length > 0 && !selectedRows.length && (
            <FormControlLabel
              control={
                <Checkbox
                  name="all items"
                  value={allItems}
                  onChange={() => setAllItems(!allItems)}
                  defaultChecked={false}
                />
              }
              label="Apply to all filtered Items"
            />
          )}
        </Styled.TitleLeft>
        {hasEroPermissions ? (
          <AddItemButton
            selecedRowsIssueIds={selecedRowsIssueIds}
            allItems={allItems}
            massUpdateItems={massUpdateItems}
            validColumns={validEroColumns}
            handleMassUpdateItems={handleMassUpdateItems}
            fieldToGenerate={unAddedEroCols[0]?.field}
          />
        ) : hasVegPermissions ? (
          <AddItemButton
            selecedRowsIssueIds={selecedRowsIssueIds}
            allItems={allItems}
            massUpdateItems={massUpdateItems}
            validColumns={validVegColumns}
            handleMassUpdateItems={handleMassUpdateItems}
            fieldToGenerate={unAddedVegCols[0]?.field}
          />
        ) : hasDocCtrlPermissions ? (
          <AddItemButton
            selecedRowsIssueIds={selecedRowsIssueIds}
            allItems={allItems}
            massUpdateItems={massUpdateItems}
            validColumns={validDocColumns}
            handleMassUpdateItems={handleMassUpdateItems}
            fieldToGenerate={unAddedDocCols[0]?.field}
          />
        ) : hasFirmPrimePermissions ? (
          <AddItemButton
            selecedRowsIssueIds={selecedRowsIssueIds}
            allItems={allItems}
            massUpdateItems={massUpdateItems}
            validColumns={validFirmPrimeColumns}
            handleMassUpdateItems={handleMassUpdateItems}
            fieldToGenerate={unAddedFirmPrimeColumns[0]?.field}
          />
        ) : hasCityWorksPermissions ? (
          <AddItemButton
            selecedRowsIssueIds={selecedRowsIssueIds}
            allItems={allItems}
            massUpdateItems={massUpdateItems}
            validColumns={validCityWorksColumns}
            handleMassUpdateItems={handleMassUpdateItems}
            fieldToGenerate={unAddedCityWorksColumns[0]?.field}
          />
        ) : (
          <AddItemButton
            selecedRowsIssueIds={selecedRowsIssueIds}
            allItems={allItems}
            massUpdateItems={massUpdateItems}
            validColumns={validColumns}
            handleMassUpdateItems={handleMassUpdateItems}
            fieldToGenerate={unAddedCols[0]?.field}
          />
        )}
      </Styled.MassUpdateTitle>
      <Styled.MassUpdateOptionsList>
        {massUpdateItems.length > 0 ? (
          (selecedRowsIssueIds.length > 0 || allItems) &&
          massUpdateItems.map((item) => (
            <MassUpdateItem
              key={item.field}
              columnDefs={columnDefs}
              field={item.field}
              currentMassUpdate={massUpdateItems}
              handleSelect={handleMassUpdateItems}
            />
          ))
        ) : (
          <Styled.Fallback>Add columns for mass update</Styled.Fallback>
        )}
      </Styled.MassUpdateOptionsList>
      <Styled.AddFieldWrapper>
        {massUpdateItems.length > 0 &&
          (selecedRowsIssueIds.length > 0 || allItems) && (
            <>
              <Styled.AddItemButton
                title="Update Records"
                onClick={massUpdateClick}
                isActive
              />
              <Styled.DecorateButton type="submit" />
            </>
          )}
      </Styled.AddFieldWrapper>
    </Styled.MassUpdateOptionsWrapper>
  );
};

export default MassUpdateOptions;
