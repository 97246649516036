import * as yup from "yup";

export const CreateMuniPermitsValidationSchema = yup.object().shape({
  permitType: yup.string().required(),
  // permitDateSubmitted: yup.string().required(),
  permitTimeFrame: yup.string(),
  // permitIssuedDate: yup.string().required(),
  k2Reference: yup.string().required(),
});

export const CreateMuniPermitsValidationSchemaAssets = yup.object().shape({
  muniPermitType: yup.string().required(),
  // muniPermitDateSubmitted: yup.string().required(),
  miniPermitTimeFrame: yup.string(),
  // muniPermitIssuedDate: yup.string().required(),
  k2Reference: yup.string().required(),
});
