import { TileSection } from "../TileSection";
import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import useEnvPermitContentCreator from "./useEnvPermitContentCreator";
import RowItem from "./RowItem";
import { useDispatch } from "react-redux";
import { Divider, Typography } from "@material-ui/core";
import {
  deleteEnvPermit,
  getEnvPermits,
} from "@sdge-store/issueTracker/issueTracker.async";
import { useAppSelector } from "../../app/hooks";
import { DeleteIcon } from "../../icons";
import {
  deleteEnvPermitAssets,
  getEnvPermitsAssets,
} from "@sdge-store/customers/customers.async";
import { selectConstruction } from "@sdge-store/construction/construction.selector";
import { deleteEnvPermitConstruction } from "@sdge-store/construction/construction.async";
import { usePermission } from "@sdge-hooks";
import { parsePoleHistoryDate } from "@sdge-utils/dateParser";

const EnvPermitTileContent: React.FC<{
  customerPage?: boolean;
  constructionPage?: boolean;
}> = ({ customerPage, constructionPage }) => {
  const dispatch = useDispatch();
  const { activeIssueId, requested, cardsLoading } = useAppSelector(
    (state) => state.issueTrackerSlice
  );
  const { activeRowId } = useAppSelector((state) => state.customersSlice);
  const { getPermission } = usePermission();
  const { activeConstructionGroupName } = useAppSelector(selectConstruction);
  const { programInfo } = useAppSelector(
    (state) => state.drawerState.programData
  );
  useEffect(() => {
    customerPage
      ? activeRowId && dispatch(getEnvPermitsAssets(activeRowId))
      : !constructionPage &&
        activeIssueId &&
        dispatch(getEnvPermits(activeIssueId));
  }, [activeIssueId, activeRowId, activeConstructionGroupName]);

  const contentItems = useEnvPermitContentCreator(
    customerPage,
    constructionPage
  );
  const removeEnvPermit = (payload: any) => {
    customerPage
      ? dispatch(deleteEnvPermitAssets(payload))
      : constructionPage
      ? dispatch(deleteEnvPermitConstruction(payload))
      : dispatch(deleteEnvPermit(payload));
  };
  const hasPermissions =
    getPermission("delEnviBesideConstPage") ||
    getPermission("delEnvPermitTile") ||
    (getPermission("del_tile_const") && constructionPage);

  if (cardsLoading) {
    return <> </>;
  }

  if (cardsLoading) {
    return <> </>;
  }

  return (
    <>
      {!contentItems?.length && requested && (
        <Grid container justifyContent="center">
          <Box mt="20%" width="70%">
            <Typography align="center">No Environmental Permits</Typography>
          </Box>
        </Grid>
      )}
      {contentItems?.map((item: any, i: number) => {
        return (
          <Box key={i}>
            {programInfo.bmcdTier3 !== "Y" && hasPermissions ? (
              <TileSection
                title=""
                Icon={DeleteIcon}
                handleAction={() => {
                  removeEnvPermit({
                    envPermitsId: item.filter((item: any) => item.permitId)[0]
                      .permitId,
                    activeIssueId: constructionPage
                      ? activeRowId
                      : constructionPage
                      ? activeConstructionGroupName
                      : activeIssueId,
                    id:
                      constructionPage &&
                      item.filter((item: any) => item.permitId)[0].permitId,
                  });
                }}
              />
            ) : (
              <Divider />
            )}
            {item.map(({ id, title, content, name, date }: any) => {
              return (
                <RowItem
                  title={title}
                  key={id}
                  content={parsePoleHistoryDate(content)?.toString()}
                  name={name}
                  date={date}
                  actualPermitId={item[7]?.permitId || ""}
                  customerPage={customerPage}
                  constructionPage={constructionPage}
                />
              );
            })}
          </Box>
        );
      })}
    </>
  );
};

export default EnvPermitTileContent;
