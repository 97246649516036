import React from "react";
import { render } from "react-dom";
import App from "./app";
import { store } from "./app/store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./utils/theme/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import PortalProvider from "./layouts/PortalLayout/Portal.context";

render(
  <React.StrictMode>
    <Provider store={store}>
      <PortalProvider>
        <App />
      </PortalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
