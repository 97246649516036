import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const QuickActionWrapper = styled.div<StyledPropsWithTheme>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Styled = {
  QuickActionWrapper,
};
