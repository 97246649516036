import React, { FC, useEffect } from "react";
import { TablePropsType } from "@sdge-utils/globalTypes";
import { Styled } from "./ProgramsSummary.styled";
import Card from "../Card";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  handleFilter,
  handleFilteringRules,
} from "@sdge-store/overlaps/overlaps.slice";
import { selectProgramsSummary } from "@sdge-store/programs/programs.selector";
import { filterOverlapsData } from "@sdge-store/overlaps/overlaps.async";
import { fetchProgramsData } from "@sdge-store/programs/programs.async";
import { selectOverlaps } from "@sdge-store/overlaps/overlaps.selector";
import { Envcard } from "../Envcard";
import { useLocation } from "react-router-dom";

const ProgramsSummary: FC<TablePropsType> = ({ issue }): JSX.Element => {
  const { programs_summary } = useAppSelector(selectProgramsSummary);
  const { sortingRules } = useAppSelector(selectOverlaps);
  const { pathname } = useLocation();
  const packageSum = pathname.split("/").includes("summary_package");
  const poleSum = pathname.split("/").includes("summary_pole");
  const { poleSummary, packageSummary } = useAppSelector(
    (state) => state.issueTrackerSlice
  );

  const summary = packageSum ? packageSummary : poleSum ? poleSummary : [];
  const newSummary = Object?.keys(summary || {})?.map((item) => {
    return {
      title:
        (item === "at90PercentNotSubmitted" && "At 90 Percent Not Submitted") ||
        (item === "envPermitsReleasesNotSubmitted" &&
          "Env Permits Releases Not Submitted") ||
        (item === "envPermitsReleasesPassed30Days" &&
          "Env Permits Releases Passed 30 Days") ||
        (item === "muniPermitsNotSubmitted" && "Muni Permits Not Submitted") ||
        (item === "permitsNotIssuedPassed30Days" &&
          "Permits Not Issued Passed 30 Days") ||
        "",
      changed90complete: summary[item].changed90complete,
      notIssuedPassed30Days: summary[item].notIssuedPassed30Days,
      permitsAtRisk: summary[item].permitsAtRisk,
    };
  });

  const { filters } = useAppSelector(selectOverlaps);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProgramsData());
  }, [dispatch]);

  const selectedProgramCode = filters.find(
    (item) => item.fieldName === "programNames"
  )?.value;

  const genereateProgramsFilter = (value: string, fieldName: string) => {
    const filterRule = [
      {
        operation: "equals",
        negate: false,
        fieldName: fieldName,
        value: value,
      },
    ];
    dispatch(filterOverlapsData([0, sortingRules, filterRule]));
    dispatch(handleFilter(filterRule));
    dispatch(handleFilteringRules(filterRule));
  };

  return (
    <Styled.TableGroup>
      {issue ? (
        <>
          {newSummary?.map((cardInfo: any, index: number) => {
            return (
              <Envcard
                cardInfo={cardInfo}
                key={index}
                onClick={() => {
                  genereateProgramsFilter(
                    cardInfo.program_code,
                    "programNames"
                  );
                }}
              />
            );
          })}
        </>
      ) : (
        <>
          {programs_summary?.map((cardInfo: any, index: number) => {
            return (
              <Card
                active={selectedProgramCode === cardInfo.program_code}
                cardInfo={cardInfo}
                key={cardInfo.program_id + "" + index}
                onClick={() => {
                  genereateProgramsFilter(
                    cardInfo.program_code,
                    "programNames"
                  );
                }}
              />
            );
          })}
        </>
      )}
    </Styled.TableGroup>
  );
};

export default ProgramsSummary;
