export type DrawerPropsType = {
  handleDrawerClose: () => void;
  isDark: boolean;
};

export enum Direction {
  LeftToRight = "ltr",
}

export type PageType = { open?: boolean };
