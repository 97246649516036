import React, { useEffect } from "react";
import { ProgramsSummary, OverlapsMainTable, Map } from "../../components";
import { Styled } from "./OverlappingPage.styled";
import { useLocation } from "react-router-dom";
import { getLayers } from "@sdge-store/Map/map.async";
import { useDispatch } from "react-redux";
import { handleRouterChange } from "@sdge-store/drawer/drawer.slice";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { ErrorBoundary } from "@sdge-pages";
import { setActiveIssueId } from "@sdge-store/issueTracker/issueTracker.slice";

const OverlappingPage: React.FC = () => {
  // "https://jsonplaceholder.typicode.com/users"
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const map = pathname.split("/").includes("map");
  useEffect(() => {
    dispatch(setActiveIssueId(""));
    dispatch(getLayers());
    dispatch(
      handleRouterChange({
        parentPath: RightDrawerParentPaths.OVERLAPPING_TABLE,
      })
    );
  }, []);

  return (
    <Styled.OverlappingPageWrapper>
      {map ? (
        <Map key="AIzaSyD3jpcQHDLYnj-ulGUJMtLiXIqKpEi2FNo" />
      ) : (
        <ErrorBoundary>
          <ProgramsSummary />
        </ErrorBoundary>
      )}
      <OverlapsMainTable />
    </Styled.OverlappingPageWrapper>
  );
};

export default OverlappingPage;
