import { useEffect } from "react";
import { Styled } from "./CellHistory.styled";
import { parsePoleHistoryDate, toLocalTimeZone } from "@sdge-utils/dateParser";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getPoleHistory } from "@sdge-store/drawer/drawer.async";
import { selectRightDrawer } from "@sdge-store/drawer/drawer.selector";
import PoleHistoryDescription from "./PoleHistoryDescription";
import { camelCaseTransform } from "@sdge-utils/dataTransform";

const PoleHistory = () => {
  const dispatch = useAppDispatch();
  const {
    issueId,
    poleHistory: histories,
    cardId,
  } = useAppSelector(selectRightDrawer);

  useEffect(() => {
    if (issueId || cardId) dispatch(getPoleHistory());
  }, [issueId, cardId]);

  if (!histories?.length) {
    return <Styled.NoHistory>No History</Styled.NoHistory>;
  }

  const sortedHistory = [...histories];

  return (
    <Styled.HistoryWrapper>
      {sortedHistory
        .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
        .map((history, index: number) => {
          const { userId, date, changes } = history;

          return (
            <div key={index}>
              {userId && (
                <Styled.Header>
                  {changes.length > 0 &&
                    `${userId} on ${
                      date
                        ? parsePoleHistoryDate(
                            toLocalTimeZone(date),
                            "MM/DD/YYYY, HH:mm:ss"
                          )
                        : "N/A"
                    }`}
                </Styled.Header>
              )}
              {changes.map((changeItem: any, index: number) => {
                return (
                  <Styled.HistoryItem key={index}>
                    <Styled.Body>
                      <Styled.Text>
                        {changeItem.path &&
                          `${camelCaseTransform(changeItem.path.slice(1))}:`}
                      </Styled.Text>
                      <PoleHistoryDescription changeItem={changeItem} />
                    </Styled.Body>
                  </Styled.HistoryItem>
                );
              })}
            </div>
          );
        })}
    </Styled.HistoryWrapper>
  );
};

export default PoleHistory;
