import React from "react";

const Emergency = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 7C6 4.79086 7.79086 3 10 3H14C16.2091 3 18 4.79086 18 7V16H6V7Z"
        stroke="#F10000"
        strokeWidth="2"
      />
      <path
        d="M4 17C4 16.4477 4.44772 16 5 16H19C19.5523 16 20 16.4477 20 17V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V17Z"
        stroke="#F10000"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect x="11" y="6" width="2" height="7" fill="#F10000" />
      <path d="M15.5 8.5V10.5L8.5 10.5L8.5 8.5L15.5 8.5Z" fill="#F10000" />
    </svg>
  );
};

export default Emergency;
