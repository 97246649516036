import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { Props404 } from "@sdge-pages/404/404.types";

const Wrapper404 = styled(Box)<StyledPropsWithTheme<Props404>>`
  background-color: ${() => {
    return "red";
  }};
`;

export const Styled = { Wrapper404 };
