import { FC } from "react";
import { Styled } from "./Acknowledge.styled";
import { useForm } from "react-hook-form";
import {
  StandardButton,
  SelectInput,
  TextInputWithController,
} from "../../../";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { handleSetNotification } from "../../../../app/store/drawer/drawer.slice";
import { setAcknowledge } from "../../../../app/store/drawer/drawer.async";
import { yupResolver } from "@hookform/resolvers/yup";
import acknowledgeValidationSchema from "./AcknowledgeForm.validation";
import { priority } from "./AcknowledgeForm.constants";
import { AcknowledgeFormTypes } from "@sdge-assets/OverlappingDrawer/Components/AcknowledgeForm/AcknowledgeForm.types";
import { parseDateString } from "@sdge-utils/dateParser";

const AcknowledgeForm: FC<AcknowledgeFormTypes> = ({
  setAcknowledgmentsOpen,
}) => {
  //
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(acknowledgeValidationSchema),
  });
  const selectedNotification = useAppSelector(
    (state) => state.drawerState.selectedNotification
  );
  const { eventType, systemName, status, changeTime } = selectedNotification;
  const dispatch = useAppDispatch();

  const formSubmit = (data: any) => {
    dispatch(
      setAcknowledge({
        type: "type",
        status: data.status,
        comment: data.comment,
      })
    );
    dispatch(handleSetNotification({}));
    setAcknowledgmentsOpen(false);
  };

  const handleCloseForm = () => {
    setAcknowledgmentsOpen(false);
    dispatch(
      handleSetNotification({ ...selectedNotification, acknowledged: true })
    );
  };

  return (
    <Styled.Form onSubmit={handleSubmit(formSubmit)}>
      <Styled.TextWrapper>
        <Styled.Row>
          <Styled.Text weight={700}>{eventType}</Styled.Text>&nbsp;
          <Styled.Text weight={400}>
            {systemName === "core" ? "in" : "with"}
          </Styled.Text>
          &nbsp;
          <Styled.Text weight={700}>{systemName}</Styled.Text>
        </Styled.Row>
        <Styled.Row>
          <Styled.Text>{status}</Styled.Text>
          &nbsp;at&nbsp;
          <Styled.Text>{parseDateString(changeTime, "MM/DD/YYYY")}</Styled.Text>
        </Styled.Row>
      </Styled.TextWrapper>
      <SelectInput
        optionsData={priority}
        control={control}
        defaultValue={""}
        name={"status"}
        label="Status"
        errorMessage={errors.status?.message}
      />
      <TextInputWithController
        label="Comment"
        control={control}
        name="comment"
        errorMessage={errors.comment?.message}
      />
      <Styled.Row gap>
        <StandardButton title="Acknowledge" type="submit" pallet="primary" />
        <StandardButton
          title="Cancel"
          type="button"
          pallet="secondary"
          onClick={handleCloseForm}
        />
      </Styled.Row>
    </Styled.Form>
  );
};

export default AcknowledgeForm;
