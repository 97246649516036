import React, { useMemo } from "react";
import { Router } from "react-router-dom";
import { createTheme, CssBaseline } from "@material-ui/core";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@emotion/react";
import { darkTheme, history, lightTheme } from "../../utils";

import GlobalStyles from "./GlobalStyles";
import { useAppSelector } from "../hooks";
import { ThemeTypes } from "../store/theme/theme.types";

const AppWrapper: React.FC = ({ children }) => {
  const { theme } = useAppSelector((state) => state.appTheme);
  const appTheme = useMemo(
    () => createTheme(theme === ThemeTypes.DARK ? darkTheme : lightTheme),
    [theme]
  );
  return (
    <Router history={history}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={appTheme}>
          <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <GlobalStyles />
            {children}
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Router>
  );
};

export default AppWrapper;
