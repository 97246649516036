import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryInstance } from "../../../api/config";
import { errorHandler } from "../../../api/utils";
import { GetAutocompleteValuesByFilter } from "@sdge-store/autoComplete/autoComplete.types";

export const getUniqueValues: any = createAsyncThunk(
  "autoComplete/getUniqueValues",
  async (_, { dispatch }) => {
    try {
      const { data }: any = await queryInstance.get(
        "/issue-tracker/unique-values"
      );
      return Object.keys(data).reduce((acc, key) => {
        return {
          ...acc,
          [key]: data[key].sort(),
        };
      }, {});
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getAutocompleteValuesByFilter: any = createAsyncThunk(
  "autoComplete/getAutocompleteValuesByFilter",
  async ({ group, filter }: GetAutocompleteValuesByFilter, { dispatch }) => {
    const changedGroup = group
      .split("")
      .map((item) => (item === "#" ? item.replace("#", "%23") : item))
      .join("");
    const changedFilter = filter
      .split("")
      .map((item) => (item === "#" ? item.replace("#", "%23") : item))
      .join("");
    try {
      const { data }: any = await queryInstance.get(
        `/issue-tracker/autocomplete/${changedGroup}?filter=${changedFilter}`
      );
      const payload = {
        [group]: data.sort(),
      };
      return payload;
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
