import { RowIcon } from "@sdge-components/MainTable/components";
import { TreeIcon } from "../../../../icons";
import React from "react";
import envPermits from "./envPermits";
import muniPermit from "./muniPermit";
import customer from "./customer";
import sap from "./sap";
import overlapping from "./overlapping";

export const columnDefs = [
  {
    cellRendererFramework: (params: any) => <RowIcon data={params.data} />,
    suppressMenu: true,
    sorting: true,
    filtering: true,
    suppressMovable: true,
    colId: "event",
    lockPosition: true,
    lockPinned: true,
    pinned: true,
    minWidth: 90,
    suppressNavigable: true,
  },
  {
    cellRendererFramework: ({ data }: any) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data.isEnvironmental && <TreeIcon />}
      </div>
    ),
    suppressMenu: true,
    colId: "isEnvironmental",
    suppressMovable: true,
    lockPosition: true,
    lockPinned: true,
    minWidth: 30,
    maxWidth: 50,
    pinned: true,
    resizable: false,
    suppressNavigable: true,
  },
  {
    maxWidth: 60,
    suppressMenu: true,
    suppressMovable: true,
    lockPosition: true,
    colId: "checkbox",
    lockPinned: true,
    pinned: true,
    resizable: false,
    suppressNavigable: true,
  },
  {
    headerName: "Id",
    field: "id",
    sortField: "issue_id.keyword",
    filterField: "issue_id",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    invisible: true,
  },
  {
    headerName: "Approved Date",
    field: "approvedDate",
    sortField: "issue_approved_date.keyword",
    filterField: "issue_approved_date",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Assessment Report URL",
    field: "assessmentReportURL",
    sortField: "automated_assessment_report_location.keyword",
    filterField: "automated_assessment_report_location",
    hide: false,
    pinned: null,
    sorting: true,
    edit: true,
    filtering: false,
    cellRendererFramework: ({ data }: any) => {
      return (
        <a href={data.assessmentReportURL} target={"_blank"}>
          Assessment Report URL
        </a>
      );
    },
  },
  {
    headerName: "Assigned",
    field: "assigned",
    filterField: "assigned",
    sortField: "assigned.keyword",
    hide: false,
    edit: true,
    pinned: null,
    sorting: true,
    filtering: true,
    cellEditor: "agSelectCellEditor",
    docEdit: true,
  },
  {
    headerName: "Ball In Court",
    field: "ballInCourt",
    sortField: "ballincourt.keyword",
    filterField: "ballincourt",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
    cellEditor: "agSelectCellEditor",
    docEdit: true,
  },
  {
    headerName: "Circuit Id",
    field: "circuitId",
    sortField: "circuit_id.keyword",
    filterField: "circuit_id",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Classification",
    field: "classification",
    sortField: "classification.keyword",
    filterField: "classification",
    hide: false,
    pinned: null,
    sorting: true,
    edit: true,
    filtering: true,
  },
  {
    field: "cordoba90completionDate",
    filtering: true,
    headerName: "Cordoba 90 Completion Date",
    sortField: "cordoba_90_completion_date.keyword",
    filterField: "cordoba_90_completion_date",
    hide: false,
    pinned: null,
    sorting: true,
  },
  {
    field: "bmcdTier3",
    filtering: true,
    sorting: true,
    hide: false,
    pinned: null,
    headerName: "BMCD Tier-3",
    sortField: "bmcd_tier_3.keyword",
    filterField: "bmcd_tier_3",
  },
  {
    field: "constructionDate",
    filtering: true,
    hide: false,
    pinned: null,
    headerName: "Construction Date",
    sortField: "constructiondate.keyword",
    filterField: "constructiondate",
  },
  {
    headerName: "Complexity",
    field: "complexity",
    sortField: "complexity_calc.keyword",
    filterField: "complexity_calc",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Condition Code Id",
    field: "conditionCodeId",
    sortField: "condition_code_id.keyword",
    filterField: "condition_code_id",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Cross Check",
    field: "crossCheck",
    sortField: "cross_check.keyword",
    filterField: "cross_check",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Crossarm Replacement",
    field: "crossarmReplacement",
    sortField: "crossarm_replacement.keyword",
    filterField: "crossarm_replacement",
    hide: false,
    pinned: null,
    sorting: true,
    edit: true,
    filtering: true,
  },
  {
    headerName: "Description",
    field: "description",
    sortField: "issue_description.keyword",
    filterField: "issue_description",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "District Name",
    field: "districtName",
    sortField: "district_name.keyword",
    filterField: "district_name",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Facility Id",
    field: "facilityId",
    sortField: "facility_id.keyword",
    filterField: "facility_id",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Forecast Veg Clear Date",
    field: "forecastedVegClearDate",
    sortField: "forecasted_veg_clear_date.keyword",
    filterField: "forecasted_veg_clear_date",
    vegEdit: true,
    edit: true,
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Goal Date",
    field: "goalDate",
    sortField: "goal_date.keyword",
    filterField: "goal_date",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Group Name",
    field: "groupName",
    sortField: "group_name.keyword",
    filterField: "group_name",
    hide: false,
    pinned: null,
    sorting: true,
    edit: true,
    filtering: true,
  },
  {
    headerName: "Identified Date",
    field: "identifiedDate",
    sortField: "issue_identified_date.keyword",
    filterField: "issue_identified_date",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Initiation Complete Date",
    field: "initiationCompleteDate",
    sortField: "e2_initiation_complete_date.keyword",
    filterField: "e2_initiation_complete_date",
    hide: false,
    pinned: null,
    sorting: true,
    edit: true,
    filtering: true,
  },
  {
    headerName: "KMZ URL",
    field: "kmzURL",
    sortField: "kmz_link.keyword",
    filterField: "kmz_link",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: false,
    edit: true,
    cellRendererFramework: ({ data }: any) => {
      return (
        <>
          {data.kmzURL === "N/A" ? (
            "N/A"
          ) : !data.kmzURL ? (
            ""
          ) : (
            <a href={data.kmzURL} target={"_blank"}>
              {data.kmzURL === "N/A" ? "N/A" : "KMZ Link"}
            </a>
          )}
        </>
      );
    },
  },
  {
    headerName: "Latitude",
    field: "latitude",
    sortField: "latitude.keyword",
    filterField: "latitude",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Longitude",
    field: "longitude",
    sortField: "longitude.keyword",
    filterField: "longitude",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Number of Poles Per Group",
    field: "numberOfPoles",
    sortField: "%23_of_poles.keyword",
    filterField: "#_of_poles",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    cellRendererFramework: ({ data }: any) => {
      return (
        <>
          {!data.numberOfPoles
            ? null
            : // : data.numberOfPoles.includes(".")
              // ? data.numberOfPoles.slice(0, -2)
              data.numberOfPoles}
        </>
      );
    },
  },
  {
    headerName: "Number of Issues Per Pole",
    field: "issuesPerPole",
    sortField: "issues_per_pole.keyword",
    filterField: "issues_per_pole",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    cellRendererFramework: ({ data }: any) => {
      return (
        <>
          {!data.issuesPerPole
            ? null
            : // : data.issuesPerPole.includes(".")
              // ? data.issuesPerPole.slice(0, -2)
              data.issuesPerPole}
        </>
      );
    },
  },
  {
    headerName: "Pole Replacement",
    field: "poleReplacement",
    sortField: "pole_replacement.keyword",
    filterField: "pole_replacement",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    edit: true,
  },
  {
    headerName: "Priority",
    field: "priority",
    sortField: "priority.keyword",
    filterField: "priority",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: " Issue Resolved Date",
    field: "resolvedDate",
    sortField: "issue_resolved_date.keyword",
    filterField: "issue_resolved_date",
    eroEdit: true,
    vegEdit: true,
    cityWorksEdit: true,
    firmPrimeEdit: true,
    edit: true,
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
    docEdit: true,
  },
  {
    headerName: "Status",
    field: "status",
    sortField: "status.keyword",
    filterField: "status",
    hide: false,
    pinned: null,
    sorting: true,
    cityWorksEdit: true,
    edit: true,
    filtering: true,
    cellEditor: "agSelectCellEditor",
    docEdit: true,
  },
  {
    headerName: "Thomas Bros",
    field: "thomasBros",
    sortField: "thomas_bros.keyword",
    filterField: "thomas_bros",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Tl Id",
    field: "tlId",
    sortField: "tlId.keyword",
    filterField: "tlId",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Type",
    field: "type",
    sortField: "issue_type.keyword",
    filterField: "issue_type",
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
  {
    headerName: "Vets",
    field: "vets",
    sortField: "vets_%23.keyword",
    filterField: "vets_#",
    vegEdit: true,
    edit: true,
    hide: false,
    pinned: null,
    sorting: true,
    filtering: true,
  },
];

const additionalIssueColumnDefs = [
  {
    field: "submittedDate",
    filtering: true,
    headerName: "Submitted Date",
    sortField: "sap_jn_form_submitted_date.keyword",
    filterField: "sap_jn_form_submitted_date",
  },
  {
    field: "wo",
    filtering: true,
    headerName: "Wo",
    sortField: "wo#53xxxx.keyword",
    filterField: "wo#53xxxx",
  },
  {
    field: "mcuOrder",
    filtering: true,
    headerName: "Mcu Order",
    filterField: "mcu_order",
    sortField: "mcu_order.keyword",
  },
  {
    field: "projectNumber",
    filtering: true,
    headerName: "Project Number",
    filterField: "project_number",
    sortField: "project_number.keyword",
  },
  {
    field: "jobNumber",
    filtering: true,
    headerName: "Job Number",
    sortField: "job_number.keyword",
    filterField: "job_number",
  },
  {
    field: "contractorNumber",
    filtering: true,
    headerName: "Contractor Name",
    sortField: "contractor_number.keyword",
    filterField: "contractor_number",
  },
  {
    field: "jobNotificationOpenDate",
    filtering: true,
    headerName: "Job Notification Open Date",
    sortField: "job_notification_open_date.keyword",
    filterField: "job_notification_open_date",
  },
  {
    field: "flown",
    filtering: true,
    headerName: "Flown",
    sortField: "flown.keyword",
    filterField: "flown",
  },
  {
    field: "flownDate",
    sortField: "flown_date.keyword",
    filterField: "flown_date",
    filtering: true,
    headerName: "Flown Date",
  },
  {
    field: "reqStartDate",
    filtering: true,
    headerName: "Req Start Date",
    sortField: "req_start_date.keyword",
    filterField: "req_start_date",
  },
  {
    field: "reqEndDate",
    filtering: true,
    headerName: "Req End Date",
    sortField: "req_end_date.keyword",
    filterField: "req_end_date",
  },
  {
    field: "pspsStart",
    filtering: true,
    headerName: "Psps Start",
    sortField: "psps_start.keyword",
    filterField: "psps_start",
  },
  {
    field: "pspsEnd",
    filtering: true,
    headerName: "Psps End",
    sortField: "psps_end.keyword",
    filterField: "psps_end",
  },
  {
    field: "ifcActual",
    filtering: true,
    headerName: "Ifc Actual",
    sortField: "ifcactual.keyword",
    filterField: "ifcactual",
  },
  {
    field: "eshMfb",
    filtering: true,
    headerName: "Esh Mfb",
    sortField: "esh_mfb.keyword",
    filterField: "esh_mfb",
  },
  {
    field: "hftd",
    filtering: true,
    headerName: "Hftd",
    sortField: "hftd.keyword",
    filterField: "hftd",
  },
  {
    field: "eshBudget",
    filtering: true,
    headerName: "Esh Budget",
    sortField: "esh_budget.keyword",
    filterField: "esh_budget",
  },
  {
    field: "constFin",
    filtering: true,
    headerName: "Const Fin",
    sortField: "constfin.keyword",
    filterField: "constfin",
  },
  {
    field: "primeEshProgram",
    filtering: true,
    headerName: "Prime Esh Program",
    sortField: "prime_esh_program.keyword",
    filterField: "prime_esh_program",
  },
  {
    field: "jobType",
    filtering: true,
    headerName: "Job Type",
    sortField: "job_type.keyword",
    filterField: "job_type",
  },
  {
    field: "activityName",
    filtering: true,
    headerName: "Activity Name",
    sortField: "activity_name.keyword",
    filterField: "activity_name",
  },
  {
    field: "ifcFinish",
    filtering: true,
    headerName: "Ifc Finish",
    sortField: "ifc_finish.keyword",
    filterField: "ifc_finish",
  },
  {
    field: "pmrNotificationDescription",
    filtering: true,
    headerName: "Pmr Notification Description",
    sortField: "pmr_notification_description.keyword",
    filterField: "pmr_notification_description",
  },
  {
    field: "pmrReqEndDate",
    filtering: true,
    headerName: "Pmr Req End Date",
    sortField: "pmr_req_end_date.keyword",
    filterField: "pmr_req_end_date",
  },
  {
    field: "lidarProgram",
    filtering: true,
    headerName: "Lidar Program",
    sortField: "lidar_program.keyword",
    filterField: "lidar_program",
  },
  {
    field: "lidarPoleScope",
    filtering: true,
    headerName: "Lidar Pole Scope",
    sortField: "lidar_polescope.keyword",
    filterField: "lidar_polescope",
  },
  {
    field: "modifiedDate",
    filtering: true,
    headerName: "Modified Date",
    sortField: "polemodifieddate.keyword",
    filterField: "polemodifieddate",
  },
  {
    field: "dugDate",
    filtering: true,
    headerName: "Dug Date",
    sortField: "poleholedugdate.keyword",
    filterField: "poleholedugdate",
  },
  {
    field: "setDate",
    filtering: true,
    headerName: "Set Date",
    sortField: "polesetdate.keyword",
    filterField: "polesetdate",
  },
  {
    field: "oldPoleConductorDate",
    filtering: true,
    headerName: "Old Pole Conductor Date",
    sortField: "oldpolenoconductordate.keyword",
    filterField: "oldpolenoconductordate",
  },
  {
    field: "newPoleConductorDate",
    filtering: true,
    headerName: "New Pole Conductor Date",
    sortField: "newpoleconductordate.keyword",
    filterField: "newpoleconductordate",
  },
  {
    field: "toppedDate",
    filtering: true,
    headerName: "Topped Date",
    sortField: "poletoppeddate.keyword",
    filterField: "poletoppeddate",
  },
  {
    field: "removedDate",
    filtering: true,
    headerName: "Removed Date",
    sortField: "poleremoveddate.keyword",
    filterField: "poleremoveddate",
  },
  {
    field: "completionDate",
    filtering: true,
    headerName: "Completion Date",
    sortField: "completion_date.keyword",
    filterField: "completion_date",
  },
  {
    field: "complianceDate",
    filtering: true,
    headerName: "Compliance Date",
    sortField: "compliancedate.keyword",
    filterField: "compliancedate",
  },
  {
    field: "ifcDate",
    filtering: true,
    headerName: "Ifc Date",
    sortField: "ifcdate.keyword",
    filterField: "ifcdate",
  },
  {
    field: "constructionStatus",
    filtering: true,
    headerName: "Construction Status",
    sortField: "constructionstatu.keyword",
    filterField: "constructionstatus",
  },
  {
    field: "reliabilityBudget",
    filtering: true,
    headerName: "Reliability Budget",
    sortField: "reliability_budget.keyword",
    filterField: "reliability_budget",
  },
  {
    field: "reliabilityMfb",
    filtering: true,
    headerName: "Reliability Mfb",
    sortField: "reliability_mfb.keyword",
    filterField: "reliability_mfb",
  },
  {
    field: "wisePoleScope",
    filtering: true,
    headerName: "WisePoleScope",
    sortField: "wise_polescope.keyword",
    filterField: "wise_polescope",
  },
  {
    field: "proCore",
    filtering: true,
    headerName: "Pro Core",
    sortField: "procore.keyword",
    filterField: "procore",
  },
  {
    field: "overlappingStatus",
    filtering: true,
    headerName: "Overlapping Status",
    sortField: "overlapping_status.keyword",
    filterField: "overlapping_status",
  },
  {
    field: "name",
    filtering: true,
    headerName: "Name",
    sortField: "customer_name.keyword",
    filterField: "customer_name",
  },
  {
    field: "apInfo",
    filtering: true,
    headerName: "Ap Info",
    sortField: "ap_info.keyword",
    filterField: "ap_info",
  },
  {
    field: "isSensitive",
    filtering: true,
    headerName: "Sensitive Customer",
    sortField: "sensitive_customer.keyword",
    filterField: "sensitive_customer",
  },
  {
    field: "apnAddress1",
    filtering: true,
    headerName: "Apn Address1",
    sortField: "apn_address_1.keyword",
    filterField: "apn_address_1",
  },
  {
    field: "apnAddress2",
    filtering: true,
    headerName: "Apn Address2",
    sortField: "apn_address_2.keyword",
    filterField: "apn_address_2",
  },
  {
    field: "accountNumber",
    filtering: true,
    headerName: "Account Number",
    sortField: "account_number.keyword",
    filterField: "account_number",
  },
  {
    field: "meterNumber",
    filtering: true,
    headerName: "Meter Number",
    sortField: "meter_number.keyword",
    filterField: "meter_number",
  },
  {
    field: "email",
    filtering: true,
    headerName: "Email",
    sortField: "email.keyword",
    filterField: "email",
  },
  {
    field: "phoneNumbers",
    filtering: true,
    headerName: "Phone Numbers",
    sortField: "phone_numbers.keyword",
    filterField: "phone_numbers",
  },
  {
    field: "notes",
    filtering: true,
    headerName: "Notes",
    sortField: "notes.keyword",
    filterField: "notes",
  },
  {
    field: "envReleaseDateSubmitted",
    filtering: true,
    headerName: "Env Release Date Submitted",
    sortField: "environmental_release_date_submitted.keyword",
    filterField: "environmental_release_date_submitted",
  },
  {
    field: "envPermitTimeFrame",
    filtering: true,
    headerName: "Env Permit Time Frame",
    sortField: "env_permit_time_frame.keyword",
    filterField: "env_permit_time_frame",
  },
  {
    field: "envReleaseIssuedDate",
    filtering: true,
    headerName: "Env Release Issued Date",
    sortField: "environmental_release_issued_date.keyword",
    filterField: "environmental_release_issued_date",
  },
  {
    field: "releaseExpirationDate",
    filtering: true,
    headerName: "Release Expiration Date",
    sortField: "release_expiration_date.keyword",
    filterField: "release_expiration_date",
  },
  {
    field: "specialRequirements",
    filtering: true,
    headerName: "Environmental Constraints",
    sortField: "special_requirements.keyword",
    filterField: "special_requirements",
  },
  {
    field: "n1PermitDateSubmitted",
    filtering: true,
    headerName: "N1 Permit Date Submitted",
    sortField: "#1_permit_date_submitted.keyword",
    filterField: "#1_permit_date_submitted",
  },
  {
    field: "n2PermitSubmittedDate",
    filtering: true,
    headerName: "N2 Permit Submitted Date",
    sortField: "#2_permit_submitted_date.keyword",
    filterField: "#2_permit_submitted_date",
  },
  {
    field: "n1PermitTimeFrame",
    filtering: true,
    headerName: "N1 Permit Time Frame",
    sortField: "#1_permit_time_frame.keyword",
    filterField: "#1_permit_time_frame.keyword",
  },
  {
    field: "n2PermitTimeFrame",
    filtering: true,
    headerName: "N2 Permit Time Frame",
    sortField: "#2_permit_time_frame.keyword",
    filterField: "#2_permit_time_frame",
  },
  {
    field: "n1PermitIssuedDate",
    filtering: true,
    headerName: "N1 Permit Issued Date",
    filterField: "#1_permit_issued_date",
    sortField: "#1_permit_issued_date.keyword",
  },
  {
    field: "n2PermitIssuedDate",
    filtering: true,
    headerName: "N2 Permit Issued Date",
    filterField: "#2_permit_issued_date",
    sortField: "#2_permit_issued_date.keyword",
  },
  {
    field: "n1PermitExpirationDate",
    filtering: true,
    headerName: "N1 Permit Expiration Date",
    sortField: "#1_permit_expiration_date.keyword",
    filterField: "#1_permit_expiration_date",
  },
  {
    field: "n2PermitExpirationDate",
    filtering: true,
    headerName: "N2 Permit Expiration Date",
    sortField: "#2_permit_expiration_date.keyword",
    filterField: "#2_permit_expiration_date",
  },
  {
    field: "envReleaseNeeded",
    filtering: true,
    headerName: "Env Release Needed",
    sortField: "environmental_release_needed.keyword",
    filterField: "environmental_release_needed",
  },
  // {
  //   field: "dateSubmitted",
  //   filtering: true,
  //   headerName: "Date Submitted",
  //   sortField: "date_submitted.keyword",
  //   filterField: "date_submitted",
  // },
  {
    field: "forecastedVegClearDate",
    filtering: true,
    headerName: "Forecasted Veg Clear Date",
    filterField: "forecasted_veg_clear_date",
    sortField: "forecasted_veg_clear_date.keyword",
  },
  {
    field: "rowNum",
    filtering: true,
    headerName: "Row Num",
    filterField: "row_num",
    sortField: "row_num.keyword",
  },
  {
    field: "poleId",
    filtering: true,
    headerName: "Pole Id",
    filterField: "pole_id",
    sortField: "pole_id.keyword",
  },
  {
    field: "sdgeAuthorizedIfcDate",
    filtering: true,
    headerName: "SDGE Authorized Ifc Date",
    filterField: "`sdg&e_authorized_ifc_date`",
    sortField: "`sdg&e_authorized_ifc_date.keyword`",
  },
  {
    field: "reliabilityIfcActual",
    filtering: true,
    headerName: "Reliability Ifc Actual",
    filterField: "reliability_ifc_actual",
    sortField: "reliability_ifc_actual.keyword",
  },
  {
    field: "tedIfcActual",
    filtering: true,
    headerName: "Ted Ifc Actual",
    filterField: "ted_ifc_actual",
    sortField: "ted_ifc_actual.keyword",
  },
  {
    field: "workOrderNumber",
    filtering: true,
    headerName: "Work Order Number",
    filterField: "work_order_number",
    sortField: "work_order_number.keyword",
  },
  {
    field: "constFinish",
    filtering: true,
    headerName: "Const Finish",
    filterField: "const_finish",
    sortField: "const_finish.keyword",
  },
  {
    field: "programNames",
    filtering: true,
    headerName: "Program Names",
    filterField: "program_names",
    sortField: "program_names.keyword",
  },
  {
    field: "permitId",
    filtering: true,
    headerName: "Permit ID",
    filterField: "permit_id",
    sortField: "permit_id.keyword",
  },
  {
    field: "quickRelease",
    filtering: true,
    headerName: "Quick Release",
    filterField: "quick_release",
    sortField: "pquick_release.keyword",
  },
  {
    field: "ets_#",
    filtering: true,
    headerName: "ETS #",
    filterField: "ets_#",
    sortField: "ets_#.keyword",
  },
  {
    field: "permitType",
    filtering: true,
    headerName: "Permit Type",
    filterField: "permit_type",
    sortField: "permit_type.keyword",
  },
  {
    field: "permitDateSubmitted",
    filtering: true,
    headerName: "Permit Date Submitted",
    filterField: "permit_date_submitted",
    sortField: "permit_date_submitted.keyword",
  },
  {
    field: "permitTimeFrame",
    filtering: true,
    headerName: "Muni Constraints",
    filterField: "permit_time_frame",
    sortField: "permit_time_frame.keyword",
  },
  {
    field: "permitIssuedDate",
    filtering: true,
    headerName: "Permit Issued Date",
    filterField: "permit_issued_date",
    sortField: "permit_issued_date.keyword",
  },
  {
    field: "permitExpirationDate",
    filtering: true,
    headerName: "Permit Expiration Date",
    filterField: "permit_expiration_date",
    sortField: "permit_expiration_date.keyword",
  },
  {
    field: "k2ReferenceId",
    filtering: true,
    headerName: "K2 Reference #",
    filterField: "k2_reference_#",
    sortField: "k2_reference_#.keyword",
  },
];

const columnsOrder = columnDefs.slice(3).map((item) => item.field);

const filteringOptions = [
  {
    headerName: "Grid Values",
    filtering: true,
    children: columnDefs.filter((item) => item.headerName),
  },
  {
    headerName: "Env Permit",
    filtering: true,
    children: envPermits,
  },
  {
    headerName: "Muni Permit",
    filtering: true,
    children: muniPermit,
  },
  {
    headerName: "Customer",
    filtering: true,
    children: customer,
  },
  {
    headerName: "Overlapping",
    filtering: true,
    children: overlapping,
  },
  {
    headerName: "Sap",
    filtering: true,
    children: sap,
  },
];

export const Constants = {
  columnDefs,
  columnsOrder,
  additionalIssueColumnDefs,
  filteringOptions,
};
