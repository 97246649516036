import styled from "@emotion/styled/";
import { Typography, Button, Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { TextInputWithController } from "@sdge-components/TextInput";

const Title = styled(Typography)<StyledPropsWithTheme>`
  color: ${({ theme }) => theme.palette.formItems.labelColor[100]};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: 12px;
`;

const TileName = styled(Typography)<StyledPropsWithTheme>`
  color: ${({ theme }) => theme.palette.formItems.labelColor[100]};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-bottom: 8px;
`;

const TileButton = styled(Button)<StyledPropsWithTheme>`
  text-transform: none;
  color: ${({ theme }) => theme.palette.formItems.labelColor[100]};
  font-size: 12px;
`;

const TileContainer = styled(Box)<StyledPropsWithTheme>`
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 7px;
    height: 7px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.light} !important;
    opacity: 0.15;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const CustomersInput = styled(TextInputWithController)`
  margin: 20px 0;
`;

const AddButton = styled(Button)<StyledPropsWithTheme>`
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: white;
`;

export const Styled = {
  Title,
  TileButton,
  TileName,
  TileContainer,
  CustomersInput,
  AddButton,
};
