import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";

export const getParentPath = (pathname: string) => {
  const splitedPath = pathname.split("/");
  if (splitedPath.includes("overlapping"))
    return RightDrawerParentPaths.OVERLAPPING_TABLE;
  else if (splitedPath.includes("customers"))
    return RightDrawerParentPaths.CUSTOMER_TABLE;
  else if (splitedPath.includes("issue_tracker"))
    return RightDrawerParentPaths.ISSUE_TRACKER_TABLE;
};
