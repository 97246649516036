import { FC } from "react";
import { AttachButtonWithControllerPropsType } from "./AttachButton.types";
import { Controller } from "react-hook-form";
import { AttachButton } from "@sdge-components";

const AttachButtonWithController: FC<AttachButtonWithControllerPropsType> = ({
  name,
  control,
  defaultValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <AttachButton onChange={onChange} value={value} />
      )}
    />
  );
};

export default AttachButtonWithController;
