import { useAppSelector } from "../../app/hooks";
import { selectUser } from "@sdge-store/user/user.selector";
import { UsePermissionTypes } from "@sdge-hooks/usePermission/usePermission.types";

const usePermission: UsePermissionTypes = () => {
  const { writePermissions } = useAppSelector(selectUser);

  return {
    getPermission: (params) => {
      return writePermissions.includes(params);
    },
  };
};

export default usePermission;
