import styled from "@emotion/styled";
import { GitPullRequest } from "react-feather";
import { isActiveType, StyledPropsWithTheme } from "../../utils/globalTypes";

const OverlappingLogo = styled(GitPullRequest)<
  StyledPropsWithTheme<isActiveType>
>`
  color: ${({ theme, isActive }) =>
    isActive ? "#fff" : theme.palette.text.secondary};
`;

export const Styled = { OverlappingLogo };
