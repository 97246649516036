import {
  LoginPropertyTypes,
  RefreshPropertyTypes,
} from "./login.property.types";

export const loginProperty = (): LoginPropertyTypes => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const redirectUrl = process.env.REACT_APP_AUTH_REMOTE_URL as string;
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID as string;

  const loginPageUrl = `${authUrl}/login?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${redirectUrl}`;
  const getTokenUrl = `${authUrl}/oauth2/token`;
  return {
    grant_type: "authorization_code",
    login_page_url: loginPageUrl,
    client_id: clientId,
    redirect_url: redirectUrl,
    get_token_url: getTokenUrl,
  };
};

export const refreshProperty = (): RefreshPropertyTypes => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID as string;
  const refreshToken = localStorage.getItem("refresh_token") as string;
  const getTokenUrl = `${authUrl}/oauth2/token`;

  return {
    grant_type: "refresh_token",
    client_id: clientId,
    get_token_url: getTokenUrl,
    refresh_token: refreshToken,
  };
};
