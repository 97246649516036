import React, { useEffect } from "react";
import {
  InfoTileContent,
  Map,
  CustomerMainTable,
  Tile,
  EnvPermitTileContent,
  MuniPermitTileContent,
} from "../../components";

import { Styled } from "../OverlappingPage/OverlappingPage.styled";
import { Box } from "@material-ui/core";
import ParcelTileContent from "@sdge-components/ParcelTileContent";
import CustomerTileContent from "@sdge-components/CustomerTileContent";
import { useLocation } from "react-router";
import { getLayers } from "@sdge-store/Map/map.async";
import { useDispatch } from "react-redux";
import { handleRouterChange } from "@sdge-store/drawer/drawer.slice";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { useAppSelector } from "../../app/hooks";
import {
  handleChangeRequested,
  setActiveIssueId,
} from "@sdge-store/issueTracker/issueTracker.slice";
import Loader from "../../app/Router/Async/Loader";
import { usePermission } from "@sdge-hooks";

const CustomersPage: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { getPermission } = usePermission();

  const map = pathname.split("/").includes("map");
  const { createCustomerLoading, exportLoading } = useAppSelector(
    (state) => state.customersSlice
  );

  const hasCustomerPermissions = getPermission("editPoleCustomer");

  useEffect(() => {
    dispatch(setActiveIssueId(""));
    dispatch(getLayers());
    dispatch(
      handleRouterChange({
        parentPath: RightDrawerParentPaths.CUSTOMER_TABLE,
      })
    );
    dispatch(handleChangeRequested(false));
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    swipe: false,
    copy: true,
  };

  return (
    <Styled.OverlappingPageWrapper>
      {(createCustomerLoading || exportLoading) && <Loader />}
      {map && <Map key="AIzaSyD3jpcQHDLYnj-ulGUJMtLiXIqKpEi2FNo" />}
      <CustomerMainTable />
      <Box py="20px">
        <Styled.SliderWrapper>
          <Styled.TilesSlider {...settings}>
            <Tile title="Parcel">
              <ParcelTileContent />
            </Tile>
            <Tile
              title="Customer"
              button={hasCustomerPermissions ? "Add Customer" : ""}
            >
              <CustomerTileContent issueTracker={false} />
            </Tile>
            <Tile title="Overlapping">
              <InfoTileContent />
            </Tile>
            <Tile
              title="Environmental Permits"
              button={
                getPermission("add_tile_ENVC") || getPermission("add_tile_EnD")
                  ? ""
                  : "Add Env Permit"
              }
            >
              <EnvPermitTileContent customerPage />
              <></>
            </Tile>
            <Tile
              title="Muni Permits"
              button={
                getPermission("add_tile_ENVC") || getPermission("add_tile_EnD")
                  ? ""
                  : "Add Muni Permit"
              }
            >
              <MuniPermitTileContent customerPage />
              <></>
            </Tile>
          </Styled.TilesSlider>
        </Styled.SliderWrapper>
      </Box>
    </Styled.OverlappingPageWrapper>
  );
};

export default CustomersPage;
