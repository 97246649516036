import { FC } from "react";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { Styled } from "./DatePickerInput.styled";
import { Controller } from "react-hook-form";
import { DatePickerInputProps } from "./DatePickerInput.types";
import { parseDateString } from "@sdge-utils/dateParser";

const DatePickerInput: FC<DatePickerInputProps> = ({
  control,
  label,
  name,
  errorMessage,
  defaultValue,
  readOnly,
  changeSubmit,
  parseFormat,
}) => {
  const handleInputChangeSubmit = () => {
    if (changeSubmit) {
      const button = document.createElement("button");

      button.type = "submit";

      changeSubmit.current.appendChild(button);
      button.click();
      button?.parentNode?.removeChild(button);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? parseDateString(new Date())}
      render={({ field: { onChange, value } }) => {
        return (
          <Styled.DatePickerWrapper>
            <Styled.LocalizationProvider dateAdapter={DateAdapter}>
              <Styled.DatePicker
                label={label}
                onChange={(data: any) => {
                  onChange(parseDateString(data, parseFormat));
                  handleInputChangeSubmit();
                }}
                value={value}
                renderInput={(params) => {
                  const changedParams = {
                    ...params,
                    inputProps: {
                      ...params.inputProps,
                      readOnly: readOnly,
                      value: value && parseDateString(value, "MM/DD/YYYY"),
                    },
                  };
                  return (
                    <Styled.Input
                      {...changedParams}
                      type="date"
                      error={!!errorMessage}
                    />
                  );
                }}
              />
            </Styled.LocalizationProvider>
            {!!errorMessage && <Styled.Error>{errorMessage}</Styled.Error>}
          </Styled.DatePickerWrapper>
        );
      }}
    />
  );
};

export default DatePickerInput;
