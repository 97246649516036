import styled from "@emotion/styled";
import { Box } from "@material-ui/core";

const PreflightPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const TableGroup = styled(Box)`
  display: flex;
  justify-content: space-between;
  > div:nth-child(2n + 1) {
    margin-right: 20px;
  }
  > div:nth-child(2) {
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Styled = { PreflightPageWrapper, TableGroup };
