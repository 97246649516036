import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { Button } from "@sdge-components";
import { StyledPropsWithTheme } from "../../../../utils/globalTypes";

const QuickViewsOptionsWrapper = styled.form<StyledPropsWithTheme>`
  width: 415px;
  max-height: 250px;
  background: ${({ theme }) => theme.palette.tables?.main?.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  row-gap: 5px;
  padding: 8px;
  font-size: 16px;
  overflow: hidden;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;
const QuickViewsOptionsList = styled(Box)<StyledPropsWithTheme>`
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 6px;
  position: relative;
  z-index: 1;
  row-gap: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 5px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    opacity: 0.15;
  }
`;
const QuickViewsButton = styled(Button)<StyledPropsWithTheme>`
  justify-content: flex-start;
`;

const QuickViewsButtonWrapper = styled(Box)<
  StyledPropsWithTheme<{ isActive?: boolean }>
>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  ${({ isActive, theme }) =>
    isActive && `background:${theme.palette.primary.light}`};
`;

const OptionButtons = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Styled = {
  QuickViewsOptionsWrapper,
  QuickViewsOptionsList,
  QuickViewsButton,
  OptionButtons,
  QuickViewsButtonWrapper,
};
