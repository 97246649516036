import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { Typography, Box } from "@mui/material";

const SubTitle = styled(Typography)<StyledPropsWithTheme>`
  color: ${({ theme }) => theme.palette.formItems.labelColor[100]};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: 12px;
`;

const Divider = styled(Box)<StyledPropsWithTheme>`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.formItems.labelColor[100]};
`;

export const Styled = {
  SubTitle,
  Divider,
};
