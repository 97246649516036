import * as React from "react";
import { Button, Grid } from "@material-ui/core";
import { useAppSelector } from "../../../app/hooks";
import { Styled } from "./LayersMenu.styled";
import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { deleteLayer, getLayers } from "@sdge-store/Map/map.async";
import { useDispatch } from "react-redux";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import { usePermission } from "@sdge-hooks";

const LayersMenu: React.FC<any> = ({ buttonRef, setKmlUrl }) => {
  const { layers } = useAppSelector((state) => state.maps);
  const { getPermission } = usePermission();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (item: any) => {
    setAnchorEl(null);
    setKmlUrl(`${process.env.REACT_APP_BASE_URL}${item.url}`);
  };

  const handleDeleteLayer = async (name: string) => {
    try {
      await dispatch(deleteLayer(name));
      dispatch(getLayers());
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "success",
          message: "Layer is deleted",
        })
      );
    } catch (e) {
      dispatch(
        handleChangeSnackbarState({
          isOpen: true,
          alertColor: "error",
          message: "Layer was not deleted",
        })
      );
    }
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      />
      <Styled.layersMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {layers.length &&
          layers.map((item, i) => {
            return (
              <Styled.LayersMenuItem
                onClick={() => handleClose(item)}
                key={i}
                disabled={!item.isLoaded}
              >
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item>{item.name}</Grid>
                  {!item.isLoaded ? (
                    <Grid item>Not ready yet</Grid>
                  ) : (
                    !getPermission("readonly") && (
                      <Grid item>
                        <CloseIcon
                          onClick={() => {
                            handleDeleteLayer(item.name);
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Styled.LayersMenuItem>
            );
          })}
      </Styled.layersMenu>
    </div>
  );
};

export default LayersMenu;
