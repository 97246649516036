import {
  // SortAscIcon,
  // SortDescIcon,
  // FilterIcon,
  FreezeIcon,
  HideIcon,
} from "../../icons";
import { FC } from "react";
import { Styled } from "./ColumnFilter.styled";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import {
//   generateSortArr,
//   isLongitude,
//   sortRequest,
// } from "../../utils/helpers/sortHelpers";
import { ColumnFilterI } from "./ColumnFilter.types";
import { SortingColumnButtonType } from "./components/SortingColumnButton/SortingColumnButton.type";
import { SortingColumnButton } from "./components";
// import { generateFilterArr } from "@sdge-utils/helpers/filterHelpers";

const OverlapsColumnFilter: FC<ColumnFilterI> = ({
  params,
  // sortData,
  hideColumn,
  pinColumn,
  // sortAction,
  // filterOptions,
  store,
  // handleFilter,
}) => {
  const field = params.colDef.field;

  const { columnDefs } = useAppSelector(store);
  const dispatch = useAppDispatch();
  const pinnedFieldIndex = columnDefs.findIndex(
    (item: any) => item.field === field
  );
  const pinnedField = columnDefs[pinnedFieldIndex].pinned;
  const hideCol = (field: string) => {
    dispatch(hideColumn(field));
  };
  const freezeCol = (field: string) => {
    dispatch(pinColumn(field));
  };
  // const showFilterTab = () => {
  //   dispatch(filterOptions(true));
  // };

  // const currentColumn = columnDefs.filter((item: any) => item.field === field);
  // const isSortingOrFiltering = currentColumn[0].sorting;
  // let direction;
  const buttons: SortingColumnButtonType[] = [
    // {
    //   title: "Sort A → Z",
    //   icon: SortAscIcon,
    //   disabled: !isSortingOrFiltering,
    //   onClick: () => {
    //     direction = isLongitude(field, "ASC");
    //     dispatch(sortAction(generateSortArr(field, direction, sort)));
    //     dispatch(
    //       sortData([
    //         0,
    //         sortRequest(field, direction, sort, searchInputValue),
    //         filteringRules,
    //       ])
    //     );
    //   },
    // },
    // {
    //   title: "Sort Z → A",
    //   icon: SortDescIcon,
    //   disabled: !isSortingOrFiltering,
    //   onClick: () => {
    //     direction = isLongitude(field, "DESC");
    //     dispatch(sortAction(generateSortArr(field, direction, sort)));
    //     dispatch(
    //       sortData([
    //         0,
    //         sortRequest(field, direction, sort, searchInputValue),
    //         filteringRules,
    //       ])
    //     );
    //   },
    // },
    // {
    //   title: "Add filter",
    //   icon: FilterIcon,
    //   disabled: !isSortingOrFiltering,
    //   onClick: (e) => {
    //     e?.stopPropagation();
    //     showFilterTab();
    //     dispatch(
    //       handleFilter(
    //         generateFilterArr(
    //           currentFilter,
    //           currentColumn[0].filterField || currentColumn[0].field,
    //           currentFilter
    //         )
    //       )
    //     );
    //   },
    // },
    {
      title: "Hide column",
      icon: HideIcon,
      disabled: false,
      onClick: () => {
        hideCol(field);
      },
    },
    {
      title: `${pinnedField === "left" ? "Unfreeze" : "Freeze"} column`,
      icon: FreezeIcon,
      disabled: false,
      onClick: () => {
        freezeCol(field);
      },
    },
  ];

  return (
    <Styled.ColumnFilterWrapper>
      {buttons.map(({ title, icon, disabled, onClick }) => (
        <SortingColumnButton
          key={title}
          title={title}
          icon={icon}
          disabled={disabled}
          onClick={onClick}
        />
      ))}
    </Styled.ColumnFilterWrapper>
  );
};

export default OverlapsColumnFilter;
