import React, { useCallback, useMemo, useRef, useState } from "react";
import MainTable from "./MainTable";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCustomers } from "@sdge-store/customers/customers.selector";
import {
  handleLoadedPagesChange,
  handleSort,
  handleHiddenColumn,
  handlePinnedColumn,
  filterOptions,
  handleSearch,
  fieldsHiddenOptions,
  sortOptions,
  handleFilter,
  quickViewsOptions,
  handleSelectedRows,
  handleSetActiveRow,
  handleColumnDefs,
  handleColumnOrder,
  handleInputText,
  handleSelectedIds,
  massUpdateOptions,
} from "@sdge-store/customers/customers.slice";
import {
  fetchCustomersData,
  getCustomersList,
  getEnvPermitsAssets,
  getMuniPermitsAssets,
  getTilesInfo,
  getTilesParcelInfo,
  sortCustomersData,
} from "@sdge-store/customers/customers.async";

import {
  generateColumnDefs,
  generateColumnOrder,
  generateExportBody,
} from "@sdge-utils/helpers/filterHelpers/filterHelpers";
import { GridOptions, SelectionChangedEvent } from "ag-grid-community";
import { DownloadLinks, RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import {
  handleChangeDrawerOptions,
  handleRouterChange,
} from "@sdge-store/drawer/drawer.slice";
import { handleSetRowData } from "@sdge-store/Map/map.slice";
import { handleChangeRequested } from "@sdge-store/issueTracker/issueTracker.slice";
import { handleChangeSnackbarState } from "@sdge-store/snackbar/snackbar.slice";
import {
  CustomerHideOptionsMenu,
  CustomersFilterOptionsMenu,
  CustomersQuickViewsOptions,
  CustomersSortOptionsMenu,
} from "@sdge-components";
import CustomerMassUpdateOptions from "@sdge-components/MassUpdateOptions/CustomerMassUpdateOptions";
import {
  assetsParcelFields,
  assetsParcelHeaders,
  constructionInteractionsFields,
  constructionInteractionsHeaders,
} from "./components/ExportMenu/ExportMenu.constants";
import { Styled } from "./components/ExportMenu/ExportMenu.styled";
import { ExportMenu } from "./components/ExportMenu";
import { useExport } from "./useExport";
import { customerItemsDateConvert } from "@sdge-utils/helpers/gridHelpers/gridHelpers";
import { usePermission } from "@sdge-hooks";

const isThirdColumn = (params: any) => {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  const indexOfButton = displayedColumns.findIndex(
    (item: any) => item.maxWidth === 50
  );
  const thisIsFirstColumn = displayedColumns[indexOfButton] == params.column;

  return thisIsFirstColumn;
};

const CustomerMainTable = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gridApi, setGridApi] = useState<any>(null);
  const [columnApi, setColumnApi] = useState<any>(null);

  const exportFilteredFile = useExport();

  const {
    assets,
    totalElements,
    loading,
    page,
    sortingRules,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    totalPages,
    areQuickVeiwsOptionsOpen,
    selectedRows,
    inputText,
    massUpdateItemsRequest,
    areMassUpdateOptionsOpen,
    searchInputValue,
    currentFilter: filter,
    bmcdTierVisibility,
    selectedRowsIds,
  } = useAppSelector(selectCustomers);

  const { getPermission } = usePermission();

  const hasPermissionsToMassUpdate = getPermission("customerMassEdit");
  const hasPermissions = getPermission("readonly");

  /////////

  const [exportType, setExportType] = useState("assets");
  const buttonRef = useRef<any>(null);
  const allFilteringCols = [...columnDefs];
  let exportRequest: DownloadLinks;
  let fileName: string;
  let body: any;
  const columnNames = columnDefs
    .filter((item: any) => item.field)
    .map((item: any) => {
      return item.field;
    });
  const headers = columnDefs
    .filter((item: any) => item.headerName)
    .map((item: any) => {
      return item.headerName;
    });

  const handleExportClick = () => {
    buttonRef?.current?.click();
  };

  switch (exportType) {
    case "parcel": {
      exportRequest = DownloadLinks.ASSETS_PARCEL;
      fileName = "assets_parcel";
      body = {
        filters: filter,
        searchText: searchInputValue.substring(12) || null,
        components: [
          { name: "asset", columns: columnNames, headers: headers },
          {
            name: "parcel",
            columns: assetsParcelFields,
            headers: assetsParcelHeaders,
          },
        ],
      };
      break;
    }
    case "assetsAndInteractions": {
      exportRequest = DownloadLinks.ASSETS_INTERACTIONS;
      fileName = "assets_interactions";

      body = {
        filters: filter,
        searchText: searchInputValue.substring(12) || null,
        components: [
          { name: "asset", columns: columnNames, headers: headers },
          {
            name: "interaction",
            columns: constructionInteractionsFields,
            headers: constructionInteractionsHeaders,
          },
        ],
      };
      break;
    }
    case "assets":
    default: {
      exportRequest = DownloadLinks.ASSETS;
      fileName = "assets";
      body = {
        searchText: searchInputValue.substring(12) || null,
        filters: filter,
        components: [{ name: "asset", columns: columnNames, headers: headers }],
      };
      break;
    }
  }

  const handleExportSubmit = async () => {
    dispatch(
      handleChangeSnackbarState({
        isOpen: true,
        alertColor: "success",
        message: "Download has started",
      })
    );
    const generatedExportBody = generateExportBody(
      allFilteringCols,
      filteringRules,
      bmcdTierVisibility
    );
    exportFilteredFile(
      exportRequest,
      {
        ...generatedExportBody,
        ...body,
        filters: bmcdTierVisibility
          ? body.filters
          : [
              ...body.filters,
              {
                fieldName: "bmcd_tier_3",
                negate: true,
                operation: "equals",
                relation: "AND",
                value: "Y",
              },
            ],
        ids: selectedRowsIds,
      },
      fileName
    );
  };
  const selectionRef = useRef<any>(null);
  const getSelectedRowData = (params: SelectionChangedEvent) => {
    clearTimeout(selectionRef.current);
    selectionRef.current = setTimeout(() => {
      const selectedNodes = params.api.getSelectedNodes();
      const selectedRowsIndexes = selectedNodes.map((item) => item.rowIndex);
      const selectedRowsIds = selectedNodes.map((item) => item.data.id);
      dispatch(handleSelectedRows(selectedRowsIndexes));
      dispatch(handleSelectedIds(selectedRowsIds));
    }, 300);
    return () => {
      clearTimeout(selectionRef.current);
    };
  };

  const getRowData = useCallback(({ data }: any) => {
    const drawerRouterOptions = {
      parentPath: RightDrawerParentPaths.CUSTOMER_TABLE,
      backArrow: false,
      prevPath: "/",
      newPath: "overlapping",
      subPath: "info",
    };
    const drawerOptions = {
      isOpen: true,
      title: data.id,
      cardId: data.id,
    };
    dispatch(handleRouterChange(drawerRouterOptions));
    dispatch(handleChangeDrawerOptions(drawerOptions));

    dispatch(handleSetRowData(data));
    dispatch(getTilesInfo(data.id));
    dispatch(getTilesParcelInfo(data.id));
    dispatch(getCustomersList(data.id));
    dispatch(getEnvPermitsAssets(data.id));
    dispatch(getMuniPermitsAssets(data.id));
    dispatch(handleSetActiveRow(data.id));
    dispatch(handleChangeRequested(true));
  }, []);

  const gridOptions: GridOptions = useMemo(
    () => ({
      suppressDragLeaveHidesColumns: true,
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
      suppressPropertyNamesCheck: true,
      overlayNoRowsTemplate:
        ' <img src="https://www.ag-grid.com/example-assets/loading.gif" />',
      suppressMenuHide: true,
      rowHeight: 40,
      onGridReady: (params) => {
        if (!loading) {
          params.columnApi.autoSizeAllColumns();
        }
        setGridApi(params.api);
        setColumnApi(params.columnApi);
      },
      onDragStopped: (params) => {
        const allColumns: any = params.columnApi.getAllGridColumns();
        dispatch(handleColumnDefs(generateColumnDefs(allColumns)));
        dispatch(
          handleColumnOrder(generateColumnOrder(allColumns, "customer"))
        );
      },
      rowSelection: "multiple",
      onRowClicked: (rowInfo) => getRowData(rowInfo),
      onSelectionChanged: (params) => getSelectedRowData(params),
      rowMultiSelectWithClick: true,
    }),
    []
  );
  const rowItems = useMemo(() => customerItemsDateConvert(assets), [assets]);
  const data = {
    items: rowItems,
    totalElements,
    loading,
    page,
    isPaginated,
    sort,
    columnDefs,
    filteringRules,
    areHiddenFieldsOpen,
    areFilterOptionsOpen,
    areSortOptionsOpen,
    totalPages,
    sortingRules,
    areQuickVeiwsOptionsOpen,
    selectedRows,
    areMassUpdateOptionsOpen,
    inputText,
    massUpdateItemsRequest,
    MassUpdateOptions: CustomerMassUpdateOptions,
    FilterOptionsMenu: CustomersFilterOptionsMenu,
    HideOptionsMenu: CustomerHideOptionsMenu,
    QuickViewsOptions: CustomersQuickViewsOptions,
    SortOptionsMenu: CustomersSortOptionsMenu,
    title: "All Issues",
    hasPermissions,
  };

  const storeFunctions = {
    fieldsHiddenOptions,
    sortOptions,
    filterOptions,
    quickViewsOptions,
    handleSearch,
    handleLoadedPagesChange,
    sortData: sortCustomersData,
    handleHiddenColumn,
    handlePinnedColumn,
    handleSort,
    handleFilter,
    fetchData: fetchCustomersData,
    handleSelectedRows,
    handleInputText,
  };

  const options = {
    gridOptions,
    massUpdateOptions: hasPermissionsToMassUpdate ? massUpdateOptions : null,
    isValueSetter: true,
    storeSelector: selectCustomers,
    handleExportClick,
    isThirdColumn,
    columnApi,
  };

  return (
    <>
      <Styled.ExportTypeContainer>
        <ExportMenu
          buttonRef={buttonRef}
          setExportType={setExportType}
          handleExportSubmit={handleExportSubmit}
        />
      </Styled.ExportTypeContainer>
      <MainTable
        data={data}
        storeFunctions={storeFunctions}
        options={options}
      />
    </>
  );
};

export default CustomerMainTable;
