import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const NotificationItemsLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    opacity: 0.15;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const NotificationItemWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const Dot = styled(Box)<StyledPropsWithTheme>`
  color: ${({ theme }) => theme.palette.notificationItem.dot[100]};
  height: 100%;
  display: flex;
  align-items: start;
  padding-top: 6px;
  width: 20px;
`;

const Body = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled(Box)<{ gap?: number }>`
  display: flex;
  gap: ${({ gap }) => gap}px;
`;

const Text = styled(Box)<StyledPropsWithTheme<{ weight?: number }>>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ weight }) => weight};
  color: ${({ theme }) => theme.palette.formItems.textColor[100]};
  letter-spacing: 0.2688px;
`;

const ArrowWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Styled = {
  NotificationItemsLayout,
  NotificationItemWrapper,
  Dot,
  Body,
  Row,
  Text,
  ArrowWrapper,
};
