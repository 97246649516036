import { Dialog, IconButton, Box } from "@material-ui/core";

import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0;
`;

const Modal = styled(Dialog)`
  .MuiPaper-root {
    background-color: #222840;
  }
`;

const ModalContainer = styled(Box)<StyledPropsWithTheme>`
  overflow-y: auto;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 5px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    opacity: 0.15;
  }
`;

export const Styled = {
  CloseIconButton,
  Modal,
  ModalContainer,
};
