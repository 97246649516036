import styled from "@emotion/styled";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const Link = styled.a<StyledPropsWithTheme>`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};
  border-bottom: 2px solid;
  white-space: normal;
`;

export const Styled = { Link };
