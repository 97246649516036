import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../utils/globalTypes";
import { Plus } from "react-feather";

type PoleTableType = { areProps?: boolean };

const PoleTableWrapper = styled(Box)<StyledPropsWithTheme<PoleTableType>>`
  background: ${({ theme }) => theme.palette.primary.main};
  ${({ areProps, theme }) =>
    !areProps && `border: 1px dashed ${theme.palette.tables?.main?.light};`}
  height: 130px;
  width: 245px;
  padding: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  transition: all 0.3s ease 0s;
`;

const AddTableWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
const PlusIcon = styled(Plus)<StyledPropsWithTheme>`
  width: 50px;
  height: 50px;
  color: ${({ theme }) => theme.palette.tables?.main?.light};
`;

export const Styled = { PoleTableWrapper, AddTableWrapper, PlusIcon };
