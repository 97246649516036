import React, { FC, useState } from "react";
import { Styled } from "./DatePickerRange.styled";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { parseDateString } from "@sdge-utils/dateParser";
import { DatePickerRangeWithOutControllerProps } from "./DatePickerRange.types";

const DatePickerRangeWithoutController: FC<
  DatePickerRangeWithOutControllerProps
> = ({ value, onChange, label, errorMessage, readOnly, parseFormat }) => {
  const [firstInputValue, secondInputValue] = value
    ? value?.split(", ")
    : ["", ""];
  const [localErrorMessage, setLocalErrorMessage] = useState<string>("");

  const handleSetFirstValue = (data: any) => {
    const firstValueTemp = parseDateString(data, parseFormat);
    if (
      secondInputValue &&
      Date.parse(firstValueTemp) > Date.parse(secondInputValue)
    ) {
      setLocalErrorMessage("We can't pick a start date after the end date");
    } else {
      onChange(`${firstValueTemp}, ${secondInputValue}`);
      setLocalErrorMessage("");
    }
  };

  const handleSetSecondValue = (data: any) => {
    const secondValueTemp = parseDateString(data, parseFormat);

    if (!firstInputValue) {
      setLocalErrorMessage("I first you should set start time");
    } else if (Date.parse(firstInputValue) > Date.parse(secondValueTemp)) {
      setLocalErrorMessage("We can't pick an end date before the start date");
    } else {
      onChange(`${firstInputValue}, ${secondValueTemp}`);
      setLocalErrorMessage("");
    }
  };

  return (
    <Styled.DatePickerWrapper>
      <Styled.LocalizationProvider dateAdapter={DateAdapter}>
        <Styled.DatePicker
          label={label}
          onChange={handleSetFirstValue}
          value={firstInputValue}
          renderInput={(params) => {
            const changedParams = {
              ...params,
              inputProps: {
                ...params.inputProps,
                readOnly: readOnly,
                value:
                  firstInputValue &&
                  parseDateString(firstInputValue, "MM/DD/YYYY"),
              },
            };
            return (
              <Styled.Input
                {...changedParams}
                type="date"
                error={!!errorMessage}
              />
            );
          }}
        />
        <Styled.DatePicker
          label={label}
          onChange={handleSetSecondValue}
          value={secondInputValue}
          renderInput={(params) => {
            const changedParams = {
              ...params,
              inputProps: {
                ...params.inputProps,
                readOnly: readOnly,
                value:
                  secondInputValue &&
                  parseDateString(secondInputValue, "MM/DD/YYYY"),
              },
            };
            return (
              <Styled.Input
                {...changedParams}
                type="date"
                error={!!errorMessage}
              />
            );
          }}
        />
      </Styled.LocalizationProvider>
      {(!!errorMessage || !!localErrorMessage) && (
        <Styled.Error>{errorMessage || localErrorMessage}</Styled.Error>
      )}
    </Styled.DatePickerWrapper>
  );
};

export default DatePickerRangeWithoutController;
