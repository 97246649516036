import styled from "@emotion/styled";
import { Home } from "react-feather";
import { StyledPropsWithTheme, isActiveType } from "../../utils/globalTypes";

const CityLogo = styled(Home)<StyledPropsWithTheme<isActiveType>>`
  color: ${({ theme, isActive }) =>
    isActive ? "#fff" : theme.palette.text.secondary};
`;

export const Styled = { CityLogo };
