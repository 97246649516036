const TreeIcon = ({ size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 5.37931V6.5L12.5 10.2069H8H3.5L8 6.5V5.37931H5L8 2L11 5.37931H8Z"
        fill="#AFB1BA"
      />
      <path
        d="M8 14V9.24138V4.89655V2M8 2L5 5.37931H8M8 2L11 5.37931H8M8 5.37931V6.5M8 6.5L3.5 10.2069H12.5L8 6.5Z"
        stroke="#AFB1BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TreeIcon;
