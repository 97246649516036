import styled from "@emotion/styled";
import { Badge } from "@material-ui/core";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const BadgeWrapper = styled(Badge)<StyledPropsWithTheme>`
  margin: 0 10px 0 0;
  & .MuiBadge-badge {
    border-radius: 50%;
    padding: 0 2px;
    top: 1px;
    right: 1px;
    background-color: ${({ theme }) => theme.palette.bell.background[100]};
  }

  &.MuiBadge-root {
    cursor: pointer;
    user-select: none;
  }
`;

export const Styled = {
  BadgeWrapper,
};
