const FailureIcon = ({ size = 24, ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#FF1414"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
      <path d="M16 16C16 16 14.5 14 12 14C9.5 14 8 16 8 16" />
      <path d="M15 9H15.01" />
      <path d="M9 9H9.01" />
    </svg>
  );
};

export default FailureIcon;
