import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";
import { CarWrapperPropsType } from "./Card.types";

const CardWrapper = styled(Box)<StyledPropsWithTheme<CarWrapperPropsType>>`
  padding: 12px;
  background-color: ${({ theme }) => theme.palette.card.background[100]};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  min-width: 250px;
  :hover {
    cursor: pointer;
  }

  ${(props) =>
    props.active &&
    `
      background-color: ${props.theme.palette.card.backgroundActive[100]};
      border: 1px solid ${props.theme.palette.card.borderActive[100]};
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    `}
`;

const Row = styled(Box)<
  StyledPropsWithTheme<{ bold?: boolean; disabled?: boolean }>
>`
  font-family: IBM Plex Sans;
  font-size: 14px;
  display: flex;
  line-height: 19.88px;
  justify-content: space-between;
  gap: 6px;
  
  ${(props) =>
    props.bold &&
    `
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 4px;

  `}

  & > span {
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.palette.card.textDisabled[100]
        : theme.palette.card.text[100]}};
  }
  
`;

export const Styled: any = {
  CardWrapper,
  Row,
};
