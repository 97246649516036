import styled from "@emotion/styled";
import { Box, Drawer } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../utils/globalTypes";

const RightDrawer = styled(Drawer)<StyledPropsWithTheme>`
  & .MuiDrawer-paper {
    overflow-x: hidden;
    max-width: 450px;
    background-color: ${({ theme }) =>
      theme.palette.rightDrawer.background[100]};
  }
`;

const Header = styled(Box)`
  width: 450px;
  box-sizing: border-box;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderControl = styled(Box)`
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 24px;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const Styled = {
  RightDrawer,
  Header,
  HeaderControl,
  Body,
};
