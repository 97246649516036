import * as React from "react";
import { Menu } from "@mui/material";
import { DeleteIcon, EditIcon, SubMenuIcon } from "../../../../icons";
import { Styled } from "./InteractionItem.styled";
import { FC } from "react";

const DropDownMenu: FC<any> = ({ handleEdit, handleDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditLocal = () => {
    handleEdit();
    handleClose();
  };

  const handleDeleteLocal = () => {
    handleDelete();
    handleClose();
  };
  return (
    <div>
      <Styled.IconWrapper onClick={handleClick} id="demo-positioned-button">
        <SubMenuIcon />
      </Styled.IconWrapper>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Styled.DropDownItem onClick={handleEditLocal}>
          <EditIcon /> Edit
        </Styled.DropDownItem>
        <Styled.DropDownItem onClick={handleDeleteLocal}>
          <DeleteIcon /> Delete
        </Styled.DropDownItem>
      </Menu>
    </div>
  );
};

export default DropDownMenu;
