import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppTheme, ThemeTypes } from "./theme.types";
const theme = window.localStorage.getItem("appTheme");
const initialAppTheme: AppTheme = {
  theme: theme || ThemeTypes.DARK,
};
export const themeSlice = createSlice({
  name: "appTheme",
  initialState: initialAppTheme,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeTypes>) => {
      state.theme = action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
