import { createAsyncThunk } from "@reduxjs/toolkit";
import { queryInstance } from "../../../api/config";
import {
  removeAllTokens,
  setTimeOuts,
  setToken,
} from "@sdge-hooks/useAuth/useAuth.utils";
import { paths } from "@sdge-utils/routes";
import axios from "axios";
import { HandleLoginTypes } from "./user.types";
import { errorHandler } from "../../../api/utils";
import { History } from "history";
import { loginProperty } from "@sdge-utils/login.property";
import { countExpiryTime } from "@sdge-utils/helpers/authHelpers/authHelpers";

const loginPropertyData = loginProperty();

export const login: any = createAsyncThunk(
  "user/login",
  async ({ code, history }: HandleLoginTypes, { dispatch }) => {
    const params = new URLSearchParams();
    params.append("grant_type", loginPropertyData.grant_type);
    params.append("client_id", loginPropertyData.client_id);
    params.append("redirect_uri", loginPropertyData.redirect_url);
    params.append("code", code);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    try {
      const { data }: any = await axios.post(
        loginPropertyData.get_token_url,
        params,
        config
      );
      const expiryTime = countExpiryTime(data.expires_in);
      const tokens = {
        access_token: data.access_token,
        id_token: data.id_token,
        refresh_token: data.refresh_token,
      };
      const expiryTimes = {
        expiry_time: expiryTime,
      };

      setToken(tokens);
      setTimeOuts(expiryTimes);
      history.push(`/${paths.overlapping}`);
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const logout: any = createAsyncThunk(
  "user/logout",
  async (history: History, { dispatch }) => {
    try {
      removeAllTokens();
      history.push(`/${paths.login}`);
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);

export const getUserInfo: any = createAsyncThunk(
  "user/getUserInfo",
  async (_, { dispatch }) => {
    try {
      const { data }: any = await queryInstance.get("/user/info");
      return data ?? {};
    } catch (e) {
      errorHandler(e, dispatch);
    }
  }
);
