import React, { Suspense, useContext } from "react";
import { AppWrapper } from "./AppWrapper";
import Router from "./Router";
import Snackbar from "../components/Snackbar";
import RightDrawer from "../layouts/RightDrawer/RightDrawer.router";
import { PortalLayout, PortalContext } from "../layouts";
import { SessionSecurityAlert } from "@sdge-components";

const App: React.FC = () => {
  const { isActive: isPortalActive } = useContext(PortalContext);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Snackbar />
      <AppWrapper>
        <Router />
        <RightDrawer />
        {isPortalActive && (
          <PortalLayout>
            <SessionSecurityAlert />
          </PortalLayout>
        )}
      </AppWrapper>
    </Suspense>
  );
};

export default App;
