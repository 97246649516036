import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { StyledPropsWithTheme } from "@sdge-utils/globalTypes";

const HistoryWrapper = styled(Box)`
  padding: 34px 23px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const HistoryItem = styled(Box)`
  display: flex;
  width: 100%;
`;

const Header = styled(Box)`
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 700;
  color: #e3e4e7;
  letter-spacing: 0.0168em;
`;

const Body = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
`;

const Text = styled(Box)`
  font-family: IBM Plex Sans;
  color: #e3e4e7;
  font-size: 14px;
`;

const NoHistory = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Typography)<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.formItems.textColor};
  white-space: nowrap;
`;

const Content = styled(Typography)<StyledPropsWithTheme>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.formItems.textColor};
  word-break: break-all;
`;

const AddList = styled(Box)<StyledPropsWithTheme>`
  display: flex;
  flex-direction: column;
`;

export const Styled = {
  HistoryWrapper,
  HistoryItem,
  Header,
  Body,
  Text,
  NoHistory,
  Title,
  Content,
  AddList,
};
