import React, { FC, lazy, Suspense } from "react";
import RightDrawer from "./RightDrawer";
import { RightDrawerPaths } from "../../utils/globalTypes";
import { useAppSelector } from "../../app/hooks";

const NotificationDrawer = lazy(
  () => import("../../components/NotificationDrawer")
);
const OverlappingDrawer = lazy(
  () => import("../../components/OverlappingDrawer")
);

const QuickActionDrawer = lazy(
  () => import("../../components/QuickActionDrawer")
);

const PrivateDrawerRouter: FC = () => {
  const path = useAppSelector((state) => state.drawerState.router.newPath);

  switch (path) {
    case RightDrawerPaths.NOTIFICATION:
      return <NotificationDrawer />;
    case RightDrawerPaths.OVERLAPPING:
      return <OverlappingDrawer />;
    case RightDrawerPaths.QUICK_ACTION:
      return <QuickActionDrawer />;
    default:
      return null;
  }
};

const RightDrawerRouter: FC = ({ ...rest }) => {
  return (
    <RightDrawer {...rest}>
      <Suspense fallback={<div>Loading...</div>}>
        <PrivateDrawerRouter />
      </Suspense>
    </RightDrawer>
  );
};

export default RightDrawerRouter;
