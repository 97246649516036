export const ballInCourt = [
  {
    id: "Blank",
    value: "",
    label: "BLANK",
  },
  {
    id: "CMP",
    value: "CMP",
    label: "CMP",
  },
  {
    id: "Construction Services",
    value: "Construction Services",
    label: "Construction Services",
  },
  {
    id: "Cordoba",
    value: "Cordoba",
    label: "Cordoba",
  },
  {
    id: "E2",
    value: "E2",
    label: "E2",
  },
  {
    id: "ERO",
    value: "ERO",
    label: "ERO",
  },
  {
    id: "FiRM/Prime",
    value: "FiRM/Prime",
    label: "FiRM/Prime",
  },
  {
    id: "KP ENV Customer",
    value: "KP ENV Customer",
    label: "KP ENV Customer",
  },
  {
    id: "KP ENV Environmental Release",
    value: "KP ENV Environmental Release",
    label: "KP ENV Environmental Release",
  },
  {
    id: "KP ENV Permits",
    value: "KP ENV Permits",
    label: "KP ENV Permits",
  },
  {
    id: "Reliability",
    value: "Reliability",
    label: "Reliability",
  },
  {
    id: "TCM",
    value: "TCM",
    label: "TCM",
  },
  {
    id: "TED CMP",
    value: "TED CMP",
    label: "TED CMP",
  },
  {
    id: "Veg Management",
    value: "Veg Management",
    label: "Veg Management",
  },
  {
    id: "WiSE",
    value: "WiSE",
    label: "WiSE",
  },
  {
    id: "TED CMP",
    value: "TED CMP",
    label: "TED CMP",
  },
];

const priority = [
  {
    id: "emergency",
    value: "emergency",
    label: "Emergency",
  },
  {
    id: "priority",
    value: "priority",
    label: "Priority",
  },
  {
    id: "non-critical",
    value: "non-critical",
    label: "Non-critical",
  },
];

const replacement = [
  {
    id: "N",
    value: "N",
    label: "N",
  },
  {
    id: "Y",
    value: "Y",
    label: "Y",
  },
];

const status = [
  {
    id: "Descoped",
    value: "Descoped",
    label: "Descoped",
  },
  {
    id: "Grouping_Approval",
    value: "Grouping Approval",
    label: "Grouping Approval",
  },
  {
    id: "In_Design",
    value: "In-Design",
    label: "In-Design",
  },
  {
    id: "SAP_Initiation",
    value: "SAP Initiation",
    label: "SAP Initiation",
  },
  {
    id: "Customer_Info",
    value: "Customer Info",
    label: "Customer Info",
  },
  {
    id: "Assigned",
    value: "Assigned",
    label: "Assigned",
  },
  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
];

const additionalBallInCourt = [
  { id: "Reliability", value: "Reliability", label: "Reliability" },
  {
    id: "KP ENV Permits",
    value: "KP ENV Permits",
    label: "KP ENV Permits",
  },
  {
    id: "KP ENV Environmental Release",
    value: "KP ENV Environmental Release",
    label: "KP ENV Environmental Release",
  },
  {
    id: "WiSE",
    value: "WiSE",
    label: "WiSE",
  },
  { id: "Design Complete", value: "Design Complete", label: "Design Complete" },
];

const overlappingDrawerTitles: any = {
  circuit: "Circuit #",
  district: "District Name",
  issueIdentifiedDate: "Issue Identified Date",
  issueApprovedDate: "Issue Approved Date",
  conditionCodeId: "Condition Code ID",
  ballInCourt: "Ball in Court",
  groupName: "Group Name",
  issueStatus: "Status",
  priority: "Priority",
  poleReplacement: "Pole Replacement",
};

export const Constants = {
  ballInCourt,
  priority,
  replacement,
  status,
  additionalBallInCourt,
  overlappingDrawerTitles,
};
