import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { StyledPropsWithTheme } from "../../../../utils/globalTypes";

const HideOptionsWrapper = styled(Box)<StyledPropsWithTheme>`
  width: 300px;
  height: 400px;
  background: ${({ theme }) => theme.palette.tables?.main?.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  padding: 8px;
  font-size: 16px;
  ::-webkit-scrollbar {
    overflow-y: hidden;
    width: 5px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    opacity: 0.15;
  }
`;

const HideOptionsItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`;

export const Styled = { HideOptionsWrapper, HideOptionsItem };
