import { Styled } from "@sdge-pages/OverlappingPage/OverlappingPage.styled";
import {
  EnvPermitTileContent,
  InfoTileContent,
  IssueMainTable,
  Map,
  MuniPermitTileContent,
  ProgramsSummary,
  SapTileContent,
  Tile,
} from "@sdge-components";
import { Box } from "@material-ui/core";
import CustomerTileContent from "@sdge-components/CustomerTileContent";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getLayers } from "@sdge-store/Map/map.async";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { RightDrawerParentPaths } from "@sdge-utils/globalTypes";
import { handleRouterChange } from "@sdge-store/drawer/drawer.slice";
import { handleChangeRequested } from "@sdge-store/issueTracker/issueTracker.slice";
import Loader from "../../app/Router/Async/Loader";
import { ErrorBoundary } from "@sdge-pages/index";
import {
  getPackageSummary,
  getPoleSummary,
} from "@sdge-store/issueTracker/issueTracker.async";
import { usePermission } from "@sdge-hooks";

const IssueTracker = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { getPermission } = usePermission();
  const {
    createIssueLoading,
    createCustomerLoading,
    updateIssueLoading,
    activeIssueId,
    getPoleSumLoad,
    getPackageSumLoad,
    packageSummary,
    poleSummary,
    cardsLoading,
  } = useAppSelector((state) => state.issueTrackerSlice);
  const hasPermissions =
    (getPermission("add_tile_EnD") && activeIssueId) ||
    (getPermission("add_tile_ENVC") && activeIssueId) ||
    (getPermission("add_pol_customer") && activeIssueId) ||
    (getPermission("add_tile_DocCtrl") && activeIssueId);

  const isMap = pathname.split("/").includes("map");
  const packageSum = pathname.split("/").includes("summary_package");
  const poleSum = pathname.split("/").includes("summary_pole");
  const isENV = packageSum || poleSum;

  useEffect(() => {
    dispatch(getLayers());
    dispatch(
      handleRouterChange({
        parentPath: RightDrawerParentPaths.ISSUE_TRACKER_TABLE,
      })
    );
    dispatch(handleChangeRequested(false));
  }, []);

  useEffect(() => {
    packageSum && !packageSummary && dispatch(getPackageSummary());
    poleSum && !poleSummary && dispatch(getPoleSummary());
  }, [packageSum, poleSum]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    swipe: false,
    copy: true,
  };

  return (
    <Styled.OverlappingPageWrapper>
      {(createIssueLoading ||
        updateIssueLoading ||
        createCustomerLoading ||
        getPoleSumLoad ||
        getPackageSumLoad ||
        cardsLoading) && <Loader />}
      {isMap && <Map key="AIzaSyD3jpcQHDLYnj-ulGUJMtLiXIqKpEi2FNo" />}
      {isENV && (
        <ErrorBoundary>
          <ProgramsSummary issue />
        </ErrorBoundary>
      )}
      <IssueMainTable />
      <Box py="20px">
        <Styled.SliderWrapper>
          <Styled.TilesSlider {...settings}>
            <Tile title="Overlapping" isIssueTrackerPage>
              <InfoTileContent />
            </Tile>
            <Tile
              title="Environmental Permits"
              button={getPermission("add_tile_EnD") ? "" : "Add Env Permit"}
              isIssueTrackerPage
            >
              <EnvPermitTileContent />
            </Tile>
            <Tile
              title="Muni Permits"
              button={getPermission("add_tile_EnD") ? "" : "Add Muni Permit"}
              isIssueTrackerPage
            >
              <MuniPermitTileContent />
            </Tile>
            <Tile title="Sap" isIssueTrackerPage>
              <SapTileContent />
            </Tile>
            <Tile
              title="Customer"
              isIssueTrackerPage
              button={hasPermissions ? "Add Customer" : ""}
            >
              <CustomerTileContent issueTracker />
            </Tile>
          </Styled.TilesSlider>
        </Styled.SliderWrapper>
      </Box>
    </Styled.OverlappingPageWrapper>
  );
};

export default IssueTracker;
