import React from "react";
import { CircularProgress } from "@material-ui/core";

import { WithLoadingProps } from "./WithLoading.types";

import { Styled } from "./WithLoading.styled";

const WithLoading: React.FC<WithLoadingProps> = ({
  loading,
  children,
  size = 70,
}) =>
  loading ? (
    <Styled.LoadingWrapper>
      <CircularProgress size={size} disableShrink color="inherit" />
    </Styled.LoadingWrapper>
  ) : (
    <>{children}</>
  );

export default WithLoading;
