import React, { FC } from "react";
import { Styled } from "./SortingColumnButton.styled";
import { SortingColumnButtonType } from "./SortingColumnButton.type";

const SortingColumnButton: FC<SortingColumnButtonType> = ({
  title,
  icon: Icon,
  disabled,
  onClick,
}) => {
  return (
    <Styled.SortingButton
      title={title}
      icon={Icon}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default SortingColumnButton;
