import { CustomRoute, CustomRoutes } from "./routes.types";

export const flatten = (routes: CustomRoutes, basePath = "") => {
  return routes?.reduce((acc: CustomRoute[], routeObject: CustomRoute) => {
    acc.push({
      ...routeObject,
      ...{
        path: `${basePath}/${routeObject.path}`,
      },
    });
    if (routeObject.contains && routeObject.contains.length) {
      acc = acc.concat(
        flatten(routeObject.contains, `${basePath}/${routeObject.path}`)
      );
    }

    return acc;
  }, []);
};
